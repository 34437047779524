import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { heights } from "shared/styles/height";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    background: primaryColors.WHITE,
    border: "1px solid" + neutralColors.GRAY3,
    borderRadius: "8px",
    padding: "0px 16px",
    minHeight: heights.FIELD_HIGH,
    ...typography.BODY1,
    overflow: "auto",
    gap: "8px",
  },
  containerWithTags: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  placeholder: {
    pointerEvents: "none",
    position: "absolute",
    color: neutralColors.GRAY4,
  },
  tag: {
    display: "inline-block",
    borderRadius: "4px",
    padding: "5px 8px",
    $nest: {
      "&.valid": {
        border: "1px solid " + primaryColors.PURPLE2,
        color: primaryColors.PURPLE2,
      },
      "&.invalid": {
        border: "1px solid " + utilityColors.RED,
        color: utilityColors.RED,
      },
    },
  },
  deleteIcon: {
    display: "inline-block",
    marginLeft: "10px",
    height: "11px",
    cursor: "pointer",
  },
  input: {
    display: "inline-flex",
    flex: "1 1 fit-content",
    alignItems: "center",
    outline: "none",
    ...typography.BODY1,
  },
});
