import { neutralColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

export const sharedTableHeaderStyles = stylesheet({
  arrowIconSpacing: {
    marginLeft: "12px",
  },
  /**  TODO reuse occurences with IconButton */
  arrowIconButton: {
    borderRadius: "3px",
    padding: "8px",
    marginLeft: "8px",
  },
  arrowIcon: {
    height: "100%",
    width: "12px",
    color: neutralColors.GRAY5,
  },
});
