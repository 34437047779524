import { neutralColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    ...typography.BODY2,
  },
  label: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
  image: { marginRight: "12px" },
});

export default styles;
