import React from "react";
import { HashLink as RouterHashLink } from "react-router-hash-link";

import { Link as MuiLink, LinkProps as MuiLinkProps } from "@material-ui/core";
import { utilityColors } from "@pulsemarket/constants";
import { classes, style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

import styles from "./Link.styles";

type CustomLinkProps<C extends React.ElementType> = Omit<
  MuiLinkProps<C, { component?: C }>,
  "to" | "color"
>;

export type LinkProps<C extends React.ElementType> = CustomLinkProps<C> & {
  url: string | { pathname: string };
  disabled?: boolean;
  /** Color when active */
  color?: NestedCSSProperties["color"];
};

export default function Link({
  children,
  url,
  className,
  underline,
  color = utilityColors.ORANGE,
  ...rest
}: LinkProps<typeof RouterHashLink>) {
  return (
    <MuiLink
      underline={underline}
      component={RouterHashLink}
      to={url}
      className={classes(
        styles.link,
        rest.disabled && styles.disabled,
        color && !rest.disabled && style({ color }),
        className
      )}
      {...rest}
    >
      {children}
    </MuiLink>
  );
}
