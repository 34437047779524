import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  secondPageInputContainer: {
    display: "flex",
    margin: "12px",
    flexDirection: "column",
    backgroundColor: neutralColors.GRAY2,
    borderRadius: px(8),
    padding: px(24),
  },
  inputLabel: {
    ...typography.SUBTITLE2,
    paddingBottom: px(7),
    color: primaryColors.BLACK,
    borderBottom: px(1),
    marginBottom: px(32 - 7 - 1),
    borderBottomStyle: "solid",
    borderBottomColor: neutralColors.GRAY3,
  },
  rowItemsContainer: { display: "flex", flexDirection: "column", gap: px(25) },
  rowItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...typography.BODY2,
    flex: 2,
  },
  labelSecondPage: {
    ...typography.BODY2,
    color: primaryColors.BLACK,
    flex: 2,
  },
  span: {
    ...typography.BODY2,
    color: neutralColors.GRAY5,
    flex: 5,
  },
});
