export const primaryColors = {
  ERROR: "#E00000",
  TEXT_ACTIVE: "#384048",
  TEXT_HELPER: "#5E6368",
  TEXT_DISABLED: "#A2A3A4",
  BLACK: "#313131",
  PURPLE3: "#4F2542",
  PURPLE2_HOVER: "#5B3152",
  PURPLE2: "#69385E",
  PURPLE1: "#D3BCCF",
  HOVER: "#E7DFE5",
  ACTIVE: "#E1D5DF",
  WHITE: "#FFFFFF",
};

export const neutralColors = {
  GRAY5: "#6C757D",
  GRAY4: "#9FA2B4",
  GRAY3: "#CED4DA",
  GRAY3_ACTIVE: "#BEC5CC",
  GRAY3_HOVER: "#C7CDD3",
  GRAY2: "#E9ECEF",
  GRAY1: "#F1F3F5",
  GRAY0: "#F8F9FA",
};

export const utilityColors = {
  RED: "#D54857",
  RED_HOVER: "#BD3B49",
  RED_ACTIVE: "#BD3B49",
  RED_OUTLINE_HOVER: "#F3DEE2",
  RED_OUTLINE_FOCUSED: "#EFCDD1",
  ORANGE: "#FF5D45",
  ORANGE_HOVER: "#E2513C",
  ORANGE_LIGHT_HOVER: "#EDBDB6",
  ORANGE_ACTIVE: "#C64532",
  ORANGE_OUTLINED_HOVER: "#FFF0ED",
  ORANGE_OUTLINED_ACTIVE: "#FFEAE6",
  YELLOW: "#FCB831",
  YELLOW_HOVER: "#FADCA2",
  GREEN: "#63C26E",
  GREEN_OUTLINE_HOVER: "#E2F1E5",
  GREEN_OUTLINE_FOCUSED: "#D3EBD7",
  CYAN: "#92D3D7",
  CYAN_HOVER: "#CBE8EB",
  BLUE: "#2D9CDB",
  BLUE_LIGHT_HOVER: "#AED0E3",
  LILAC: "#A85B98",
  LILAS_LIGHT_HOVER: primaryColors.PURPLE1,
  TAG_SELECTED: "#EEDEEA",
};

export enum AppColors {
  Orange = "#FF5D45",
  Yellow = "#FCB831",
  Blue = "#2D9CDB",
  Red = "#D54857",
  Cyan = "#92D3D7",
  Lilac = "#A85B98",
  Purple = "#69385E",
  Green = "#63C26E",
  Gray = "#6C757D", // GRAY5
}

export const inputColors = {
  DEFAULT: neutralColors.GRAY4,
  HOVER: neutralColors.GRAY5,
  FOCUSED: utilityColors.LILAC,
  ERROR: utilityColors.RED,
  DISABLED: {
    BORDER: neutralColors.GRAY4,
  },
};

export const excelColors = {
  WHITE: "ffffff",
  BLACK: "000000",
  ORANGE: "ff5d45",
  GRAY: "f2f2f2",
  PURPLE: "69385e",
  GREEN: "e2efda",
  PEACH: "fdf0e7",
  TURQUOISE: "92d3d7",
};
