import { useQuery, UseQueryOptions } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { getDataRooms } from "shared/client";
import { DataRoomDto, DataRoomFilters } from "shared/model";

export default function useDataRooms<T = DataRoomDto[]>(
  options?: Omit<
    UseQueryOptions<
      DataRoomDto[],
      unknown,
      T,
      readonly ["dataRooms", DataRoomFilters | undefined]
    >,
    "enabled"
  > & { filters?: DataRoomFilters }
) {
  const { data: ownCompany } = useOwnCompany();

  return useQuery(
    queryKeys.dataRooms(options?.filters),
    () =>
      getDataRooms(
        ownCompany!.id,
        options?.filters
          ? {
              search: options.filters?.search,
              createdAfter:
                options.filters?.createdAfter?.toISOString() || null,
              createdBefore:
                options.filters?.createdBefore?.toISOString() || null,
            }
          : undefined
      ),
    {
      enabled: !!ownCompany?.id,
      ...options,
    }
  );
}
