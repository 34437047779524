import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { RfpResponseDto } from "@pulsemarket/api-client";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useUserData from "hooks/queries/useUserData";
import { createRFPResponse } from "shared/client";
import { DynamicRfpResponseStatus, NewRfpResponse } from "shared/model";
import generateAlmostUniqueId from "shared/utils/generateAlmostUniqueId";

export default function useCreateRFPresponse() {
  const client = useQueryClient();

  const { data: ownCompany } = useOwnCompany();
  const { data: userData } = useUserData();

  return useMutation(
    (rfpResponse: NewRfpResponse) => {
      return createRFPResponse(rfpResponse);
    },
    {
      mutationKey: mutationKeys.createRFPResponse,
      // When mutate is called:
      onMutate: async (rfpResponse: NewRfpResponse) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(queryKeys.rfpResponses(ownCompany!.id));
        // Snapshot the previous values
        const previousSearchResult = client.getQueryData<RfpResponseDto[]>(
          queryKeys.rfpResponses(ownCompany!.id)
        );

        // Optimistically update to the new value
        const newRFPRefponse: RfpResponseDto = {
          createdAt: new Date(),
          updatedAt: new Date(),
          companyId: ownCompany!.id,
          rfpId: rfpResponse.rfpId,
          id: generateAlmostUniqueId(),
          userId: userData!.id,
          answerIds: [],
          status: DynamicRfpResponseStatus.Draft,
          ownerName: rfpResponse.ownerName,
          ownerTitle: rfpResponse.ownerTitle,
          ownerEmail: rfpResponse.ownerEmail,
          folderId: generateAlmostUniqueId(),
          companyName: ownCompany!.name,
          rfpInvitationId: "", //TODO: Missing RFP invitaiton id. It may need for optimistic update
        };

        if (previousSearchResult) {
          client.setQueryData<RfpResponseDto[]>(
            queryKeys.rfpResponses(ownCompany!.id),
            [...previousSearchResult, newRFPRefponse]
          );
        }
        return { previousSearchResult };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousSearchResult) {
          client.setQueryData<RfpResponseDto[]>(
            queryKeys.rfpResponses(ownCompany!.id, ""),
            context.previousSearchResult
          );
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.rfpResponse(data?.id));
        client.invalidateQueries(queryKeys.rfpsByInvitation()[0]);
        client.invalidateQueries(queryKeys.rfpResponses(ownCompany!.id)[0]);
        client.invalidateQueries(queryKeys.rfpInvitation()[0]);
      },
    }
  );
}
