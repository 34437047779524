import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import { ROUTES } from "@pulsemarket/constants";
import FlowPopupContent from "components/FlowPopupContent";
import { Loading } from "components/FullScreenLoading";
import GroupDescription from "components/PulsePage/Groups/GroupDescription";
import GroupSettings from "components/PulsePage/Groups/GroupSettings";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import SvgIcon from "components/ui/SvgIcon";
import { useFormik } from "formik";
import useCreateGroup from "hooks/queries/useCreateGroup";
import useUserData from "hooks/queries/useUserData";
import { getZones, uploadImage } from "shared/client";
import { DataRoomDto, GroupDetailsDto, NewGroup } from "shared/model";
import { BlobType } from "shared/model";

import styles from "./CreateGroupModal.styles";

export type GroupData = {
  name: string;
  description: string;
  zones: string[]; // up to 3 zones picked from dropdown
  datarooms: Pick<DataRoomDto, "id" | "name">[];
  avatar: string;
  backgroundImage: string; // upload img from pc to cloud, then save id
  isPrivate: boolean;
  isVisible: boolean;
  allowMemberInvitations: boolean;
  onlyAdminCanCreateContent: boolean;
  postsRequireApproval: boolean;
  filesRequireApproval: boolean;
};

export interface CreateGroupProps {
  onSuccess: (group: GroupDetailsDto) => void;
  onCancel: () => void;
}

export default function CreateGroup({
  onSuccess,
  onCancel = () => {},
}: CreateGroupProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: createGroup } = useCreateGroup();
  const { data: userData } = useUserData();
  const [step, setStep] = useState(0);
  const history = useHistory();
  const { data: zones } = useQuery(queryKeys.zones, () => getZones(), {
    placeholderData: [],
  });

  const [avatar, setAvatar] = useState<File>();
  const [backgroundImage, setBackgroundImage] = useState<File>();

  const formik = useFormik<GroupData>({
    initialValues: {
      name: "",
      description: "",
      zones: [],
      datarooms: [],
      avatar: "",
      backgroundImage: "",
      isPrivate: true,
      isVisible: false,
      allowMemberInvitations: false,
      onlyAdminCanCreateContent: true,
      postsRequireApproval: false,
      filesRequireApproval: false,
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const newGroup: NewGroup = {
          ...values,
          adminId: userData!.companyId,
        };
        const groupData = await createGroup(newGroup);

        if (avatar) {
          await uploadImage({
            file: avatar,
            uploadImageProps: {
              blobType: BlobType.GroupAvatar,
              groupId: groupData.id,
            },
          });
        }
        if (backgroundImage) {
          await uploadImage({
            file: backgroundImage,
            uploadImageProps: {
              groupId: groupData.id,
              blobType: BlobType.GroupBackGroundImage,
            },
          });
        }
        onSuccess(groupData);
        history.push(ROUTES.GROUP(groupData.id));
      } catch (err) {
        console.error(err);
      } finally {
        formik.setSubmitting(false);
      }
      return;
    },
  });
  return (
    // To-Do, styles do not match 100% design
    <div className={styles.CreateGroup}>
      <FlowPopupContent step={step}>
        <SimpleModalBody
          key="0"
          title={<Title />}
          onClose={onCancel}
          buttons={[
            {
              variant: "normal",
              color: "orange",
              disabled:
                formik.isSubmitting ||
                formik.values.name === "" ||
                formik.values.name.length > 100 ||
                formik.values.description.length > 2000 ||
                formik.values.zones.length === 0,
              disableCloseAfterCallback: true,
              onClick: () => setStep(1),
              children: "Next",
            },
          ]}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <GroupDescription
              avatar={formik.values.avatar}
              onAvatarChange={(img, file) => {
                formik.setFieldValue("avatar", img);
                setAvatar(file);
              }}
              backgroundImage={formik.values.backgroundImage}
              onBackgroundChange={(img, file) => {
                formik.setFieldValue("backgroundImage", img);
                setBackgroundImage(file);
              }}
              name={formik.values.name}
              onNameChange={formik.handleChange("name")}
              description={formik.values.description}
              onDescriptionChange={formik.handleChange("description")}
              zoneOptions={zones || []}
              zones={formik.values.zones}
              onZonesChange={(ids) => formik.setFieldValue("zones", ids)}
            />
          )}
        </SimpleModalBody>
        <SimpleModalBody
          title={
            <div className={styles.titleContainer}>
              <SvgIcon
                src="/icons/back_icon.svg"
                className={styles.svg}
                onClick={() => setStep(0)}
              />
              <Title />
            </div>
          }
          key="1"
          buttons={[
            {
              style: { margin: "40px auto" },
              variant: "normal",
              color: "orange",
              disabled:
                formik.isSubmitting ||
                formik.values.name === "" ||
                formik.values.zones.length === 0,
              disableCloseAfterCallback: true,
              onClick: () => formik.handleSubmit(),
              children: "Create Group",
            },
          ]}
        >
          <GroupSettings
            values={formik.values}
            onChange={(value, field) => {
              formik.setFieldValue(field, value);
            }}
          />
        </SimpleModalBody>
      </FlowPopupContent>
    </div>
  );
}

function Title() {
  return <p className={styles.title}>Create group</p>;
}
