export enum AttributeModelType {
  SupplierRecord = "SupplierRecord",
  SupplierProduct = "SupplierProduct",
  Company = "Company",
}

export enum AttributeValueType {
  Text = "Text",
  Numeric = "Numeric",
  Date = "Date",
  SingleChoice = "SingleChoice",
  MultiChoice = "MultiChoice",
}
