import { useCallback } from "react";
import { UseQueryOptions } from "react-query";

import { AssetDto, FolderDto } from "@pulsemarket/api-client";
import {
  AssetRepositoryItem,
  FolderAPIItem,
  ListItem,
} from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import {
  AssetColor,
  AssetIconSource,
} from "components/AssetRepository/constants";
import { ListItemType } from "components/AssetRepository/types";
import dayjs from "dayjs";
import useSearchAssets from "hooks/queries/useSearchAssets";
import { getIconForFile } from "shared/constants/icons";
import { AssetSearchOptions, FolderItemDto } from "shared/model";
import { rfpDateFormat, updateDateFomat } from "shared/utils/time";

// ! These functions should only be called on elements that the API responded with,
// ! But not after storing and modifying them in any way, as they should have a listItemType field by then
export function isAsset(
  item: FolderAPIItem | AssetRepositoryItem
): item is AssetDto {
  return !!(item as AssetDto).mimeType;
}

export function isFolder(
  item: FolderAPIItem | AssetRepositoryItem
): item is FolderDto {
  return !(item as AssetDto).mimeType;
}

export function isApiItem(
  item: FolderAPIItem | AssetRepositoryItem
): item is FolderAPIItem {
  return !(item as ListItem).listItemType;
}

export function getCreateDateForApiItems(asset: FolderAPIItem): Date {
  if (isAsset(asset)) {
    return asset.createdAt;
  }
  return asset.createdAt;
}
export function getUpdateDateForApiItems(asset: FolderAPIItem): Date {
  if (isAsset(asset)) {
    return asset.updatedAt;
  }

  return asset.updatedAt;
}

const transformData = (asset: FolderAPIItem): AssetRepositoryItem => {
  const createdDate = getCreateDateForApiItems(asset);
  const updatedDate = getUpdateDateForApiItems(asset);
  const dataIsAsset = isAsset(asset);
  const listItemType = dataIsAsset ? ListItemType.File : ListItemType.Folder;

  return {
    ...asset,
    name: dataIsAsset ? asset.name : asset.name,
    createdDate: dayjs(createdDate).format(rfpDateFormat),
    updatedDate: dayjs(updatedDate).format(updateDateFomat),
    listItemType,
    selected: false,
    color: !dataIsAsset ? AssetColor["Folder"] : getIconForFile(asset).color,
    icon: !dataIsAsset ? AssetIconSource["Folder"] : getIconForFile(asset).src,
  };
};

export default function useAssetItems(
  searchOptions: AssetSearchOptions,
  options?: Omit<
    UseQueryOptions<
      FolderItemDto[],
      unknown,
      AssetRepositoryItem[],
      readonly ["assetRepositorySearch", AssetSearchOptions]
    >,
    "select" | "notifyOnChangeProps"
  > & {
    companyId?: string;
  }
) {
  const itemsQuery = useSearchAssets<AssetRepositoryItem[]>(searchOptions, {
    notifyOnChangeProps: "tracked",
    // https://tkdodo.eu/blog/react-query-render-optimizations#notifyonchangeprops
    select: useCallback((data) => data.map(transformData), []),
    ...options,
  });

  return itemsQuery;
}
