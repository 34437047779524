import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { ButtonForModalWithSelectableItems } from "components/assets/RepositoryModal";
import Button from "components/ui/Button";
import { useListContext } from "contexts/List/ListProvider";
import { classes, style } from "typestyle";

import styles from "./PageLayoutWithSidebarAndActions.styles";

type PageLayoutWithSidebarAndActionsProps<T> = {
  children?: React.ReactNode;
  sidebar?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  /** Use for views that are openable in a Modal (eg. Asset Repository). Must be wrapped with a ListContext. */
  actions?: ButtonForModalWithSelectableItems<T>[];
  className?: string;
  childClasses?: {
    sideBar?: string;
    mainContent?: string;
  };
};

/* todo reuse on
  - pulse page
  - rfp
  - company account
*/

export default function PageLayoutWithSidebarAndActions<T>({
  children,
  sidebar,
  header,
  footer,
  actions,
  className,
  childClasses,
}: PageLayoutWithSidebarAndActionsProps<T>) {
  const hasActions = actions && !!actions.length;
  const { listData } = useListContext<T & { selected?: boolean }>();

  const selectedItems = useMemo(
    () => listData.filter((item) => item.selected),
    [listData]
  );

  return (
    <Grid
      container
      direction="column"
      className={classes(
        styles.container,
        hasActions &&
          style({
            paddingBottom: "50px",
          }),
        className
      )}
      wrap="nowrap"
    >
      <Grid
        item
        container
        direction="row"
        spacing={2}
        className={style({
          height: !hasActions ? "100%" : "calc(100% - 25px)",
        })}
      >
        {sidebar && (
          <Grid
            item
            container
            xs={2}
            className={classes(styles.sideBarContainer, childClasses?.sideBar)}
          >
            {sidebar}
          </Grid>
        )}
        <Grid
          item
          xs={sidebar ? 10 : 12}
          container
          direction="column"
          className={styles.rightSide}
        >
          {header && <Grid item>{header}</Grid>}
          <Grid item className={styles.mainContentContainer}>
            <div
              className={classes(styles.mainContent, childClasses?.mainContent)}
            >
              {children}
            </div>
          </Grid>
          {footer && <Grid item>{footer}</Grid>}
        </Grid>
      </Grid>
      {hasActions ? (
        <Grid item container justifyContent="flex-end" spacing={2}>
          {actions.map(
            ({ onClick, disabled, disableIfNoItemsSelected, ...action }) => {
              return (
                <Grid item key={action.text}>
                  {/* @ts-expect-error TS 5 upgrade todo */}
                  <Button
                    onClick={() => {
                      onClick(selectedItems);
                    }}
                    disabled={
                      (disableIfNoItemsSelected && !selectedItems.length) ||
                      disabled
                    }
                    {...action}
                  >
                    {action.text}
                  </Button>
                </Grid>
              );
            }
          )}
        </Grid>
      ) : null}
    </Grid>
  );
}
