import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { px } from "csx";
import { stylesheet } from "typestyle";

export const dividerSpace = px(20);

const styles = stylesheet({
  CreateList: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "stretch",
    width: px(400),
  },
  contentWrapper: {
    margin: "32px",
  },
  companyNameText: {
    color: primaryColors.PURPLE3,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    marginTop: "25px",
  },
  createListButton: {
    width: "100%",
    backgroundColor: utilityColors.ORANGE,
    padding: "0px 40px",
  },

  buttonLabel: {
    margin: `${px(0)} ${px(8)}`,
  },
  createButtonLabel: {
    color: primaryColors.WHITE,
  },
});

export default styles;
