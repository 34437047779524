import React, { useEffect, useRef } from "react";

import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { classes, stylesheet } from "typestyle";

export type SwitchComponentProps = {
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

const ANIM_DURATION = "100ms";

const styles = stylesheet({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    color: neutralColors.GRAY4,
    marginLeft: "8px",
    ...typography.BODY2,
  },
  svg: {
    cursor: "pointer",
  },
  svgDisabled: {
    cursor: "default",
  },
});

const activeColor = primaryColors.PURPLE2;
const disabledColor = neutralColors.GRAY2;

const Switch = ({
  value,
  onChange,
  disabled = false,
}: SwitchComponentProps) => {
  const svgId = useRef(Date.now() + Math.floor(Math.random() * 100));
  const defaultValue = useRef(value);

  const background = useRef<SVGAnimationElement>(null);
  const circle = useRef<SVGAnimationElement>(null);

  useEffect(() => {
    background.current?.beginElement();
    circle.current?.beginElement();
  }, [value]);

  return (
    <div className={styles.wrapper}>
      <svg
        className={classes(styles.svg, disabled && styles.svgDisabled)}
        aria-disabled={disabled}
        onClick={() => {
          if (disabled) {
            return;
          }
          background.current?.beginElement();
          circle.current?.beginElement();

          onChange(!value);
        }}
        width="42"
        height="25"
        viewBox="0 0 42 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          id={svgId.current + "rect"}
          width="42"
          height="24.5"
          rx="12.25"
          fill={
            disabled
              ? disabledColor
              : defaultValue.current
              ? activeColor
              : neutralColors.GRAY1
          }
        />

        <circle
          id={svgId.current + "circle"}
          cx={value ? "29.75" : "11.75"}
          cy="12.25"
          r="8.75"
          fill="white"
        />

        <animate
          ref={background}
          xlinkHref={"#" + svgId.current + "rect"}
          attributeName="fill"
          from={!value ? activeColor : neutralColors.GRAY1}
          to={
            value
              ? disabled
                ? disabledColor
                : activeColor
              : neutralColors.GRAY1
          }
          dur={ANIM_DURATION}
          fill="freeze"
          begin="indefinite"
        />

        <animate
          ref={circle}
          xlinkHref={"#" + svgId.current + "circle"}
          attributeName="cx"
          from={!value ? "29.75" : "11.75"}
          to={value ? "29.75" : "11.75"}
          dur={ANIM_DURATION}
          begin="indefinite"
        />
      </svg>

      <div className={styles.text}>{value ? "On" : "Off"}</div>
    </div>
  );
};

export default Switch;
