import IconButton from "components/ui/IconButton";
import insertedImageStyles from "components/ui/InsertedImage/InsertedImage.styles";
import YouTubeVideo, { YouTubeVideoProps } from "components/ui/YouTubeVideo";
import useYouTubeData from "hooks/useYouTubeData";
import { DeletableComponentProps } from "shared/utils/componentTypes";
import { getYouTubeVideoReadableDuration } from "shared/utils/time";
import { classes, stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    position: "relative",
  },
  frame: {
    position: "relative",
    zIndex: 0,
  },
  iframe: {
    height: "242px",
  },
  deleteIconWrapper: {
    bottom: "42px",
  },
  editIconWrapper: {
    bottom: "42px",
    right: "48px",
  },
});

export default function EditableYouTubeVideo({
  onChange,
  disableDelete,
  onDelete,
  disableEdit,
  onEdit,
  ...props
}: DeletableComponentProps &
  YouTubeVideoProps & { disableEdit?: boolean; onEdit?: () => void }) {
  const { data: youTubeData } = useYouTubeData(props.item.reference);

  return (
    <div className={styles.root}>
      <YouTubeVideo
        {...props}
        className={classes(styles.frame, props.className)}
        iframeClassName={styles.iframe}
        item={{
          ...props.item,
          length:
            youTubeData?.duration &&
            getYouTubeVideoReadableDuration(youTubeData.duration),
        }}
      />
      {!disableEdit && (
        <div
          className={classes(
            insertedImageStyles.deleteIconWrapper,
            styles.editIconWrapper
          )}
        >
          <IconButton
            tooltip="Edit"
            color="orange"
            src="/icons/edit_icon.svg"
            iconClassname={insertedImageStyles.deleteIcon}
            onClick={onEdit}
          />
        </div>
      )}
      {!disableDelete && (
        <div
          className={classes(
            insertedImageStyles.deleteIconWrapper,
            styles.deleteIconWrapper
          )}
        >
          <IconButton
            tooltip="Delete"
            color="orange"
            src="/icons/delete_icon.svg"
            iconClassname={insertedImageStyles.deleteIcon}
            onClick={onDelete}
          />
        </div>
      )}
    </div>
  );
}
