import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { zeroPaddingMargin } from "shared/styles/classes";
import { stylesheet } from "typestyle";

import { typography } from "../../shared/styles/typography";

const containerBox = {
  backgroundColor: primaryColors.WHITE,
  display: "flex",
  width: "100%",
  height: "100%",
  borderRadius: "8px",
};

const sharedStyles = stylesheet({
  caption: {
    ...typography.BODY2,
    ...zeroPaddingMargin,
    color: neutralColors.GRAY5,
    marginBottom: "24px",
    paddingRight: "20%",
  },
  subtitle: {
    ...zeroPaddingMargin,
    ...typography.SUBTITLE2,
    marginBottom: "24px",
    paddingRight: "20%",
  },
  buttonContainer: {
    marginTop: "auto",

    height: "33px",
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    position: "relative",
    width: "100%",
    gap: "20px",
  },
});

export const buttonStyles = stylesheet({
  containerBox: {
    ...containerBox,
    flexDirection: "column",
    position: "relative",
    padding: "42px",
  },
  imageAndDescription: {
    ...containerBox,
    flexDirection: "row",
  },
  content: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    flexDirection: "column",
    paddingLeft: "23px",
    flex: 4,
  },
  title: {
    ...zeroPaddingMargin,
    ...typography.HEADLINE6,
    color: primaryColors.PURPLE2,
    marginBottom: "24px",
  },
  buttonsContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  illustrationContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "80%",
  },
});

export const linkStyles = stylesheet({
  imageAndDescription: {
    ...containerBox,
    flexDirection: "row",
  },
  containerBox: {
    ...containerBox,
    flexDirection: "column",
    position: "relative",
    padding: "42px",
  },
  illustrationContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "30px",
    maxHeight: "80%",
  },
  illustration: {
    width: "80%",
    maxWidth: "250px",
  },
  content: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    flex: 4,
  },
  title: {
    ...zeroPaddingMargin,
    ...typography.HEADLINE6,
    color: primaryColors.PURPLE2,
    marginBottom: "24px",
  },
});

export default sharedStyles;
