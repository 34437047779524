import React, { useState } from "react";

import Header from "layout/Header";
import SideMenu from "layout/SideMenu";
import { classes } from "typestyle";

import styles, { sideMenuClass } from "./Layout.styles";

type LayoutProps = {
  children: React.ReactNode;
};

function Layout(props: LayoutProps) {
  const [isSideMenuOpen, setSideMenuOpen] = useState<boolean>(false);

  const toggleSideMenuOpen = () => {
    setSideMenuOpen((prevState) => !prevState);
  };

  const closeSideMenu = () => {
    setSideMenuOpen(() => false);
  };

  return (
    <div className={styles.sideMenuOverlay}>
      {isSideMenuOpen && (
        <div className={styles.backgroundFilter} onClick={closeSideMenu} />
      )}
      <div className={sideMenuClass(isSideMenuOpen)}>
        <SideMenu
          isOpen={isSideMenuOpen}
          toggleMenu={toggleSideMenuOpen}
          closeMenu={closeSideMenu}
        />
      </div>
      <div
        className={classes(
          styles.layoutContent,
          !isSideMenuOpen && styles.layoutContentFull
        )}
      >
        <div className={styles.pageContent}>
          <Header className={styles.header} />
          <div className={styles.page}>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
