import { GroupDetailsDto } from "shared/model";

import { GroupAccessContext } from "./types";

export const GROUP_TAB_URLS = {
  posts: "#posts",
  files: "#files",
  allFiles: "#files-all",
  videos: "#files-videos",
  images: "#files-images",
  documents: "#files-documents",
};

type GetGroupContentStateParams = {
  group?: GroupDetailsDto;
  companyId?: string;
  email?: string;
  isOwner?: boolean;
  isMember?: boolean;
};

export function getGroupContentState({
  group,
  companyId,
  email,
  isOwner,
  isMember,
}: GetGroupContentStateParams): GroupAccessContext {
  const isJoinRequestSent =
    !!companyId &&
    group?.requestedAccess
      .map((m) => {
        return m.invitedCompany?.id;
      })
      .includes(companyId);

  const invitationForCompany = group?.invitations.find(
    (invitation) => invitation.invitedCompany?.id === companyId
  );

  const invitationForUser = group?.invitations.find(
    (invitation) => !!email && invitation.invitedEmail === email
  );

  const isInvited = !!invitationForCompany || !!invitationForUser;

  if (isOwner) {
    return GroupAccessContext.Own;
  }

  if (group?.isPrivate) {
    if (!isMember && !isJoinRequestSent && !isInvited) {
      return GroupAccessContext.PrivateJoinable;
    }

    if (isMember) {
      return GroupAccessContext.PrivateJoined;
    }

    if (isJoinRequestSent) {
      return GroupAccessContext.PrivateJoinRequestSent;
    }

    if (isInvited) {
      return GroupAccessContext.PrivateInvited;
    }
  } else {
    if (isMember) {
      return GroupAccessContext.PublicJoined;
    }
    return GroupAccessContext.PublicJoinable;
  }
  return GroupAccessContext.PublicJoinable;
}
