import { useMemo } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import { useGetAuthToken } from "hooks/queries/useAuth";
import { ApiError, getUser } from "shared/client";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";
import { UserDto } from "shared/model";

type BaseUser = Partial<Pick<UserDto, "companyId" | "id">>;

const getDefaultUser = (
  baseParams?: BaseUser
): UserDto & { isPlaceholder?: boolean } => ({
  id: baseParams?.id || "",
  companyId: baseParams?.companyId || "",
  email: "",
  firstName: "",
  lastName: "",
  jobTitle: "",
  payments: {},
  seenWelcomePopup: true,
  emailNewRFP: true,
  emailRFPResponse: true,
  emailGroupInvitation: true,
  emailGroupUpdates: true,
  isPlaceholder: true,
});

export default function useUserData() {
  const { data: tokenData } = useGetAuthToken();
  const history = useHistory();
  const localCompanyId = tokenData?.companyId || "";
  const localAccountId = tokenData?.userId || "";
  const placeholderData = useMemo(
    () =>
      getDefaultUser({
        id: localAccountId,
        companyId: localCompanyId,
      }),
    [localAccountId, localCompanyId]
  );

  return useQuery(
    queryKeys.user,
    async () => {
      const user = (await getUser(localAccountId)) as UserDto & {
        isPlaceholder?: boolean;
      };
      return user;
    },
    {
      placeholderData,
      enabled: !!localAccountId,
      ...longCachedQueryOptions,
      notifyOnChangeProps: "tracked",
      retry: (retryCount, error) => {
        if (error instanceof ApiError) {
          if (error.status === 404) {
            return false;
          }
        }

        if (retryCount > 3) {
          return false;
        }

        return true;
      },
      onError: (error) => {
        if (error instanceof ApiError) {
          if (error.status === 404) {
            if (history.location.pathname === "/") {
              return;
            }
            return history.replace("/" + history.location.search);
          }
        }
      },
      onSuccess: (user) => {
        if (!!localCompanyId) {
          return;
        }
        localStorage.setItem(ACTING_COMPANY_ID, user.companyId);
      },
    }
  );
}
