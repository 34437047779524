import getVideoId from "get-video-id";

export const emailValidatorFn = (str: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    str
  );

export const nameValidatorFn = (str: string) =>
  /^[A-Za-zŽžÀ-ÿ0-9 -]*$/.test(str);

export function formikErrorIfEmpty(value: string | undefined) {
  return !value ? "" : undefined;
}

export function validateYouTubeUrl(youtubeUrl?: string): boolean {
  if (youtubeUrl === undefined || youtubeUrl === "") {
    return true;
  }
  return getVideoId(youtubeUrl).service === "youtube";
}

export function validatePasswordStrength(password: string) {
  // Check for minimum 8 characters
  if (password.length < 8)
    return "Password must be at least 8 characters long.";

  // Check for at least 2 numbers
  const numMatches = password.match(/\d/g);
  if (numMatches === null || numMatches.length < 2)
    return "Password must contain at least 2 numbers.";

  // Check for at least 2 letters
  const letterMatches = password.match(/[a-zA-Z]/g);
  if (letterMatches === null || letterMatches.length < 2)
    return "Password must contain at least 2 letters.";
}
