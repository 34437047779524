import Button from "components/ui/Button";
import { filesize } from "filesize";
import { classes } from "typestyle";

import styles from "./FileUploadingWindow.styles";
import IconDisplay from "./IconDisplay";

export enum PromiseState {
  Pending = "pending",
  Resolved = "resolved",
  Rejected = "rejected",
}
export type CustomFileUploaderHeaderText = {
  [state in PromiseState]?: string;
};

export type FileUploadingWindowProps = {
  fileName: string; // todo it should be able to list multiple files
  /** Default 1. Should be derived from `fileNames` prop when implemented */
  fileCount?: number;
  size: number;
  destination?: string;
  status: PromiseState;
  errors?: string[];
  customHeaderText?: CustomFileUploaderHeaderText;
  onClose?: () => void;
};

function FileUploadingWindow({
  fileName,
  size,
  fileCount = 1,
  destination = "My files",
  status,
  errors = [],
  customHeaderText,
  onClose,
}: FileUploadingWindowProps) {
  const headerText =
    customHeaderText && customHeaderText[status]
      ? customHeaderText[status]
      : (() => {
          switch (status) {
            case "pending":
              return `Uploading ${fileCount} item${
                fileCount > 1 ? "s" : ""
              } to ${destination}`;
            case "resolved":
              return `Uploaded ${fileCount} item${
                fileCount > 1 ? "s" : ""
              } to ${destination}`;
            default:
              return `Error uploading ${fileName}:`;
          }
        })();

  const StatusDisplay = () => {
    switch (status) {
      case "pending" || "resolved":
        return (
          <>
            <p className={styles.text}>{fileName}</p>
            <p className={styles.text}>{filesize(size)}</p>
          </>
        );
      default:
        return (
          <>
            {errors.map((error, index) => (
              <span
                key={index}
                className={classes(styles.text, styles.errorText)}
              >
                {error}
              </span>
            ))}
          </>
        );
    }
  };
  return (
    <>
      <div className={styles.container}>
        <IconDisplay status={status} />
        <div className={styles.textContainer}>
          <p className={styles.headerText}>{headerText}</p>
          <StatusDisplay />
        </div>
      </div>

      {status !== "pending" && (
        <div className={styles.buttonContainer}>
          <Button color="orange" variant="normal" onClick={onClose}>
            OK, Got it!
          </Button>
        </div>
      )}
    </>
  );
}

export default FileUploadingWindow;
