import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  iconContainer: {
    position: "relative",
    display: "flex",
    width: "120px",
    height: "120px",
    margin: "36px",
    border: `1px solid ${neutralColors.GRAY4}`,
    borderRadius: "8px",
    background: primaryColors.WHITE,
    justifyContent: "center",
    alignItems: "center",
  },
  statusIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48px",
    height: "48px",
    borderRadius: "8px",
    background: neutralColors.GRAY1,
    border: `1px solid ${neutralColors.GRAY3}`,
    marginLeft: "25px",
    flexShrink: 0,
  },
  statusIcon: {
    padding: 0,
    width: "26px",
    height: "26px",
  },
  mainIcon: {
    maxWidth: "60px",
  },
  rotate: {
    animation: "rotation 5s infinite linear",
  },
});

export default styles;
