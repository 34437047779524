import { px } from "csx";
import { hiddenScrollbarStyles } from "shared/styles/styles";
import { stylesheet } from "typestyle";

export const styles = stylesheet({
  postsContainer: {
    width: "100%",
    gap: px(30),
    display: "flex",
    flexDirection: "column",
    maxHeight: px(600),
    ...hiddenScrollbarStyles,
  },
});
