import { DialogProps } from "@material-ui/core";
import { primaryColors } from "@pulsemarket/constants";
import { CustomizableDialogProps } from "components/ui/Modal/Modal";

export const uncloseableDialogSettings: (size?: DialogProps["maxWidth"]) => {
  props: CustomizableDialogProps;
} = (size) => ({
  props: {
    disableEscapeKeyDown: true,
    disableBackdropClick: true,
    maxWidth: size,
    PaperProps: {
      style: {
        backgroundColor: primaryColors.WHITE,
      },
    },
  },
});
