import React, { Suspense, useCallback, useContext, useState } from "react";
import { useQueryClient } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { Loading } from "components/FullScreenLoading";
import TagInputBox from "components/TagInputBox";
import Button from "components/ui/Button";
import MultiSelectList, {
  MultiSelectListProps,
} from "components/ui/MultiSelectList";
import SubSelect from "components/ui/SubSelect";
import SvgIcon from "components/ui/SvgIcon";
import ModalContext from "contexts/ModalContext";
import useGetMySuppliers from "hooks/queries/useMySuppliers";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { getCompanies } from "shared/client";
import {
  CombinedInvitationRecipientInput,
  CombinedInvitationRecipientType,
} from "shared/model";
import { emailValidatorFn } from "shared/validators";
import { classes, style } from "typestyle";
import lazyRetry from "util/lazyRetry";

import styles, { recipientTagStyles } from "./CombinedInvitationSender.styles";

const VendorSearchModal = React.lazy(() =>
  lazyRetry(
    () => import("components/VendorSearch/VendorSearchModal"),
    "VendorSearchModal"
  )
);

/** todo move to shared file */
export type InvitationsByType = {
  emails: string[];
  companies: string[];
  suppliers: string[];
};

type CombinedInvitationSenderProps = {
  title: string;
  subtitle?: string;
  onInvite: (params: {
    recipients: CombinedInvitationRecipientInput[];
  }) => Promise<void>;
  /** Used to block already sent invitations. None is blocked if empty. */
  existingInvitations?: InvitationsByType;
  /** Appears in the individual lists when the invitation already exists. */
  disabledReason?: string;
  className?: string;
  confirmationModal?: React.ReactNode;
};

export default function CombinedInvitationSender({
  title,
  subtitle,
  className,
  onInvite,
  existingInvitations: invitations,
  disabledReason,
  confirmationModal,
}: CombinedInvitationSenderProps) {
  const client = useQueryClient();
  const { openModal, closeModal } = useContext(ModalContext);

  const [isSending, setIsSending] = useState<boolean>(false);

  // todo this can't show all suppliers, we need a multiselect component that can paginate
  const { data: suppliersData } = useGetMySuppliers({ limit: 100, offset: 0 });
  const { data: ownCompany } = useOwnCompany();

  const [recipients, setRecipients] = useState<
    CombinedInvitationRecipientInput[]
  >([]);

  const [newCheckedSupplierIds, setNewCheckedSupplierIds] = useState<string[]>(
    []
  );

  const invitedSupplierIds = recipients
    .filter(
      (recipient) => recipient.type === CombinedInvitationRecipientType.Supplier
    )
    .map((recipient) => recipient.invitee);
  const selectedSupplierIds = invitedSupplierIds.concat(newCheckedSupplierIds);

  const addUniqueRecipients = useCallback(
    async (addedRecipients: CombinedInvitationRecipientInput[]) => {
      setRecipients((oldRecipients) => {
        const uniqueRecipients = [...oldRecipients, ...addedRecipients].filter(
          (v, i, a) => a.findIndex((v2) => v2.invitee === v.invitee) === i
        );

        return uniqueRecipients;
      });
    },
    []
  );

  const removeRecipient = useCallback(
    async (removedRecipient: CombinedInvitationRecipientInput) => {
      setRecipients((recipients) =>
        recipients.filter(
          (recipient) => recipient.invitee !== removedRecipient.invitee
        )
      );

      if (removedRecipient.type === CombinedInvitationRecipientType.Supplier) {
        setNewCheckedSupplierIds((ids) =>
          ids.filter((id) => id !== removedRecipient.invitee)
        );
      }
    },
    []
  );

  const getSubOptions = useCallback<
    (companyListId: string) => Promise<MultiSelectListProps["options"]>
  >(
    async (companyListId) => {
      return (
        await client.fetchQuery(queryKeys.companies({ companyListId }), () =>
          getCompanies({ companyListId })
        )
      ).map((item) => ({
        id: item.id,
        name: item.name,
        avatar: { src: item.avatar || "/icons/company_account.svg" },
        disabled: (id) => invitations?.companies.includes(id),
      }));
    },
    [client, invitations?.companies]
  );

  const hasDuplicateEmails = recipients.some((recipient) => {
    if (recipient.type === "Email") {
      return invitations?.emails.includes(recipient.invitee);
    }
    return false;
  });

  return (
    <div className={classes(styles.combinedInvitationSender, className)}>
      <h4 className={styles.title}>{title}</h4>
      {subtitle && <h5 className={styles.subtitle}>{subtitle}</h5>}
      <div className={styles.inputContainer}>
        <TagInputBox
          className={styles.emailInput}
          placeholder="Email addresses semicolon separated;"
          separators={[";"]}
          validatorFn={emailValidatorFn}
          // tags are rendered outside
          tags={[]}
          onRemove={(tag) => {
            removeRecipient({
              invitee: tag,
              type: CombinedInvitationRecipientType.Email,
            });
          }}
          onAdd={(newTags) =>
            addUniqueRecipients(
              recipients.concat(
                newTags.filter(emailValidatorFn).map((id) => {
                  return {
                    type: CombinedInvitationRecipientType.Email,
                    invitee: id,
                  };
                })
              )
            )
          }
        />

        <Button
          variant="normal"
          color="orange"
          size="small"
          className={styles.button}
          disabled={!recipients.length || hasDuplicateEmails}
          isLoading={isSending}
          onClick={async () => {
            try {
              setIsSending(true);
              await onInvite({ recipients });
              setRecipients([]);
              if (confirmationModal) {
                openModal(confirmationModal, { keepHistory: true });
              }
            } catch (err) {
              console.log(err);
            } finally {
              setIsSending(false);
            }
          }}
        >
          Send invite
        </Button>
      </div>
      <div className={styles.companyAndSupplierContainer}>
        <div className={styles.supplierSelect}>
          {ownCompany?.companyLists && (
            <SubSelect
              className={styles.supplierSelect}
              options={ownCompany.companyLists.map((list) => ({
                name: list.name,
                id: list.id,
              }))}
              placeholder="Select from your Lists"
              multiSelectListProps={{
                getSubOptions,
                asPopover: true,
                disabledReason: disabledReason,
                emptyContent: (
                  <p>No companies were found in the selected list.</p>
                ),
              }}
              onSelect={(ids) => {
                const newRecipients = recipients.concat(
                  ids.map((i) => {
                    return {
                      invitee: i.id,
                      type: CombinedInvitationRecipientType.Company,
                      name: i.name,
                    };
                  })
                );
                addUniqueRecipients(newRecipients);
              }}
              submitLabel="Add"
            />
          )}
        </div>
        <div className={styles.supplierSelect}>
          <MultiSelectList
            options={(suppliersData?.results || []).map((supplier) => ({
              name: supplier.name,
              id: supplier.id,
              disabled: (id) =>
                invitations?.suppliers.includes(id) ||
                recipients
                  .filter(
                    (recipient) =>
                      recipient.type ===
                      CombinedInvitationRecipientType.Supplier
                  )
                  .map((inivitation) => inivitation.invitee)
                  .includes(id),
            }))}
            asPopover
            selectedIds={selectedSupplierIds}
            actionsClassName={style({ justifyContent: "space-between" })}
            actions={[
              {
                color: "orange",
                label: "Add",
                disabled: (selectedIds) => !selectedIds.length,
                onClick: (items) => {
                  const newRecipients = (suppliersData?.results || [])
                    .filter((supplier) =>
                      items.map((i) => i.id).includes(supplier.id)
                    )
                    .map((newRecipient) => ({
                      invitee: newRecipient.id,
                      type: CombinedInvitationRecipientType.Supplier,
                      name: newRecipient.name,
                    }));

                  addUniqueRecipients(recipients.concat(newRecipients));
                },
              },
              {
                variant: "transparent",
                color: "orange",
                label: "Select all",
                disabled: (selectedIds) =>
                  selectedIds.length === suppliersData?.results.length,
                onClick: () => {
                  setNewCheckedSupplierIds(
                    (suppliersData?.results || [])
                      .filter(
                        (supplier) =>
                          !invitedSupplierIds.includes(supplier.id) &&
                          !invitations?.suppliers.includes(supplier.id)
                      )
                      .map((i) => i.id)
                  );
                },
              },
            ]}
            disabledReason={disabledReason}
            placeholder="Select from your Suppliers"
          />
        </div>
        <Button
          className={styles.findNewSuppliersButton}
          variant="outlined"
          color="orange"
          size="small"
          onClick={() => {
            openModal(
              <Suspense fallback={<Loading isWindowed />}>
                <VendorSearchModal
                  companyDisabled={(company) => {
                    if (!company?.id) {
                      return false;
                    }
                    return invitations?.companies.includes(company.id);
                  }}
                  companyDisabledReason={disabledReason}
                  onSelect={(companies) => {
                    closeModal();
                    addUniqueRecipients(
                      companies.map((c) => {
                        return {
                          type: CombinedInvitationRecipientType.Company,
                          invitee: c.id,
                          name: c.name,
                        };
                      })
                    );
                  }}
                />
              </Suspense>,
              {
                props: {
                  maxWidth: "lg",
                  paperClassName: style({
                    padding: "0px",
                    overflow: "hidden",
                  }),
                },
                keepHistory: true,
              }
            );
          }}
        >
          Find new Suppliers
        </Button>
      </div>
      <div className={styles.recipients}>
        {recipients.map((r) => {
          return (
            <RecipientTag
              key={r.name || r.invitee}
              id={r.invitee}
              email={
                r.type === CombinedInvitationRecipientType.Email
                  ? r.invitee
                  : undefined
              }
              name={r.name}
              onRemoveTag={() => {
                removeRecipient(r);
              }}
              isDuplicate={invitations?.emails.includes(r.invitee)}
            />
          );
        })}
      </div>
      {hasDuplicateEmails && (
        <div className={styles.errorMessageContainer}>
          <SvgIcon src="/icons/alert_icon.svg" className={styles.alertIcon} />
          <span>Email address already invited</span>
        </div>
      )}
    </div>
  );
}

type RecipientTagProps = {
  id: string;
  email?: string;
  name?: string;
  onRemoveTag: () => void;
  isDuplicate?: boolean;
};

function RecipientTag({
  id,
  email,
  name,
  onRemoveTag,
  isDuplicate,
}: RecipientTagProps) {
  return (
    <div
      className={classes(
        recipientTagStyles.tag,
        isDuplicate && recipientTagStyles.duplicate
      )}
      onClick={onRemoveTag}
    >
      {email || name || id}
      <SvgIcon
        src="/icons/close_icon.svg"
        className={recipientTagStyles.svgIcon}
      />
    </div>
  );
}
