import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { buttonContainerStyles } from "pages/PulseGroup/Post/shared.styles";
import { zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  commentRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: px(24),
    alignItems: "center",
  },
  leftSide: { display: "flex", alignItems: "center", flex: 1 },
  userProfile: { marginRight: "8px" },
  commentInput: { flex: 1 },
  nonEditableComment: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  commentAuthorRow: {
    display: "flex",
    flexDirection: "row",
    gap: px(6),
    alignItems: "baseline",
  },
  commentAuthorName: {
    ...typography.SUBTITLE2,
  },
  commentText: {
    ...zeroPaddingMargin,
    ...typography.CAPTION,
    backgroundColor: neutralColors.GRAY1,
    borderRadius: px(8),
    alignItems: "center",
    width: "100%",
    color: primaryColors.BLACK,
    padding: px(12),
  },
  postCommentButton: {
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  editButtons: buttonContainerStyles,
  closeIconButton: {
    marginLeft: "auto",
  },
  closeIcon: {
    width: "13px",
    height: "13px",
  },
  checkIcon: {
    width: "14px",
    height: "10px",
  },
});

export default styles;
