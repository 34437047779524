import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { VendorSearchFeatures } from "components/VendorSearch/VendorSearch";
import { CompanyFilter } from "shared/model";

export type VendorFilterContextType = {
  companyListId: string | null;
  filters: CompanyFilter;
  setFilters: React.Dispatch<React.SetStateAction<CompanyFilter>>;
  /** Can be used to conditionally disable different menus of the vendor search */
  hiddenFeatures: VendorSearchFeatures[];
};

export const VendorSearchContext = React.createContext<VendorFilterContextType>(
  {
    companyListId: null,
    filters: {},
    setFilters: () => {},
    hiddenFeatures: [],
  }
);

export function useVendorSearchContext() {
  return useContext(VendorSearchContext);
}

export default function VendorFilterProvider({
  children,
  hiddenFeatures = [],
}: {
  children: React.ReactNode;
  hiddenFeatures?: VendorSearchFeatures[];
}) {
  const [filters, setFilters] = useState<CompanyFilter>({});
  const params = useParams<{ companyListId?: string }>();

  return (
    <VendorSearchContext.Provider
      value={{
        companyListId: params.companyListId || filters.companyListId || null,
        filters,
        setFilters,
        hiddenFeatures,
      }}
    >
      {children}
    </VendorSearchContext.Provider>
  );
}
