import React from "react";

import Button, { ButtonProps } from "components/ui/Button/Button";
import SvgIcon from "components/ui/SvgIcon";
import Tooltip from "components/ui/Tooltip";

import styles from "./LockedButtonStyles";

export type LockedButtonProps = ButtonProps & {
  locked?: boolean;
  tooltip: string;
};

const LockedButtonWrapper = ({
  children,
  locked,
  tooltip,
}: {
  children: React.ReactElement;
  locked?: boolean;
  tooltip: string;
}) =>
  locked ? (
    <Tooltip title={tooltip} backdrop>
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );

const LockedButton = React.forwardRef<HTMLButtonElement, LockedButtonProps>(
  (
    { children, locked, disabled, tooltip, ...props }: LockedButtonProps,
    ref
  ) => {
    return (
      <LockedButtonWrapper locked={locked} tooltip={tooltip}>
        <Button
          {...props}
          ref={ref}
          disabled={locked || disabled}
          endIcon={
            locked && (
              <SvgIcon
                className={styles.lockIcon}
                src="/icons/lock_closed_icon.svg"
              />
            )
          }
        >
          {children}
        </Button>
      </LockedButtonWrapper>
    );
  }
);

export default LockedButton;
