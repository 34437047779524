import { utilityColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

import { typography } from "../../../shared/styles/typography";

const styles = stylesheet({
  tag: {
    color: utilityColors.ORANGE,
    ...typography.BODY2,
    border: `1px solid ${utilityColors.ORANGE}`,
    borderRadius: "4px",
    padding: "5px 18px",
    cursor: "default",
    display: "inline-flex",
    alignItems: "center",
  },
});

export default styles;
