import { neutralColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  iconSmall: {
    height: "22px",
    width: "22px",
  },
  searchIcon: {
    height: "16px",
    width: "16px",
    color: neutralColors.GRAY5,
    $nest: {
      "&.small": {
        height: "22px",
        width: "22px",
      },
    },
  },
  search: {
    width: "235px",
    marginLeft: "8px",
  },
  searchUnderLine: {
    $nest: {
      "&:before": {
        borderBottom: `1px solid ${neutralColors.GRAY5}`,
      },
      "&:after": {
        borderBottom: `2px solid ${neutralColors.GRAY5}`,
      },
      "&:hover:before": {
        borderBottom: `2px solid ${neutralColors.GRAY5}`,
      },
    },
  },
  popoverContainer: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
    padding: "12px",
    background: neutralColors.GRAY0,
    border: `1px solid ${neutralColors.GRAY3}`,
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTop: 0,
  },
});

export default styles;
