import React from "react";
import { Props as ReactSVGProps, ReactSVG } from "react-svg";

import { classes } from "typestyle";

import styles, { svgIconColor } from "./SvgIcon.styles";

export type SvgIconProps = Exclude<ReactSVGProps, "className"> & {
  iconClassname?: string;
  className?: string;
  color?: string;
  hoverColor?: string;
};

export default function SvgIcon({
  wrapper = "div",
  iconClassname = "",
  className,
  color,
  hoverColor,
  ref,
  beforeInjection,
  afterInjection,
  evalScripts,
  src,
  ...rest
}: SvgIconProps) {
  return (
    <ReactSVG
      beforeInjection={(svg) => {
        svg.classList.add(styles.baseIcon);
        svg.classList.add(svgIconColor(color, hoverColor));
        svg.removeAttribute("height");
        svg.removeAttribute("width");
        beforeInjection && beforeInjection(svg);
      }}
      afterInjection={(svg) => {
        afterInjection && afterInjection(svg);
      }}
      src={src}
      evalScripts="always"
      wrapper={wrapper}
      className={classes(styles.wrapper, className)}
      {...rest}
    />
  );
}
