import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  card: {
    width: "100%",
    backgroundColor: neutralColors.GRAY0,
    padding: "19px 21px",
    borderColor: neutralColors.GRAY3,
    borderStyle: "solid",
    borderRadius: "8px",
    borderWidth: "1px",
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: px(20),
    gap: px(10),
    width: "100%",
  },

  title: { ...typography.HEADLINE6, color: primaryColors.BLACK },
  content: {
    display: "inline-flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    color: neutralColors.GRAY5,
    margin: `auto ${px(76 - 24)}`,
  },
  fullWidth: { margin: px(0) },
});

export default styles;
