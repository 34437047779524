import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { MemoryRouter, Route, Switch, useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import { FeatureFlagEnum } from "@pulsemarket/constants";
import { Loading } from "components/FullScreenLoading";
import { GroupData } from "components/PulsePage/Groups/createGroup/CreateGroupModal";
import GroupDataroomSettings from "components/PulsePage/Groups/GroupDataroomSettings/GroupDataroomSettings";
import GroupDescription from "components/PulsePage/Groups/GroupDescription";
import GroupMembers from "components/PulsePage/Groups/GroupMembers";
import GroupSettings from "components/PulsePage/Groups/GroupSettings";
import ReviewFiles from "components/PulsePage/Groups/ReviewFiles";
import ReviewPosts from "components/PulsePage/Groups/ReviewPosts";
import Button from "components/ui/Button";
import ModalHeader from "components/ui/Modal/Header";
import PageLayoutWithSidebar from "components/ui/PageLayoutWithSidebar";
import ModalContext from "contexts/ModalContext";
import { useFormik } from "formik";
import { useGroup } from "hooks/queries/useGroup";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useSetGroupInvitationStatus from "hooks/queries/useSetGroupInvitationStatus";
import { useUpdateGroup } from "hooks/queries/useUpdateGroup";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import GroupSettingsSidebar from "pages/PulseGroup/GroupSettingsPage/sidebar";
import { getZones, uploadImage } from "shared/client";
import { BlobType, GroupInvitationStatus } from "shared/model";
import { useFormikReturnType } from "shared/utils/formik";
import { style } from "typestyle";

import { styles } from "./GroupSettingsPageStyles";

export enum GroupSettingTab {
  Description = "Description",
  Settings = "Settings",
  Members = "Members",
  Posts = "Posts",
  Files = "Files",
  Datarooms = "Datarooms",
}

export type GroupSettingsProps = {
  groupId: string;
  initialPage: GroupSettingTab;
};

const routes = {
  [GroupSettingTab.Description]: "/description",
  [GroupSettingTab.Settings]: "/settings",
  [GroupSettingTab.Members]: "/members",
  [GroupSettingTab.Posts]: "/posts",
  [GroupSettingTab.Files]: "/files",
  [GroupSettingTab.Datarooms]: "/datarooms",
};

const GroupSettingsPage = ({ groupId, initialPage }: GroupSettingsProps) => {
  const { data: group, isLoading: isGroupLoading } = useGroup(groupId);
  const { mutateAsync: updateGroup } = useUpdateGroup();
  const { data: managingFilesEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.manageGroupFilesApproval,
  });

  const [avatar, setAvatar] = useState<File>();
  const [backgroundImage, setBackgroundImage] = useState<File>();

  const { mutate: setGroupInvitationStatus } = useSetGroupInvitationStatus();
  const { closeModal } = useContext(ModalContext);

  const { data: zones } = useQuery(queryKeys.zones, () => getZones(), {
    placeholderData: [],
    ...longCachedQueryOptions,
  });

  const formik = useFormik<GroupData>({
    initialValues: {
      name: group?.name || "",
      description: group?.description || "",
      zones: group?.zones.map((z) => z.id) || [],
      datarooms: group?.datarooms || [],
      avatar: group?.avatar || "",
      backgroundImage: group?.backgroundImage || "",
      isPrivate: !!group?.isPrivate,
      isVisible: !!group?.isVisible,
      allowMemberInvitations: !!group?.allowMemberInvitations,
      onlyAdminCanCreateContent: !!group?.onlyAdminCanCreateContent,
      postsRequireApproval: !!group?.postsRequireApproval,
      filesRequireApproval: !!group?.filesRequireApproval,
    },
    onSubmit: async (values) => {
      try {
        await updateGroup({
          ...values,
          id: groupId,
          datarooms: values.datarooms?.map((d) => d.id) || [],
        });
      } catch (err) {
        console.error(err);
      }
      return;
    },
  });

  const { data: ownCompany } = useOwnCompany();

  const history = useHistory();

  if (!group || isGroupLoading) {
    return <Loading />;
  }

  const joinRequests = group.requestedAccess;

  return (
    <div className={styles.popupContainer}>
      <MemoryRouter initialEntries={[routes[initialPage]]}>
        <PageLayoutWithSidebar
          className={styles.borderLessContainer}
          sidebar={
            <GroupSettingsSidebar
              isOwner={group.ownerCompanyId === ownCompany?.id}
            />
          }
        >
          <ModalHeader
            onClose={closeModal}
            className={style({
              position: "absolute",
              top: "20px",
              right: "20px",
            })}
          />

          <Switch>
            {/* todo add lazy-loaded routes */}
            <Route path={routes.Description} exact>
              <GroupDescription
                extraAvatarStyles={styles.avatarStyle}
                avatar={formik.values.avatar}
                onAvatarChange={(img, file) => {
                  formik.setFieldValue("avatar", img);
                  setAvatar(file);
                }}
                backgroundImage={formik.values.backgroundImage}
                onBackgroundChange={(img, file) => {
                  formik.setFieldValue("backgroundImage", img);
                  setBackgroundImage(file);
                }}
                name={formik.values.name}
                onNameChange={formik.handleChange("name")}
                description={formik.values.description}
                onDescriptionChange={formik.handleChange("description")}
                zoneOptions={zones || []}
                zones={formik.values.zones}
                onZonesChange={(ids) => formik.setFieldValue("zones", ids)}
              />
            </Route>
            <Route path={routes.Settings} exact>
              <GroupSettings
                onDelete={() => history.push("/")}
                groupId={groupId}
                values={formik.values}
                onChange={(value, field) => {
                  formik.setFieldValue(field, value);
                }}
              />
            </Route>
            <Route path={routes.Members} exact>
              <GroupMembers
                title="Invite Members"
                groupId={group.id}
                members={group.members}
                ownerCompanyId={group.ownerCompanyId}
                requestedAccess={joinRequests}
                onMemberRequestDecline={async (groupInvitationId: string) =>
                  setGroupInvitationStatus({
                    groupInvitationId,
                    status: GroupInvitationStatus.Declined,
                  })
                }
                onMemberRequestAccept={async (groupInvitationId: string) =>
                  setGroupInvitationStatus({
                    groupInvitationId,
                    status: GroupInvitationStatus.Joined,
                  })
                }
              />
            </Route>
            <Route path={routes.Posts} exact>
              <ReviewPosts />
            </Route>
            {managingFilesEnabled && (
              <Route path={routes.Files} exact>
                <ReviewFiles />
              </Route>
            )}

            <Route path={routes.Datarooms} exact>
              <GroupDataroomSettings
                datarooms={formik.values.datarooms}
                onSetDatarooms={(datarooms) =>
                  formik.setFieldValue("datarooms", datarooms)
                }
              />
            </Route>
          </Switch>
        </PageLayoutWithSidebar>

        <BottomBar
          formik={formik}
          avatar={avatar}
          backGrounImage={backgroundImage}
          groupId={groupId}
        />
      </MemoryRouter>
    </div>
  );
};

export default GroupSettingsPage;

function BottomBar({
  groupId,
  formik,
  avatar,
  backGrounImage,
}: {
  groupId: string;
  formik: useFormikReturnType<GroupData>;
  avatar?: File;
  backGrounImage?: File;
}) {
  const { closeModal } = useContext(ModalContext);

  return (
    <div className={styles.buttonContainer}>
      <Switch>
        <Route path={[routes.Members, routes.Posts, routes.Files]} exact>
          {null}
        </Route>
        <Route>
          <Button
            variant="normal"
            color="gray"
            type="button"
            disabled={!formik.dirty}
            onClick={async (e) => {
              e.preventDefault();
              formik.resetForm();
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="normal"
            color="orange"
            type="submit"
            disabled={!formik.dirty}
            onClick={async (e) => {
              e.preventDefault();
              if (avatar) {
                await uploadImage({
                  file: avatar,
                  uploadImageProps: {
                    blobType: BlobType.GroupAvatar,
                    groupId: groupId,
                  },
                });
              }

              if (backGrounImage) {
                await uploadImage({
                  file: backGrounImage,
                  uploadImageProps: {
                    blobType: BlobType.GroupBackGroundImage,
                    groupId: groupId,
                  },
                });
              }

              await formik.submitForm();
              closeModal();
            }}
          >
            Save
          </Button>
        </Route>
      </Switch>
    </div>
  );
}
