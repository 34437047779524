import React, { useCallback, useRef, useState } from "react";

import SvgIcon from "components/ui/SvgIcon";
import { useMutationObservable } from "hooks/useMutationObserver";
import { classes } from "typestyle";

import styles from "./TagInputBoxStyles";

type Props = {
  className?: string;
  separators?: string[];
  placeholder?: string;
  tags: string[];
  validatorFn?: (tag: string) => boolean;
  onAdd: (tags: string[]) => void;
  onRemove: (tag: string) => void;
};

const TagInputBox = ({
  className,
  separators = [",", ";", " "],
  placeholder,
  tags,
  validatorFn,
  onAdd,
  onRemove,
}: Props) => {
  const inputRef = useRef<HTMLSpanElement>(null);
  const [text, setText] = useState("");

  const updateText = useCallback(() => {
    setText(inputRef.current?.innerText.trim() || "");
  }, []);

  useMutationObservable(inputRef.current, updateText);

  return (
    <div
      className={classes(
        styles.container,
        !!tags.length && styles.containerWithTags,
        className
      )}
    >
      {!tags.length && !text && (
        <span className={styles.placeholder}>{placeholder}</span>
      )}

      {!!tags.length &&
        tags.map((tag) => (
          <Tag
            key={tag}
            tag={tag}
            onRemove={() => {
              onRemove(tag);
            }}
            isValid={(validatorFn && validatorFn(tag)) ?? true}
          />
        ))}

      <span
        ref={inputRef}
        onBlur={(e) => {
          e.preventDefault();

          const text = e.currentTarget.innerText.trim();

          if (!!text) {
            if (!tags.includes(text)) {
              onAdd(tags.concat([text]));
            }
          }

          setText("");
          e.currentTarget.innerText = "";
        }}
        contentEditable
        className={styles.input}
        onPaste={(e) => {
          // Do not let the text appear in the input
          e.preventDefault();

          const paste = e.clipboardData.getData("text");

          const re = new RegExp(`[${separators.join("")}]`);

          const matches = paste
            .split(re)
            .map((e) => e.trim())
            .filter((email) => !!email);

          onAdd([...new Set(tags.concat(matches))]);
        }}
        onKeyPress={(e) => {
          if (
            separators.includes(e.nativeEvent.key) ||
            e.nativeEvent.key === "Enter"
          ) {
            e.preventDefault();

            const text = e.currentTarget.innerText.trim();

            if (!!text) {
              if (!tags.includes(text)) {
                onAdd(tags.concat([text]));
              }
            }

            setText("");
            e.currentTarget.innerText = "";
          }
        }}
      />
    </div>
  );
};

type TagProps = {
  tag: string;
  onRemove: (tag: string) => void;
  isValid: boolean;
};

const Tag = ({ tag, onRemove, isValid }: TagProps) => {
  return (
    <div className={classes(styles.tag, isValid ? "valid" : "invalid")}>
      {tag}

      <SvgIcon
        onClick={() => onRemove(tag)}
        className={styles.deleteIcon}
        src="/icons/close_icon.svg"
      />
    </div>
  );
};

export default TagInputBox;
