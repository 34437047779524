import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  headerImage: {
    width: "100%",
    height: "38vh",
    maxHeight: "412px",
    border: `3px solid ${primaryColors.WHITE}`,
    position: "relative",
    backgroundColor: neutralColors.GRAY0,
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "100%",
    maxHeight: "412px",
    objectFit: "cover",
  },
  cyanCloud: {
    position: "absolute",
    color: utilityColors.CYAN,
    top: 0,
    width: "100%",
    zIndex: 1,
  },
  grayCloud: {
    position: "absolute",
    color: neutralColors.GRAY1,
    top: 0,
    width: "100%",
  },
  cameraIconContainer: {
    backgroundColor: primaryColors.WHITE,
    position: "absolute",
    right: "14px",
    bottom: "13px",
    width: "28px",
    height: "28px",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  editIcon: {
    color: primaryColors.PURPLE2,
    height: "17px",
    width: "19px",
  },
  backgroundSpan: {
    ...typography.CAPTION,
    position: "absolute",
    right: "0",
    bottom: "-28px",
    color: neutralColors.GRAY5,
  },
  maxSizeSpan: {
    ...typography.CAPTION,
    position: "absolute",
    bottom: "-46px",
    right: "0",
    color: neutralColors.GRAY5,
  },
  tryAgain: {
    ...typography.CAPTION,
    width: "fit-content",
    position: "absolute",
    bottom: "-46px",
    right: "0",
    color: utilityColors.RED,
  },
});
