export enum AssetType {
  Document = "Document",
  Certificate = "Certificate",
}

export enum AllowedExtensions {
  txt = "txt",
  pdf = "pdf",
  doc = "doc",
  jpg = "jpg",
  jpeg = "jpeg",
  gif = "gif",
  png = "png",
  mp4 = "mp4",
  xlsx = "xlsx",
}

export enum AllowedMimes {
  text_plain = "text/plain",
  application_pdf = "application/pdf",
  image_jpeg = "image/jpeg",
  image_gif = "image/gif",
  image_png = "image/png",
  video_mp4 = "videomp4",
  application_msword = "application/msword",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //xlsx
}
