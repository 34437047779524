import { RFPType } from "./procurementTypes";

export const ROUTES = {
  // ******************* Auth *******************
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/reset_password",
  VERIFY_EMAIL: "/verify_email",

  // ******************* Value Chain *******************
  RFP_MANAGEMENT_FOR_BUYERS: "/documents",
  RFP_MANAGEMENT_FOR_SUPPLIERS: "/documentresponses",
  RFP_MANAGEMENT_RFP: "/rfp",
  RFP_MANAGEMENT_RFI: "/rfi",
  RFP_MANAGEMENT_DDQ: "/ddq",
  RFP_MANAGEMENT_QTP: "/qtp",
  RFP_MANAGEMENT_RFP_RESPONSES: "/rfpresponses",
  RFP_MANAGEMENT_RFI_RESPONSES: "/rfiresponses",
  RFP_MANAGEMENT_DDQ_RESPONSES: "/ddqresponses",
  RFP_MANAGEMENT_QTP_RESPONSES: "/qtpresponses",

  // ******************* Document editor *******************
  RFP: ({ rfpType, id }: { rfpType: RFPType; id: string }) =>
    `/${rfpType.toLowerCase()}/${id}`,
  RFPRESPONSE: ({
    rfpType,
    rfpId,
    responseId,
  }: {
    rfpType: RFPType;
    rfpId: string;
    responseId: string;
  }) => `/${rfpType.toLowerCase()}/${rfpId}/response/${responseId}`,

  // ******************* Passport console *******************
  // todo figure out better paths
  PASSPORT_MANAGEMENT: "/passports",
  MY_ESG_PASSPORT: "/passports/esg/my_passports",
  ESG_INVITATIONS: "/passports/esg/invitations",

  /// ******************* CSRD *******************
  CSRD: "/csrd",
  CSRD_DASHBOARD: "/csrd/dashboard",
  CSRD_MATERIALITY_ASSESSEMENT: "/csrd/materiality_assessment",
  CSRD_QUESTIONNAIRES: "/csrd/questionnaires",
  CSRD_QUESTIONS: "/csrd/questions",
  CSRD_GAP_ANALYSIS: "/csrd/gap_analysis",
  CSRD_SUSTAINABILITY_REPORT: "/csrd/sustainability_report",
  CSRD_KEY_DOCUMENTS: "/csrd/key_documents",
  CSRD_MY_VIEWS: "/csrd/my_views",

  // ******************* Group *******************
  GROUP: (id: string) => `/groups/${id}`,

  // ******************* Suppliers *******************
  MY_SUPPLIERS: "/mysuppliers",
  /**
   * Use this route to get supplier specific sub routes
   * @param id supplier id
   * @returns supplier sub route
   * @example ROUTES.SUPPLIER(supplierId).products
   */
  SUPPLIER: (id: string) => {
    return {
      /**
        Root url for supplier. Automatically redirects to info page
      */
      root: `${ROUTES.MY_SUPPLIERS}/${id}`,
      /**
        Info page where you can edit supplier meta data
      */
      info: `${ROUTES.MY_SUPPLIERS}/${id}/info`,
      /**
        Supplier contracts page. Add a second parameter when using useHistory hook to redirect to specific contract.
        @example history.push(ROUTES.SUPPLIER(supplierId).contracts,{ contractId: contractId }) 
      */
      contracts: `${ROUTES.MY_SUPPLIERS}/${id}/contracts`,
      /**
        Supplier's passport invitations and responses
      */
      passports: `${ROUTES.MY_SUPPLIERS}/${id}/passports`,
      /**
        Supplier's RFx invitations and responses
      */
      procurement: `${ROUTES.MY_SUPPLIERS}/${id}/procurement`,
      /**
        Supplier's attachments are listed on this page
      */
      attachments: `${ROUTES.MY_SUPPLIERS}/${id}/attachments`,
      /**
        Supplier's products are listed on this page
      */
      products: `${ROUTES.MY_SUPPLIERS}/${id}/products`,
      /**
        Supplier's new product page
      */
      newProduct: `${ROUTES.MY_SUPPLIERS}/${id}/products/new`,
      /**
       Product details page
      */
      product: (productId: string) =>
        `${ROUTES.MY_SUPPLIERS}/${id}/products/${productId}`,
    };
  },
  DATA_MANAGEMENT: {
    root: "/data_management",
    lookupTables: (id?: string) =>
      `/data_management/lookup_tables${id ? `/${id}` : ""}`,
    matrices: (id?: string) => `/data_management/matrices${id ? `/${id}` : ""}`,
  },

  // ******************* Company *******************
  COMPANY_EDIT_INFO: "/company/edit/info",
  COMPANY_EDIT_ABOUT: "/company/edit/about",
  COMPANY_EDIT_SECTORS: "company/edit/sectors",
};
