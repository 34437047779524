import { useEffect, useState } from "react";

import useDataRoom from "hooks/queries/useDataRoom";
import useIsPublicVisitor from "hooks/useIsPublicVisitor";
import {
  PUBLIC_VIEWER_DATA_ROOM_COMPANYID,
  PUBLIC_VIEWER_DATA_ROOM_ID,
} from "shared/constants/sessionstorage";

export default function usePublicVisitor({
  dataRoomId,
}: {
  dataRoomId?: string | null;
}) {
  const { isPublicVisitor, isLoading: isPublicVisitorLoading } =
    useIsPublicVisitor();

  const [dataRoomCompanyId, setDataRoomCompanyId] = useState<string | null>(
    window.sessionStorage.getItem(PUBLIC_VIEWER_DATA_ROOM_COMPANYID) ?? null
  );
  const [calculatedDataRoomId, setDataRoomId] = useState<string | null>(
    (window.sessionStorage.getItem(PUBLIC_VIEWER_DATA_ROOM_ID) || dataRoomId) ??
      null
  );
  const { data, isLoading } = useDataRoom(dataRoomId);

  useEffect(() => {
    if (isPublicVisitorLoading || isLoading || !dataRoomId) {
      return;
    }
    if (isPublicVisitor) {
      window.sessionStorage.setItem(PUBLIC_VIEWER_DATA_ROOM_ID, dataRoomId);
      setDataRoomId(dataRoomId);

      if (data) {
        window.sessionStorage.setItem(
          PUBLIC_VIEWER_DATA_ROOM_COMPANYID,
          data.companyId
        );
        setDataRoomCompanyId(data.companyId);
      }
    } else {
      window.sessionStorage.removeItem(PUBLIC_VIEWER_DATA_ROOM_ID);
      window.sessionStorage.removeItem(PUBLIC_VIEWER_DATA_ROOM_COMPANYID);
      setDataRoomCompanyId(null);
      setDataRoomId(null);
    }
  }, [
    setDataRoomCompanyId,
    data,
    isLoading,
    dataRoomId,
    isPublicVisitor,
    isPublicVisitorLoading,
  ]);

  return {
    dataRoomId: calculatedDataRoomId,
    dataRoomCompanyId,
    isLoading: isPublicVisitorLoading || isLoading,
  };
}
