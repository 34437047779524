import { useCallback, useContext, useMemo } from "react";
import { useQueryClient } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { CompanyCardProps } from "components/CompanyCard/CompanyCard";
import { ButtonProps } from "components/ui/Button/Button";
import { Selectable } from "components/ui/Table/types";
import AddToList from "components/vendorlists/AddToList";
import RemoveFromList from "components/vendorlists/RemoveFromList";
import { checkIsInOrg } from "components/vendorlists/utils";
import { useListContext } from "contexts/List/ListProvider";
import ModalContext from "contexts/ModalContext";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { CompanyCardDto, CompanyDto, Identifiable } from "shared/model";

type CardOptions = {
  compact?: boolean;
  selectable?: boolean;
  disabled?: boolean | ((company: CompanyDto) => boolean);
  companyDisabled?: (company?: CompanyCardDto) => boolean | undefined;
  disabledReason?: string;
};

export default function useCompanyCards(
  companies: (CompanyDto & Selectable)[],
  options?: CardOptions
) {
  const client = useQueryClient();
  const { data: ownCompany } = useOwnCompany();
  const { openModal, closeModal } = useContext(ModalContext);
  const { onToggleSelect } = useListContext<CompanyDto & Selectable>();

  const openAddToListModal = useCallback<(company: CompanyCardDto) => void>(
    (company: CompanyCardDto) => {
      openModal(
        <AddToList
          company={{ id: company.id, name: company.name }}
          onSuccess={() => {
            closeModal();
          }}
          onCancel={() => {
            closeModal();
          }}
        />
      );
    },
    [openModal, closeModal]
  );

  const openRemoveFromListModal = useCallback<
    (company: CompanyCardDto) => void
  >(
    (company: CompanyCardDto) => {
      openModal(
        <RemoveFromList
          company={{ id: company.id, name: company.name }}
          onSuccess={() => {
            closeModal();
          }}
          onCancel={() => {
            closeModal();
          }}
        />
      );
    },
    [openModal, closeModal]
  );

  const cards: CompanyCardProps[] = useMemo<CompanyCardProps[]>(() => {
    return companies.map((company) => {
      const isInCompanyList = checkIsInOrg(
        company.id,
        ownCompany!.companyLists
      );
      const buttons: (ButtonProps & Identifiable)[] = [
        {
          id: `${company.id}button1`,
          children: "Add to List",
          variant: "normal",
          color: "orange",
          onMouseEnter: () => client.prefetchQuery(queryKeys.ownCompany),
          onClick: () => openAddToListModal(company),
        },
      ];
      if (isInCompanyList) {
        buttons.push({
          id: `${company.id}button2`,
          children: "Remove",
          variant: "normal",
          color: "red",
          onMouseEnter: () => client.prefetchQuery(queryKeys.ownCompany),
          onClick: () => openRemoveFromListModal(company),
        });
      }

      return {
        company,
        buttons,
        compact: options?.compact,
        onToggleSelect: options?.selectable
          ? () => {
              onToggleSelect(company.id);
            }
          : undefined,
        selected: company.selected,
        disabled: options?.companyDisabled,
        disabledReason: options?.disabledReason,
      };
    });
  }, [
    openAddToListModal,
    openRemoveFromListModal,
    companies,
    ownCompany,
    client,
    options?.compact,
    options?.selectable,
    options?.disabledReason,
    options?.companyDisabled,
    onToggleSelect,
  ]);

  return cards;
}
