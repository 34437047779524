import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { postCompanyListCompany } from "shared/client";
import { CompanyDetailsDto } from "shared/model";

import useOwnCompany from "./useOwnCompany";

type AddCompanyToListHookParams = {
  onSuccess: () => void;
};

type AddToListParams = {
  listId: string;
  companyId: string;
};

export default function useAddCompanyToList(
  options?: AddCompanyToListHookParams
) {
  const client = useQueryClient();
  const { data: ownCompany } = useOwnCompany();

  return useMutation(
    ({ listId, companyId }: AddToListParams) =>
      postCompanyListCompany(ownCompany!.id, listId, companyId),
    {
      mutationKey: mutationKeys.addCompanyToList,
      // When mutate is called:
      onMutate: async ({ listId, companyId }: AddToListParams) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(queryKeys.ownCompany);

        // Snapshot the previous value
        const previousOrganization = client.getQueryData<CompanyDetailsDto>(
          queryKeys.ownCompany
        );

        // Optimistically update to the new value
        if (previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(queryKeys.ownCompany, {
            ...previousOrganization,
            companyLists: previousOrganization.companyLists.map((list) =>
              list.id === listId
                ? { ...list, companyIds: [...list.companyIds, companyId] }
                : list
            ),
          });
        }

        return { previousOrganization };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(
            queryKeys.ownCompany,
            context.previousOrganization
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        client.invalidateQueries(queryKeys.ownCompany);
      },
      onSuccess: options?.onSuccess,
    }
  );
}
