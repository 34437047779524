import * as React from "react";
import AnimateCC from "react-adobe-animate";

import { primaryColors } from "@pulsemarket/constants";
import { classes, style } from "typestyle";

const loadingClassFullScreen = style({
  position: "fixed",
  zIndex: 1200,
  background: "white",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
});

const loadingClassWindowedScreen = style({
  // paddingTop: "24px",
  display: "flex",
});

const wrapperClass = style({
  margin: "auto",
  height: "90px",
  width: "90px",
  marginBottom: "24px",
});

const centerClass = style({
  margin: "auto",
  fontSize: "18px",
  fontWeight: 600,
  color: primaryColors.BLACK,
});

interface LoadingProps {
  message?: string;
  isWindowed?: boolean;
  className?: string;
}

export function Loading(props: LoadingProps) {
  return (
    <div
      className={
        props.isWindowed ? loadingClassWindowedScreen : loadingClassFullScreen
      }
    >
      <div className={centerClass}>
        <div className={classes(wrapperClass, props.className)}>
          <AnimateCC animationName="pulsespin" paused={false} />
        </div>
        {props.message}
      </div>
    </div>
  );
}
