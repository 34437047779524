export const zonesDetails = [
  {
    id: "0",
    description:
      "For FinTech companies that are providing financial technology services that improve and automate the delivery and use of financial services. ",
    image: "/img/Fintech.png",
  },
  {
    id: "1",
    description:
      "For RegTech companies providing services involved in the management of regulatory processes within the financial services industry through technology for regulatory, monitoring, reporting and compliance. ",
    image: "/img/Regtech.png",
  },
  {
    id: "2",
    description:
      "For companies providing software and hardware for automating HR resources within an organization from talent acquisition to payroll and workforce analysis to performance management. ",
    image: "/img/HRTech.png",
  },
  {
    id: "3",
    description:
      "For companies who are providing socially responsible consulting and investment services guiding organisations on setting standards for non-financial factors of social, environmental and governance.",
    image: "/img/ESG.png",
  },
  {
    id: "4",
    description:
      "For companies who are providing creative services for promoting business for sales and marketing purposes by utilising online and digital technologies.",
    image: "/img/DigitalMarketing.png",
  },
  {
    id: "5",
    description:
      "For companies who are involved in creating, distributing and administrating insurance business for processing, automating and handling insurance online.",
    image: "/img/InsurTech.png",
  },
  {
    id: "6",
    description:
      "For companies who are involved in providing professional advice, expertise and project management for organisations looking for external support ",
    image: "/img/Consultancy.png",
  },
  {
    id: "7",
    description:
      "For companies who are involved in providing technical expertise and management of organizations’ IT systems and support.",
    image: "/img/ItServices.png",
  },
  {
    id: "8",
    description: "",
    image: "/img/zones_standard.svg",
  },
  {
    id: "9",
    description:
      "For companies who are involved in supplying gas, electricity and green energy services for organisations.",
    image: "/img/zones_standard.svg",
  },
];
