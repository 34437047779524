export enum StatusIndicatorModelType {
  RFP = "RFP",
  RFPInvitation = "RFPInvitation",
  RFPResponse = "RFPResponse",
  RFPResponseEvaluation = "RFPResponseEvaluation",
  RFPResponseAnalysis = "RFPResponseAnalysis",
  RFPResponseSuccess = "RFPResponseSuccess",
  Passport = "Passport",
  PassportInvitation = "PassportInvitation",
  CSRD = "CSRD",
}
