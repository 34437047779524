import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { CompanyCardProps } from "components/CompanyCard/CompanyCard";
import ConfirmationModalBody from "components/ConfirmationModalBody";
import DisplaySelector, { DisplayType } from "components/DisplaySelector";
import { unclosableDialogProps } from "components/ui/Modal";
import { Selectable } from "components/ui/Table/types";
import AddToList from "components/vendorlists/AddToList";
import CompaniesGridOrList from "components/VendorSearch/CompaniesGridOrList";
import VendorSearchFirstAccessBox from "components/VendorSearch/CompanyListWithTitle/FirstAccessBox";
import { useVendorSearchContext } from "components/VendorSearch/VendorFilterProvider";
import { useListContext } from "contexts/List/ListProvider";
import ModalContext from "contexts/ModalContext";
import useDeleteCompanyFromList from "hooks/queries/useDeleteCompanyFromList";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { getCompanyList } from "shared/client";
import { CompanyCardDto, CompanyDto } from "shared/model";

import styles from "./CompanyListSearchStyles";

type CompanyListSearchProps = {
  companies?: (CompanyDto & Selectable)[];
  compactCards?: boolean;
  selectableCards?: boolean;
  companyDisabled?: (company?: CompanyCardDto) => boolean | undefined;
  disabledReason?: string;
};

export default function CompanyListSearch({
  companies = [],
  compactCards,
  selectableCards,
  companyDisabled,
  disabledReason,
}: CompanyListSearchProps) {
  const history = useHistory();
  const { companyListId } = useVendorSearchContext();
  const { data: ownCompany } = useOwnCompany();
  const { openModal, closeModal } = useContext(ModalContext);
  const { onToggleSelect } = useListContext<CompanyDto & Selectable>();
  const [display, setDisplay] = useState<DisplayType>(DisplayType.CARDS);

  const client = useQueryClient();

  const { data: companyList } = useQuery(
    queryKeys.companyList(companyListId || ""),
    () => getCompanyList(ownCompany!.id, companyListId || ""),
    { enabled: !!companyListId }
  );

  const { mutate: deleteCompanyFromList } = useDeleteCompanyFromList({
    optimisticlyUpdateSearch: true,
  });

  useEffect(() => {
    if (
      ownCompany!.companyLists.length &&
      !companyListId &&
      !client.isMutating({ mutationKey: mutationKeys.createCompanyList })
    ) {
      history.push(
        `${window.location.pathname}/${ownCompany!.companyLists[0].id}`
      );
    }
    if (!ownCompany!.companyLists.length && !!companyListId) {
      history.push("/vendorsearch/companylist");
    }
  }, [ownCompany, history, companyListId, client]);

  const openAddToListModal = useCallback<(company: CompanyDto) => void>(
    (company: CompanyDto) => {
      openModal(
        <AddToList
          company={{ id: company.id, name: company.name }}
          onSuccess={() => {
            closeModal();
          }}
          onCancel={() => {
            closeModal();
          }}
        />
      );
    },
    [openModal, closeModal]
  );

  const openRemoveFromListModal = useCallback<(company: CompanyDto) => void>(
    (company) => {
      openModal(
        <ConfirmationModalBody
          action={async () => {
            if (companyListId) {
              deleteCompanyFromList({
                listId: companyListId,
                companyId: company.id,
              });
            }
          }}
          title={`Remove ${company.name}`}
          buttonText="Yes, remove company"
          onClose={closeModal}
        >
          Are you sure you want to remove {company.name} from your&nbsp;
          {companyList?.name} List?
        </ConfirmationModalBody>,
        { props: unclosableDialogProps }
      );
    },
    [closeModal, openModal, companyListId, companyList, deleteCompanyFromList]
  );

  if (!companyListId) {
    return <VendorSearchFirstAccessBox />;
  }

  return (
    <>
      <h2 className={styles.title}>
        {companyList?.name}{" "}
        <DisplaySelector display={display} onDisplayChange={setDisplay} />
      </h2>
      {!companies.length ? (
        <p className={styles.emptyContent}>
          This List is empty, explore the Supplier Search to find new suppliers.
        </p>
      ) : (
        <CompaniesGridOrList
          type="Company"
          display={display}
          cards={companies.map(
            (company) =>
              ({
                company: company,
                compact: compactCards,
                selected: company.selected,
                onToggleSelect: selectableCards
                  ? () => {
                      onToggleSelect(company.id);
                    }
                  : undefined,
                disabled: companyDisabled,
                disabledReason: disabledReason,
                buttons: [
                  {
                    id: `${company.id}button1`,
                    children: "Add to List",
                    variant: "normal",
                    color: "orange",
                    onClick: () => openAddToListModal(company),
                  },
                  {
                    id: `${company.id}button2`,
                    children: "Remove",
                    variant: "normal",
                    color: "red",
                    onClick: () => openRemoveFromListModal(company),
                  },
                ],
              } as CompanyCardProps)
          )}
        />
      )}
    </>
  );
}
