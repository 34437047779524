import { primaryColors } from "@pulsemarket/constants";
import { spacing } from "shared/styles/spacing";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "14px",
    $nest: {
      "& label": {
        marginBottom: spacing.LABEL_MARGIN,
      },
    },
  },
  contentContainer: {
    ...typography.BODY1,
    color: primaryColors.BLACK,
    padding: 0,
    marginBottom: "32px",
    overflowY: "initial",
  },
  paragraph: {
    paddingRight: "5%",
  },
  buttonsContainer: {
    position: "absolute",
    bottom: "18px",
    right: "32px",
  },
  cancelButton: {
    marginRight: "14px",
  },
  modalContent: {
    marginBottom: "10%",
  },
});
