import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

type YouTubeVideosAPIResult = {
  kind: string;
  etag: string;
  items: Array<{
    kind: string;
    etag: string;
    id: string;
    contentDetails: {
      duration: string;
      dimension: string;
      definition: string;
      caption: string;
      licensedContent: boolean;
      contentRating: any;
      projection: string;
    };
  }>;
  pageInfo: {
    totalResults: number;
    resultsPerPage: number;
  };
};

export default function useYouTubeData(id: string) {
  return useQuery(
    queryKeys.youTubeVideo(id),
    async () => {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${id}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`,
        {
          method: "GET",
        }
      );

      return (await response.json()) as YouTubeVideosAPIResult;
    },
    {
      select: (data) => {
        return data.items?.[0].contentDetails;
      },
      ...longCachedQueryOptions,
    }
  );
}
