import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { clickable } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  selectedTagCloseIcon: {
    width: "13px",
    height: "13px",
    marginLeft: "11px",
    ...clickable,
  },
  unSelectedTag: {
    borderColor: primaryColors.PURPLE2,
    color: primaryColors.PURPLE2,
    ...clickable,
  },
  textField: {
    marginBottom: "24px",
  },
  input: {
    border: `1px solid ${neutralColors.GRAY2}`,
    borderRadius: "8px",
    background: primaryColors.WHITE,
    color: primaryColors.BLACK,
    ...typography.BODY1,
    $nest: {
      "& ::placeholder": {
        color: neutralColors.GRAY4,
      },
    },
  },

  searchIconButton: {
    width: "19px",
    height: "19px",
  },
  searchIcon: {
    width: "16px",
  },
  popoverContainer: {
    padding: "12px",
    background: neutralColors.GRAY0,
    border: `1px solid ${neutralColors.GRAY3}`,
    // later this should only be applied to the bottom borders
    borderRadius: "8px",
    maxHeight: "161px",
    overflowY: "scroll",
  },
  clickableSearchText: {
    color: neutralColors.GRAY5,
    ...clickable,
  },
  grayText: {
    color: neutralColors.GRAY4,
    marginLeft: "4px",
  },
  groupName: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
});

export default styles;
