import { useQuery, UseQueryOptions } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { searchAssets, searchDataRoomAssets } from "shared/client";
import { AssetSearchOptions, FolderItemDto } from "shared/model";

export default function useSearchAssets<T = FolderItemDto[]>(
  searchOptions: AssetSearchOptions,
  options?: UseQueryOptions<
    FolderItemDto[],
    unknown,
    T,
    readonly ["assetRepositorySearch", AssetSearchOptions]
  > & {
    companyId?: string;
  }
) {
  const { data: ownCompany } = useOwnCompany();

  return useQuery(
    queryKeys.assetRepositorySearch(searchOptions),
    () => {
      if (!options?.companyId) {
        return searchAssets(ownCompany!.id, searchOptions);
      } else {
        return searchDataRoomAssets(options.companyId, searchOptions);
      }
    },
    {
      enabled:
        !!options?.companyId || (!options?.companyId && !!ownCompany?.id),
      notifyOnChangeProps: options?.notifyOnChangeProps,
      ...options,
    }
  );
}
