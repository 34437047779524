import { neutralColors } from "@pulsemarket/constants";
import { forceTextOneLine } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    filter: "drop-shadow(0px 16.1502px 32.3005px rgba(35, 23, 5, 0.1))",
    borderRadius: "8px",
  },
  title: {
    display: "block",
    marginTop: "12px",
    ...typography.BODY2,
    color: neutralColors.GRAY5,
    ...forceTextOneLine,
  },
  frame: {
    borderRadius: "8px",
    width: "100%",
  },
});

export default styles;
