import { useState } from "react";

import { IconButton, InputAdornment } from "@material-ui/core";
import Input, { InputProps } from "components/ui/Input";
import SvgIcon from "components/ui/SvgIcon";

import styles from "./PasswordInput.styles";
export default function PasswordInput({ className, ...props }: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      {...props}
      className={className}
      required
      label={props.label || "Password"}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" color="red">
            <IconButton
              aria-label="Toggle password visibility"
              onClick={() => setShowPassword((val) => !val)}
            >
              {showPassword ? (
                <SvgIcon
                  src="/icons/visibility_off_icon.svg"
                  className={styles.inputIcon}
                />
              ) : (
                <SvgIcon
                  src="/icons/view_icon.svg"
                  className={styles.inputIcon}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
