import { ReactNode } from "react";

import { ButtonProps } from "components/ui/Button";
import LockedButton from "components/ui/LockedButton";
import { LockedButtonProps } from "components/ui/LockedButton/LockedButton";
import { MaybeTipProvider } from "components/ui/TipProvider/TipProvider";
import { TipType } from "store/TipStore";
import { classes } from "typestyle";

import sharedStyles, { buttonStyles, linkStyles } from "./GetStartedBox.styles";

export type GetStartedBoxProps = {
  className?: string;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  caption?: string | ReactNode;
  action?: () => void;
  linkClassName?: string;
  /** ButtonProps - Is overwritten by default rules of the GetStartedBox component */
  lockedButtonProps?: Partial<LockedButtonProps>;
  name: string;
  type: "button" | "link" | "outlined";
  /** Explicitly set button variant */
  buttonVariant?: ButtonProps["variant"];
  image?: JSX.IntrinsicElements["img"] | React.ReactNode;
  secondaryButtonProps?: Partial<LockedButtonProps>;
  secondaryName?: string;
  secondAction?: () => void;
  secondaryVariant?: ButtonProps["variant"];
  tipType?: TipType;
};

const isTypeButton = (type: "button" | "link" | "outlined") =>
  type === "button";

/**
 * todo reimplement based on https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=12929%3A84669&t=HT9uWwyNrWHXgtlQ-0
 * - name suggestion: CallToActionCard
 */
export default function GetStartedBox({
  className,
  title,
  subtitle,
  caption,
  name,
  type,
  action = () => {},
  linkClassName,
  lockedButtonProps,
  buttonVariant,
  image,
  secondaryButtonProps,
  secondaryName,
  secondAction,
  secondaryVariant,
  tipType,
}: GetStartedBoxProps): JSX.Element {
  const isButton = isTypeButton(type);

  const styles = isButton ? buttonStyles : linkStyles;

  return (
    <div className={classes(styles.containerBox, className)}>
      <div className={styles.imageAndDescription}>
        <div className={styles.illustrationContainer}>
          {image &&
          //render <img> if image has src
          // render children if image is a ReactNode
          typeof image === "object" &&
          "src" in image ? (
            <img
              {...image}
              alt="Illustration"
              className={classes(
                !isButton && linkStyles.illustration,
                image?.className
              )}
            />
          ) : (
            image
          )}
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          {subtitle && <h2 className={sharedStyles.subtitle}>{subtitle}</h2>}
          {caption && <p className={sharedStyles.caption}>{caption}</p>}
          <div className={sharedStyles.buttonRow}>
            <div className={sharedStyles.buttonContainer}>
              <MaybeTipProvider type={tipType}>
                {isButton ? (
                  <LockedButton
                    {...(lockedButtonProps as LockedButtonProps)}
                    variant={buttonVariant || "normal"}
                    color="orange"
                    size="small"
                    disableElevation
                    onClick={action}
                  >
                    {name}
                  </LockedButton>
                ) : (
                  <LockedButton
                    {...(lockedButtonProps as LockedButtonProps)}
                    color="orange"
                    variant={
                      lockedButtonProps?.variant ?? buttonVariant ?? "link"
                    }
                    disableElevation
                    size="small"
                    onClick={action}
                    className={linkClassName}
                  >
                    {name}
                  </LockedButton>
                )}
              </MaybeTipProvider>
            </div>

            {!!secondAction && (
              <div className={sharedStyles.buttonContainer}>
                {isButton ? (
                  <LockedButton
                    {...(secondaryButtonProps as LockedButtonProps)}
                    variant={secondaryVariant || "normal"}
                    color="orange"
                    disableElevation
                    size="small"
                    onClick={secondAction}
                  >
                    {secondaryName}
                  </LockedButton>
                ) : (
                  <LockedButton
                    {...(secondaryButtonProps as LockedButtonProps)}
                    color="orange"
                    variant={
                      secondaryButtonProps?.variant ?? buttonVariant ?? "link"
                    }
                    disableElevation
                    size="small"
                    onClick={secondAction}
                    className={linkClassName}
                  >
                    {secondaryName}
                  </LockedButton>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
