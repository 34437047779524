import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { px } from "csx";
import { clickable, zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { keyframes, stylesheet } from "typestyle";

export const likeAnimation = keyframes({
  "20%": { color: utilityColors.ORANGE },
  "70%": { rotate: "-15deg", scale: "1.3", filter: "blur(0.5px)" },
});

export const reactionCountStyles = stylesheet({
  paper: {
    padding: "12px",
    overflow: "auto",
    maxHeight: "220px",
    background: primaryColors.WHITE,
    color: primaryColors.BLACK,
    ...typography.BODY2,
  },
  user: { ...zeroPaddingMargin, margin: "4px 0px" },
});

export const styles = stylesheet({
  card: {
    width: "100%",
    backgroundColor: neutralColors.GRAY0,
    padding: "19px 21px",
    borderColor: neutralColors.GRAY3,
    borderStyle: "solid",
    borderRadius: "8px",
    borderWidth: "1px",
    display: "flex",
    flexDirection: "column",
  },
  editableMainContainer: {
    display: "flex",
    flexDirection: "row",
    gap: px(11),
  },
  nonEditableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: px(11),
  },
  postCompanyName: {
    ...typography.HEADLINE6,
    color: primaryColors.BLACK,
    textAlign: "left",
    alignContent: "center",
  },

  postCardHeader: { display: "flex", flexDirection: "row", gap: px(11) },
  companyDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  content: { ...typography.BODY2, padding: px(10) },
  attachmentsContainer: {
    margin: "8px 0px",
  },
  reactionsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: px(10),
    paddingLeft: px(10),
  },
  interactionDivider: {
    background: neutralColors.GRAY3,
  },
  likeButton: {
    height: px(18),
    cursor: "pointer",
    $nest: {
      "&:hover": {
        color: neutralColors.GRAY3_HOVER,
      },
      "&:focus": {
        color: utilityColors.ORANGE,
      },
    },
  },
  likeButtonClicked: {
    color: utilityColors.ORANGE,
    animationName: likeAnimation,
    animationDuration: "0.5s",
    animationIterationCount: "1",
  },
  reactionCount: {
    ...clickable,
    ...typography.CAPTION,
    color: primaryColors.BLACK,
    marginLeft: px(4),
  },
  commentsCount: {
    ...zeroPaddingMargin,
    ...typography.CAPTION,
    marginLeft: "auto",
    color: primaryColors.BLACK,
  },
  commentsContainer: { display: "flex", flexDirection: "column", gap: px(12) },
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    gap: px(30),
  },

  contentBox: { display: "flex", flexDirection: "column", width: "100%" },
  companyAvatar: { borderRadius: "100%" },
  iconButton: {
    height: px(18),
  },
  approvePostButtonsContainer: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    gap: px(16),
  },
  loadCommentsButton: {
    alignSelf: "baseline",
  },
  attachmentIconsContainer: {
    display: "flex",
    alignItems: "center",
    gap: px(8),
  },
  editIconsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: px(8),
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: px(8),
  },
});
