import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { GroupDto } from "@pulsemarket/api-client";
import { ROUTES } from "@pulsemarket/constants";
import { Loading } from "components/FullScreenLoading";
import { LeaveGroupModal } from "components/Group/modals";
import { ButtonProps } from "components/ui/Button";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import ModalContext from "contexts/ModalContext";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useRequestGroupAccess from "hooks/queries/useRequestGroupAccess";
import useSetGroupInvitationStatus from "hooks/queries/useSetGroupInvitationStatus";
import { GroupSettingTab } from "pages/PulseGroup/GroupSettingsPage/GroupSettingsPage";
import {
  GroupInvitationStatus,
  GroupMemberRelationStatus,
  Identifiable,
} from "shared/model";

import GroupCard from "../GroupCard";

function JoinableGroupCard({ group }: { group: GroupDto }) {
  const history = useHistory();
  const { mutate: requestAccessToGroup } = useRequestGroupAccess();

  const { data: ownCompany } = useOwnCompany();
  const { closeModal, openModal } = useContext(ModalContext);

  const groupCardActionButton = (
    group: GroupDto
  ): Record<GroupMemberRelationStatus, ButtonProps & Identifiable> => {
    return {
      [GroupMemberRelationStatus.JoinRequestPending]: {
        id: `${group.id}-group-button1`,
        children: "Pending Approval",
        disabled: true,
        variant: "transparent",
        color: "gray",
      },
      [GroupMemberRelationStatus.PrivateJoinable]: {
        id: `${group.id}-group-join`,
        children: "Ask to join",
        variant: "outlined",
        color: "orange",
        onClick: () => {
          requestAccessToGroup({ groupId: group.id });
        },
      },
      [GroupMemberRelationStatus.Owned]: {
        id: `${group.id}-group-button1`,
        children: "Add member",
        variant: "outlined",
        color: "orange",
        onClick: () =>
          history.push(ROUTES.GROUP(group.id), {
            initialSettingsPage: GroupSettingTab.Members,
          }),
      },
      [GroupMemberRelationStatus.Invited]: {
        id: `${group.id}-group-button1`,
        children: "Join",
        variant: "outlined",
        color: "orange",
        onClick: () => {
          setGroupInvitationStatus({
            groupInvitationId: group.invitationId!,
            status: GroupInvitationStatus.Joined,
          });
          history.push(ROUTES.GROUP(group.id));
        },
      },
      [GroupMemberRelationStatus.PublicJoinable]: {
        id: `${group.id}-group-button1`,
        children: "Join",
        variant: "outlined",
        color: "orange",
        onClick: () => {
          requestAccessToGroup({ groupId: group.id });
          history.push(ROUTES.GROUP(group.id));
        },
      },
      [GroupMemberRelationStatus.Removed]: {
        id: `${group.id}-group-join`,
        children: "Ask to join",
        variant: "outlined",
        color: "orange",
        onClick: () => {
          requestAccessToGroup({
            groupId: group.id,
          });
        },
      },
      [GroupMemberRelationStatus.Joined]: {
        ...(group.allowMemberInvitations || !group.isPrivate
          ? {
              id: `${group.id}-group-button1`,
              children: "Add member",
              variant: "outlined",
              color: "orange",
              onClick: () =>
                history.push(ROUTES.GROUP(group.id), {
                  initialSettingsPage: GroupSettingTab.Members,
                }),
            }
          : {
              id: `${group.id}-group-button1_blank`,
              variant: "transparent",
            }),
      },
    };
  };

  const { mutateAsync: setGroupInvitationStatus } =
    useSetGroupInvitationStatus();

  if (!ownCompany) {
    return <Loading isWindowed />;
  }

  return (
    <GroupCard
      group={group}
      buttons={[
        ...[groupCardActionButton(group)[group.memberStatus]],

        {
          id: `${group.id}-group-view`,
          children: "View group",
          variant: "outlined",
          color: "purple",
          onClick: () => {
            history.push(ROUTES.GROUP(group.id));
          },
        },
      ]}
      kebabMenuActions={[
        {
          text: "Copy link to group",
          onClick: () => {
            navigator.clipboard.writeText(
              `${window.location.origin}/${ROUTES.GROUP(group.id)}`
            );
            openModal(
              <SimpleModalBody
                title="Link copied"
                buttons={[
                  {
                    variant: "normal",
                    color: "orange",
                    onClick: () => closeModal(),
                    children: "Ok, Thanks",
                  },
                ]}
              >
                The link was copied. Paste to share this group with others.
              </SimpleModalBody>,
              { keepHistory: true }
            );
          },
        },
        ...(group.memberStatus === GroupMemberRelationStatus.Joined &&
        !!group.invitationId
          ? [
              {
                text: "Leave this group",
                onClick: () => {
                  if (!group.invitationId) {
                    return;
                  }
                  openModal(
                    <LeaveGroupModal
                      activeGroupInvitationId={group.invitationId}
                    />
                  );
                },
              },
            ]
          : []),
        ...(group.memberStatus === GroupMemberRelationStatus.Owned
          ? [
              {
                text: "Settings",
                onClick: () =>
                  history.push(ROUTES.GROUP(group.id), {
                    initialSettingsPage: GroupSettingTab.Settings,
                  }),
              },
            ]
          : []),
      ]}
    />
  );
}

export default JoinableGroupCard;
