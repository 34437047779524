import { percent, px } from "csx";
import { forceTextOneLine } from "shared/styles/styles";
import { style } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

import { fontWeight } from "./typography";

export const colorClass = (color: NestedCSSProperties["color"]) =>
  style({
    color,
  });
export const hoverColorClass = (color: NestedCSSProperties["color"]) =>
  style({
    $nest: { "&::hover": { color } },
  });
export const backgroundColorClass = (
  backgroundColor: NestedCSSProperties["backgroundColor"]
) =>
  style({
    backgroundColor,
  });

export const circleClass = style({
  borderRadius: percent(100),
});

export const semiBold = style({
  fontWeight: fontWeight.SEMI_BOLD,
});

export const width = (width: NestedCSSProperties["width"]) =>
  style({
    width,
  });
export const height = (height: NestedCSSProperties["height"]) =>
  style({
    height,
  });
export const fullWidthClass = style({
  width: percent(100),
});

export const fullHeightClass = style({
  height: percent(100),
});

export const zeroPaddingMargin = {
  padding: 0,
  margin: 0,
};

// TODO replace all occurences of this class, add the "zeroPaddingMargin" style object instead
export const zeroPaddingMarginClass = style({
  padding: 0,
  margin: 0,
});
export const preWrap = style({
  whiteSpace: "pre-wrap",
});

export const noOverFlow = style({
  overflow: "hidden",
});
export const noTextOverflowClass = style(forceTextOneLine);
export const leftBorderClass = (borderRadius: number, width: number) =>
  style({
    position: "absolute",
    left: 0,
    top: 0,
    height: percent(100),
    width: px(width),
    borderTopLeftRadius: px(borderRadius),
    borderBottomLeftRadius: px(borderRadius),
  });

export const relative = style({
  position: "relative",
});
export const absolute = style({
  position: "absolute",
});

export const block = style({
  display: "block",
});
export const hidden = style({
  visibility: "hidden",
});

export const marginTopClass = (size: NestedCSSProperties["marginTop"]) =>
  style({
    marginTop: size,
  });
export const marginBottomClass = (size: NestedCSSProperties["marginBottom"]) =>
  style({
    marginBottom: size,
  });
export const marginRightClass = (size: NestedCSSProperties["marginRight"]) =>
  style({
    marginRight: size,
  });
export const marginLeftClass = (size: NestedCSSProperties["marginLeft"]) =>
  style({
    marginLeft: size,
  });

export const autoMarginLeft = style({
  marginLeft: "auto",
});
export const autoMarginRight = style({
  marginRight: "auto",
});

export const paddingLeft = (size: NestedCSSProperties["paddingLeft"]) =>
  style({
    paddingLeft: size,
  });
export const paddingRight = (size: NestedCSSProperties["paddingRight"]) =>
  style({
    paddingRight: size,
  });
export const paddingTop = (size: NestedCSSProperties["paddingTop"]) =>
  style({
    paddingTop: size,
  });
export const paddingBottom = (size: NestedCSSProperties["paddingBottom"]) =>
  style({
    paddingBottom: size,
  });

export const noSelectStyles: NestedCSSProperties = {
  userSelect: "none",
  pointerEvents: "none",
};

export const clickable = {
  cursor: "pointer",
};

// TODO replace all occurences of this class, add the "clickable" style object instead
export const clickableClass = style({
  ...clickable,
});

export const textAlignCenterClass = style({
  textAlign: "center",
});
