import React from "react";

import GetStartedBox from "components/GetStartedBox";
import Link from "components/ui/Link";
import SvgIcon from "components/ui/SvgIcon";
import { CompanyDetailsDto } from "shared/model";

import ProductsOrServices from "./ProductOrService";

import styles from "./ProductsAndServicesStyles";

interface ProductsAndServicesProps {
  company: CompanyDetailsDto;
  isOwner: boolean;
  historyPush: (url: string) => void;
}

const getStarted = {
  title: "Products and Services",
  subtitle: "Showcase areas of expertise",
  caption:
    "Add and tag your company’s range of products, services and skills in one place, optimised for buyers actively searching for just what you offer. ",
  image: { src: "/illustrations/illustration_productsandservices.png" },
  name: "Add Products and Services",
};

export default function ProductsAndServices({
  company,
  isOwner,
  historyPush,
}: ProductsAndServicesProps) {
  return (
    <div className={styles.mainContainer}>
      {!company.productsAndServices.length && isOwner && (
        <GetStartedBox
          type="link"
          action={() => historyPush(`/company/edit/productsandservices`)}
          {...getStarted}
        />
      )}
      {!!company?.productsAndServices?.length && (
        <>
          {company?.productsAndServices?.map((element) => (
            <ProductsOrServices
              key={element.id}
              image={element.image}
              title={element.title}
              description={element.description}
            />
          ))}
          {isOwner && (
            <div className={styles.addCardContainer}>
              <div className={styles.linkContainer}>
                <Link url="/company/edit/productsandservices">
                  <SvgIcon
                    src="/icons/add_small_icon.svg"
                    className={styles.icon}
                  />
                  Add Product or Service
                </Link>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
