import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { Button, Menu, MenuItem } from "@material-ui/core";
import { neutralColors, primaryColors } from "@pulsemarket/constants";
import SvgIcon from "components/ui/SvgIcon";
import { colorClass } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { classes, style } from "typestyle";

export type KebabMenuAction = {
  text: string;
  disabled?: boolean;
  onClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    boxId?: string
  ) => void;
  entryIcon?: React.ReactNode;
  disableCloseAfterAction?: boolean;
};

export type KebabMenuProps = {
  className?: string;
  actions: KebabMenuAction[];
  icon?: React.ReactNode;
};

const KebabMenu = React.forwardRef<HTMLButtonElement, KebabMenuProps>(
  (props, refx) => {
    const ref = useRef<HTMLButtonElement>(null);

    useImperativeHandle(refx, () => ref.current!);

    const [visible, setVisible] = useState(false);

    const closeMenu = useCallback(() => {
      setVisible(false);
    }, []);

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Button
          className={props.className}
          ref={ref}
          size="small"
          style={{ minWidth: 0 }}
          onClick={(e) => {
            e.stopPropagation();

            setVisible(true);
          }}
        >
          {props.icon || (
            <SvgIcon
              src="/icons/kebab_menu.svg"
              className={classes(
                style({ height: "14px" }),
                colorClass(neutralColors.GRAY5)
              )}
            />
          )}
        </Button>

        <Menu
          open={visible}
          anchorEl={ref.current}
          onClose={closeMenu}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {props.actions.map((action) => {
            return (
              <MenuItem
                className={style({
                  ...typography.BODY1,
                  color: primaryColors.BLACK,
                })}
                key={action.text}
                disabled={action.disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  action.onClick(e);
                  if (action.disableCloseAfterAction) {
                    return;
                  }
                  closeMenu();
                }}
              >
                {action.entryIcon && (
                  <span className={style({ marginRight: "14px" })}>
                    {action.entryIcon}
                  </span>
                )}
                {action.text}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
);

export default KebabMenu;
