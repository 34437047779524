import React from "react";

import { Form as FormikForm, FormikProvider } from "formik";
import { useFormikReturnType } from "shared/utils/formik";

type Props = {
  className?: string;
  children: React.ReactNode;
  formik: useFormikReturnType<any>;
  saveOnEnter?: boolean;
};

function Form({ className, children, formik, saveOnEnter = true }: Props) {
  return (
    <FormikProvider value={formik}>
      <FormikForm className={className}>
        {children}

        {saveOnEnter && <input type="submit" hidden />}
      </FormikForm>
    </FormikProvider>
  );
}

export default Form;
