import { Colored, HasIcon, Selectable } from "components/ui/Table/types";

export enum ListItemType {
  Proposal = "Proposal",
  Award = "Award",
  Certificate = "Certificate",
  Image = "Image",
  Video = "Video",
  Module = "Module",
  Question = "Question",
  Policy = "Policy",
  Template = "Template",
  Folder = "Folder",
  File = "File",
  NewFolder = "NewFolder",
}

// Asset DTOs are extended by these types
export interface Identifiable {
  id: string;
}

// Colored and HasIcon interfaces can be removed...
// todo this type should be removed/renamed as it is a duplication, we have another ListItemType field on items that we show on asset repository
export interface HasDataType {
  dataType: ListItemType;
}

export type LocalRepositoryData = Identifiable &
  Selectable &
  HasDataType &
  Colored &
  HasIcon;

export type ExtendedWithLocalRepositoryData<T> = T & LocalRepositoryData;
