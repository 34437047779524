import { neutralColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  containerPaper: {
    backgroundColor: neutralColors.GRAY0,
    padding: "32px",
    width: "100%",
  },
});

export default styles;
