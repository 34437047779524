import React from "react";

import { classes } from "typestyle";

import styles from "./TagStyles";

type TagProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  children: React.ReactNode;
  className?: string;
};

export default function Tag({
  children,
  className,
  ...props
}: TagProps): JSX.Element {
  return (
    <span {...props} className={classes(styles.tag, className)}>
      {children}
    </span>
  );
}
