import React from "react";

import GetStartedBox from "components/GetStartedBox";
import PassportsList from "components/PassportsList";
import useIsAdmin from "hooks/queries/useIsAdmin";
import permissionMessages from "shared/constants/permissionMessages";
import { CompanyDetailsDto } from "shared/model";

import styles from "./PulsePassportStyles";

interface PulsePassportProps {
  company: CompanyDetailsDto;
  isOwner: boolean;
  historyPush: (url: string) => void;
}

const getPassport = {
  title: "The Pulse Passport",
  caption: `Get the Pulse passport to unlock self-assessment tools, knowledge guides and Pulse Passport icon to access your ESG position, 
  expand your knowledge and showcase the standards of excellence your company offers.`,
  image: { src: "/illustrations/illustration_pulsepassport.png" },
  name: "Get Passport",
};

export default function PulsePassport({
  company,
  isOwner,
  historyPush,
}: PulsePassportProps) {
  const { isAdmin, loading, error } = useIsAdmin();

  return (
    <div className={styles.mainContainer}>
      {!company.passports?.length && isOwner && (
        <GetStartedBox
          type="link"
          action={() => historyPush(`/company/edit/pulsepassport`)}
          {...getPassport}
          lockedButtonProps={{
            locked: !loading && !error && !isAdmin,
            tooltip: permissionMessages.pulsePassport,
          }}
        />
      )}

      {!!company.passports.length && (
        <PassportsList
          passports={company.passports}
          isOwner={isOwner}
          companyId={company.id}
        />
      )}
    </div>
  );
}
