import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import useGetCompany from "hooks/queries/useGetCompany";
import { useGroup } from "hooks/queries/useGroup";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useUserData from "hooks/queries/useUserData";
import {
  getGroupContentState,
  GROUP_TAB_URLS,
} from "pages/PulseGroup/Group/constants";
import { GroupAccessContext, GroupLayout } from "pages/PulseGroup/Group/types";
import { GroupSettingTab } from "pages/PulseGroup/GroupSettingsPage/GroupSettingsPage";
import useOpenGroupSettings from "pages/PulseGroup/GroupSettingsPage/useOpenGroupSettings";
import {
  CompanyDetailsDto,
  GroupDetailsDto,
  GroupInvitationDto,
} from "shared/model";
import { WithRequired } from "shared/utilityTypes";

export type GroupContextType = {
  layout: GroupLayout;
  group?: GroupDetailsDto;
  ownerCompany?: CompanyDetailsDto;
  isLoading?: boolean;
  isMember: boolean;
  isOwner: boolean;
  activeInvitation?: WithRequired<GroupInvitationDto, "invitedCompany">;
  access?: GroupAccessContext;
  shouldShowContent: boolean;
};

export const GroupContext = React.createContext<GroupContextType>({
  layout: "posts",
  isMember: false,
  isOwner: false,
  shouldShowContent: false,
});

export function useGroupContext() {
  return useContext(GroupContext);
}

const locationToLayout: Record<string, GroupLayout> = {
  [GROUP_TAB_URLS.posts]: "posts",
  [GROUP_TAB_URLS.files]: "files",
  [GROUP_TAB_URLS.allFiles]: "files",
  [GROUP_TAB_URLS.videos]: "files",
  [GROUP_TAB_URLS.images]: "files",
  [GROUP_TAB_URLS.documents]: "files",
};

export default function GroupProvider({
  children,
  groupId,
}: {
  children?: React.ReactNode;
  groupId: string;
}) {
  const location = useLocation();
  const history = useHistory();
  const { data: group, isLoading: groupLoading } = useGroup(groupId);
  const { data: ownerCompany, isLoading: ownerCompanyLoading } = useGetCompany(
    group?.ownerCompanyId
  );
  const { data: user } = useUserData();
  const { data: ownCompany, isLoading: ownCompanyLoading } = useOwnCompany();

  const initialSettingsPage = (
    location.state as { initialSettingsPage?: GroupSettingTab }
  )?.initialSettingsPage;

  const openGroupSettings = useOpenGroupSettings();

  useEffect(() => {
    if (!!initialSettingsPage && !!group) {
      //Clear history.state to prevent reopening settings modal on refresh
      window.history.replaceState({}, document.title);

      openGroupSettings({
        groupId: group.id,
        initialPage: initialSettingsPage,
      });
    }
  }, [
    openGroupSettings,
    group,
    history,
    history.location,
    initialSettingsPage,
  ]);

  const isLoading = groupLoading || ownerCompanyLoading || ownCompanyLoading;

  const activeInvitation = group?.members.find(
    (invitation) =>
      !!ownCompany?.id &&
      ownCompany.id &&
      invitation.invitedCompany?.id === ownCompany.id
  );

  const isMember = !!activeInvitation;
  const isOwner = !!ownCompany?.id && ownCompany.id === ownerCompany?.id;

  const access = getGroupContentState({
    companyId: ownCompany?.id,
    email: user?.email,
    group: group,
    isMember,
    isOwner,
  });

  const shouldShowContent =
    isOwner || isMember || access === GroupAccessContext.PublicJoinable;

  return (
    <GroupContext.Provider
      value={{
        layout: locationToLayout[location.hash] || "posts",
        group,
        ownerCompany,
        isLoading,
        isMember,
        isOwner,
        activeInvitation,
        access,
        shouldShowContent,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
}
