import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/ui/Button";
import Input from "components/ui/Input";
import { useFormik } from "formik";
import { useForgotPassword } from "hooks/queries/useAuth";

import { styles } from "./../AuthFormLayout/AuthForm.styles";

export default function ForgotPasswordForm({
  onSubmit,
}: {
  onSubmit: (email: string) => void;
}) {
  const { mutateAsync: forgotPassword } = useForgotPassword();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      try {
        await forgotPassword(values.email);
      } catch (err) {
        console.log(err);
      }
      onSubmit(values.email);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className={styles.root}>
        <h2 className={styles.title}>Forgot your password?</h2>
        <p className={styles.subtitle}>
          We'll email you instructions on how to reset your password
        </p>

        <Input
          className={styles.input}
          required
          name="email"
          autoComplete="username"
          type="email"
          label="Email"
          placeholder="Email address"
          value={formik.values.email}
          onChange={formik.handleChange("email")}
        />
      </div>

      <Button
        variant="normal"
        color="orange"
        fullWidth
        onClick={() => {
          formik.handleSubmit();
        }}
      >
        Reset password
      </Button>

      <div className={styles.helperTextContainer}>
        <p className={styles.helperText}>
          Remember the password?{" "}
          <Button
            variant="text"
            color="orange"
            onClick={() => {
              history.push("/");
            }}
          >
            Go Back to Login
          </Button>
        </p>
      </div>
    </form>
  );
}
