import {
  AnswerAdditionalData,
  AssetDto,
  AssetLabelDto,
  ChoiceDto,
  FolderDto,
  MatrixCellDto,
  RfpDto,
} from "@pulsemarket/api-client";
import {
  AttributeModelType,
  AttributeValueType,
  DashboardType,
  Direction,
  QuestionType,
  RfpResponseAnalysisStatus,
  RfpResponseEvaluationStatus,
  RfpResponseStatus,
  RFPType,
} from "@pulsemarket/constants";
import { ModuleEvaluation } from "components/rfps/RFPSettings/EvaluationCriteria/EvaluationCriteria";
import { PartialBy, WithRequired } from "shared/utilityTypes";

//Utility
export interface Identifiable {
  id: string;
  image?: string;
}
export interface Pair {
  name: string;
  id: string;
}
export type PaginationParams = {
  limit: number;
  offset: number;
};

// Dtos
export interface RfpQuestionTemplateDto {
  id: string;
  content: string;
}

export enum ContainerType {
  Assets = "assets",
  Rfps = "rfps",
  images = "images",
  DataRoom = "dataRoom",
  Passports = "passports",
  Reports = "reports",
}

export enum ContextType {
  Users = "users",
  Companies = "companies",
  Groups = "groups",
}

export enum RfpInvitationStatus {
  Pending = "Pending",
  Accepted = "Accepted",
  Declined = "Declined",
  Responded = "Responded",
}

export enum DynamicRfpInvitationStatus {
  Pending = "Pending",
  Declined = "Declined",
  Accepted = "Accepted",
  Responded = "Responded",
  /** After due date and it's Pending */
  NotResponded = "NotResponded",
  Expired = "Expired",
}

/** = not passport. Required as a separate enum because of JS loops */
export enum StandardRFPType {
  RFI = "RFI",
  RFP = "RFP",
  DDQ = "DDQ",
  QTP = "QTP",
}

export interface RfpInvitation {
  id: string;
  rfpId: string;
  companyId: string;
  invitedCompany: string;
  userId: string;
  status: DynamicRfpInvitationStatus;
  responseDate: string;
  distributionDate: string;
  invitedCompanyName?: string;
  invitedCompanyId?: string;
  invitedSupplierId?: string;
  invitedEmail?: string;
  invitedCompanyAvatar?: string;
  contactJobTitle?: string;
  contactName?: string;
  contactEmail?: string;
  responseId?: string;
}

export enum RFPStatus {
  Draft = "Draft",
  Distributed = "Distributed",
  InReview = "InReview",
  AnalyticalReview = "AnalyticalReview",
  Completed = "Completed",
}

export enum EvaluationType {
  QuestionnaireAndQuestion = "QuestionnaireAndQuestion",
  Question = "Question",
  Questionnaire = "Questionnaire",
}

export type NewRFP = {
  name: string;
  dueDate: Date;
  budget: number;
  ownerName: string;
  ownerTitle: string;
  ownerEmail: string;
  type: RFPType;
};

export type CloneRfxParams = {
  orignalRfxId: string;
  targetCompanyId: string;
};

export type RfpFilters = {
  name?: string;
  statusIds?: string[];
  type?: RFPType;
  sort?: { [key in keyof RfpDto]?: "asc" | "desc" };
  pagination?: PaginationParams;
};

export type RfpInvitationFilters = {
  statusIds?: string[];
  respondedAfter?: Date | null;
  respondedBefore?: Date | null;
  distributedAfter?: Date | null;
  distributedBefore?: Date | null;
};

/** Filters for quering submitted responses for a specific RFP */
export type ReceivedResponseFilters = {
  evaluationStatuses?: RfpResponseEvaluationStatus[];
  analysisStatuses?: RfpResponseAnalysisStatus[];
  distributedAfter?: Date | null;
  distributedBefore?: Date | null;
  respondedAfter?: Date | null;
  respondedBefore?: Date | null;
  reviewedAfter?: Date | null;
  reviewedBefore?: Date | null;
};

/** Filters for quering responses that a company was invited to answer */
export type RfpResponseFilters = {
  statusIds?: string[];
  rfpName?: string;
  type?: RFPType;
  invitedCompanyId?: string;
  invitedSupplierId?: string;
};

export type SupplierFilters = {
  ids?: string[];
  name?: string;
  risk?: SupplierRiskCategory[];
  cost?: SupplierCostCategory[];
  status?: string[];
  passport?: string[];
  response?: StandardRFPType[];
  responseStatus?: Exclude<
    DynamicRfpInvitationStatus,
    DynamicRfpInvitationStatus.Expired
  >[];
  products?: string[];
  attributes?: AttributeChoiceFilterInput[];
  groupId?: string;
};

export type SupplierProductFilter = {
  ids?: string[];
  name?: string;
  status?: string[];
  supplierId?: string[];
  attributes?: AttributeChoiceFilterInput[];
};
export type SupplierGroupFilter = {
  name?: string;
};

export type AttributeFilter = {
  attributeTypeId: string;
  attributeChoiceIds: string[];
};

export type AttributeChoiceFilterInput = {
  attributeTypeId: string;
  attributeTypeName: string;
  choices: (AttributeChoiceDto & { isSelected: boolean })[];
};

export type AttributeSchemaQuery = {
  withChoicesOnly?: boolean;
  showHidden?: boolean;
};

export type IncomingRfpDto = {
  name: string;
  type: RFPType;
  id: string;
  dueDate: string;
  distributedOn?: string;
  responseStatus: DynamicRfpResponseStatus;
  rfpResponseId?: string;
  rfpInvitationId?: string;
  rfpInvitationStatus?: DynamicRfpInvitationStatus;
  responseDate?: string;
  companyName: string;
  distributionDate: string;
};

export type RfpWithWeightsDto = {
  id: string;
  modules: ModuleEvaluation[];
};

export type UpdateWeightsInModulesInput = {
  moduleWeights: ModuleEvaluation[];
};

export type ScoreSettingDto = {
  id: string;
  score: number;
  description: string;
  title: string;
};

export type CreateScoreSettingsInput = {
  title: string;
  isDefault: boolean;
  scoreSettings: SetScoreNamesInput[];
};

export type UpdateScoreSettingsInput = {
  scoreSettingsGroupId: string;
  title?: string;
  isDefault?: boolean;
  scoreSettings?: SetScoreNamesInput[];
};

export type SetScoreNamesInput = Pick<
  ScoreSettingDto,
  "score" | "title" | "description"
>;

export type UpdateEvaluationTypeInput = {
  evaluationType: EvaluationType;
};

export enum DynamicRfpResponseStatus {
  /** Applies to unanswered invitations */
  New = "New",
  Draft = "Draft",
  /** Read from invitation status */
  Declined = "Declined",
  Submitted = "Submitted",
  InReview = "InReview",
  /** After due date and it's not submitted nor declined */
  NotResponded = "NotResponded",
}

export interface NewRfpResponse {
  rfpId: string;
  /** `Draft` by default */
  status?: RfpResponseStatus;
  ownerName: string;
  ownerTitle: string;
  ownerEmail: string;
  declineNote?: string;
  accept: boolean;
}

export type UpdateRfpResponseInput = {
  ownerEmail?: string;
  ownerTitle?: string;
  ownerName?: string;
  declineNote?: string;
  status?: RfpResponseStatus;
  evaluationStatus?: RfpResponseEvaluationStatus;
  analysisStatus?: RfpResponseAnalysisStatus;
};

export interface RfpResponseDetailedScores {
  totalScore: number;
  evaluationType: string;
  modules: {
    id: string;
    title: string;
    questionnaires: {
      id: string;
      title: string;
      order: number;
      weight: number;
      score: number;
      questions: {
        id: string;
        order: number;
        score: number;
        weight: number;
        weightedScore: number;
        answerId: string | null;
        type: QuestionType;
        nextQuestionId: string | null;
        isIfQuestion: boolean;
        choices: { id: string; nextQuestionId: string | null }[];
      }[];
    }[];
  }[];
}

type AdditionalData =
  | Omit<AnswerAdditionalData, "choiceId">
  | AnswerAdditionalData[];

export type FolderItemDto = FolderDto | AssetDto;

export type FolderListDto = {
  id: string;
  name: string;
  children: FolderListDto[];
};

export type FolderTreeFilters = {
  isHidden?: boolean;
};

export interface PathDto {
  name: string;
  folderId: string;
}

export type NewFolder = {
  companyId: string;
  userId: string;
  name: string;
  parentFolderId: string;
  labels?: AssetLabelDto[];
};

export type UploadAsset = {
  id: string | null;
  companyId: string;
  userId: string;
  parentFolderId: string | null;
  name: string;
  createdAt: string;
};

export type AssetSearchOptions = {
  name: string;
  labelIds?: string[];
  folderId?: string;
  showHidden: boolean;
};

export interface UpdateQuestionInput {
  content?: string;
  order?: number;
  isVerified?: boolean;
  weight?: number;
  type?: QuestionType;
  options?: string;
  newChoice?: PartialBy<ChoiceDto, "id">;
  deletedChoiceId?: ChoiceDto["id"];
  updatedChoice?: Omit<ChoiceDto, "order"> & { order?: undefined };
  isMandatory?: boolean;
  isKillerQuestion?: boolean;
  isIfQuestion?: boolean;
  isAttachmentAllowed?: boolean;
  isAutoScored?: boolean;
  autoScoringDirection?: Direction | null;
  isAttachmentMandatory?: boolean;
  isDateAllowed?: boolean;
  isDateMandatory?: boolean;
  isExpirationMandatory?: boolean;
  /** set to null to remove relation */
  nextQuestionId?: string | null;
  note?: string | null;
}

export interface StandardizedQuestionDto {
  content: string;
}

export interface StandardizedQuestionnaireDto {
  id: string;
  title: string;
  introduction: string;
  questions: StandardizedQuestionDto[];
}

export interface UpdateAnswerInput {
  content?: string;
  additionalData?: AdditionalData;
  note?: string;
}

export type ScoreAnswerInput = {
  score: number;
};

export interface ProductOrService {
  id: string;
  image: string;
  title: string;
  description: string;
}

export interface CompanyDto {
  id: string;
  name: string;
  employees: Pair;
  avatar?: string;
  backgroundImage?: string;
  phoneNumber: string;
  address: string;
  city?: string;
  zipCode?: string;
  state?: string;
  country?: Pair;
  linkedInUrl?: string;
  contactName?: string;
  email?: string;
  youtubeUrl?: string;
  website?: string;
  about?: string;
  industries: Pair[];
  sector: Pair[];
  specialities: Pair[];
  servicing: Pair[];
  zones: Pair[];
  companyListIds: string[];
  productsAndServices: ProductOrService[];
  creationDate: string;
  passports: PassportDto[];
}

export interface CompanyProfileDto {
  id: string;
  name: string;
  avatar?: string;
  backgroundImage?: string;
  about: string;
}

export enum MediaProvider {
  YouTube = "YouTube",
  Raw = "Raw",
}

export type MediaItemDto = {
  id: string;
  name: string;
  reference: string;
  provider: MediaProvider;
  createdAt: Date;
  updatedAt: Date;
};

export type DataRoomDto = {
  id: string;
  name: string;
  visitors: number;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  rootFolderId: string;
  ownerId: string | null;
  videoCount: number | null;
  documentCount: number | null;
};

export type DataRoomFilters = {
  search?: string | null;
  createdAfter?: Date | null;
  createdBefore?: Date | null;
};

export type DataRoomDetailsDto = {
  id: string;
  name: string;
  visitors: number;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  folderId: string;
  owner: Pick<
    UserDto,
    "firstName" | "lastName" | "id" | "profileImage" | "jobTitle"
  > | null;
};

export type DataRoomInvitationDto = {
  id: string;
  email: string;
  invitedUser?: { id: string; profileImage?: string };
};

export type CreateDataRoomInvitationInput = {
  emails: string[];
};

export type UpdateDataRoomInput = {
  name: string;
};

export type CreateDataRoomInput = {
  name: string;
};

export type CloneAssetsInput = {
  assetIds: string[];
  folderIds: string[];
};

export type CompanyDetailsDto = {
  id: string;
  name: string;
  employees?: Pair;
  avatar?: string;
  backgroundImage?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  country?: Pair;
  linkedInUrl?: string;
  website?: string;
  about?: string;
  headline?: string;
  rssUrl?: string;
  industries: Pair[];
  sectors: Pair[];
  specialities: Pair[];
  servicing: Pair[];
  zones?: Pair[];
  productsAndServices: ProductOrService[];
  companyLists: CompanyListDto[];
  creationDate: string;
  youtubeUrl?: string;
  passports: PassportDto[];
  email?: string;
  contactName?: string;
  invitations?: CompanyMemberInvitation[];
  assetRepositoryId?: string;
  attributes: AttributeTypeWithValuesDto[];
  dashboardType: DashboardType;
};

export type CompanyUpdateDto = {
  id: string;
  name: string;
  employees?: string;
  avatar?: string;
  backgroundImage?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  country?: string;
  linkedInUrl?: string;
  youtubeUrl?: string;
  website?: string;
  about?: string;
  headline?: string;
  rssUrl?: string;
  industries: string[];
  sectors: string[];
  specialities: string[];
  servicing: string[];
  zones?: string[];
  companyListIds: string[];
  productsAndServicesIds: string[];
  creationDate: string;
  passports: CompanyPassportUpdateDto[];
  email?: string;
  contactName?: string;
  attributes?: SetAttributeInput[];
};

export type CompanyFilter = {
  companyName?: string;
  country?: string[];
  industry?: string[];
  sectors?: string[];
  specialities?: string[];
  servicing?: string[];
  zones?: string[];
  employees?: string[];
  passports?: string[];
  fromSubset?: string[];
  companyListId?: string | null;
};

export type CompanyListDto = {
  id: string;
  name: string;
  companyIds: string[];
};

export type PassportTypeId =
  | "esg_health_check_v1"
  | "financial_service_readiness_v1";

export type PassportTypeDto = {
  id: PassportTypeId;
  name: string;
  description: string;
  surveyMonkeyUrl: string;
  color?: string;
};

export type CompanyPassportUpdateDto = {
  passportId: string;
  show: boolean;
};

export type PassportDto = {
  id: string;
  type: PassportTypeDto;
  show: boolean;
  createdAt: string;
  expirationDate: string;
  score: number;
};

export enum PassportShareConfig {
  None = "None",
  Public = "Public",
  /** Only share with companies who sent an invitation */
  Limited = "Limited",
}

export enum PassportStatus {
  None = "None",
  Draft = "Draft",
  InReview = "InReview",
  Submitted = "Submitted",
  Completed = "Completed",
  CloseToExpire = "CloseToExpire",
  Expired = "Expired",
}

export type PassportItemDto = {
  rfpResponseId: string;
  status: PassportStatus;
  passport?: PassportDto;
};

export enum PassportInvitationStatus {
  Pending = "Pending",
  Completed = "Completed",
}

export type PassportInvitationDto = {
  rfpId: string;
  rfpInvitationId: string;
  invitedCompanyId?: string;
  invitedSupplierId?: string;
  invitedEmail?: string;
  inviteeName: string;
  status: PassportInvitationStatus;
  rfpResponse?: PassportItemDto;
};

export type PassportInvitationFilter = {
  invitedCompanyId?: string;
  invitedSupplierId?: string;
  /** whether to include invitations where the invitee is the queriying company */
  includeSelf?: boolean;
};

export type CompanyCardDto = {
  id: string;
  name: string;
  avatar?: string;
  city?: string;
  country?: Pair;
  specialities?: Pair[];
};

export type NonIdentifiable<T> = Omit<T, "id">;
export type OldNewModule = {
  title: string;
  orgId: string;
  assetsId: string[];
};

/* Users */

export interface UserDto {
  id: string;
  companyId: string;
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  payments: Record<string, boolean>;
  seenWelcomePopup: boolean;
  emailNewRFP: boolean;
  emailRFPResponse: boolean;
  emailGroupInvitation: boolean;
  emailGroupUpdates: boolean;
  profileImage?: string;
  linkedInUrl?: string;
  role?: string;
}

export interface UserProfileDto {
  id: string;
  email: string;
  companyId: string;
  firstName: string;
  lastName: string;
  jobTitle?: string;
  profileImage?: string;
}

export enum InvitationType {
  rfp = "rfp",
  group = "group",
  companyMember = "companyMember",
}

export type InvitationInput = {
  type: InvitationType;
  id: string;
  inviterCompany?: string;
};

export type RegistrationInput = {
  user: NewUserInput;
  companyName?: string;
  invitation?: InvitationInput;
};

export type LoginInput = {
  email: string;
  password: string;
};

export interface User {
  id: string;
  companyIds: string[];
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  seenWelcomePopup: boolean;
  emailNewRFP: boolean;
  emailRFPResponse: boolean;
  emailGroupInvitation: boolean;
  emailGroupUpdates: boolean;
}

export interface NewUserInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface OldUserInput {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
}

export interface NewCompany {
  name: string;
  zones?: string[];
}

export interface CompanyMemberInvitation {
  invitationId: string;
  email: string;
  expirationDate: string;
  isActive: boolean;
  id: string;
}

/* Feedback */

export type FeedbackType = "Feedback" | "Suggestion" | "Issue";

export type Feedback = {
  type: FeedbackType;
  email: string;
  description: string;
};

export interface UserCompanyRole {
  id: string;
  userId: string;
  companyId: string;
  roleId: string;
}

export interface NewUserCompanyRole {
  userId: string;
  companyId: string;
  roleId: string;
}

export interface Role {
  id: string;
  name: string;
  description: string;
}

export interface NewGroup {
  adminId: string;
  name: string;
  description: string;
  zones: string[];
  isPrivate: boolean;
  isVisible: boolean;
  allowMemberInvitations: boolean;
}

export interface GroupDetailsDto {
  id: string;
  createdAt: string;
  ownerCompanyId: string;
  name: string;
  description: string;
  zones: Pair[];
  avatar: string;
  avatarBase64?: string;
  backgroundImage: string;
  backgroundImageBase64?: string;
  isPrivate: boolean;
  isVisible: boolean;
  allowMemberInvitations: boolean;
  onlyAdminCanCreateContent: boolean;
  postsRequireApproval: boolean;
  filesRequireApproval: boolean;
  invitations: GroupInvitationDto[];
  members: WithRequired<GroupInvitationDto, "invitedCompany">[];
  requestedAccess: WithRequired<GroupInvitationDto, "invitedCompany">[];
  datarooms?: Pick<DataRoomDto, "id" | "name">[];
}

export class GroupFilesDto {
  mediaItems?: (MediaItemDto & {
    status: GroupResourceApprovalStatus;
    userId: string;
  })[];
  attachments?: (AssetDto & {
    status: GroupResourceApprovalStatus;
    userId: string;
  })[];
  insertedMedia?: (MappedInsertedAsset & {
    status: GroupResourceApprovalStatus;
    userId: string;
  })[];
}

export type UserPreviewDto = {
  firstName: string;
  lastName: string;
  jobTitle?: string;
  profileImage?: string | null;
};

export interface PostWithReferencesDto {
  id: string;
  text: string;
  createdAt: Date;
  updatedAt?: Date;
  isDraft: boolean;
  isApproved: boolean;
  isEdited: boolean;
  author: UserProfileDto;
  company: CompanyProfileDto;
  reactionCount: number;
  isLiked: boolean;
  comments?: CommentDto[];
  commentsCount?: number;
  attachments?: AssetDto[];
  insertedMedia?: MappedInsertedAsset[];
  mediaItems: MediaItemDto[];
}

export type PostsQueryResult = {
  total: number;
  posts: PostWithReferencesDto[];
};

export type PostDto = {
  id: string;
  text: string;
  createdAt: Date;
  updatedAt?: Date;
  isDraft: boolean;
  groupId: string;
};

export type PostFilters = {
  isDraft?: boolean;
  isApproved?: boolean;
};

export type GroupFilesFilters = {
  isApproved?: boolean;
};

export type ReactionDetailsDto = {
  firstName: string;
  lastName: string;
  companyName: string;
};

export interface NewPostInput {
  text: string;
  isDraft: boolean;
}

export interface UpdatePostInput {
  text?: string;
  isDraft?: boolean;
  isApproved?: boolean;
}

export interface CommentDto {
  id: string;
  text: string;
  createdAt: Date;
  updatedAt?: Date;
  isEdited: boolean;
  attachments: AssetDto[];
  user: UserProfileDto;
  company: CompanyProfileDto;
  status: CommentStatus;
  answerId?: string;
}

export interface NewCommentInput {
  referenceId?: string;
  text?: string;
  referenceType: CommentReferenceType;
}

export type CommentReferenceType = "post" | "answer";

/** RFP Messages - used for notifications */
export type MessageDetailsDto = {
  id: string;
  anwerId: string;
  moduleId: string;
  rfpId: string;
  rfpResponseId: string;
  rfpName: string;
  rfpType: RFPType;
};

export class UpdateCommentInput {
  text?: string;
  postId?: string;
}

export enum GroupMemberRelationStatus {
  Owned = "Owned",
  Joined = "Joined",
  JoinRequestPending = "JoinRequestPending",
  PublicJoinable = "PublicJoinable",
  Invited = "Invited",
  PrivateJoinable = "PrivateJoinable",
  Removed = "Removed",
}

export interface GroupInvitationDto {
  id: string;
  createdAt: Date;
  joinDate?: Date;
  isJoinRequest: boolean;
  status: GroupInvitationStatus;
  updatedAt?: Date;
  invitedCompany?: CompanyDto;
  invitedSupplier?: SupplierDto;
  invitedEmail?: string;
}

export type CombinedInvitationTargetDto = {
  invitedEmail?: string | null;
  invitedSupplierId?: string | null;
  invitedCompanyId?: string | null;
};

export type CombinedInvitationRecipientInput = {
  type: CombinedInvitationRecipientType;
  invitee: string;
  name?: string;
};

export enum GroupInvitationStatus {
  Joined = "Joined",
  Approved = "Approved",
  Declined = "Declined",
  Denied = "Denied",
  Pending = "Pending",
  Removed = "Removed",
  WaitingForApproval = "WaitingForApproval",
  Left = "Left",
}

export enum CombinedInvitationRecipientType {
  Email = "Email",
  Company = "Company",
  Supplier = "Supplier",
}

export type CombinedInvitationInput = {
  type: CombinedInvitationRecipientType;
  invitee: string;
};

export enum GroupResourceApprovalStatus {
  Pending = "Pending",
  Approved = "Approved",
  Declined = "Declined",
}

export enum CommentStatus {
  Pending = "Pending",
  Sent = "Sent",
}

export class UpdateGroupInput {
  name?: string;
  description?: string;
  zones?: string[];
  datarooms?: string[];
  isPrivate?: boolean;
  isHidden?: boolean;
  allowMemberInvitations?: boolean;
  onlyAdminCanCreateContent?: boolean;
  postsRequireApproval?: boolean;
}

export type GroupSearchOptions = {
  name?: string;
  zones?: string[];
};

export type BlobStorageType = {
  containerType: ContainerType;
  contextType: ContextType;
};
export interface PaginatedDto<TData> {
  total: number;
  limit: number;
  offset: number;
  results: TData[];
}

export interface SupplierListItemDto {
  id: string;
  name: string;
  isActive: boolean;
  lastActivityDate?: string;
  avatar?: string;
  companyId?: string;
  passport?: PassportDto;
  contractsCount: number;
  responsesCount: number;
  productsCount: number;
  riskCategory: SupplierRiskCategory;
  cost: SupplierCostCategory;
}

export interface SupplierDto {
  id: string;
  avatar?: string;
  name: string;
  contactEmail: string;
  contactName?: string;
  contactPhone?: string;
  website?: string;
  isActive?: boolean;
  mappedCompanyId?: string;
  contractsCount: number;
  productsCount: number;
  folderId: string;
  cost: SupplierCostCategory;
  riskCategory: SupplierRiskCategory;
  overview?: string;
  attributes: AttributeTypeWithValuesDto[];
}

export interface NewSupplierInput {
  name: string;
  contactEmail: string;
  contactName?: string;
  contactPhone?: string;
  website?: string;
  isActive?: boolean;
  attributes?: SetAttributeInput[];
}

export interface UpdateSupplierInput {
  name?: string;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: string;
  website?: string;
  isActive?: boolean;
  riskCategory?: SupplierRiskCategory;
  cost?: SupplierCostCategory;
  overview?: string;
  attributes?: SetAttributeInput[];
}

export enum SupplierContractRiskCategory {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export enum SupplierCostCategory {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export enum SupplierRiskCategory {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export type SupplierContractDto = {
  id: string;
  name: string;
  startDate?: string;
  endDate?: string;
  budget: number;
  department?: string;
  riskCategory: SupplierContractRiskCategory;
  supplierRecordId: string;
  isActive: boolean;
  attachments?: AssetDto[];
};

export interface NewSupplierContractInput {
  supplierRecordId: string;
  name: string;
  startDate?: string;
  endDate?: string;
  budget: number;
  department?: string;
  riskCategory: string;
}

export interface UpdateSupplierContractInput {
  name?: string;
  startDate?: string;
  endDate?: string;
  budget?: number;
  department?: string;
  riskCategory?: string;
}

export type NewAttributeTypeInput = {
  name: string;
  description?: string;
  isHidden?: boolean;
  isRequired?: boolean;
  isSystemAttribute?: boolean;
  modelType: AttributeModelType;
  valueType: AttributeValueType;
  choices?: string[];
};

export type UpdateAttributeTypeInput = {
  name?: string;
  description?: string;
  isHidden?: boolean;
  isRequired?: boolean;
  valueType?: AttributeValueType;
  choices?: UpdateAttributeChoiceInput[];
};

export type UpdateAttributeChoiceInput = {
  id: string;
  value: string;
};

export type AttributeTypeDto = {
  id: string;
  name: string;
  desription?: string;
  isHidden: boolean;
  isRequired?: boolean;
  isSystemAttribute?: boolean;
  modelType: AttributeModelType;
  valueType: AttributeValueType;
  choices?: AttributeChoiceDto[];
};

export type AttributeValueOrChoice = {
  textValue?: string;
  numericValue?: number;
  dateValue?: Date;
  choices?: AttributeChoiceDto[];
};

export type AttributeDto = AttributeValueOrChoice & {
  attributeTypeId: string;
};

export type AttributeChoiceDto = {
  id: string;
  value: string;
};

export type AttributeTypeWithValuesDto = AttributeTypeDto & {
  relatedAttribute?: AttributeDto;
};

export type AttributeLookupTableGroupDto = {
  id: string;
  name: string;
  tables: AttributeLookupTableDto[];
};

export type AttributeLookupTableDto = {
  id: string;
  name: string;
  description?: string;
  isGlobal: boolean;
};

export type AttributeLookupTableValueDto = {
  attributeTypeId: string;
  value: AttributeDto;
};

export type AttributeLookupTableKeyDto = {
  attributeTypeId: string;
  choice: AttributeChoiceDto;
};

export type AttributeLookupTableRowDto = {
  id: string;
  keys: AttributeLookupTableKeyDto[];
  values: AttributeLookupTableValueDto[];
};

export enum AttributeLookupDataType {
  key = "key",
  value = "value",
}

export type AttributeLookupTableColumnDto = {
  attributeTypeId: string;
  name: string;
  choices: AttributeChoiceDto[];
  type: AttributeLookupDataType;
};

export type LookupTableDataDto = {
  columns: AttributeLookupTableColumnDto[];
  rows: AttributeLookupTableRowDto[];
  table: AttributeLookupTableDto;
};

export type SupplierProductDto = {
  id: string;
  name: string;
  supplierId: string;
  description?: string;
  isActive: boolean;
  isArchived: boolean;
  createdAt: Date;
  updatedAt: Date;
  supplier?: SupplierListItemDto;
  folderId: string;
};

export type DisonnectSuppliersFromGroupsInput = {
  supplierIds: string[];
  supplierGroupIds: string[];
};

export type UpdateSupplierGroupInput = {
  name: string;
};

export type SupplierProductDetailsDto = Omit<
  SupplierProductDto,
  "createdAt" | "updatedAt"
> & {
  attributes: AttributeTypeWithValuesDto[];
};

export interface NewSupplierProductInput {
  supplierId: string;
  name: string;
  description?: string;
  isActive?: boolean;
  attributes?: SetAttributeInput[];
}

export interface UpdateSupplierProductInput {
  supplierProductId: string;
  name?: string;
  description?: string;
  isActive?: boolean;
  isArchived?: boolean;
  attributes?: SetAttributeInput[];
}

export type SetAttributeInput = {
  attributeTypeId: string;
  textValue?: string;
  numericValue?: number;
  dateValue?: Date;
  choices?: string[];
};

export type CloneAttachmentsInput = {
  assetIds: string[];
};

export type NewCertificateInput = {
  name: string;
  fromDate: Date;
  toDate: Date;
  applicant?: string;
  number?: string;
  note?: string;
  auditor?: string;
  scope?: string;
  assetId: string;
};

export interface PulsePage {
  id: string;
  name: string;
  memberCount: number;
  avatar?: string;
}

export enum NotificationType {
  InvitedForRFP = "InvitedForRFP",
  InvitedForDDQ = "InvitedForDDQ",
  InvitedForRFI = "InvitedForRFI",
  InvitedForQTP = "InvitedForQTP",
  /** todo - is this needed? */
  InvitedForPassport = "InvitedForPassport",
  RFPResponseReceived = "RFPResponseReceived",
  DDQResponseReceived = "DDQResponseReceived",
  RFIResponseReceived = "RFIResponseReceived",
  QTPResponseReceived = "QTPResponseReceived",
  GroupInvitationReceived = "GroupInvitationReceived",
  GroupJoinRequestReceived = "GroupJoinRequestReceived",
  GroupInvitationApproved = "GroupInvitationApproved",
  GroupDeleted = "GroupDeleted",
  PostApproveRequestReceived = "PostApproveRequestReceived",
  PostApproved = "PostApproved",
  DocumentMessageReceived = "DocumentMessageReceived",
  DocumentReplyReceived = "DocumentReplyReceived",
  /** TBD - not on wireframes */
  // PassportResponseSent = "PassportResponseSent",
  OwnPassportReportReady = "OwnPassportReportReady",
  RenewPassport = "RenewPassport",
  InvitedContactPassportReportReady = "InvitedContactPassportReportReady",
}

export interface NotificationDto {
  id: string;
  createdAt: Date;
  isRead: boolean;
  type: NotificationType;
  relatedId: string;
  sentByCompany: CompanyDto;
}

export enum BlobType {
  UserProfile = "UserProfile",
  CompanyAvatar = "CompanyAvatar",
  CompanyBackGroundImage = "CompanyBackGroundImage",
  GroupAvatar = "GroupAvatar",
  GroupBackGroundImage = "GroupBackGroundImage",
  ProductsAndServices = "ProductsAndServices",
}

export interface NewImageInput {
  blobType: BlobType;
  userId?: string;
  companyId?: string;
  productId?: string;
  groupId?: string;
}

export interface NotificationsResponse {
  total: number;
  unread: number;
  notifications: NotificationDto[];
}

export type AttachmentMode = "attachment" | "insert";
export type RfpAttachmentTarget = "module" | "questionnaire" | "question";
export type MappedInsertedAsset = { id: string; url: string };

export type MatrixDto = {
  id: string;
  name: string;
  allowAdditonalRows: boolean;
  isDeletable: boolean;
  columns?: MatrixColumnDto[];
};

export type MatrixColumnDto = {
  field: string;
  type: string;
  matrixId: string;
  id: string;
  order: number;
};

export enum MatrixColumnType {
  number = "number",
  text = "text",
}

export type UpdateMatrixColumnInput = {
  matrixColumnId: string;
  field?: string;
  type?: MatrixColumnType;
  order?: number;
};

export type MatrixRowDto = {
  id: string;
  cells: MatrixCellDto[];
  company?: CompanyDto;
  rfp?: RfpDto;
};

export type MatrixDataDto = {
  rows: MatrixRowDto[];
  columns: MatrixColumnDto[];
};

// These types are duplicated with backend and were added after the API client generation was merged.
// Please re-merge with API-client generation branch and remove the below types and update the related imports to use @pulsemarket/api-client

export type QuestionFilter = {
  labelIds?: string[];
  rfxType?: RFPType;
};

export type QuestionLabelFilter = {
  rfxType?: RFPType;
};
