import { Dispatch, SetStateAction, useContext } from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import { primaryColors, utilityColors } from "@pulsemarket/constants";
import Button from "components/ui/Button";
import ModalContext from "contexts/ModalContext";
import { useFormik } from "formik";
import useSwitchCompany from "hooks/queries/useSwitchCompany";
import { connectToCompany } from "shared/client";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";
import { clickableClass, colorClass, semiBold } from "shared/styles/classes";
import { URL } from "shared/urls";
import { classes } from "typestyle";

import styles from "./ExistingUserInvitationPopup.styles";

type ExistingUserInvitationPopupProps = {
  className?: string;
  companyName: string;
  setLoadingMessage: Dispatch<SetStateAction<string | null>>;
  token: string;
  inviterCompanyId: string;
};

export default function ExistingUserInvitationPopup({
  className,
  companyName,
  setLoadingMessage,
  inviterCompanyId,
  token,
}: ExistingUserInvitationPopupProps) {
  const { closeModal } = useContext(ModalContext);
  const switchCompany = useSwitchCompany();

  const formik = useFormik<{ acceptedTerms: boolean }>({
    initialValues: { acceptedTerms: false },
    onSubmit: async function () {
      setLoadingMessage(
        "Registering user with company member invitation token"
      );

      await connectToCompany(token, inviterCompanyId);

      localStorage.setItem(ACTING_COMPANY_ID, inviterCompanyId);
      await switchCompany({ companyId: inviterCompanyId });

      closeModal();
      setLoadingMessage(null);
    },
    validate({ acceptedTerms }) {
      if (!acceptedTerms) {
        return {
          acceptedTerms: "Required",
        };
      }
    },
  });
  return (
    <div className={classes(styles.root, className)}>
      <h2 className={styles.title}>Welcome back to Pulse Market</h2>
      <p className={styles.infoText}>
        You've signed up for the
        <span className={styles.companyName}> {companyName}</span> team, and you
        can switch companies at any moment on the platform.
      </p>
      <img src="/illustrations/illustration_teams.svg" alt="" />
      <form onSubmit={formik.handleSubmit}>
        <div>
          {/* Could be a separate component, reused in RegistrationPopupContent */}
          <FormControlLabel
            className={styles.terms}
            control={
              <Checkbox
                checked={formik.values.acceptedTerms}
                name="checkedE"
                onChange={formik.handleChange("acceptedTerms")}
                style={{
                  color:
                    formik.errors.acceptedTerms && formik.touched.acceptedTerms
                      ? utilityColors.RED
                      : primaryColors.PURPLE2,
                }}
              />
            }
            label={
              <span>
                I agree with{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URL.PRIVACY_POLICY}
                  className={classes(
                    colorClass(primaryColors.PURPLE2),
                    clickableClass,
                    semiBold
                  )}
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={URL.TERMS_OF_USE}
                  className={classes(
                    colorClass(primaryColors.PURPLE2),
                    clickableClass,
                    semiBold
                  )}
                >
                  Terms of Use
                </a>
                .
              </span>
            }
          />
        </div>
        <div>
          <Button
            variant="normal"
            color="orange"
            type="submit"
            className={styles.submitButton}
          >
            Get Started
          </Button>
        </div>
      </form>
    </div>
  );
}
