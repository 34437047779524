import React from "react";

import SvgIcon from "components/ui/SvgIcon";
import { classes, style } from "typestyle";

import styles, {
  backgroundColor,
  borderColor,
  iconColor,
} from "./AttachmentRow.styles";

export type AttachmentStatus = "default" | "uploading" | "uploaded" | "error";

export type AttachmentRowProps = {
  name: string;
  size?: string;
  status: AttachmentStatus;
  onRemove?: () => void;
  onDownload?: () => void;
  className?: string;
  isDeleteIconHidden?: boolean;
  isDownloadIconHidden?: boolean;
  icon: {
    color: string;
    src: string;
  };
};

function UploadingInfo() {
  return (
    <>
      <div className={classes(styles.infoIconWrapper, iconColor("uploading"))}>
        <SvgIcon
          className={classes(styles.infoIcon)}
          src="/icons/replace_icon.svg"
        />
      </div>
      <div className={styles.infoText}>Uploading</div>
    </>
  );
}

function UploadedInfo() {
  return (
    <>
      <div className={classes(styles.infoIconWrapper, iconColor("uploaded"))}>
        <SvgIcon
          className={classes(styles.infoIcon)}
          src="/icons/check_icon.svg"
        />
      </div>
      <div className={styles.infoText}>Uploaded</div>
    </>
  );
}

function ErrorInfo({ onRemove }: { onRemove?: () => void }) {
  return (
    <>
      <div className={styles.errorInfoLeft}>
        <div className={classes(styles.infoIconWrapper, iconColor("error"))}>
          <SvgIcon
            className={classes(
              styles.infoIcon,
              style({ width: "20px", height: "20px", padding: "3px" })
            )}
            src="/icons/close_icon.svg"
          />
        </div>
        <div className={styles.infoText}>Error</div>
      </div>
      <SvgIcon
        onClick={onRemove}
        className={classes(styles.closeIcon)}
        src="/icons/close_icon.svg"
      />
    </>
  );
}

const infoComponents = {
  error: ErrorInfo,
  uploaded: UploadedInfo,
  uploading: UploadingInfo,
};

export default function AttachmentRow({
  name,
  status,
  size,
  onRemove,
  className,
  isDeleteIconHidden,
  isDownloadIconHidden,
  onDownload,
  icon,
}: AttachmentRowProps) {
  return (
    <div className={classes(styles.root, borderColor(status), className)}>
      <div className={classes(styles.leftBorder, backgroundColor(status))} />
      <div className={styles.container}>
        <div className={styles.fileInfoContainer}>
          <div className={styles.fileIconContainer}>
            <SvgIcon
              src={icon.src}
              className={classes(styles.fileIcon, style({ color: icon.color }))}
            />
          </div>
          <div className={styles.name}>{name}</div>
        </div>
        <div className={styles.info}>
          <div className={styles.divider} />
          <div>
            {status === "default" ? (
              <div className={styles.defaultInfo}>
                <span>{size}</span>
                {!isDownloadIconHidden && (
                  <SvgIcon
                    onClick={onDownload}
                    src="/icons/download_icon.svg"
                    className={styles.deleteIcon}
                  />
                )}
                {!isDeleteIconHidden && (
                  <SvgIcon
                    onClick={onRemove}
                    src="/icons/delete_icon.svg"
                    className={styles.deleteIcon}
                  />
                )}
              </div>
            ) : (
              React.createElement(
                infoComponents[status as Exclude<AttachmentStatus, "default">],
                { onRemove }
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
