import * as Sentry from "@sentry/react";
import { Hub } from "@sentry/react";
import type { Transaction } from "@sentry/types";
import { saveAs } from "file-saver";
import { fileTypeFromBlob } from "file-type/core";
import JSZip from "jszip";
import { ApiError, UploadApiError } from "shared/client/apiError";

//TODO: Read corresponding API endpoints from env variables
function formatEndpoint(path: string) {
  const { host } = window.location;
  const protocol = "https:";
  if (host.indexOf("dev") > -1) {
    return `${protocol}//api.dev.pulsemarket.com${path}`;
  }
  if (host.indexOf("qa") > -1) {
    return `${protocol}//api.qa.pulsemarket.com${path}`;
  }
  if (host.indexOf("preprod") > -1) {
    return `${protocol}//api.preprod.pulsemarket.com${path}`;
  }
  if (host.indexOf("127") > -1 || host.indexOf("localhost") > -1) {
    return `http://${host}${path}`;
  }
  return `${protocol}//api.live.pulsemarket.com${path}`;
}

function readDataFromResponse(response: Response) {
  const contentType = response.headers.get("content-type");

  if (contentType === null) {
    return Promise.resolve(null);
  }
  if (contentType.startsWith("text/")) {
    return response.text();
  }

  return response.json();
}

function getNewSentryTransaction(
  ...transactionParams: Parameters<typeof Sentry.startTransaction>
): { transaction: Transaction | null; hub: Hub } {
  const hub = Sentry.getCurrentHub();
  const existingTransaction = hub.getScope()?.getTransaction();

  let newTransaction: Transaction | null = null;

  if (!existingTransaction) {
    newTransaction = Sentry.startTransaction(...transactionParams);

    hub.configureScope((scope) => {
      scope.setSpan(newTransaction as Transaction);
    });
  }

  return { transaction: newTransaction, hub };
}

function stopSentryTransaction({
  hub,
  transaction,
}: {
  transaction: Transaction;
  hub: Hub;
}) {
  transaction.finish();
  hub.configureScope((scope) => scope.setSpan(undefined));
}

export async function getJSON<T, S = any>(
  path: string,
  name: string,
  params?: S
): Promise<T> {
  const endpoint = formatEndpoint(path);

  const { transaction, hub } = getNewSentryTransaction({
    name,
    op: "API Call",
    data: { path, params },
  });

  try {
    const url = new URL(endpoint);
    if (params) {
      for (let [key, value] of Object.entries(params)) {
        if (value instanceof Date) {
          value = value.toJSON();
        }
        if (typeof value !== "undefined") {
          if (Array.isArray(value)) {
            const isObjectInArray = value.some((v) => typeof v === "object");
            if (isObjectInArray) {
              url.searchParams.set(key, JSON.stringify(value));
            } else {
              url.searchParams.set(key, value.join(","));
            }
          } else if (typeof value === "object") {
            url.searchParams.set(key, JSON.stringify(value));
          } else {
            url.searchParams.set(key, value as string);
          }
        }
      }
    }

    const response = await fetch(url.href, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      const { message } = await response.json();
      throw new ApiError(response.status, message);
    }
    return (await readDataFromResponse(response)) as T;
  } catch (err) {
    throw err;
  } finally {
    if (transaction) {
      stopSentryTransaction({ hub, transaction });
    }
  }
}

export async function postJSON<T, R = any>(
  path: string,
  name: string,
  data?: T
) {
  const { transaction, hub } = getNewSentryTransaction({
    name: `POST ${name}`,
    op: "API Call",
    data: { path, data },
  });

  try {
    const endpoint = formatEndpoint(path);

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const { message } = await response.json();
      throw new ApiError(response.status, message);
    }
    return (await readDataFromResponse(response)) as R;
  } catch (err) {
    throw err;
  } finally {
    if (transaction) {
      stopSentryTransaction({ hub, transaction });
    }
  }
}

export async function putJSON<T, R = any>(
  path: string,
  name: string,
  data?: T
) {
  const { transaction, hub } = getNewSentryTransaction({
    name: `PUT ${name}`,
    op: "API Call",
    data: { path, data },
  });

  try {
    const endpoint = formatEndpoint(path);

    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const { message } = await response.json();
      throw new ApiError(response.status, message);
    }
    return (await readDataFromResponse(response)) as R;
  } catch (err) {
    throw err;
  } finally {
    if (transaction) {
      stopSentryTransaction({ hub, transaction });
    }
  }
}

export async function deleteAPI<R = any>(path: string, name: string) {
  const { transaction, hub } = getNewSentryTransaction({
    name: `DELETE ${name}`,
    op: "API Call",
  });

  try {
    const endpoint = formatEndpoint(path);
    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      const { message } = await response.json();
      throw new ApiError(response.status, message);
    }
    return (await readDataFromResponse(response)) as R;
  } catch (err) {
    throw err;
  } finally {
    if (transaction) {
      stopSentryTransaction({ hub, transaction });
    }
  }
}

export async function uploadAPI<T extends { [key: string]: any }, R = any>(
  path: string,
  name: string,
  files: File[] | File,
  body?: T
) {
  const { transaction, hub } = getNewSentryTransaction({
    name: `POST ${name}`,
    op: "API Call",
    data: { body },
  });

  try {
    const endpoint = formatEndpoint(path);
    const formData = new FormData();

    if (Array.isArray(files)) {
      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i]);
      }
    } else {
      formData.append("file", files);
    }

    if (body) {
      Object.keys(body).forEach((key) => {
        const jsonField =
          typeof body[key] !== "object" ? body[key] : JSON.stringify(body[key]);
        formData.append(key, jsonField);
      });
    }
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {},
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      const responseObj = await response.json();
      throw new UploadApiError(
        response.status,
        responseObj.message || response.statusText,
        responseObj.fileName,
        responseObj.errors
      );
    }
    return (await readDataFromResponse(response)) as R;
  } catch (err) {
    throw err;
  } finally {
    if (transaction) {
      stopSentryTransaction({ hub, transaction });
    }
  }
}

export async function downloadZip(
  path: string,
  name: string,
  data: {
    companyId?: string;
    folderIds: string[];
    assetIds: string[];
    zipFileName: string;
    dataRoomId?: string;
    contractId?: string;
    rfpId?: string;
    postId?: string;
    groupId?: string;
  },
  open?: boolean
) {
  const { transaction, hub } = getNewSentryTransaction({
    name: `POST ${name}`,
    op: "API Call",
    data: { path, data },
  });

  try {
    const endpoint = formatEndpoint(path);

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    const zippedBlob = await response.blob();

    const zip = await JSZip.loadAsync(zippedBlob);

    const numberOfFiles = Object.keys(zip.files).length;

    if (open && numberOfFiles === 1) {
      const file = Object.values(zip.files)[0];

      const zblob = await file.async("arraybuffer");

      const blobWithouthMime = new Blob([zblob]);

      const type = await fileTypeFromBlob(blobWithouthMime);

      if (type) {
        const blob = new Blob([blobWithouthMime], {
          type: type.mime,
        });
        const url = URL.createObjectURL(blob);

        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = data.zipFileName;
        fileLink.target = "_blank";
        fileLink.click();
        fileLink.remove();
      }
    } else {
      const name = data.zipFileName.endsWith(".zip")
        ? data.zipFileName
        : data.zipFileName + ".zip";

      saveAs(zippedBlob, name);
    }
  } catch (err) {
    throw err;
  } finally {
    if (transaction) {
      stopSentryTransaction({ hub, transaction });
    }
  }
}

export async function fileGET<S = any>(
  path: string,
  name: string,
  params?: S
): Promise<Blob> {
  const endpoint = formatEndpoint(path);

  const { transaction, hub } = getNewSentryTransaction({
    name: `GET ${name}`,
    op: "API Call",
    data: { path, params },
  });

  try {
    const url = new URL(endpoint);
    if (params) {
      for (let [key, value] of Object.entries(params)) {
        if (value instanceof Date) {
          value = value.toJSON();
        }
        if (typeof value !== "undefined") {
          if (Array.isArray(value)) {
            const isObjectInArray = value.some((v) => typeof v === "object");
            if (isObjectInArray) {
              url.searchParams.set(key, JSON.stringify(value));
            } else {
              url.searchParams.set(key, value.join(","));
            }
          } else if (typeof value === "object") {
            url.searchParams.set(key, JSON.stringify(value));
          } else {
            url.searchParams.set(key, value as string);
          }
        }
      }
    }

    const response = await fetch(url.href, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new ApiError(response.status, response.statusText);
    }
    return await response.blob();
  } catch (err) {
    throw err;
  } finally {
    if (transaction) {
      stopSentryTransaction({ hub, transaction });
    }
  }
}
