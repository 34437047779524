import * as React from "react";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import { passportExpirationDateFormat } from "shared/utils/time";

Font.register({
  family: "Mulish",
  fonts: [
    {
      src: "/fonts/Mulish-ExtraLight.ttf",
      fontWeight: 300,
    },
    {
      src: "/fonts/Mulish-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "/fonts/Mulish-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/Mulish-ExtraBold.ttf",
      fontWeight: 800,
    },
  ],
});

export interface PassportPDFProps {
  companyName: string;
  passportName?: string;
  date: { from: Date; until: Date };
  score?: number;
}

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#F8F9FA",
    fontFamily: "Mulish",
  },
  container: {
    height: "1411px",
    width: "2000px",
    display: "flex",
    flexDirection: "row",
  },
  leftColumn: {
    flex: 6,
    padding: "144px",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  rightColumn: {
    flex: 6,
  },
  badge: {
    height: "500px",
    objectFit: "contain",
    position: "absolute",
    right: "400px",
    top: "706px",
  },
  cyanCloud: {
    height: "100%",
    position: "absolute",
    right: 0,
  },
  grayCloud: {
    height: "100%",
    position: "absolute",
    right: 259,
  },
  logo: {
    height: "144px",
    objectFit: "contain",
    marginBottom: "59px",
    marginRight: "auto",
  },
  title: {
    fontSize: "63px",
    fontWeight: 300,
    fontStyle: "normal",
    textAlign: "left",
    paddingBottom: "16px",
    borderBottom: "2px",
    marginBottom: "60px",
    color: "#313131",
  },
  subtitle: {
    fontSize: "36px",
    fontWeight: 600,
    color: "#313131",
    marginBottom: "20px",
  },
  company: {
    fontSize: "110px",
    fontWeight: 800,
    color: "#69385E",
    marginBottom: "22px",
  },
  score: {
    fontSize: "110px",
    fontWeight: 800,
    color: "#FF5D45",
    marginBottom: "30px",
  },
  validDate: {
    fontSize: "36px",
    fontWeight: 600,
    letterSpacing: "0.25px",
    color: "#6C757D",
    marginBottom: "9px",
  },
  signature: {
    height: "138px",
    objectFit: "contain",
    marginBottom: "59px",
    marginRight: "auto",
    borderBottom: "2px",
  },
  signerName: {
    fontSize: "36px",
    fontWeight: 600,
    letterSpacing: "0.25px",
    marginBottom: "24px",
  },
  signerPosition: {
    fontSize: "25px",
    fontWeight: 600,
    letterSpacing: "0.25px",
  },
});

// Create Document Component
export default function PassportPDF({
  companyName,
  passportName = "Pulse Market ESG Passport",
  date,
  score,
}: PassportPDFProps) {
  const expirateionDate = dayjs(date.until).format(
    passportExpirationDateFormat
  );
  const issueDate = dayjs(date.from).format(passportExpirationDateFormat);
  return (
    <Document
      creator="Pulse Market"
      title={`ESG Certification for ${companyName}`}
    >
      <Page
        style={styles.page}
        size={{ width: "2000px", height: "1411px" }}
        orientation="landscape"
      >
        <View style={styles.container}>
          <View style={styles.leftColumn}>
            <Image style={styles.logo} src="/logo/pulse_logo_dark.png" />
            <Text style={styles.title}>{passportName}</Text>
            <Text style={styles.subtitle}>Awarded to:</Text>
            <Text
              style={[
                styles.company,
                {
                  fontSize: `${
                    110 / (Math.floor(companyName.length / 15) || 1)
                  }px`,
                  lineHeight: "0.8",
                  paddingBottom: "6%",
                },
              ]}
            >
              {companyName}
            </Text>
            {score && (
              <>
                <Text style={styles.subtitle}>Score:</Text>
                <Text style={styles.score}>{score}%</Text>
              </>
            )}
            <Text style={styles.validDate}>
              on {issueDate} valid until {expirateionDate}
            </Text>
            <Image
              style={styles.signature}
              src="/illustrations/michael_sign.png"
            />
            <Text style={styles.signerName}>Michael O'Shea</Text>
            <Text style={styles.signerPosition}>CEO Pulse Market</Text>
          </View>
          <View style={styles.rightColumn}>
            <Image
              style={styles.grayCloud}
              src="/illustrations/grayCloud.png"
            />
            <Image
              style={styles.cyanCloud}
              src="/illustrations/cyanCloud.png"
            />
            <Image
              style={styles.badge}
              src="/illustrations/ESG_passport_badge.png"
            />
          </View>
        </View>
      </Page>
    </Document>
  );
}
