export enum RFPType {
  RFP = "RFP",
  DDQ = "DDQ",
  RFI = "RFI",
  QTP = "QTP",
  Passport = "Passport",
  ESRS = "ESRS",
}

export const documentTypeNames: Record<
  RFPType,
  { fullName: string; shortName: string }
> = {
  DDQ: { fullName: "Due Diligence Questionnaire", shortName: "DDQ" },
  QTP: { fullName: "Quick to Procure", shortName: "QTP" },
  RFP: { fullName: "Request for Proposal", shortName: "RFP" },
  RFI: { fullName: "Request for Information", shortName: "RFI" },
  Passport: { fullName: "Passport", shortName: "ESG" },
  ESRS: {
    fullName: "European Sustainability Reporting Standards",
    shortName: "ESRS",
  },
};
