import { useGetAuthToken } from "hooks/queries/useAuth";

export default function useIsPublicVisitor() {
  const { data: tokenData, isLoading } = useGetAuthToken();

  return {
    isPublicVisitor: !tokenData,
    isLoading: isLoading,
  };
}
