import React from "react";

import { MediaItemDto } from "shared/model";
import { classes } from "typestyle";

import styles from "./YouTubeVideo.styles";

export type YouTubeVideoProps = {
  item: Pick<MediaItemDto, "id" | "name" | "reference"> & { length?: string };
  className?: string;
  iframeClassName?: string;
} & JSX.IntrinsicElements["iframe"];

export default function YouTubeVideo({
  item,
  className,
  iframeClassName,
  ...props
}: YouTubeVideoProps) {
  return (
    <div className={classes(styles.root, className)}>
      <iframe
        {...props}
        className={classes(styles.frame, iframeClassName)}
        title={item.name}
        frameBorder="0"
        src={`https://www.youtube.com/embed/${item.reference}?autoplay=0&origin=${window.origin}`}
      />
      <span className={styles.title}>
        {item.name}
        {item?.length ? ` - ${item.length}` : ""}
      </span>
    </div>
  );
}
