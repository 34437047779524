import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { InvitationsByType } from "components/CombinedInvitationSender/CombinedInvitationSender";
import {
  getGroup,
  getGroupFiles,
  getGroupInvitations,
  getUserPreviewsBasedOnGroupInvitation,
} from "shared/client";
import { GroupFilesFilters } from "shared/model";

export function useGroup(groupId: string) {
  return useQuery(queryKeys.group(groupId), () => getGroup(groupId), {
    notifyOnChangeProps: "tracked",
  });
}

export function useGroupFiles(groupId?: string, filters?: GroupFilesFilters) {
  return useQuery(
    queryKeys.groupFiles(groupId, filters),
    () => getGroupFiles(groupId!, filters),
    {
      enabled: !!groupId,
      notifyOnChangeProps: "tracked",
    }
  );
}

export function useUserPreviewsBasedOnGroupInvitation(companyId?: string) {
  return useQuery(
    queryKeys.userPreviews(companyId),
    () => getUserPreviewsBasedOnGroupInvitation(companyId!),
    { enabled: !!companyId }
  );
}

export function useGroupInvitations(groupId?: string) {
  return useQuery(
    queryKeys.groupInvitations(groupId),
    () => getGroupInvitations(groupId!),
    {
      enabled: !!groupId,
      select: (data) =>
        data.reduce<InvitationsByType>(
          (invitations, invitation) => {
            if (invitation.invitedEmail) {
              invitations.emails.push(invitation.invitedEmail);
            }
            if (invitation.invitedCompanyId) {
              invitations.companies.push(invitation.invitedCompanyId);
            }
            if (invitation.invitedSupplierId) {
              invitations.suppliers.push(invitation.invitedSupplierId);
            }
            return invitations;
          },
          {
            emails: [],
            companies: [],
            suppliers: [],
          }
        ),
      notifyOnChangeProps: "tracked",
    }
  );
}
