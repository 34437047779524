import React from "react";

import Input from "components/ui/Input/Input";
import { useFormik } from "formik";

import styles from "./EditableTextCellStyles";

type EditableTextProps = {
  text: string;
  isEditing?: boolean;
  className?: string;
  onSubmit: (newValue: string) => void;
  disableOnBlur?: boolean;
  required?: boolean;
  validityText?: string;
};

export default function EditableText({
  text,
  isEditing,
  className,
  onSubmit = () => {},
  disableOnBlur = false,
  required,
  validityText,
}: EditableTextProps) {
  const formik = useFormik({
    initialValues: { text },
    validate: (values) => {
      if (required && !values.text) {
        return { text: "Required" };
      }
      return {};
    },
    onSubmit: (values) => {
      onSubmit(values.text);
    },
  });

  return (
    <div className={className}>
      {isEditing ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
          onBlur={() => {
            if (disableOnBlur) {
              return;
            }
            formik.handleSubmit();
          }}
        >
          <Input
            inputRef={(input: HTMLInputElement) => {
              if (!input || !validityText || formik.isValid) {
                input?.setCustomValidity("");
                return;
              }
              input.setCustomValidity(validityText);
            }}
            helperTextExpectedLines={0}
            size="small"
            autoFocus
            InputProps={{
              classes: { root: styles.input },
            }}
            onClick={(e) => e.stopPropagation()}
            required={required}
            onChange={(e) => formik.setFieldValue("text", e.target.value)}
            value={formik.values.text}
          />
        </form>
      ) : (
        text
      )}
    </div>
  );
}
