import React from "react";
import { useHistory } from "react-router-dom";

import { ROUTES, TokenType } from "@pulsemarket/constants";
import Button from "components/ui/Button";
import Input from "components/ui/Input";
import { useFormik } from "formik";
import { useForgotPassword, useResendVerifyEmail } from "hooks/queries/useAuth";
import { emailValidatorFn } from "shared/validators";

import { styles } from "../AuthFormLayout/AuthForm.styles";

export default function InvalidTokenForm({
  onSubmit,
  type,
}: {
  onSubmit: (email: string) => void;
  type: TokenType;
}) {
  const history = useHistory();
  const { mutateAsync: forgotPassword } = useForgotPassword();
  const { mutateAsync: sendVerifyEmail } = useResendVerifyEmail();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      if (values.email && !emailValidatorFn(values.email)) {
        return { email: "Email is required" };
      }
    },
    validateOnChange: false,
    onSubmit: async (values) => {
      formik.validateForm();
      try {
        if (type === TokenType.PasswordReset) {
          await forgotPassword(values.email);
        } else if (type === TokenType.EmailVerify) {
          await sendVerifyEmail(values.email);
        }
      } catch (err) {}
      onSubmit(values.email);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className={styles.root}>
        <h2 className={styles.title}>
          Oops! It seems the link you used is no longer valid.
        </h2>
        <p className={styles.subtitle}>
          Please enter your email address below to receive a{" "}
          {type === TokenType.PasswordReset ? "password reset" : "verification"}{" "}
          link.
        </p>

        <Input
          className={styles.input}
          required
          type="email"
          label="Email"
          placeholder="Email address"
          value={formik.values.email}
          onChange={formik.handleChange("email")}
        />
      </div>

      <Button
        variant="normal"
        color="orange"
        fullWidth
        disabled={!formik.dirty}
        onClick={() => {
          formik.handleSubmit();
        }}
      >
        {type === TokenType.PasswordReset
          ? "Reset password"
          : "Resend verification email"}
      </Button>
      {type === TokenType.PasswordReset && (
        <div className={styles.helperTextContainer}>
          <p className={styles.helperText}>
            Remember the password?{" "}
            <Button
              variant="text"
              color="orange"
              onClick={() => {
                history.push(ROUTES.LOGIN);
              }}
            >
              Go Back to Login
            </Button>
          </p>
        </div>
      )}
    </form>
  );
}
