import React from "react";

import Avatar from "components/ui/Avatar";
import { DEFAULT_USER_AVATAR } from "shared/constants/icons";
import { classes } from "typestyle";

import styles from "./UserPreview.styles";

type UserPreviewProps = {
  className?: string;
  avatar?: string | null;
  name: string;
  role?: string;
};

export default function UserPreview({
  avatar,
  name,
  role,
  className,
}: UserPreviewProps) {
  return (
    <div className={classes(styles.root, className)}>
      <Avatar
        src={avatar || DEFAULT_USER_AVATAR}
        className={styles.avatar}
        size="32"
      />
      <div className={styles.texts}>
        <span className={styles.name}>{name}</span>
        {role && <span className={styles.role}>{role}</span>}
      </div>
    </div>
  );
}
