import { utilityColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  icon: {
    width: "16px",
    height: "16px",
  },
  informationIcon: {
    color: utilityColors.BLUE,
  },
  warningIcon: {
    color: utilityColors.YELLOW,
  },
  customWidth: {
    maxWidth: "100 !important",
  },
});

export default styles;
