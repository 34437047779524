import { primaryColors } from "@pulsemarket/constants";
import { clickable, zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  zoneBoxContainer: { display: "flex", justifyContent: "center" },
  zoneBox: clickable,
  title: {
    color: primaryColors.PURPLE2,
    ...typography.SUBTITLE1,
    ...zeroPaddingMargin,
    marginBottom: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
});

export default styles;
