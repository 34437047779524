import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { AttachmentStatus } from "components/ui/Attachments/AttachmentRow";
import { forceTextOneLine } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { style, stylesheet } from "typestyle";

const statusColor: Record<AttachmentStatus, string> = {
  default: neutralColors.GRAY3,
  uploaded: utilityColors.GREEN,
  uploading: utilityColors.ORANGE,
  error: utilityColors.RED,
};

export const backgroundColor = (status: AttachmentStatus) => {
  return style({ background: statusColor[status] });
};

export const borderColor = (status: AttachmentStatus) => {
  return style({ borderColor: statusColor[status] });
};

export const iconColor = (status: AttachmentStatus) => {
  return style({
    background: statusColor[status],
    color: primaryColors.WHITE,
  });
};

const styles = stylesheet({
  root: {
    background: neutralColors.GRAY1,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "8px",
    position: "relative",
    $nest: {
      "&:hover": {
        background: neutralColors.GRAY0,
      },
    },
  },
  container: {
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "32px",
    paddingRight: "24px",
  },
  leftBorder: {
    width: "8px",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    borderBottomLeftRadius: "8px",
    borderTopLeftRadius: "8px",
  },
  fileInfoContainer: {
    display: "flex",
    alignItems: "center",
    ...typography.CAPTION,
    maxWidth: "75%",
    paddingRight: "12px",
  },
  fileIconContainer: {
    height: "26px",
    minWidth: "26px",
    border: `0.5px solid ${neutralColors.GRAY4}`,
    background: primaryColors.WHITE,
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px",
    flex: 1,
  },
  fileIcon: {
    height: "18px",
  },
  name: { ...forceTextOneLine },
  divider: {
    width: "16px",
    borderLeft: `1px solid ${neutralColors.GRAY3}`,
    height: "20px",
  },
  info: {
    flexBasis: "25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  defaultInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...typography.CAPTION,
    color: primaryColors.BLACK,
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
  infoIconWrapper: {
    borderRadius: "100%",
    width: "20px",
    height: "20px",
    marginRight: "8px",
  },
  infoText: {
    ...typography.CAPTION,
  },
  deleteIcon: {
    color: utilityColors.ORANGE,
    height: "15px",
    marginLeft: "16px",
    cursor: "pointer",
  },
  closeIcon: {
    width: "12px",
    color: neutralColors.GRAY5,
    cursor: "pointer",
    marginLeft: "16px",
  },
  errorInfoLeft: {
    display: "flex",
    alignItems: "center",
  },
});

export default styles;
