import React, { useCallback } from "react";

import Modal from "components/ui/Modal";
import { CustomizableDialogProps } from "components/ui/Modal/Modal";

export type ModalOptions = {
  props?: CustomizableDialogProps;
  /** If true, keeps previously displayed modals mounted */
  keepHistory?: boolean;
};

export interface ModalContextType {
  /** Opens a React component inside a modal window. You can supply props to the underliying `<Dialog />` component with the second parameter.
   *
   * You can render anything inside, but in the general case you can use the already implemented `<SimpleModalBody />` component. We also have a `<ConfirmationModalBody />` defined based on the former component.
   *
   * Successive openModal calls will stack the modals. You can close the last one with the `closeModal` function.
   * If you supply the `history` prop, the last modal be replace the stacked history, this is the default behaviour.
   *
   * @example
   * const { openModal, closeModal } = useContext(ModalContext);
   * openModal(<SimpleModalBody>Content of the modal</SimpleModalBody>, props)
   */
  openModal: (component: React.ReactNode, options?: ModalOptions) => void;
  closeModal: () => void;
}

export type ModalHistoryEntry = {
  content: React.ReactNode;
  props: CustomizableDialogProps;
};

/** This hook should strictly be only used inside the `ModalProvider`, use `ModalContext` to update the state. */
export const useModal = () => {
  const [history, setHistory] = React.useState<ModalHistoryEntry[]>([]);

  const openModal = useCallback(
    (content: React.ReactNode, options?: ModalOptions) => {
      const elementToAppend = { content, props: { ...options?.props } };

      setHistory((modalContentHistory) => {
        if (!options?.keepHistory) {
          return [elementToAppend];
        }

        const newHistory = modalContentHistory.slice();
        const lastEl = newHistory.pop();

        if (lastEl) {
          return [
            ...newHistory,
            { ...lastEl, props: { ...lastEl?.props, keepMounted: true } },
            elementToAppend,
          ];
        }

        return [...newHistory, elementToAppend];
      });
    },
    []
  );

  /**
   * Closes the last modal window. If there is a previous modal window in the modalContentHistory, it will be opened again.
   */
  const closeModal = useCallback(() => {
    setHistory((modalContentHistory) => {
      return modalContentHistory.slice(0, -1);
    });
  }, []);

  return {
    openModal,
    closeModal,
    history,
  };
};

const ModalContext = React.createContext<ModalContextType>({
  openModal: () => {},
  closeModal: () => {},
});

export const ModalConsumer = ModalContext.Consumer;

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const { openModal, closeModal, history } = useModal();
  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      <Modal history={history} />
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContext;
