import { neutralColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  headerClass: {
    background: neutralColors.GRAY0,
    color: neutralColors.GRAY5,
  },
  buttonIcon: {
    width: "14px",
    height: "14px",
    marginRight: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default styles;
