import { primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  RemoveFromList: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "stretch",
    width: px(400),
    background: primaryColors.WHITE,
    borderRadius: px(8),
    padding: "32px",
  },
  companyNameText: {
    color: primaryColors.PURPLE3,
  },
});

export default styles;
