import React from "react";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@pulsemarket/constants";
import { Loading } from "components/FullScreenLoading";
import Button from "components/ui/Button";
import { useForgotPassword } from "hooks/queries/useAuth";
import { classes } from "typestyle";

import { styles } from "../AuthFormLayout/AuthForm.styles";

export default function ResetPasswordSentForm({
  email,
  isMigratedUser,
}: {
  email: string;
  isMigratedUser?: boolean;
}) {
  const { mutateAsync: forgotPassword, isLoading } = useForgotPassword();
  const history = useHistory();
  const handleSubmit = async () => {
    try {
      await forgotPassword(email);
    } catch (err) {
      console.log(err);
    }
  };
  if (isLoading) {
    return <Loading />;
  }

  return (
    <form autoComplete="off">
      <div className={styles.root}>
        <h2 className={styles.title}>
          {isMigratedUser
            ? "It’s time to change your password!"
            : "Password Recovery"}
        </h2>
        {!isMigratedUser && (
          <p
            className={classes(styles.inline, styles.subtitle, styles.tightFit)}
          >
            If we found a user with email address{" "}
            <span className={styles.boldText}>{email}</span> in the account, you
            will receive an email from us shortly.
          </p>
        )}
        {isMigratedUser && (
          <>
            <p className={styles.subtitle}>
              We're updating Pulse's authentication system and it's time for you
              to change your password! Please verify your email to receive the
              instructions on how to do this.
            </p>
          </>
        )}
        <p className={classes(styles.subtitle, styles.tightFit)}>
          If you do not receive the email within a few minutes, please check
          your junk/spam email folder or try to resend the email.
        </p>

        {!isMigratedUser && (
          <p
            className={classes(styles.inline, styles.subtitle, styles.tightFit)}
          >
            Unsure which email you used for your Pulse account, or not sure what
            account you are associated with?
            <ContactUsButton />
            <p />
          </p>
        )}

        <Button
          variant="normal"
          color="orange"
          fullWidth
          onClick={() => {
            handleSubmit();
          }}
        >
          Resend email
        </Button>

        <div className={styles.helperTextContainer}>
          <p className={styles.helperText}>
            {isMigratedUser
              ? "Having problems to login? "
              : "Remember the password? "}
            {isMigratedUser ? (
              <ContactUsButton />
            ) : (
              <Button
                variant="text"
                color="orange"
                onClick={() => {
                  history.push(ROUTES.LOGIN);
                }}
              >
                Go Back to Login
              </Button>
            )}
          </p>
        </div>
      </div>
    </form>
  );
}

function ContactUsButton() {
  return (
    <Button
      variant="text"
      color="orange"
      onClick={() => {
        window.open(
          "mailto:clientservices@pulsemarket.com" +
            "?subject=" +
            encodeURIComponent("Email address") +
            "&body=" +
            encodeURIComponent(
              "Please send me my email address associated with my Pulse account."
            )
        );
      }}
    >
      Contact us
    </Button>
  );
}
