import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import KebabMenu from "components/ui/KebabMenu";
import MultiSelectList from "components/ui/MultiSelectList/MultiSelectList";
import SvgIcon from "components/ui/SvgIcon/SvgIcon";
import useDataRooms from "hooks/queries/useDataRooms";
import { DataRoomDto } from "shared/model";
import { classes } from "typestyle";

import styles from "./GroupDataroomSettings.styles";

type GroupDataroomSettingsProps = {
  datarooms: Pick<DataRoomDto, "id" | "name">[];
  onSetDatarooms: (datarooms: Pick<DataRoomDto, "id" | "name">[]) => void;
};

const GroupDataroomSettings = ({
  onSetDatarooms,
  datarooms,
}: GroupDataroomSettingsProps) => {
  const [newCheckedDatarooms, setNewCheckedDatarooms] = useState<DataRoomDto[]>(
    []
  );

  const { data: ownDatarooms } = useDataRooms();
  return (
    <div>
      <div className={styles.title}>Advanced Setting</div>

      <div className={styles.settingsContainer}>
        <div className={styles.subtitle}>Linked Data room</div>
        <MultiSelectList
          className={classes(styles.multiSelectListHeader, styles.dataroomGrid)}
          placeholder="Select from your data rooms"
          selectedIds={newCheckedDatarooms.map((dataroom) => dataroom.id)}
          options={(ownDatarooms || []).map((dataroom) => ({
            name: dataroom.name,
            id: dataroom.id,
            disabled: (id) => datarooms.map((droom) => droom.id).includes(id),
          }))}
          asPopover
          showArrow={true}
          multiple={true}
          actions={[
            {
              color: "orange",
              label: "Add",
              closeOnClick: true,
              disabled: (selectedIds) => !selectedIds.length,
              onClick: (items) => {
                const newDatarooms = (ownDatarooms || []).filter((dataroom) =>
                  items.map((i) => i.id).includes(dataroom.id)
                );

                onSetDatarooms([...new Set(datarooms.concat(newDatarooms))]);
              },
            },
            {
              variant: "transparent",
              color: "orange",
              label: "Select all",
              disabled: (selectedIds) =>
                selectedIds.length === ownDatarooms?.length,
              onClick: () => {
                setNewCheckedDatarooms(
                  (ownDatarooms || []).filter(
                    (ownDataroom) =>
                      !newCheckedDatarooms
                        .map((datarrom) => datarrom.id)
                        .includes(ownDataroom.id)
                  )
                );
              },
            },
          ]}
        />
        <div className={styles.dataroomGrid}>
          {datarooms.map((dataroom) => (
            <DataroomItem
              key={dataroom.id}
              dataroom={dataroom}
              onRemoveDataroom={(dataroomId) => {
                const newDatarooms = datarooms.filter(
                  (dataroom) => dataroom.id !== dataroomId
                );
                onSetDatarooms(newDatarooms);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export function DataroomItem({
  dataroom,
  redirectOnClick,
  onRemoveDataroom,
  className,
}: {
  dataroom: Pick<DataRoomDto, "id" | "name">;
  redirectOnClick?: boolean;
  onRemoveDataroom?: (dataroomId: string) => void;
  className?: string;
}) {
  const history = useHistory();
  return (
    <div className={classes(styles.dataroomCard, className)}>
      <div className={styles.iconContainer}>
        <SvgIcon src="/icons/data-room.svg" className={styles.dataroomAvatar} />
      </div>
      <span
        className={classes(styles.dataroomName, redirectOnClick && styles.link)}
        onClick={() => {
          if (redirectOnClick) {
            history.push(`/datarooms/${dataroom.id}`);
          }
        }}
      >
        {dataroom.name}
      </span>
      {onRemoveDataroom && (
        <KebabMenu
          actions={[
            {
              text: "Delete",
              onClick: () => {
                onRemoveDataroom(dataroom.id);
              },
            },
          ]}
        />
      )}
    </div>
  );
}

export default GroupDataroomSettings;
