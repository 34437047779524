import { neutralColors, utilityColors } from "@pulsemarket/constants";
import { px } from "csx";
import { stylesheet } from "typestyle";

const downIconSize = "12px";

const styles = stylesheet({
  MultiSelect: {
    display: "inline-block",
  },
  button: {
    backgroundColor: neutralColors.GRAY0,
    justifyContent: "space-between",
  },
  text: {
    whiteSpace: "nowrap",
    display: "flex",
    width: `calc(100% - ${downIconSize})`,
  },
  name: {
    textAlign: "start",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
  },
  arrowDown: {
    width: downIconSize,
    height: downIconSize,
    flexBasis: downIconSize,
    flexShrink: 0,
  },
  filterCount: {
    marginLeft: px(8),
    color: utilityColors.ORANGE,
  },
  clearButton: {
    color: neutralColors.GRAY5,
    margin: px(4),
  },
  closeIcon: {
    margin: px(8),
    width: px(13),
    color: neutralColors.GRAY5,
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
});

export default styles;
