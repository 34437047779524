import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  root: {
    display: "inline-flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "right",
    width: px(200),
  },
  tooltipContainer: { display: "flex", flexDirection: "column" },
  avatarBox: {
    height: "200px",
    width: "200px",
    backgroundColor: neutralColors.GRAY1,
    position: "relative",
    border: `3px solid ${primaryColors.WHITE}`,
    borderRadius: "7px",
    cursor: "pointer",
  },
  profileImageBox: {
    height: "78px",
    width: "78px",
    backgroundColor: neutralColors.GRAY1,
    position: "relative",
    border: `3px solid ${primaryColors.WHITE}`,
    borderRadius: "7px",
    cursor: "pointer",
    marginRight: "30px",
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "7px",
  },
  defaultAvatar: {
    width: "100%",
    height: "100%",
    padding: "15%",
    color: utilityColors.CYAN,
    pointerEvents: "none",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    color: utilityColors.LILAC,
    pointerEvents: "none",
  },
  iconContainer: {
    position: "absolute",
    width: "28px",
    height: "28px",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    $nest: {
      "&:hover": {
        background: primaryColors.HOVER,
        $nest: {
          "& svg": {
            color: primaryColors.BLACK,
          },
        },
      },
    },
  },
  cameraIconContainer: {
    backgroundColor: primaryColors.WHITE,
    right: "14px",
    bottom: "13px",
  },
  cameraIconContainerProfileImage: {
    right: "2px",
    bottom: "2px",
  },
  span: {
    ...typography.CAPTION,
    marginTop: px(10),
    marginRight: px(0),
    marginLeft: "auto",
    color: neutralColors.GRAY5,
  },
  maxSizeSpan: {
    ...typography.CAPTION,
    marginRight: px(0),
    marginLeft: "auto",
    color: neutralColors.GRAY5,
  },
  tryAgain: {
    ...typography.CAPTION,
    marginRight: px(0),
    marginLeft: "auto",
    color: utilityColors.RED,
  },
});
