import React from "react";

import { CardMedia } from "@material-ui/core";
import GetStartedBox from "components/GetStartedBox";
import CompanyDetailsCard from "components/PulsePage/About/CompanyDetailsCard";
import { checkYoutubeUrl } from "components/PulsePage/helpers";
import TagsBox from "components/PulsePage/TagsBox";
import CompanyListWithTitle from "components/VendorSearch/CompanyListWithTitle";
import useCompanyCards from "hooks/useCompanyCards";
import { CompanyDetailsDto, CompanyDto } from "shared/model";

import styles from "./AboutStyles";

interface AboutProps {
  company: CompanyDetailsDto;
  isOwner: boolean;
  historyPush: (url: string) => void;
  similarVendors: CompanyDto[];
}

const getStarted = {
  title: "Create your Pulse Page",
  subtitle: "Edit your Company info.",
  caption:
    "Add your company info, specialities, services, brand content, assets and all products and services you offer to stand out from the crowd.  ",
  image: { src: "/illustrations/illustration_yourpulsecompany.png" },
  name: "Get Started",
};

const about = {
  title: "About",
  subtitle: "Show the Buyers who you are",
  caption:
    "Add a company description, a video introduction to showcase you company's skills, products and areas of expertise",
  image: { src: "/illustrations/illustration_about.png" },
  name: "Edit company about",
};

export default function About({
  company,
  isOwner,
  historyPush,
  similarVendors = [],
}: AboutProps) {
  const listOfSimilarVendors = useCompanyCards(similarVendors);
  const youtubeVideoId = checkYoutubeUrl(company?.youtubeUrl || "");
  const targetMarketDisplay = isOwner ? true : !!company.servicing.length;
  const companyDetailsInformation = !!(
    company.phoneNumber ||
    company.address ||
    company.city ||
    company.country ||
    company.employees ||
    company.website ||
    company.linkedInUrl ||
    company.contactName ||
    company.email
  );

  return (
    <div className={styles.mainContainer}>
      {!company?.about && !company?.youtubeUrl && isOwner && (
        <GetStartedBox
          type="link"
          action={() => historyPush(`/company/edit/info`)}
          {...getStarted}
        />
      )}

      {!company?.about && company?.youtubeUrl && isOwner && (
        <GetStartedBox
          type="link"
          action={() => historyPush(`/company/edit/details`)}
          {...about}
        />
      )}

      {company?.about && (
        <div className={styles.overviewContainer}>
          <h2 className={styles.overviewTitle}>Overview</h2>
          <p className={styles.paragraph}>{company?.about}</p>
        </div>
      )}

      {company?.youtubeUrl && (
        <div className={styles.videoContainer}>
          <CardMedia
            component="iframe"
            src={`https://www.youtube.com/embed/${youtubeVideoId.id}`}
            title="YouTube video player"
            className={styles.video}
            allowFullScreen
          />
        </div>
      )}

      <div className={styles.tagsContainer}>
        {company?.industries && (
          <div>
            <TagsBox
              title="Industry"
              selectedTags={company?.industries}
              icon="/icons/industry.svg"
              hideAddButton={!isOwner}
            />
          </div>
        )}

        {(company?.servicing.length || isOwner) && (
          <div>
            <TagsBox
              title="Target Markets"
              selectedTags={company?.servicing}
              icon="/img/servicing.png"
              hideAddButton={!isOwner}
            />
          </div>
        )}

        {companyDetailsInformation && (
          <CompanyDetailsCard
            isTargetMarketDisplayed={targetMarketDisplay}
            company={company}
          />
        )}
      </div>
      <div className={styles.basicInfoContainer}>
        {!isOwner && <CompanyListWithTitle cards={listOfSimilarVendors} />}
      </div>
    </div>
  );
}
