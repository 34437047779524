import React, { useState } from "react";

import { IconButton, Popover } from "@material-ui/core";
import { SelectOption } from "components/ui/FormSelect";
import MultiSelectBody from "components/ui/MultiSelect/Body";
import SvgIcon from "components/ui/SvgIcon";
import { sharedTableHeaderStyles } from "components/ui/Table/shared.styles";
import Tooltip, {
  purpleTooltipProps,
  TooltipWrapper,
} from "components/ui/Tooltip";
import useMultiSelect from "hooks/useMultiSelect";
import { getExpandableIconSource } from "shared/constants/icons";
import { classes } from "typestyle";

import styles from "./TableHeaderFilter.styles";

type TableHeaderFilterProps = {
  className?: string;
  columnName: string;
  onChange?: (selectedIds: string[], changedId?: string) => void;
  selectedIds: string[];
  options?: SelectOption[];
  isLoading?: boolean;
  disabled?: boolean;
};

export default function TableHeaderFilter({
  className,
  columnName,
  onChange = () => {},
  selectedIds,
  options = [],
  isLoading,
  disabled,
}: TableHeaderFilterProps) {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const { setSearch, filteredItems, clearChecked, toggleChecked } =
    useMultiSelect({
      onChange: onChange,
      options,
      selectedIds,
    });

  const popoverOpen = Boolean(anchorElement);
  function handlePopoverOpenerClick(
    event: React.MouseEvent<Element, MouseEvent>
  ) {
    setAnchorElement(event.currentTarget);
  }
  function openDropDown(event: React.MouseEvent<Element, MouseEvent>) {
    handlePopoverOpenerClick(event);
  }

  function handlePopoverClose() {
    setAnchorElement(null);
  }

  return (
    <>
      <td>
        <div
          className={classes(styles.root, className)}
          onClick={(e) => {
            if (disabled) {
              return;
            }
            openDropDown(e);
          }}
        >
          <span className={styles.columnName}>{columnName}</span>
          {!disabled && (
            <Tooltip title="Filter by" {...purpleTooltipProps}>
              <TooltipWrapper>
                <IconButton
                  className={classes(sharedTableHeaderStyles.arrowIconButton)}
                >
                  <SvgIcon
                    src={getExpandableIconSource(popoverOpen)}
                    className={sharedTableHeaderStyles.arrowIcon}
                  />
                </IconButton>
              </TooltipWrapper>
            </Tooltip>
          )}
        </div>
      </td>
      <Popover
        open={popoverOpen}
        anchorEl={anchorElement}
        getContentAnchorEl={null}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <MultiSelectBody
          filteredItems={filteredItems}
          setSearch={setSearch}
          toggleChecked={toggleChecked}
          isLoading={isLoading}
          buttons={[
            {
              variant: "transparent",
              className: styles.clearButton,
              onClick: clearChecked,
              children: (
                <>
                  <SvgIcon
                    className={styles.closeIcon}
                    src="/icons/close_icon.svg"
                  />
                  <span className={styles.clearButton}>Clear</span>
                </>
              ),
            },
          ]}
        />
      </Popover>
    </>
  );
}
