import React from "react";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@pulsemarket/constants";
import Button from "components/ui/Button";
import Input from "components/ui/Input";
import PasswordInput from "components/ui/PasswordInput";
import { useFormik } from "formik";
import { useRegister } from "hooks/queries/useAuth";
import {
  getInvitationFromLocalStorage,
  removeInvitationParamsFromLocalStorage,
} from "hooks/utils";
import { CompanyDto } from "shared/model";
import { URL } from "shared/urls";
import { emailValidatorFn, validatePasswordStrength } from "shared/validators";

import { styles } from "./../AuthFormLayout/AuthForm.styles";

export default function RegisterForm({
  inviterCompany,
  onSubmit,
}: {
  inviterCompany?: CompanyDto;
  onSubmit: (email: string) => void;
}) {
  const history = useHistory();

  const { mutateAsync: register } = useRegister();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      companyName: inviterCompany?.name || "",
    },
    validateOnChange: false,
    validate: (values) => {
      const errors: { email: string; password: string } = {
        email: "",
        password: "",
      };
      if (values.email && !emailValidatorFn(values.email)) {
        errors.email = "Invalid email address";
      }

      if (validatePasswordStrength(values.password)) {
        errors.password = validatePasswordStrength(values.password) || "";
      }
      if (errors.email || errors.password) {
        return errors;
      }
    },
    onSubmit: async (values) => {
      const invitation = getInvitationFromLocalStorage();
      formik.validateForm();
      try {
        await register({
          user: {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
          },
          companyName: values.companyName,

          //If invitation is present, add it to the request
          ...(invitation && {
            invitation: {
              id: invitation.invitationId,
              type: invitation.invitationType,
              inviterCompany: invitation.inviterCompanyId,
            },
          }),
        });

        onSubmit(values.email);
      } catch (err) {
        formik.setFieldError("email", "Email already exists");
      }
      removeInvitationParamsFromLocalStorage();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className={styles.root}>
        <h2 className={styles.title}> Welcome to Pulse Market</h2>
        <p className={styles.subtitle}>
          Get started - it's free. No credit card needed.
        </p>

        <div className={styles.inputColumns}>
          <Input
            className={styles.input}
            required
            autoComplete="given-name"
            label="First name"
            placeholder="First name"
            value={formik.values.firstName}
            onChange={formik.handleChange("firstName")}
          />
          <Input
            className={styles.input}
            required
            autoComplete="family-name"
            label="Last name"
            placeholder="Last name"
            value={formik.values.lastName}
            onChange={formik.handleChange("lastName")}
          />
        </div>
        <Input
          className={styles.input}
          required
          autoComplete="off"
          name="email"
          type="email"
          label="Email"
          placeholder="Email address"
          value={formik.values.email}
          onChange={formik.handleChange("email")}
          error={!!formik.errors.email}
          helperText={formik.errors.email}
        />

        <Input
          className={styles.input}
          autoComplete="organization"
          required
          name="companyName"
          label="Company name"
          placeholder="Company name"
          value={formik.values.companyName}
          onChange={formik.handleChange("companyName")}
          disabled={!!inviterCompany}
        />

        <PasswordInput
          className={styles.input}
          required
          autoComplete="new-password"
          value={formik.values.password}
          onChange={formik.handleChange("password")}
          error={!!formik.errors.password}
          helperText={formik.errors.password}
        />

        <div className={styles.helperTextContainer}>
          <p className={styles.helperText}>
            By proceeding, you agree to our{" "}
            <Button
              variant="text"
              color="orange"
              onClick={() => {
                window.open(URL.TERMS_OF_USE, "_blank");
              }}
            >
              terms & conditions
            </Button>{" "}
            and{" "}
            <Button
              variant="text"
              color="orange"
              onClick={() => {
                // redirect on new page to privacy policy
                window.open(URL.PRIVACY_POLICY, "_blank");
              }}
            >
              privacy policy
            </Button>
          </p>
        </div>

        <Button
          variant="normal"
          color="orange"
          fullWidth
          disabled={
            !formik.dirty ||
            formik.isSubmitting ||
            !formik.values.email ||
            !formik.values.password ||
            !formik.values.firstName ||
            !formik.values.lastName ||
            !formik.values.companyName
          }
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Create account
        </Button>

        <div className={styles.helperTextContainer}>
          <p className={styles.helperText}>
            Already have an account?{" "}
            <Button
              variant="text"
              color="orange"
              onClick={() => {
                history.push(ROUTES.LOGIN);
              }}
            >
              Log in
            </Button>
          </p>
        </div>
      </div>
    </form>
  );
}
