import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { important } from "csx";
import { maxLineNumber } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    $nest: {
      "&&": {
        paddingRight: "unset",
      },
    },
  },
  companyLogo: {
    background: primaryColors.WHITE,
    borderRadius: "8px",
    width: "45px",
    height: "45px",
    marginRight: "19px",
  },
  companyName: {
    color: primaryColors.BLACK,
    marginBottom: "8px",
    ...typography.SUBTITLE1,
    ...maxLineNumber(1),
  },
  companyLocation: {
    color: neutralColors.GRAY5,
    ...typography.CAPTION,
    ...maxLineNumber(1),
  },
  dividerCellClass: {},
  dividerInnerClass: {
    borderLeft: important(`1px solid ${neutralColors.GRAY3}`),
    height: "44px",
  },
  leftSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "50%",
  },
  leftSideTextContainer: {
    maxWidth: "80%",
  },
  rightSide: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "50%",
  },
  kebabMenu: {
    marginRight: "34px",
    marginLeft: "30px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  disabled: {
    $nest: {
      "&": {
        color: neutralColors.GRAY3,
      },
    },
  },
  disabledReason: {
    ...typography.CAPTION2,
  },
});

export default styles;
