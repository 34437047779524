import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { TokenType } from "@pulsemarket/constants";
import InvalidTokenForm from "components/auth/ResetPasswordForm/InvalidTokenForm";
import VerifyEmailForm from "components/auth/VerifyEmailForm/VerifyEmailForm";
import { Loading } from "components/FullScreenLoading";
import { useVerifyEmail } from "hooks/queries/useAuth";
import AuthenticationPageLayout from "pages/AuthenticationPageLayout";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function VerifyEmailPage() {
  const query = useQuery();
  const token = query.get("token") || "";

  const { isLoading: isVerifyEmailLoading, isError: isVerifyEmailError } =
    useVerifyEmail({ token, enabled: !!token });

  const [emailToVerify, setEmailToVerify] = useState<string | null>(null);

  if (isVerifyEmailLoading) {
    return <Loading />;
  }

  return (
    <AuthenticationPageLayout imageSrc="/illustrations/forgot_password.svg">
      {isVerifyEmailError ? (
        emailToVerify ? (
          <VerifyEmailForm email={emailToVerify} />
        ) : (
          <InvalidTokenForm
            onSubmit={(email) => {
              setEmailToVerify(email);
            }}
            type={TokenType.EmailVerify}
          />
        )
      ) : (
        <InvalidTokenForm
          onSubmit={(email) => {
            setEmailToVerify(email);
          }}
          type={TokenType.EmailVerify}
        />
      )}
    </AuthenticationPageLayout>
  );
}
