import React, { useContext, useEffect } from "react";
import { useQueryClient } from "react-query";
import { RouteComponentProps } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import { primaryColors } from "@pulsemarket/constants";
import { Loading } from "components/FullScreenLoading";
import Button from "components/ui/Button";
import ModalContext from "contexts/ModalContext";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { recordPayment } from "shared/client";
import { stylesheet } from "typestyle";

type Params = {
  status: "success" | "cancel";
};

interface Props extends RouteComponentProps<Params> {}

const styles = stylesheet({
  wrapper: {
    padding: "58px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  title: {
    fontWeight: 600,
    fontSize: "25px",
    color: primaryColors.BLACK,
    marginBottom: "24px",
  },
  text: {
    fontSize: "15px",
    color: primaryColors.BLACK,
    marginBottom: "24px",
  },
  img: {
    height: "187px",
    marginBottom: "40px",
  },
  button: {
    margin: "0 12px",
  },
});

const BeforeYouGoComponent = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Before you go!</h1>
      <p className={styles.text}>
        Are you sure you don’t want to proceed with payment to unlock the ESG
        passport tools?
      </p>
      <img
        className={styles.img}
        src="/illustrations/illustration_before_you_go_esg.svg"
        alt="illustration"
      />
      <div>
        <Button
          className={styles.button}
          color="gray"
          variant="normal"
          onClick={async () => {
            window.location.replace("https://pulsemarket.com/esg-passport/");
          }}
        >
          Cancel Payment
        </Button>

        <Button
          className={styles.button}
          color="orange"
          variant="normal"
          onClick={() => {
            window.location.replace("/");
          }}
        >
          Unlock ESG Passport tools now
        </Button>
      </div>
    </div>
  );
};

const Stripe = (props: Props) => {
  const { openModal } = useContext(ModalContext);
  const { data: company } = useOwnCompany();
  const client = useQueryClient();

  useEffect(() => {
    if (!company || !company.id) {
      return;
    }

    if (props.match.params.status === "success") {
      (async () => {
        const search = new URLSearchParams(props.location.search);
        const sessionId = search.get("session_id");

        if (sessionId) {
          await recordPayment(sessionId, company.id);
          await client.invalidateQueries(queryKeys.user);
        }
        props.history.replace("/");
      })();
    } else {
      // TODO: for other cancelled payments (non-ESG) be able to show other modals/screens
      openModal(<BeforeYouGoComponent />, {
        props: {
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          maxWidth: "lg",
        },
      });
    }
  }, [
    props.match.params.status,
    props.history,
    props.location.search,
    openModal,
    company,
    client,
  ]);

  return (
    <Loading
      message={
        props.match.params.status === "success"
          ? "Processing your payment..."
          : "Please wait..."
      }
    />
  );
};

export default Stripe;
