import { useDebugValue, useState } from "react";

import {
  useDeepCompareEffect,
  useDeepCompareLayoutEffect,
} from "hooks/useDeepCompareEffects";
import { debounce } from "shared/utils";

function countElementsBeforeWrap(container: HTMLDivElement): number {
  let fittingElementsCount = 0;
  for (const child of container.children) {
    if ((child as HTMLElement).offsetTop <= container.offsetTop) {
      fittingElementsCount++;
    } else {
      break;
    }
  }

  return fittingElementsCount;
}

type WrappedFlexItemCounterParams = {
  flexContainer: React.RefObject<HTMLDivElement>;
  items: any[];
};

/**
 * Hook that returns how many flex items fit into the first line of a parent container. Window resize is listened to.
 * Only works with flex containers that have `flex-wrap: "wrap";` and `flex-direction: "row";`.
 * @param items The items parameter is passed to re-run calculations, the underlying DOM element can be anything.
 */
export default function useFittingFlexItemsCount({
  flexContainer,
  items,
}: WrappedFlexItemCounterParams) {
  const [fittingElementCount, setFittingElementCount] = useState(0);
  const [hasDeterminedHiddenElements, setDeterminedHiddenElements] =
    useState<boolean>(false);

  useDeepCompareEffect(() => {
    setFittingElementCount(0);
    setDeterminedHiddenElements(false);
  }, [items]);

  useDeepCompareLayoutEffect(() => {
    const calculateHiddenElements = debounce(
      100,
      function calculateHiddenElements() {
        if (!flexContainer.current || hasDeterminedHiddenElements) {
          return;
        }
        const numberOfFittingElements = countElementsBeforeWrap(
          flexContainer.current
        );
        setFittingElementCount(numberOfFittingElements);
        setDeterminedHiddenElements(true);
      }
    );

    window.addEventListener("resize", calculateHiddenElements);
    return () => {
      window.removeEventListener("resize", calculateHiddenElements);
    };
  }, [items, hasDeterminedHiddenElements]);

  useDeepCompareLayoutEffect(() => {
    if (!flexContainer.current || hasDeterminedHiddenElements) {
      return;
    }

    const numberOfFittingElements = countElementsBeforeWrap(
      flexContainer.current
    );
    setFittingElementCount(numberOfFittingElements);
    setDeterminedHiddenElements(true);
  }, [flexContainer, items, hasDeterminedHiddenElements]);

  useDebugValue({ hasDeterminedHiddenElements });

  return {
    fittingElementCount,
    hiddenElementCount: items.length - fittingElementCount,
    hasDeterminedHiddenElements,
  };
}
