import { useContext, useState } from "react";

import { Divider } from "@material-ui/core";
import { FeatureFlagEnum } from "@pulsemarket/constants";
import { neutralColors, utilityColors } from "@pulsemarket/constants";
import { Loading } from "components/FullScreenLoading";
import Attachments from "components/ui/Attachments";
import AvatarIcon from "components/ui/Avatar";
import Button from "components/ui/Button";
import InsertedImage from "components/ui/InsertedImage";
import KebabMenu, { KebabMenuAction } from "components/ui/KebabMenu";
import SvgIcon from "components/ui/SvgIcon";
import ModalContext from "contexts/ModalContext";
import dayjs from "dayjs";
import { useComments } from "hooks/queries/commentHooks";
import { usePostReactionDetails } from "hooks/queries/reactionHooks";
import useDownloadAssets from "hooks/queries/useDownloadAssets";
import useLikePost from "hooks/queries/useLikePost";
import { useUpdatePost } from "hooks/queries/useUpdatePost";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import Comment from "pages/PulseGroup/Post/Comment";
import EditableYouTubeVideo from "pages/PulseGroup/Post/EditableYouTubeVideo";
import ReactionCount from "pages/PulseGroup/Post/PostCard/ReactionCount";
import { DEFAULT_COMPANY_AVATAR } from "shared/constants/icons";
import { PostWithReferencesDto } from "shared/model";
import { humanizedDateInterval } from "shared/utils/time";
import { classes, style } from "typestyle";

import { sharedPostStyles } from "../shared.styles";
import * as modals from "./modals";
import { styles } from "./PostCard.styles";

type PostInteractions = "like" | "comment" | "edit" | "delete" | "approve";

export type NonEditablePostCardProps = {
  groupId: string;
  post: PostWithReferencesDto;
  onEdit: () => void;
  allowedInteractions?: { [key in PostInteractions]?: boolean };
  isGroupOwner?: boolean;
};

/** Post that's not being edited */
export default function NonEditablePostCard({
  groupId,
  post,
  onEdit,
  allowedInteractions,
  isGroupOwner,
}: NonEditablePostCardProps) {
  const { data: commentsEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.comments,
  });
  const [loadReactionDetails, setLoadReactionDetails] =
    useState<boolean>(false);

  const { data: reactions, isLoading: loadingReactions } =
    usePostReactionDetails({ postId: post.id, enabled: loadReactionDetails });

  const {
    data: pagedComments,
    fetchNextPage,
    hasNextPage: hasMoreComments,
    isFetchingNextPage: nextCommentsLoading,
  } = useComments({
    referenceId: post.id,
    numberOfComments: post.commentsCount,
    referenceType: "post",
    initialData: () => {
      if (post.comments) {
        return {
          pages: [post.comments],
          pageParams: post.comments.length ? [undefined] : [],
        };
      }
    },
  });

  const [postLiked, setPostLiked] = useState<boolean>(false);

  const postingDate = dayjs(post.createdAt);

  const now = dayjs(new Date());
  const postingDateInterval = humanizedDateInterval(now, postingDate);

  const { mutateAsync: likePost } = useLikePost();
  const { mutateAsync: updatePost, isLoading: updatePending } = useUpdatePost();

  const { downloadAssets } = useDownloadAssets();

  const { openModal } = useContext(ModalContext);

  const comments = (pagedComments?.pages || []).flatMap((c) => c);

  const postActions: KebabMenuAction[] = [
    ...(allowedInteractions?.edit
      ? [
          {
            onClick: () => onEdit(),
            text: "Edit post",
          },
        ]
      : []),
    ...(allowedInteractions?.delete
      ? [
          {
            onClick: () => {
              openModal(
                <modals.DeletePost groupId={groupId} postId={post.id} />
              );
            },
            text: "Delete Post",
          },
        ]
      : []),
  ];

  return (
    <div className={styles.nonEditableContainer}>
      <div className={styles.postCardHeader}>
        <div className={styles.avatarContainer}>
          <AvatarIcon
            src={post.company.avatar || DEFAULT_COMPANY_AVATAR}
            className={styles.companyAvatar}
            size="40"
          />
        </div>
        <div className={styles.companyDetailsContainer}>
          <div className={styles.postCompanyName}>{post.company.name}</div>
          <div className={sharedPostStyles.postingDate}>
            {`Posted by: ${post.author.firstName} ${post.author.lastName} - ${postingDateInterval}`}
          </div>
        </div>
        {!!postActions.length && (
          <KebabMenu
            className={sharedPostStyles.kebabMenu}
            actions={postActions}
          />
        )}
      </div>

      {updatePending ? (
        <Loading isWindowed />
      ) : (
        <p className={styles.content}>{post.text}</p>
      )}

      {!!post.insertedMedia?.length && (
        <div className={styles.attachmentsContainer}>
          {post.insertedMedia.map((image) => {
            return (
              <InsertedImage
                key={image.id}
                image={image}
                onDelete={() => {}}
                disableDelete
              />
            );
          })}
        </div>
      )}

      <Attachments
        className={styles.attachmentsContainer}
        attachments={post?.attachments}
        disableDelete
        onDownload={(attachment) => {
          downloadAssets({ assetIds: [attachment.id] });
        }}
      />

      {!!post?.mediaItems.length &&
        post.mediaItems.map((item) => (
          <EditableYouTubeVideo
            key={item.id}
            item={item}
            disableDelete
            disableEdit
          />
        ))}

      {(allowedInteractions?.comment || allowedInteractions?.like) && (
        <>
          <div className={styles.reactionsRow}>
            {allowedInteractions.like && (
              <>
                <SvgIcon
                  color={
                    post.isLiked || postLiked
                      ? utilityColors.ORANGE
                      : neutralColors.GRAY4
                  }
                  src="/icons/like_icon.svg"
                  onClick={() => {
                    setPostLiked(!post.isLiked);
                    likePost({ groupId: groupId, postId: post.id });
                  }}
                  className={classes(
                    styles.likeButton,
                    postLiked && styles.likeButtonClicked
                  )}
                />
                {!!post.reactionCount && (
                  <ReactionCount
                    count={post.reactionCount}
                    reactions={reactions}
                    isLoading={loadingReactions}
                    initiateLoading={() => setLoadReactionDetails(true)}
                  />
                )}
              </>
            )}
            {commentsEnabled && post.comments && (
              <p className={styles.commentsCount}>
                {post.commentsCount} comments
              </p>
            )}
          </div>
          <Divider className={styles.interactionDivider} />
        </>
      )}

      {commentsEnabled && allowedInteractions?.comment && (
        <div className={styles.commentsContainer}>
          <Comment postId={post.id} groupId={groupId} />
          {comments.map((comment) => {
            return (
              <Comment
                key={comment.id}
                groupId={groupId}
                comment={comment}
                postId={post.id}
                isGroupOwner={isGroupOwner}
              />
            );
          })}
          {hasMoreComments && !nextCommentsLoading && (
            <Button
              variant="text"
              color="orange"
              onClick={() => fetchNextPage()}
              size="small"
              className={styles.loadCommentsButton}
            >
              Load more comments
            </Button>
          )}
          {nextCommentsLoading && (
            <Loading
              isWindowed
              className={style({
                height: "33px",
                width: "33px",
              })}
            />
          )}
        </div>
      )}
      {allowedInteractions?.approve && (
        <div className={styles.approvePostButtonsContainer}>
          <Button
            type="button"
            variant="normal"
            color="red"
            onClick={() => {
              openModal(
                <modals.DeclinePost groupId={groupId} postId={post.id} />,
                { keepHistory: true }
              );
            }}
          >
            Decline
          </Button>
          <Button
            type="button"
            variant="normal"
            color="green"
            onClick={() => {
              updatePost({
                groupId: groupId,
                post: { isApproved: true },
                postId: post.id,
              });
            }}
          >
            Approve
          </Button>
        </div>
      )}
    </div>
  );
}
