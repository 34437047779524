import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { clickable, zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

const menuItemBackground: NestedCSSProperties = {
  $nest: {
    "&:hover": {
      background: primaryColors.HOVER,
    },
  },
};

const dividerSpacing = 38;

const menuItemHeight = 48;

const styles = stylesheet({
  root: {
    marginRight: "30px",
    height: "100%",
  },
  title: {
    ...zeroPaddingMargin,
    color: primaryColors.PURPLE2,
    ...typography.SUBTITLE2,
  },
  divider: {
    backgroundColor: neutralColors.GRAY3,
    marginTop: `${dividerSpacing}px`,
    marginBottom: `${dividerSpacing}px`,
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  menuItem: {
    height: `${menuItemHeight}px`,
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  menuItemClickable: {
    ...menuItemBackground,
    ...clickable,
  },
  selectedMenuItem: {
    backgroundColor: primaryColors.HOVER,
  },
  lists: {
    height: `calc(100% - ${2 * dividerSpacing}px - ${1 * menuItemHeight}px)`,
  },
  ownLists: {
    height: `calc(100% - ${menuItemHeight}px)`,
    overflowY: "auto",
  },
});

export default styles;
