const mouseClickEvents = ["mousedown", "click", "mouseup"];

export function simulateMouseClick(element: HTMLElement) {
  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}
