import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import { FeatureFlagEnum } from "@pulsemarket/constants";
import { ApiError } from "shared/client";

import { getFeatureFlag } from "../shared/client";

export default function useFeatureEnabled({
  featureName,
}: {
  featureName: FeatureFlagEnum;
}) {
  const featureEnabled = useQuery(
    queryKeys.features(featureName),
    () => getFeatureFlag(featureName),
    {
      retry: (_, error) => {
        console.log(error);
        if (error instanceof ApiError) {
          if (error.status === 404 || error.status === 503) {
            return false;
          }
        }
        return true;
      },
      ...longCachedQueryOptions,
      enabled: !!featureName,
      placeholderData: false,
    }
  );
  return featureEnabled;
}
