import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";

import { Loading } from "components/FullScreenLoading";
import { useGetAuthToken } from "hooks/queries/useAuth";

type PrivateRouteProps = RouteProps & {
  children?: React.ReactNode;
};

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const { isLoading, isError, data: userData } = useGetAuthToken();

  const { pathname } = useLocation();

  if (isLoading && !isError) {
    return <Loading />;
  }

  const redirectToRoot = (isError || !userData?.userId) && pathname !== "/";

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return redirectToRoot ? (
          <Redirect
            push={false}
            to={{
              pathname: "/",
              state: { from: location },
              search: window.location.search,
            }}
          />
        ) : (
          children
        );
      }}
    />
  );
};

export default PrivateRoute;
