import React, { useContext } from "react";

import { ListItemType } from "components/AssetRepository/types";
import RepositoryModal from "components/assets/RepositoryModal";
import CloneOrUploadModal, {
  CloneOrUploadModalProps,
} from "components/CloneOrUploadModal";
import { fileUploaderModalProps } from "components/FileUploader/FileUploader";
import FileUploadingWindow from "components/FileUploader/FileUploadingWindow";
import {
  FileUploadingWindowProps,
  PromiseState,
} from "components/FileUploader/FileUploadingWindow/FileUploadingWindow";
import ModalContext from "contexts/ModalContext";
import { isAsset } from "hooks/queries/useAssetItems";
import { ApiError } from "shared/client";
import { isMatchingMimeType } from "util/files";

const defaultBlockedExtensions = [
  "exe",
  "bat",
  "cmd",
  "com",
  "job",
  "msi",
  "paf",
  "reg",
  "ws",
  "wsf",
];

type AttachmentUploaderProps = {
  fileUploaderProps: Partial<FileUploadingWindowProps>;
  onCloneAssets: (assets: string[]) => Promise<void>;
  onUploadAsset: (assets: File[]) => Promise<void>;
} & Pick<
  CloneOrUploadModalProps,
  "cloneButtonText" | "text" | "title" | "uploadConfig"
>;

export default function AttachmentUploader({
  fileUploaderProps,
  onCloneAssets,
  onUploadAsset,
  ...cloneOrUploadModalProps
}: AttachmentUploaderProps) {
  const { openModal, closeModal } = useContext(ModalContext);

  const enabledMimes = cloneOrUploadModalProps.uploadConfig?.allowedFileTypes;

  return (
    <CloneOrUploadModal
      {...cloneOrUploadModalProps}
      onClose={closeModal}
      onCloneRequest={() => {
        openModal(
          <RepositoryModal
            headerActionsHidden={true}
            open
            disableSelection={(row) => {
              if (row.listItemType === ListItemType.Folder) {
                return true;
              }

              if (isAsset(row)) {
                if (typeof enabledMimes === "string") {
                  return !isMatchingMimeType(enabledMimes, row.mimeType);
                }

                if (Array.isArray(enabledMimes)) {
                  return enabledMimes.some(
                    (enabledMime) =>
                      !isMatchingMimeType(enabledMime, row.mimeType)
                  );
                }
              }
            }}
            actions={[
              {
                variant: "normal",
                color: "gray",
                onClick: () => {
                  closeModal();
                },
                text: "Cancel",
              },
              {
                variant: "normal",
                color: "orange",
                onClick: async (selectedItems) => {
                  const assetIds = selectedItems
                    .filter(isAsset)
                    .map((asset) => asset.id);

                  const sharedUploadingWindowProps: Omit<
                    FileUploadingWindowProps,
                    "status"
                  > = {
                    fileName: fileUploaderProps.fileName || "Attachment",
                    size: 0,
                    destination: fileUploaderProps.destination,
                    fileCount: assetIds.length,
                  };
                  closeModal();
                  try {
                    openModal(
                      <FileUploadingWindow
                        {...sharedUploadingWindowProps}
                        status={PromiseState.Pending}
                      />,
                      {
                        props: fileUploaderModalProps(PromiseState.Pending),
                      }
                    );
                    await onCloneAssets(assetIds);
                    closeModal();
                    openModal(
                      <FileUploadingWindow
                        {...sharedUploadingWindowProps}
                        onClose={closeModal}
                        status={PromiseState.Resolved}
                      />,
                      {
                        props: fileUploaderModalProps(PromiseState.Resolved),
                      }
                    );
                  } catch (err) {
                    console.log(err);
                    if (err instanceof ApiError) {
                      closeModal();
                      if (err.status === 409) {
                        openModal(
                          <FileUploadingWindow
                            {...sharedUploadingWindowProps}
                            onClose={closeModal}
                            status={PromiseState.Rejected}
                            errors={[
                              "Conflict: A cloned asset is already attached.",
                            ]}
                          />,
                          {
                            props: fileUploaderModalProps(
                              PromiseState.Rejected
                            ),
                          }
                        );
                      } else {
                        openModal(
                          <FileUploadingWindow
                            {...sharedUploadingWindowProps}
                            onClose={closeModal}
                            status={PromiseState.Rejected}
                            errors={[err.message]}
                          />,
                          {
                            props: fileUploaderModalProps(
                              PromiseState.Rejected
                            ),
                          }
                        );
                      }
                    }
                  }
                },
                text: "Select",
                disableIfNoItemsSelected: true,
              },
            ]}
          />
        );
      }}
      uploadConfig={{
        ...cloneOrUploadModalProps.uploadConfig,
        allowMultiple: true,
        destination: fileUploaderProps.destination,
        onFilesAccepted: async (files) => {
          await onUploadAsset(files);
        },
        onFilesRejected: async (rejections) => {
          // todo handle
          console.log(rejections);
        },
        // todo use custom
        blockedFileTypes: [
          ...(cloneOrUploadModalProps.uploadConfig?.blockedFileTypes || []),
          ...defaultBlockedExtensions,
        ],
      }}
    />
  );
}
