import React from "react";

import { Loading } from "components/FullScreenLoading";
import { useGroupContext } from "contexts/Group/GroupProvider";
import useOwnCompany from "hooks/queries/useOwnCompany";
import PostsContainer from "pages/PulseGroup/Post/PostsContainer";

type ReviewPostsProps = {};

const ReviewPosts = (_: ReviewPostsProps) => {
  const { data: ownCompany } = useOwnCompany();
  const { group, isOwner } = useGroupContext();

  if (!ownCompany || !group) {
    return <Loading isWindowed />;
  }

  return (
    <PostsContainer
      groupId={group?.id}
      isApproved={false}
      isGroupOwner={isOwner}
      postsRequireApproval={group?.postsRequireApproval}
    />
  );
};

export default ReviewPosts;
