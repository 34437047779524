import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { useVendorSearchContext } from "components/VendorSearch/VendorFilterProvider";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { deleteCompanyListCompany } from "shared/client";
import { CompanyDetailsDto, CompanyDto } from "shared/model";

import { cleanFiltersWithCompanyListId } from "./useCompanies";

type DeleteParams = {
  listId: string;
  companyId: string;
};

type DeleteCompanyFromListHooksOptions = {
  optimisticlyUpdateSearch?: boolean;
};

export default function useDeleteCompanyFromList(
  options?: DeleteCompanyFromListHooksOptions
) {
  const client = useQueryClient();

  const { data: ownCompany } = useOwnCompany();

  const { filters, companyListId } = useVendorSearchContext();

  const params = cleanFiltersWithCompanyListId({
    filters,
    companyListId,
  });

  return useMutation(
    ({ listId, companyId }: DeleteParams) =>
      deleteCompanyListCompany(ownCompany!.id, listId, companyId),
    {
      mutationKey: mutationKeys.deleteCompanyFromList,
      // When mutate is called:
      onMutate: async ({ listId, companyId }: DeleteParams) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(queryKeys.ownCompany);
        // Snapshot the previous value
        const previousOrganization = client.getQueryData<CompanyDetailsDto>(
          queryKeys.ownCompany
        );
        // Optimistically update to the new value
        if (previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(queryKeys.ownCompany, {
            ...previousOrganization,
            companyLists: previousOrganization.companyLists.map((list) =>
              list.id === listId
                ? {
                    ...list,
                    companyIds: list.companyIds.filter(
                      (company) => company !== companyId
                    ),
                  }
                : list
            ),
          });
        }

        const previousSearchList = client.getQueryData<CompanyDto[]>(
          queryKeys.companies(params)
        );

        if (options?.optimisticlyUpdateSearch && previousSearchList) {
          client.setQueryData<CompanyDto[]>(
            queryKeys.companies(params),
            previousSearchList.filter((company) => company.id !== companyId)
          );
        }

        return { previousOrganization, previousSearchList };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(
            queryKeys.ownCompany,
            context.previousOrganization
          );
        }
        if (options?.optimisticlyUpdateSearch && context?.previousSearchList) {
          client.setQueryData<CompanyDto[]>(
            queryKeys.companies(params),
            context.previousSearchList
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        client.invalidateQueries(queryKeys.ownCompany);
        if (options?.optimisticlyUpdateSearch) {
          client.invalidateQueries(queryKeys.companies(params));
        }
      },
    }
  );
}
