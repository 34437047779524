import React, { useContext } from "react";

import Button from "components/ui/Button";
import ModalHeader from "components/ui/ModalHeader";
import CreateList from "components/vendorlists/CreateList";
import ModalContext from "contexts/ModalContext";
import { Pair } from "shared/model";
import { typography } from "shared/styles/typography";
import { classes, style } from "typestyle";

import styles from "./NoListStyles";

export interface NoListProps {
  company: Pair;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export default function NoList({
  company,
  onSuccess = () => {},
  onCancel = () => {},
}: NoListProps) {
  const { openModal } = useContext(ModalContext);

  function openCreateList() {
    openModal(<CreateList onSuccess={onSuccess} onCancel={onCancel} />);
  }

  return (
    <div className={styles.CreateList}>
      <div className={styles.contentWrapper}>
        <ModalHeader
          title={`Add ${company.name} to My Lists`}
          onClose={onCancel}
        />
        <p className={style(typography.BODY2)}>
          You don't have any lists yet to add{" "}
          <span className={styles.companyNameText}>{company.name}</span>. Please
          create a new list
        </p>

        <div className={styles.actions}>
          <Button
            className={styles.createListButton}
            variant="transparent"
            onClick={openCreateList}
          >
            <span
              className={classes(styles.buttonLabel, styles.createButtonLabel)}
            >
              Create New List
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}
