import { useCallback, useContext } from "react";

import GroupProvider from "contexts/Group/GroupProvider";
import ModalContext from "contexts/ModalContext";
import GroupSettingsPage, {
  GroupSettingsProps,
} from "pages/PulseGroup/GroupSettingsPage/GroupSettingsPage";
import { style } from "typestyle";

type OpenGroupSettingsModalParams = Omit<GroupSettingsProps, "groupId"> & {
  groupId?: string;
};

export default function useOpenGroupSettings() {
  const { openModal } = useContext(ModalContext);

  return useCallback(
    ({ groupId, ...props }: OpenGroupSettingsModalParams) => {
      if (!groupId) {
        return;
      }

      openModal(
        <GroupProvider groupId={groupId}>
          <GroupSettingsPage {...props} groupId={groupId} />
        </GroupProvider>,
        {
          props: {
            maxWidth: "lg",
            paperClassName: style({ width: "100%" }),
          },
        }
      );
    },
    [openModal]
  );
}
