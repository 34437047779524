import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  topMenuAccountDetailsClass: {
    display: "flex",
    flexDirection: "row",
    padding: "8px 20px",
    alignItems: "center",
  },
  header: {
    height: "105px",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divider: {
    margin: "auto 24px",
    height: "32px",
    width: "1px",
    background: neutralColors.GRAY3,
  },
  profileTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0px 20px",
  },
  profileText: {
    margin: "1px",
    textAlign: "end",
  },
  topMenuLeftContainer: {
    ...typography.SUBTITLE1,
  },
  topMenuWelcome: {
    marginLeft: "8px",
    color: primaryColors.BLACK,
    $nest: {
      span: {
        color: utilityColors.ORANGE,
      },
    },
  },
  welcomeContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "17px",
    alignItems: "center",
  },
  breadCrumbArrow: {
    marginRight: "12px",
    marginLeft: "12px",
  },
  breadCrumbContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});
