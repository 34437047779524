import React, { useContext } from "react";

import ConfirmationModalBody from "components/ConfirmationModalBody";
import ModalContext from "contexts/ModalContext";
import { useDeletePostVideo } from "hooks/queries/postMediaItemHooks";
import useDeletePost from "hooks/queries/useDeletePost";

export function DeletePost({
  postId,
  groupId,
}: {
  postId: string;
  groupId: string;
}) {
  const { closeModal } = useContext(ModalContext);
  const { mutateAsync: deletePost } = useDeletePost();

  return (
    <ConfirmationModalBody
      title="Delete Post"
      buttonText="Yes, delete"
      action={async () => {
        try {
          await deletePost({
            groupId,
            postId,
          });
        } catch (err) {
          console.log(err);
        }
      }}
      onClose={closeModal}
    >
      Are you sure you want to Delete this post?
    </ConfirmationModalBody>
  );
}

export function DeclinePost({
  groupId,
  postId,
}: {
  groupId: string;
  postId: string;
}) {
  const { closeModal } = useContext(ModalContext);
  const { mutateAsync: deletePost } = useDeletePost();

  return (
    <ConfirmationModalBody
      title="Decline"
      buttonText="Yes, Decline"
      action={async () => {
        try {
          deletePost({
            groupId,
            postId,
          });
        } catch (err) {
          console.log(err);
        }
      }}
      onClose={closeModal}
    >
      Are you sure you want to decline this post?
    </ConfirmationModalBody>
  );
}

export function DeleteVideo({
  groupId,
  videoId,
}: {
  groupId: string;
  videoId: string;
}) {
  const { closeModal } = useContext(ModalContext);
  const { mutateAsync: deleteVideo } = useDeletePostVideo({ groupId });

  return (
    <ConfirmationModalBody
      title="Delete Video"
      buttonText="Yes, delete"
      action={async () => {
        try {
          await deleteVideo({
            mediaItemId: videoId,
          });
        } catch (err) {
          console.log(err);
        }
      }}
      onClose={closeModal}
    >
      Are you sure you want to Delete this video?
    </ConfirmationModalBody>
  );
}
