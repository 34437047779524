import React from "react";
import { useHistory } from "react-router-dom";

import { neutralColors, primaryColors } from "@pulsemarket/constants";
import NotificationItem from "components/Notifications/NotificationItem";
import Button from "components/ui/Button";
import SvgIcon from "components/ui/SvgIcon";
import { NotificationDto } from "shared/model";
import { typography } from "shared/styles/typography";
import { classes, stylesheet } from "typestyle";

type Props = {
  className?: string;
  onClose: () => void;
  notifications: NotificationDto[];
};

const styles = stylesheet({
  wrapper: {
    width: "357px",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    ...typography.TITLE1,
    color: primaryColors.BLACK,
  },
  list: {
    padding: "0",
    listStyle: "none",
    margin: "28px 0px",
  },
  li: {
    listStyle: "none",
    marginBottom: "10px",
    $nest: {
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
  item: {
    display: "inline-flex",
  },
  seeAllButton: {
    alignSelf: "flex-end",
  },
  emptyContainer: {
    textAlign: "center",
  },
  emptyImg: {
    color: neutralColors.GRAY2,
    height: "80px",
  },
  emptyText: {
    color: neutralColors.GRAY5,
    ...typography.CAPTION,
  },
});

const NotificationPopUp = ({ className, notifications, onClose }: Props) => {
  const history = useHistory();

  return (
    <div className={classes(className, styles.wrapper)}>
      <div className={styles.title}>Notifications</div>
      <ul className={styles.list}>
        {notifications.map((n) => {
          return (
            <li key={n.id} className={styles.li}>
              <NotificationItem notification={n} onItemClick={onClose} />
            </li>
          );
        })}
      </ul>

      {!notifications.length ? (
        <div className={styles.emptyContainer}>
          <SvgIcon
            className={styles.emptyImg}
            src="/icons/notification_icon_on.svg"
          />
          <p className={styles.emptyText}>You don't have any notification!</p>
        </div>
      ) : (
        <Button
          className={styles.seeAllButton}
          variant="normal"
          size="small"
          onClick={() => {
            onClose();
            history.push("/notifications");
          }}
        >
          See all
        </Button>
      )}
    </div>
  );
};

export default NotificationPopUp;
