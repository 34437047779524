import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { CommentDto } from "@pulsemarket/api-client";
import { FeatureFlagEnum } from "@pulsemarket/constants";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import {
  ApiError,
  getCommentsByReferenceId,
  getMessageDetails,
} from "shared/client";
import { addComment } from "shared/client";
import { updateComment } from "shared/client";
import { deleteComment } from "shared/client";
import { sendAnswerMessages } from "shared/client";
import { CommentReferenceType, MessageDetailsDto } from "shared/model";
import { NewCommentInput } from "shared/model";
import { UpdateCommentInput } from "shared/model";

const COMMENTS_BATCH_SIZE = 3;

type UseCommentsOptions<T = CommentDto[]> = Omit<
  UseInfiniteQueryOptions<
    CommentDto[],
    ApiError,
    T,
    CommentDto[],
    ReturnType<typeof queryKeys.comments>
  >,
  "getNextPageParam"
> & {
  referenceId: string;
  referenceType: CommentReferenceType;
  pageSize?: number;
  numberOfComments?: number;
};

export function useComments<T = CommentDto[]>({
  referenceId,
  referenceType,
  pageSize = COMMENTS_BATCH_SIZE,
  enabled,
  numberOfComments = 0,
  ...options
}: UseCommentsOptions<T>) {
  const { data: commentsEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.comments,
  });

  return useInfiniteQuery(
    queryKeys.comments(referenceId),
    async ({ pageParam: cursorId }) => {
      const response = await getCommentsByReferenceId(
        referenceId,
        {
          limit: pageSize,
          cursorId: cursorId,
        },
        referenceType
      );

      return response;
    },
    {
      enabled:
        typeof enabled === "boolean"
          ? enabled
          : true && !!referenceId && commentsEnabled,
      getNextPageParam(lastPage, allPages) {
        const fetchedCount = allPages.reduce(
          (sum, page) => sum + page.length,
          0
        );

        if (numberOfComments > 0 && fetchedCount === numberOfComments) {
          return undefined;
        }

        if (lastPage.length === pageSize) {
          return lastPage.slice().reverse()[0].id;
        }

        return undefined;
      },
      ...options,
    }
  );
}

export function useAddComment({ groupId }: { groupId?: string }) {
  const client = useQueryClient();

  return useMutation(
    (newComment: NewCommentInput) => {
      return addComment(newComment);
    },
    {
      mutationKey: mutationKeys.addComment,
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(
          queryKeys.posts(groupId, { isApproved: false })
        );
        client.invalidateQueries(queryKeys.comments(variables.referenceId));
      },
    }
  );
}

export function useUpdateComment({ postId }: { postId?: string }) {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      commentId,
      comment,
    }: {
      commentId: string;
      comment: UpdateCommentInput;
    }) => {
      return updateComment(commentId, comment);
    },
    {
      mutationKey: mutationKeys.updateComment,
      onSettled: () => {
        if (postId) {
          queryClient.invalidateQueries(queryKeys.comments(postId));
        }
      },
    }
  );
}

export function useDeleteComment({
  postId,
  groupId,
}: {
  postId: string;
  groupId: string;
}) {
  const client = useQueryClient();

  return useMutation(
    ({ commentId }: { commentId: string }) => deleteComment(commentId),
    {
      mutationKey: mutationKeys.deleteComment,
      onSettled: () => {
        client.invalidateQueries(
          queryKeys.posts(groupId, { isApproved: false })
        );
        client.invalidateQueries(queryKeys.comments(postId));
      },
    }
  );
}

type SendAnswerMessagesHookParams = {
  commentIds: string[];
};

export default function useSendAnswerMessages() {
  const client = useQueryClient();

  return useMutation(
    ({ commentIds }: SendAnswerMessagesHookParams) => {
      return sendAnswerMessages(commentIds);
    },
    {
      mutationKey: mutationKeys.sendAnswerMessages,
      onSettled: () => {
        client.invalidateQueries(queryKeys.comments()[0]);
      },
    }
  );
}

type useMessageDetailsQueryOptions<T = MessageDetailsDto> = UseQueryOptions<
  MessageDetailsDto,
  Error,
  T,
  ReturnType<typeof queryKeys.messageDetails>
> & { messageId?: string };

export function useMessageDetails<T = MessageDetailsDto>({
  messageId,
  enabled,
  ...options
}: useMessageDetailsQueryOptions<T>) {
  return useQuery(
    queryKeys.messageDetails(messageId),
    () => {
      return getMessageDetails(messageId!);
    },
    {
      enabled: !!messageId && (typeof enabled === "boolean" ? enabled : true),
      ...options,
    }
  );
}
