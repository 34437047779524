import React from "react";

import { CompanyCardProps } from "components/CompanyCard/CompanyCard";
import CompanyCardsRow from "components/CompanyCard/CompanyCardsRow";

import styles from "./CompanyListWithTitleStyle";

type CompanyListWithTitleProps = {
  cards: CompanyCardProps[];
  title?: string;
};

export default function CompanyListWithTitle({
  cards,
  title,
}: CompanyListWithTitleProps) {
  return (
    <div className={styles.container}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <CompanyCardsRow cards={cards} />
    </div>
  );
}
