import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import IconButton from "components/ui/IconButton";
import SvgIcon from "components/ui/SvgIcon";
import transformFileToBase64 from "shared/utils/fileToBase64";
import { classes } from "typestyle";

import avatarStyles from "./AvatarStyles";

type AvatarProps = {
  avatar?: string;
  className?: string;
  type: "details" | "services" | "profileImage" | "groups";
  onUploadBase64: (base64Avatar: string, file: File) => void;
  showHelpText?: boolean;
  recommendedSizeText?: string;
  disabled?: boolean;
};

const avatarImage = {
  details: "/icons/company_account.svg",
  profileImage: "/icons/personal_profile.svg",
  services: "/icons/product_or_service.svg",
  groups: "/icons/group_avatar.svg",
};

export default function Avatar({
  avatar,
  type,
  className,
  onUploadBase64: onAvatarUpload,
  showHelpText = true,
  recommendedSizeText,
  disabled = false,
}: AvatarProps) {
  const [maxSizeReached, setMaxSizeReached] = useState<boolean>(false);
  const maxSize = 20485760; //bytes

  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    accept: ["image/*"],
    onDropAccepted: async (files) => {
      const base64File = await transformFileToBase64(files[0]);
      setMaxSizeReached(false);
      onAvatarUpload(base64File, files[0]);
    },
    onDropRejected: () => setMaxSizeReached(true),
    multiple: false,
    maxSize,
    disabled: disabled,
  });

  return (
    <div className={avatarStyles.root}>
      <div
        {...getRootProps()}
        className={classes(
          type === "profileImage"
            ? avatarStyles.profileImageBox
            : avatarStyles.avatarBox,
          className
        )}
      >
        <input {...getInputProps()} />
        {avatar ? (
          <img src={avatar} alt="avatar" className={avatarStyles.image} />
        ) : (
          <>
            <SvgIcon
              src={avatarImage[type]}
              className={
                type === "profileImage"
                  ? avatarStyles.profileImage
                  : avatarStyles.defaultAvatar
              }
            />
          </>
        )}
        {!disabled && (
          <div
            className={classes(
              avatarStyles.iconContainer,
              type === "profileImage"
                ? avatarStyles.cameraIconContainerProfileImage
                : avatarStyles.cameraIconContainer
            )}
          >
            <IconButton
              src="/icons/edit_a_photo.svg"
              tooltip="Edit Image"
              color="purple"
            />
          </div>
        )}
      </div>
      <div className={avatarStyles.tooltipContainer}>
        {(showHelpText || recommendedSizeText) && (
          <span className={avatarStyles.span}>
            {recommendedSizeText ?? "Recommended 200x200px"}
          </span>
        )}
        {showHelpText && !maxSizeReached && (
          <span className={avatarStyles.maxSizeSpan}>Max size 20 MB</span>
        )}
        {maxSizeReached && (
          <span className={avatarStyles.tryAgain}>
            Max size 20 MB. Try Again
          </span>
        )}
      </div>
    </div>
  );
}
