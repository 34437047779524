import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { px } from "csx";
import { clickable, zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  combinedInvitationSender: {
    color: primaryColors.BLACK,
  },
  title: {
    ...zeroPaddingMargin,
    ...typography.HEADLINE6,
    marginBottom: "24px",
  },
  subtitle: {
    ...zeroPaddingMargin,
    ...typography.BODY2,
    marginBottom: "24px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  emailInput: {
    width: "100%",
    padding: px(8),
  },
  button: {
    width: "160px",
    marginLeft: "20px",
  },
  companyAndSupplierContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: px(24),
    marginTop: "44px",
  },
  supplierSelect: { width: "100%" },
  findNewSuppliersButton: { width: "33%", minWidth: "148px" },
  errorMessageContainer: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
  recipients: {
    display: "flex",
    gap: "8px",
    margin: "16px 0px",
  },
  alertIcon: {
    height: "14px",
    color: utilityColors.YELLOW,
  },
});

export default styles;

export const recipientTagStyles = stylesheet({
  tag: {
    ...clickable,
    display: "inline-flex",
    height: px(30),
    padding: `${px(5)} ${px(15)} `,
    borderRadius: px(4),
    borderColor: primaryColors.PURPLE2,
    borderWidth: px(1),
    borderStyle: "solid",
    alignItems: "baseline",
    color: primaryColors.PURPLE3,
  },
  duplicate: {
    color: neutralColors.GRAY4,
    borderColor: neutralColors.GRAY4,
    $nest: {
      "&:hover": {
        backgroundColor: neutralColors.GRAY2,
      },
    },
  },
  svgIcon: {
    height: px(10),
    marginLeft: px(11),
  },
});
