import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import {
  cloneAssetsToPost,
  createPost,
  deletePostAttachment,
  uploadPostAttachment,
} from "shared/client";
import { AttachmentMode, NewPostInput } from "shared/model";

export default function useCreatePost() {
  const client = useQueryClient();

  return useMutation(
    ({ groupId, ...post }: NewPostInput & { groupId: string }) => {
      return createPost(groupId, post);
    },
    {
      mutationKey: mutationKeys.createPost,
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.group(variables.groupId));
        client.invalidateQueries(
          queryKeys.posts(variables.groupId, { isApproved: false }).slice(0, 2)
        );
      },
    }
  );
}

export function useUploadPostAttachment({ groupId }: { groupId: string }) {
  const client = useQueryClient();

  return useMutation(
    ({
      postId,
      files,
      mode,
    }: {
      mode: AttachmentMode;
      postId: string;
      files: File[];
    }) => {
      return uploadPostAttachment(postId, files, mode);
    },
    {
      mutationKey: mutationKeys.uploadPostAttachment,
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.posts(groupId, {})[0]);
      },
    }
  );
}

export function useCloneAssetsToPost({ groupId }: { groupId: string }) {
  const client = useQueryClient();

  return useMutation(
    ({
      postId,
      assetIds,
      mode,
    }: {
      postId: string;
      assetIds: string[];
      mode: AttachmentMode;
    }) => {
      return cloneAssetsToPost(postId, { assetIds, mode });
    },
    {
      mutationKey: mutationKeys.uploadPostAttachment,
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.posts(groupId, {})[0]);
      },
    }
  );
}

export function useDeletePostAttachment({ groupId }: { groupId: string }) {
  const client = useQueryClient();

  return useMutation(
    ({ assetId }: { assetId: string }) => {
      return deletePostAttachment(assetId);
    },
    {
      mutationKey: mutationKeys.deletePostAttachment,
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.posts(groupId, {})[0]);
      },
    }
  );
}
