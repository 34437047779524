import { useEffect, useState } from "react";

import {
  getSearchParam,
  LocalStorageKey,
  removeSearchParam,
  SearchParamEnum,
  setCookieArray,
  TriggeredPopupEnum,
} from "hooks/utils";

export const useIsComingFromInvitationRedirect = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const redirectUrl = getSearchParam(SearchParamEnum.REDIRECT_PAGE_PARAM);

    const rfpInvitationToken = getSearchParam(
      SearchParamEnum.RFP_INVITATION_TOKEN
    );

    const companyMemberInvitationToken = getSearchParam(
      SearchParamEnum.COMPANY_MEMBER_INVITATION_TOKEN
    );
    const inviterCompanyId = getSearchParam(SearchParamEnum.INVITER_COMPANY);
    const groupInvitationToken = getSearchParam(
      SearchParamEnum.GROUP_INVITATION_TOKEN
    );

    const autoTriggeredPopup = getSearchParam(
      SearchParamEnum.OPEN_POPUP
    ) as TriggeredPopupEnum | null;

    if (autoTriggeredPopup) {
      console.log("Setting cookie: ", LocalStorageKey.OPEN_POPUP);
      setCookieArray({
        key: LocalStorageKey.OPEN_POPUP,
        value: autoTriggeredPopup,
        type: "add",
      });
      removeSearchParam(SearchParamEnum.OPEN_POPUP);
    }

    if (redirectUrl) {
      console.log("Setting cookie: ", LocalStorageKey.REDIRECT);

      localStorage.setItem(LocalStorageKey.REDIRECT, redirectUrl);
      removeSearchParam(SearchParamEnum.REDIRECT_PAGE_PARAM);
    }

    //RFP Invitation
    if (rfpInvitationToken) {
      console.log("Setting cookie: ", SearchParamEnum.RFP_INVITATION_TOKEN);
      localStorage.setItem(
        LocalStorageKey.RFP_INVITATION_TOKEN,
        rfpInvitationToken
      );
      removeSearchParam(SearchParamEnum.RFP_INVITATION_TOKEN);

      //Company Member Invitation
    } else if (companyMemberInvitationToken && inviterCompanyId) {
      console.log(
        "Setting cookie: ",
        LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN
      );

      localStorage.setItem(
        LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN,
        companyMemberInvitationToken
      );

      console.log("Setting cookie: ", LocalStorageKey.INVITER_COMPANY_ID);

      localStorage.setItem(
        LocalStorageKey.INVITER_COMPANY_ID,
        inviterCompanyId
      );

      removeSearchParam(SearchParamEnum.COMPANY_MEMBER_INVITATION_TOKEN);
      removeSearchParam(SearchParamEnum.INVITER_COMPANY);

      //Group Invitation
    } else if (groupInvitationToken && inviterCompanyId) {
      console.log("Setting cookie: ", LocalStorageKey.GROUP_INVITATION_TOKEN);

      localStorage.setItem(
        LocalStorageKey.GROUP_INVITATION_TOKEN,
        groupInvitationToken
      );

      console.log("Setting cookie: ", LocalStorageKey.INVITER_COMPANY_ID);

      localStorage.setItem(
        LocalStorageKey.INVITER_COMPANY_ID,
        inviterCompanyId
      );

      removeSearchParam(SearchParamEnum.GROUP_INVITATION_TOKEN);
      removeSearchParam(SearchParamEnum.INVITER_COMPANY);
    }
    setIsLoading(false);
  }, []);
  return { isLoading };
};
