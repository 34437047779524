import React, { useCallback } from "react";

import { Loading } from "components/FullScreenLoading";
import ModalHeader from "components/ui/ModalHeader";
import MultiSelectList from "components/ui/MultiSelectList";
import useDeleteCompanyFromList from "hooks/queries/useDeleteCompanyFromList";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { Pair } from "shared/model";
import { typography } from "shared/styles/typography";
import { style } from "typestyle";

import styles from "./RemoveFromListStyles";

export interface AddToListProps {
  company: Pair;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export default function RemoveFromList({
  company,
  onSuccess = () => {},
  onCancel = () => {},
}: AddToListProps) {
  const { data: ownCompany, isFetching: companyLoading } = useOwnCompany();
  const { isLoading: deletionInProgress, mutate: deleteCompanyFromList } =
    useDeleteCompanyFromList({
      optimisticlyUpdateSearch: false,
    });

  const isLoading = companyLoading || deletionInProgress;

  async function removeFromList(selectedIds: string[]) {
    selectedIds.map((listId) =>
      deleteCompanyFromList({ listId, companyId: company.id })
    );

    onSuccess();
  }

  const getOptionsPairs = useCallback<() => Pair[]>(
    () =>
      ownCompany!.companyLists
        .filter((item) => item.companyIds.includes(company.id))
        .map((item) => ({
          name: item.name,
          id: item.id,
        })),
    [company.id, ownCompany]
  );

  return (
    <div className={styles.RemoveFromList}>
      <ModalHeader title={`Remove ${company.name}`} onClose={onCancel} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <p className={style(typography.BODY2)}>
            Are you sure you want to remove{" "}
            <span className={styles.companyNameText}>{company.name}</span> from
            one or more of your Lists?
          </p>
          <MultiSelectList
            disableUncheck
            placeholder={"Select List"}
            options={getOptionsPairs()}
            actions={[
              {
                label: "Yes, Remove Company",
                color: "orange",
                // this component shoudl know of what is selected, until that this hack is applied to make it disabled
                disabled: (selectedIds: string[]) => !selectedIds.length,
                onClick: (selectedItems: Pair[]) =>
                  removeFromList(selectedItems.map((item) => item.id)),
              },
              {
                label: "Cancel",
                color: "gray",
                onClick: () => onCancel(),
              },
            ]}
          />
        </>
      )}
    </div>
  );
}
