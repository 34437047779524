import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    display: "flex",
    alignItems: "center",
    height: "48px",
  },
  avatar: {
    marginRight: "12px",
  },
  name: {
    ...typography.SUBTITLE2,
    color: primaryColors.BLACK,
    marginBottom: "2px",
  },
  role: {
    ...typography.CAPTION,
    color: neutralColors.GRAY4,
  },
  texts: {
    display: "flex",
    flexDirection: "column",
  },
});

export default styles;
