import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { FolderAPIItem } from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import { useAssetContext } from "contexts/AssetFilter/AssetFilterProvider";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { renameAsset } from "shared/client";

type RenameAssetParams = {
  assetId: string;
  newName: string;
};

export default function useRenameAsset() {
  const client = useQueryClient();
  const { searchOptions } = useAssetContext();

  const { data: ownCompany } = useOwnCompany();

  return useMutation(
    ({ assetId, newName }: RenameAssetParams) => {
      return renameAsset(ownCompany!.id, assetId, newName);
    },
    {
      mutationKey: mutationKeys.renameAsset,
      // When mutate is called:
      onMutate: async ({ assetId: folderId, newName }: RenameAssetParams) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );
        // Snapshot the previous values
        const previousSearchResult = client.getQueryData<FolderAPIItem[]>(
          queryKeys.assetRepositorySearch(searchOptions)
        );
        // Optimistically update to the new value
        if (previousSearchResult) {
          client.setQueryData<FolderAPIItem[]>(
            queryKeys.assetRepositorySearch(searchOptions),
            previousSearchResult.map((item) =>
              item.id === folderId ? { ...item, name: newName } : item
            )
          );
        }
        return { previousSearchResult };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousSearchResult) {
          client.setQueryData<FolderAPIItem[]>(
            queryKeys.assetRepositorySearch(searchOptions),
            context.previousSearchResult
          );
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );
      },
    }
  );
}
