import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@material-ui/core";
import { Loading } from "components/FullScreenLoading";
import Avatar, { AvatarProps } from "components/ui/Avatar";
import Button from "components/ui/Button";
import { ButtonColors, ButtonProps } from "components/ui/Button/Button";
import SvgIcon from "components/ui/SvgIcon";
import { Pair } from "shared/model";
import { typography } from "shared/styles/typography";
import { classes, style } from "typestyle";

import styles from "./MultiSelectListStyles";

type WithAvatarProps<T> = T & { avatar?: AvatarProps };

export type MultiSelectListProps = {
  placeholder: string;
  options: WithAvatarProps<
    Pair & { disabled?: boolean | ((id: string) => boolean | undefined) }
  >[];
  disabledReason?: string;
  selectedIds?: string[];
  actions?: Action[];
  disableUncheck?: boolean;
  className?: string;
  multiple?: boolean;
  isLoading?: boolean;
  asPopover?: boolean;
  emptyContent?: React.ReactNode;
  actionsClassName?: string;
  showArrow?: boolean;
};

type Action = Omit<
  ButtonProps,
  "onClick" | "children" | "color" | "variant" | "disabled"
> & {
  /** Default "normal" */
  variant?: ButtonProps["variant"];
  label: string;
  icon?: string;
  color: ButtonColors;
  onClick: (selectedIds: Pair[]) => void;
  disabled?: (selectedIds: string[]) => boolean | boolean;
  closeOnClick?: boolean;
};

interface CheckablePair extends Pair {
  disabled?: boolean | ((id: string) => boolean | undefined);
  checked: boolean;
}

/**
 * Ref is passed down to the underlying Popover content when "asPopover" is used.
 */
export default React.forwardRef<HTMLDivElement, MultiSelectListProps>(
  function MultiSelectList(
    {
      placeholder,
      options,
      selectedIds,
      disabledReason,
      actions = [],
      disableUncheck = false,
      className,
      multiple = true,
      isLoading,
      asPopover,
      emptyContent,
      actionsClassName,
      showArrow = false,
    },
    ref
  ) {
    const headerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(!!asPopover && !!ref);
    const headerWidth = headerRef?.current?.offsetWidth || 0;
    const [selectionItems, setSelectionItems] = useState<
      WithAvatarProps<CheckablePair>[]
    >([]);
    useEffect(() => {
      //initialization or control from parent
      const initIds = selectedIds || [];
      const newSelectionItems = options.map((item) => {
        const included = initIds.includes(item.id);
        return {
          ...item,
          checked: included,
          disabled: item.disabled || (disableUncheck && included),
        };
      });
      setSelectionItems(newSelectionItems);
    }, [options, selectedIds, disableUncheck]);

    function toggleChecked(item: CheckablePair) {
      if (
        typeof item.disabled === "function"
          ? item.disabled(item.id)
          : item.disabled
      ) {
        return;
      }
      const newSelectionItems = selectionItems.map((selectionItem) => {
        if (item.id === selectionItem.id) {
          return {
            ...selectionItem,
            checked: !selectionItem.checked,
          };
        }
        return selectionItem;
      });
      setSelectionItems(newSelectionItems);
    }

    const ListWrapper = useMemo(
      () =>
        ({ children }: { children: React.ReactElement }) =>
          asPopover && headerRef.current ? (
            <Popover
              open={isOpen}
              onClose={() => setIsOpen(false)}
              anchorEl={headerRef?.current}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ width: headerWidth, overflow: "hidden" }} ref={ref}>
                {children}
              </div>
            </Popover>
          ) : (
            <>{children}</>
          ),
      [asPopover, headerWidth, ref, isOpen]
    );

    return (
      <div className={classes(styles.multiSelectList, className)}>
        <div>
          <div
            className={classes(
              styles.listHeader,
              !isOpen && styles.closedHeader
            )}
            ref={headerRef}
            onClick={() => setIsOpen((open) => !open)}
          >
            <span
              className={classes(
                styles.listHeaderText,
                style(typography.BODY2)
              )}
            >
              {placeholder}
            </span>
            {showArrow && (
              <SvgIcon
                className={styles.closeIcon}
                src={isOpen ? "/icons/arrow_up.svg" : "/icons/arrow_down.svg"}
              />
            )}
          </div>
          <ListWrapper>
            <>
              <List className={styles.list}>
                {isLoading && <Loading isWindowed={true} />}
                {!isLoading && !selectionItems.length && emptyContent}
                {selectionItems.map((item) => {
                  const disabled =
                    typeof item.disabled === "function"
                      ? item.disabled(item.id)
                      : item.disabled;

                  return (
                    <ListItem
                      key={item.id}
                      disabled={disabled}
                      classes={{ disabled: styles.disabledOption }}
                    >
                      <Checkbox
                        disabled={
                          disabled ||
                          (!multiple &&
                            !!selectionItems.find(
                              (otherItem) =>
                                otherItem.checked && item.id !== otherItem.id
                            ))
                        }
                        checked={item.checked}
                        onChange={() => toggleChecked(item)}
                      />
                      {item.avatar && (
                        <Avatar
                          {...item.avatar}
                          className={classes(
                            styles.avatar,
                            item.avatar.className
                          )}
                        />
                      )}
                      <ListItemText
                        classes={{ secondary: styles.disabledReason }}
                        primary={item.name}
                        secondary={disabled && disabledReason}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <Grid
                container
                spacing={2}
                className={classes(styles.actions, actionsClassName)}
              >
                {actions.map((action, index) => (
                  <Grid item key={index}>
                    {/* @ts-expect-error TS 5 upgrade todo */}
                    <Button
                      {...action}
                      variant={action?.variant ?? "normal"}
                      disabled={
                        typeof action.disabled === "function"
                          ? action.disabled(
                              (selectionItems || [])
                                .filter((item) => item.checked)
                                .map((item) => item.id)
                            )
                          : action.disabled
                      }
                      className={classes(action.className)}
                      onClick={() => {
                        action.onClick(
                          selectionItems.filter((item) => item.checked)
                        );
                        action.closeOnClick && setIsOpen(false);
                      }}
                    >
                      {action.icon && (
                        <SvgIcon
                          className={styles.closeIcon}
                          src="/icons/close_icon.svg"
                        />
                      )}
                      <span className={styles.buttonLabel}>{action.label}</span>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </>
          </ListWrapper>
        </div>
      </div>
    );
  }
);
