import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  sideMenuRoot: {
    height: "100%",
    backgroundColor: primaryColors.WHITE,
    flex: 1,
    borderRadius: "8px",
    padding: "8px",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "10px",
    marginTop: "16px",
  },
  titleIcon: {
    color: utilityColors.CYAN,
    width: "24px",
    height: "24px",
    border: `1px solid ${neutralColors.GRAY3}`,
    borderRadius: "6px",
    marginRight: "12px",
  },
  title: {
    ...typography.SUBTITLE1,
    color: primaryColors.PURPLE3,
    overflowWrap: "anywhere",
  },
  menu: {
    width: "100%",
    padding: "0",
  },
  menuItem: {
    listStyle: "none",
    width: "100%",
    padding: "16px",
    cursor: "pointer",
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.HOVER,
        $nest: {
          "&.locked": {
            backgroundColor: "unset",
          },
        },
      },
      "&.locked": {
        color: neutralColors.GRAY5,
      },
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  lockIcon: {
    width: "16px",
    height: "16px",
    color: neutralColors.GRAY5,
  },
  activeMenuItem: {
    backgroundColor: primaryColors.HOVER,
  },
  menuItemText: {
    ...typography.BODY1,
  },
  leftArrow: {
    height: "16px",
    // width: "16px",
    marginRight: "16px",
    marginLeft: "16px",
    // paddingLeft: "16px",
    // paddingRight: "16px",
  },
});
