import React, { useContext, useState } from "react";

import usePublicVisitor from "hooks/usePublicVisitor";

type PublicVisitorContextData = {
  // The data room being viewed
  viewedDataRoomId: string | null;
  // The company being viewed
  viewedCompanyId: string | null;
  setDataRoomId: React.Dispatch<string | null>;
  setCompanyId: React.Dispatch<string | null>;
  isLoading?: boolean;
};

export const PublicVisitorContext =
  React.createContext<PublicVisitorContextData>({
    viewedDataRoomId: null,
    viewedCompanyId: null,
    setDataRoomId: () => {},
    setCompanyId: () => {},
  });

export function usePublicVisitorContext() {
  return useContext(PublicVisitorContext);
}

export default function PublicVisitorProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [dataRoomId, setDataRoomId] = useState<string | null>(null);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const {
    dataRoomCompanyId: viewedDataRoomCompanyId,
    dataRoomId: viewedDataRoomId,
    isLoading,
  } = usePublicVisitor({ dataRoomId });

  return (
    <PublicVisitorContext.Provider
      value={{
        viewedDataRoomId,
        viewedCompanyId: viewedDataRoomCompanyId || companyId,
        setDataRoomId,
        setCompanyId,
        isLoading,
      }}
    >
      {children}
    </PublicVisitorContext.Provider>
  );
}
