import { createPersistedStore } from "./index";

export enum TipType {
  SupplierManagementDashboard = "SupplierManagementDashboard",
  SupplierManagementUpload = "SupplierManagementUpload",
  SupplierManagementView = "SupplierManagementView",

  ProcurementDashboard = "ProcurementDashboard",
  ProcurementCreateRFP = "ProcurementCreateRFP",
  ProcurementCreateRFI = "ProcurementCreateRFI",
  ProcurementCreateDDQ = "ProcurementCreateDDQ",
  ProcurementCreateQTP = "ProcurementCreateQTP",

  ProcurementDraftInfo = "ProcurementDraftInfo",
  ProcurementDraftAddModule = "ProcurementDraftAddModule",
  ProcurementDraftSendToReview = "ProcurementDraftSendToReview",

  ProcurementAddRecipients = "ProcurementAddRecipients",
  ProcurementSend = "ProcurementSend",

  ProcurementInvitationTable = "ProcurementInvitationTable",
  ProcurementResponseTable = "ProcurementResponseTable",

  ESGGetPassport = "ESGGetPassport",
  ESGEditSelfAssessment = "ESGEditSelfAssessment",

  AcceptRFPInvitation = "AcceptRFPInvitation",
  AcceptDDQInvitation = "AcceptDDQInvitation",
  AcceptRFIInvitation = "AcceptRFIInvitation",
  AcceptQTPInvitation = "AcceptQTPInvitation",

  ProcurementResponseReview = "ProcurementResponseReview",
  ProcurementResponseSubmit = "ProcurementResponseSubmit",

  SendRfpMessage = "SendRfpMessage",
  /** Shown for the supplier only after they receive a message on an answer */
  ReplyRfpMessages = "ReplyRfpMessages",
  PassportResponseSubmit = "PassportResponseSubmit",
}

interface TipStoreState {
  type: TipType | null;
  tipsSeen: TipType[];
}

const initialState: TipStoreState = {
  type: null,
  tipsSeen: [],
};

const TipStore = createPersistedStore(initialState, "TipStore");

export default TipStore;

export function setNextTip(type: TipType) {
  TipStore.set(
    (s) => ({
      type: type,
      tipsSeen: s.type
        ? [...s.tipsSeen.filter((t) => t !== s.type), s.type]
        : s.tipsSeen,
    }),
    "setNextTip"
  );
}

export function closeTip() {
  TipStore.set(
    (s) => ({
      type: null,
      tipsSeen: s.type
        ? [...s.tipsSeen.filter((t) => t !== s.type), s.type]
        : s.tipsSeen,
    }),
    "closeTip"
  );
}

// @ts-ignore
window.setNextTip = setNextTip;
