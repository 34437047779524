import React from "react";
import { useContext } from "react";

import {
  ExtendedWithLocalRepositoryData,
  ListItemType,
} from "components/AssetRepository/types";
import { ButtonProps } from "components/ui/Button/Button";
import EditableText from "components/ui/EditableText";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import { useListContext } from "contexts/List/ListProvider";
import ModalContext from "contexts/ModalContext";
import useCreateFolder from "hooks/queries/useCreateFolder";
import useRenameAsset from "hooks/queries/useRenameAsset";
import useRenameFolder from "hooks/queries/useRenameFolder";
import { ApiError } from "shared/client";

import { AssetRepositoryItem } from "./AssetRepositoryBody";

const conflictingFolderNameMessage = "Please give your folder a different name";
const conflictingAssetNameMessage = "Please give your file a different name";

const buttons: ButtonProps[] = [
  { variant: "normal", color: "orange", children: "Okay" },
];

export default function EditableAssetNameCell({
  row,
}: {
  row: AssetRepositoryItem;
}) {
  const { mutateAsync: createFolder } = useCreateFolder();
  const { mutateAsync: renameFolder } = useRenameFolder();
  const { mutateAsync: renameAsset } = useRenameAsset();
  const { openModal, closeModal } = useContext(ModalContext);
  const { setListData, listData } =
    useListContext<ExtendedWithLocalRepositoryData<AssetRepositoryItem>>();

  return (
    <EditableText
      text={row.name}
      isEditing={row.editingName}
      onSubmit={async (newName) => {
        if (newName === row.name) {
          setListData(
            listData.map((asset) =>
              asset.id === row.id ? { ...asset, editingName: false } : asset
            )
          );
          return;
        }

        if (row.listItemType === ListItemType.NewFolder) {
          try {
            await createFolder({ name: newName, localId: row.id });
          } catch (err) {
            if (err instanceof ApiError) {
              if (err.status === 409) {
                openModal(
                  <SimpleModalBody
                    title="Conflicting folder name"
                    children={conflictingFolderNameMessage}
                    onClose={closeModal}
                    buttons={buttons}
                  />
                );
              }
            }
          }
        }
        if (row.listItemType === ListItemType.Folder) {
          try {
            await renameFolder({ newName, folderId: row.id });
          } catch (err) {
            if (err instanceof ApiError) {
              if (err.status === 409) {
                openModal(
                  <SimpleModalBody
                    title="Conflicting folder name"
                    children={conflictingFolderNameMessage}
                    onClose={closeModal}
                    buttons={buttons}
                  />
                );
              }
            }
          }
        }
        if (row.listItemType === ListItemType.File) {
          try {
            await renameAsset({ newName, assetId: row.id });
          } catch (err) {
            if (err instanceof ApiError) {
              if (err.status === 409) {
                openModal(
                  <SimpleModalBody
                    title="Conflicting asset name"
                    children={conflictingAssetNameMessage}
                    onClose={closeModal}
                    buttons={buttons}
                  />
                );
              }
            }
          }
        }

        setListData(
          listData.map((asset) =>
            asset.id === row.id
              ? { ...asset, name: newName, editingName: false }
              : asset
          )
        );
      }}
    />
  );
}
