import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { forceTextOneLine } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  companyIcon: {
    width: "30px",
    marginRight: "5px",
  },
  invitationSender: {
    backgroundColor: primaryColors.WHITE,
    borderRadius: "8px",
    padding: "20px",
  },

  membersContainer: {
    padding: "20px",
    backgroundColor: primaryColors.WHITE,
    borderRadius: "8px",
    marginTop: "24px",
  },
  memberCardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: px(14),
    width: "100%",
  },
  recipients: {
    display: "flex",
    gap: "8px",
    margin: "16px 0px",
  },
});

export default styles;

export const groupMemberDataStyles = stylesheet({
  companyContainer: {
    display: "flex",
    alignItems: "center",
  },
  memberCompanyAvatarContainer: {
    width: px(50),
    height: px(50),
    display: "flex",
    borderRadius: px(5),
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: neutralColors.GRAY1,
  },
  memberCompanyAvatar: {
    margin: "auto",
    maxHeight: px(50),
    maxWidth: px(50),
  },
  memberCompanyName: { ...typography.SUBTITLE2, ...forceTextOneLine },
  inviteeInfoContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: px(20),
  },
  inviteeLocation: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
});

export const groupMemberBoxStyles = stylesheet({
  card: {
    backgroundColor: neutralColors.GRAY0,
    flex: "40%",
    maxWidth: "48%",
    height: px(85),
    display: "flex",
    flexDirection: "row",
    padding: `${px(21)} ${px(28)}`,
    border: "1px solid",
    borderRadius: px(8),
    borderColor: neutralColors.GRAY2,
  },
  kebabMenu: { marginLeft: "auto" },
  usersPreviewPaper: {
    width: "360px",
    background: neutralColors.GRAY0,
    borderRadius: "8px",
    borderColor: neutralColors.GRAY2,
  },
  usersPreviewContainer: {
    padding: "4px 24px",
    overflow: "auto",
    maxHeight: px(2 * 4 + 3 * 48 + 2 * 8),
  },
  userPreview: {
    marginBottom: "8px",
    $nest: {
      "&:last-child": {
        marginBottom: "0px",
      },
    },
  },
});

export const groupMemberRequestBoxStyles = stylesheet({
  container: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: neutralColors.GRAY0,
    border: "1px solid",
    borderColor: neutralColors.GRAY2,
    borderRadius: "8px",
    padding: "21px",
  },
  buttons: {
    fontSize: "13px",
  },
  declineButton: {
    marginRight: "16px",
  },
});
