import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import {
  AppColors,
  inputColors,
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";

import { heights } from "./shared/styles/height";

const theme = createMuiTheme({
  palette: {
    text: {
      primary: primaryColors.BLACK,
      //hotfixFix for #6637
      disabled: primaryColors.BLACK,
    },
    error: { main: primaryColors.ERROR },
    primary: {
      main: primaryColors.PURPLE3,
    },
    secondary: {
      main: primaryColors.PURPLE2,
    },
    background: {
      default: neutralColors.GRAY0,
      paper: neutralColors.GRAY1,
    },
  },
  overrides: {
    MuiTouchRipple: {
      ripple: {
        color: primaryColors.PURPLE1,
      },
    },
    MuiButton: {
      root: {
        height: heights.BUTTON_MEDIUM,
        fontSize: px(16),
        "&$disabled": {
          color: primaryColors.WHITE,
          backgroundColor: neutralColors.GRAY2,
        },
      },
      sizeSmall: { height: heights.BUTTON_SMALL, fontSize: px(14) },
      sizeLarge: { height: heights.BUTTON_HIGH, fontSize: px(18) },
      label: {
        color: "inherit",
      },
      // SVG Icons are used on buttons, their size is px based
      iconSizeSmall: {
        "& > *:first-child": {
          fontSize: "unset",
        },
      },
      iconSizeMedium: {
        "& > *:first-child": {
          fontSize: "unset",
        },
      },
      iconSizeLarge: {
        "& > *:first-child": {
          fontSize: "unset",
        },
      },
      contained: {
        "&$disabled": {
          color: primaryColors.WHITE,
          backgroundColor: neutralColors.GRAY2,
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: AppColors.Orange,
      },
    },
    MuiTab: {
      root: {
        ...typography.SUBTITLE2,
        color: neutralColors.GRAY3,
        "&$selected": {
          color: AppColors.Orange,
          borderBottomColor: AppColors.Orange,
        },
      },
      wrapper: {
        height: "100%",
        alignItems: "center",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: primaryColors.HOVER,
          borderRadius: "3px",
        },
        borderRadius: "3px",
        fontSize: "unset",
      },
      sizeSmall: {
        fontSize: "unset",
      },
    },
    MuiInputBase: {
      root: {
        borderColor: neutralColors.GRAY4,
        borderRadius: "4px",
      },
      input: {
        padding: "12px 16px",
      },
      inputMarginDense: {
        padding: "6px 16px",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "12px 16px",
        backgroundColor: primaryColors.WHITE,
        borderRadius: "4px",
        "&$disabled": {
          backgroundColor: neutralColors.GRAY2,
          color: neutralColors.GRAY4,
        },
      },
      inputMarginDense: {
        padding: "6px 16px",
      },
      notchedOutline: {
        borderColor: neutralColors.GRAY4,
      },
      root: {
        "&&&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: inputColors.ERROR,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: inputColors.FOCUSED,
          borderWidth: "1px",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: inputColors.HOVER,
        },
        "&&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: inputColors.DISABLED.BORDER,
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "unset",
        "&.Mui-focused, &:hover": {
          backgroundColor: primaryColors.HOVER,
        },
      },
      input: {
        padding: 0,
      },
    },
    MuiMenu: {
      paper: {
        backgroundColor: neutralColors.GRAY0,
        padding: 0,
        minWidth: px(150),
      },
      list: {
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        height: px(48),
        backgroundColor: neutralColors.GRAY0,
        "&:hover": {
          backgroundColor: primaryColors.HOVER,
        },
        "&$selected": {
          backgroundColor: primaryColors.HOVER,
        },
        "&$selected:hover": {
          backgroundColor: primaryColors.HOVER,
        },
      },
    },
    MuiCheckbox: { indeterminate: { color: AppColors.Purple } },
    MuiDivider: { root: { backgroundColor: neutralColors.GRAY3 } },
    MuiFormLabel: {
      asterisk: { color: utilityColors.RED },
      root: {
        "&.Mui-focused": {
          color: utilityColors.LILAC,
        },
        "&.Mui-disabled": {
          color: neutralColors.GRAY5,
        },
      },
    },
    MuiLinearProgress: {
      root: {
        marginTop: "auto",
        borderRadius: "4px",
      },
      colorPrimary: {
        backgroundColor: neutralColors.GRAY3,
      },
    },
  },
  typography: {
    body1: typography.BODY1,
    body2: typography.BODY2,
    caption: typography.CAPTION,
    h1: typography.HEADLINE1,
    h2: typography.HEADLINE2,
    h3: typography.HEADLINE3,
    h4: typography.HEADLINE4,
    h5: typography.HEADLINE5,
    h6: typography.HEADLINE6,
    subtitle1: typography.SUBTITLE1,
    subtitle2: typography.SUBTITLE2,
    fontFamily: [
      "Mulish",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(", "),
    button: {
      textTransform: "none",
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application
    },
  },
});

export default theme;
