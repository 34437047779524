import { FeatureFlagEnum } from "@pulsemarket/constants";
import SideMenu from "components/ui/SideMenu";
import useFeatureEnabled from "hooks/useFeatureEnabled";

const GroupSettingsSidebar = ({ isOwner }: { isOwner: boolean }) => {
  const { data: managingFilesEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.manageGroupFilesApproval,
  });

  const memberMenuItems = [
    {
      id: "members",
      text: "Members",
      historyPush: "members",
    },
  ];
  const ownerMenuItems = [
    {
      id: "descriptionn",
      text: "Description",
      historyPush: "description",
    },
    {
      id: "settings",
      text: "Settings",
      historyPush: "settings",
    },
    {
      id: "members",
      text: "Members",
      historyPush: "members",
    },
    {
      id: "posts",
      text: "Pending Posts",
      historyPush: "posts",
    },
    ...(managingFilesEnabled
      ? [
          {
            id: "files",
            text: "Pending Files",
            historyPush: "files",
          },
        ]
      : []),
    {
      id: "datarooms",
      text: "Advanced Settings",
      historyPush: "datarooms",
    },
  ];
  return (
    <SideMenu
      title="Group Settings"
      menuItems={isOwner ? ownerMenuItems : memberMenuItems}
    />
  );
};
export default GroupSettingsSidebar;
