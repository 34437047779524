import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { getDataRoom } from "shared/client";

export default function useDataRoom(dataRoomId?: string | null) {
  return useQuery(
    queryKeys.dataRoom(dataRoomId),
    () => getDataRoom(dataRoomId as string),
    { enabled: !!dataRoomId }
  );
}
