import React from "react";

import { neutralColors } from "@pulsemarket/constants";
import { textAlignCenterClass } from "shared/styles/classes";

type Props = {
  children: (React.ReactElement | false)[];
  step: number;
};

const FlowPopupContent = ({ children, step }: Props) => {
  const nonEmptyChildren = children.filter(Boolean);
  return (
    <div>
      {children[step]}
      {/* don't list steps if there's only one */}
      {nonEmptyChildren.length > 1 && (
        <div className={textAlignCenterClass}>
          <svg
            width={26 + (nonEmptyChildren.length - 1) * 50}
            height="26"
            viewBox={`0 0 ${26 + (nonEmptyChildren.length - 1) * 50} 26`}
            fill="none"
          >
            <path
              d={`M13 13 H${nonEmptyChildren.length * 50 - 26}`}
              stroke={neutralColors.GRAY2}
              stroke-width="3"
            />
            {nonEmptyChildren.map((c, i) => (
              <circle
                key={i}
                cx={13 + i * 50}
                cy="13"
                r={i === step ? 13 : 11}
                fill={i === step ? "#D5C2D1" : neutralColors.GRAY2}
              />
            ))}
          </svg>
        </div>
      )}
    </div>
  );
};

export default FlowPopupContent;
