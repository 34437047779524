import React, { useContext, useState } from "react";
import queryClient from "reactQuery/client";
import queryKeys from "reactQuery/queryKeys";

import useAssetItems from "hooks/queries/useAssetItems";
import useFolderIdParam from "hooks/useFolderIdParam";
import usePrevious from "hooks/usePrevious";
import { AssetSearchOptions, CompanyDetailsDto } from "shared/model";

export type AssetRepositoryFilterContextType = {
  searchOptions: AssetSearchOptions;
  setSearchOptions: React.Dispatch<React.SetStateAction<AssetSearchOptions>>;
};

export const AssetContext =
  React.createContext<AssetRepositoryFilterContextType>({
    searchOptions: {
      folderId: queryClient.getQueryData<CompanyDetailsDto>(
        queryKeys.ownCompany
      )?.assetRepositoryId,
      name: "",
      showHidden: false,
    },
    setSearchOptions: () => {},
  });

export function useAssetContext() {
  return useContext(AssetContext);
}

export default function AssetFilterProvider({
  children,
  companyId,
  folderId,
}: {
  children: React.ReactNode;
  companyId?: string;
  folderId?: string;
}) {
  const folderIdFromUrlParam = useFolderIdParam();
  const [searchOptions, setSearchOptions] = useState<AssetSearchOptions>({
    folderId: folderId || folderIdFromUrlParam,
    name: "",
    labelIds: undefined,
    showHidden: false,
  });

  const previousFolderId = usePrevious(searchOptions.folderId);

  useAssetItems(searchOptions, {
    onSuccess: (data) => {
      if (
        previousFolderId === searchOptions.folderId &&
        searchOptions.folderId
      ) {
        return;
      }
      // Upon navigating between folders when there are no matching items in the folder remove the label based filters
      if (searchOptions.labelIds?.length && !data.length) {
        setSearchOptions({ ...searchOptions, labelIds: undefined });
      }
    },
    companyId,
  });

  return (
    <AssetContext.Provider
      value={{
        searchOptions,
        setSearchOptions,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
}
