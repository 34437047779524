import React, { useCallback, useState } from "react";

import useUserData from "hooks/queries/useUserData";
import { PostWithReferencesDto } from "shared/model";

import EditablePostCard from "./EditablePostCard";
import NonEditablePostCard from "./NonEditablePostCard";
import { styles } from "./PostCard.styles";

type PostCardProps = {
  post?: PostWithReferencesDto;
  groupId: string;
  postsRequireApproval?: boolean;
  isGroupOwner?: boolean;
};

function PostCard({
  post,
  groupId,
  postsRequireApproval,
  isGroupOwner,
}: PostCardProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { data: user } = useUserData();

  const toggleEditingStatus = useCallback(
    function toggleIsEditing() {
      setIsEditing((isEditing) => !isEditing);
    },
    [setIsEditing]
  );

  const showPostSentToApproval = postsRequireApproval && !isGroupOwner;
  const isOwnPost = !!user?.id && user.id === post?.author.id;

  return (
    <div className={styles.card}>
      {post ? (
        isEditing || post.isDraft ? (
          <EditablePostCard
            post={post}
            groupId={groupId}
            onEditFinished={toggleEditingStatus}
            showPostSentToApproval={showPostSentToApproval}
          />
        ) : (
          <NonEditablePostCard
            groupId={groupId}
            post={post}
            onEdit={toggleEditingStatus}
            allowedInteractions={{
              approve: isGroupOwner && !post.isApproved,
              comment: post.isApproved,
              delete:
                (isGroupOwner || isOwnPost) &&
                (!postsRequireApproval ||
                  (postsRequireApproval && post.isApproved)),
              edit:
                (isGroupOwner || isOwnPost) &&
                (!postsRequireApproval ||
                  (postsRequireApproval && !post.isApproved) ||
                  isGroupOwner),
              like: post.isApproved,
            }}
            isGroupOwner={isGroupOwner}
          />
        )
      ) : (
        <EditablePostCard
          groupId={groupId}
          onEditFinished={toggleEditingStatus}
          showPostSentToApproval={showPostSentToApproval}
        />
      )}
    </div>
  );
}

export default PostCard;
