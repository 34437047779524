import { neutralColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

export default stylesheet({
  tabPanelZeroIndex: {
    backgroundColor: neutralColors.GRAY0,
    border: `1px solid ${neutralColors.GRAY3}`,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    borderTopRightRadius: "8px",
  },
  tabPanel: {
    backgroundColor: neutralColors.GRAY0,
    border: `1px solid ${neutralColors.GRAY3}`,
    borderRadius: "8px",
  },
});
