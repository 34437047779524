import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  multilineInput: {
    backgroundColor: primaryColors.WHITE,
  },
  maxCharSpan: {
    position: "absolute",
    right: "18px",
    bottom: "-30px",
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
});
