import React, { useContext } from "react";

import GetStartedBox from "components/GetStartedBox";
import CreateList from "components/vendorlists/CreateList";
import ModalContext from "contexts/ModalContext";

const getStartedVendorSearch = {
  title: "Create your List",
  subtitle: "Edit your Company info.",
  caption: `The data-driven meeting place of our platform.
  Create a buzz around your business and get
  shortlisted for all the right opportunities.`,
  image: { src: "/illustrations/3.0_buyer_seller_search_tool.png" },
  name: "Get started",
};

export default function VendorSearchFirstAccessBox() {
  const { openModal, closeModal } = useContext(ModalContext);
  return (
    <GetStartedBox
      {...getStartedVendorSearch}
      type="link"
      action={() => {
        openModal(<CreateList onSuccess={closeModal} onCancel={closeModal} />);
      }}
    />
  );
}
