import { neutralColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  link: {
    ...typography.BUTTON_MEDIUM,
  },
  disabled: {
    color: neutralColors.GRAY4,
    pointerEvents: "none",
  },
});

export default styles;
