import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  subtitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "baseline",
  },
  subtitle: {
    ...typography.SUBTITLE2,
    marginBottom: px(7),
    marginTop: px(0),
  },
  zonesLableContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: px(10),
  },
  caption: {
    ...typography.CAPTION,
    marginLeft: px(8),
    marginBottom: px(9),
    marginTop: px(0),
    color: neutralColors.GRAY5,
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: px(87),
  },
  firstPageHalfSide: {
    display: "flex",
    flexDirection: "column",
    flex: 5,
  },
  inputsRow: {
    // flex: 1,
  },
  imagesContainer: {
    position: "relative",
    height: "fit-content",
    marginBottom: px(50),
  },
  headerImage: {
    maxHeight: px(145),
    borderWidth: px(2),
    borderStyle: "solid",
    borderColor: primaryColors.WHITE,
    borderRadius: px(8),
  },
  avatarCentered: {
    position: "relative",
    zIndex: 2,
  },
  avatarBoxContainer: {
    marginTop: px(-83),
    zIndex: 2,
    textAlign: "center",
  },
  inputContainer: {
    display: "flex",
    marginBottom: px(12),
    marginLeft: px(12),
    flexDirection: "column",
  },
  inputLabel: {
    ...typography.SUBTITLE2,
    marginBottom: "7px",
    color: primaryColors.BLACK,
  },
  span: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
    marginLeft: "auto",
    marginRight: px(0),
  },
  nameLengthExceed: {
    ...typography.CAPTION,
    position: "absolute",
    right: "45px",
    top: "180px",
    color: utilityColors.RED,
  },
  descriptionLengthExceed: {
    ...typography.CAPTION,
    position: "absolute",
    right: "45px",
    top: "290px",
    color: utilityColors.RED,
  },
  firstPageBottomSide: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
  },
  zones: {
    width: "100%",
    height: "47px",
    borderRadius: "8px",
    background: primaryColors.WHITE,
    border: "1px solid #E9ECEF",
  },
});
