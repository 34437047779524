import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { extend, media, stylesheet } from "typestyle";

const detailFieldName = {
  ...typography.BODY1,
  color: neutralColors.GRAY5,
  marginRight: "8px",
};

export default stylesheet({
  companyDetailsContainer: {
    backgroundColor: primaryColors.WHITE,
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    padding: "25px",
  },
  companyDetailsContainerFullWidth: {
    backgroundColor: primaryColors.WHITE,
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    padding: "25px",
    gridColumn: "span 2",
  },
  detailContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "9px",
  },
  detail: {
    ...typography.BODY1,
  },
  detailField: {
    ...detailFieldName,
  },
  responsiveDetailField: {
    ...detailFieldName,
    ...extend(media({ maxWidth: 1240 }, { display: "none" })),
  },
  detailIcon: {
    color: neutralColors.GRAY5,
    width: "21px",
    height: "21px",
    marginRight: "13px",
  },
});
