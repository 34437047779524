import { RFPType } from "@pulsemarket/constants";
import { closeTip } from "store/TipStore";

import TipContent, { TipContentProps } from "./TipContent";

type DocumentInvitationTipContentProps = Pick<TipContentProps, "onAction"> & {
  rfpType: RFPType;
};

export default function DocumentInvitationTipContent({
  rfpType,
}: DocumentInvitationTipContentProps) {
  return (
    <TipContent
      imageSrc={`/illustrations/${rfpType.toLowerCase()}.png`}
      title="Accept this Response"
      text={`Exciting news! You've been selected to complete a ${rfpType}. Click here to accept. Best of luck.`}
      action="Ok, Thanks"
      onAction={() => closeTip()}
    />
  );
}
