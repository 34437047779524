import React, { useState } from "react";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import ConfirmationModalBody from "components/ConfirmationModalBody";
import { Loading } from "components/FullScreenLoading";
import EditableText from "components/ui/EditableText";
import { unclosableDialogProps } from "components/ui/Modal";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import WithKebabMenu from "components/ui/WithKebabMenu";
import { useVendorSearchContext } from "components/VendorSearch/VendorFilterProvider";
import { SUPPLIER_SEARCH_URL_PATH } from "components/VendorSearch/VendorSearch";
import ModalContext from "contexts/ModalContext";
import { cleanFiltersWithCompanyListId } from "hooks/queries/useCompanies";
import useDeleteCompanyList from "hooks/queries/useDeleteCompanyList";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useRenameCompanyList from "hooks/queries/useRenameCompanyList";
import { ApiError, getCompanies, getCompanyList } from "shared/client";
import { CompanyListDto } from "shared/model";
import { classes } from "typestyle";

import sidebarStyles from "../SidebarStyles";
import styles, { menuRightPosition } from "./CompanyListItemStyles";

type CompanyListItemProps = {
  list: CompanyListDto;
};

export default function CompanyListItem({ list }: CompanyListItemProps) {
  const history = useHistory();
  const location = useLocation();
  const isSupplierSearch = location.pathname === SUPPLIER_SEARCH_URL_PATH;

  const queryClient = useQueryClient();

  const [editingName, setEditingName] = useState(false);

  const { companyListId, filters, setFilters } = useVendorSearchContext();

  const { data: ownCompany } = useOwnCompany();

  const { mutateAsync: renameCompanyList, isLoading: isDeletionInProgress } =
    useRenameCompanyList();
  const { mutate: deleteCompanyList } = useDeleteCompanyList();

  const { openModal, closeModal } = useContext(ModalContext);

  return (
    <WithKebabMenu
      className={styles.container}
      actions={[
        {
          text: "Rename",
          onClick: () => {
            setEditingName(true);
          },
        },
        {
          text: "Delete",
          onClick: () => {
            openModal(
              <ConfirmationModalBody
                action={async () => {
                  deleteCompanyList(list.id);
                  history.push("/vendorsearch/companylist");
                }}
                title="Delete list"
                buttonText="Yes, delete it"
                onClose={closeModal}
              >
                {isDeletionInProgress ? (
                  <Loading />
                ) : (
                  <p>
                    Are you sure you want to delete this List? It cannot be
                    recovered later.
                  </p>
                )}
              </ConfirmationModalBody>,
              { props: unclosableDialogProps }
            );
          },
        },
      ]}
      position={{ right: menuRightPosition }}
    >
      <Link
        onMouseEnter={async () => {
          queryClient.prefetchQuery(
            queryKeys.companyList(list.id),
            () => getCompanyList(ownCompany!.id, list.id),
            { staleTime: 5000 }
          );
          const companySearchParams = cleanFiltersWithCompanyListId({
            filters,
            companyListId,
          });
          queryClient.prefetchQuery(
            queryKeys.companies(companySearchParams),
            () => getCompanies(companySearchParams),
            { staleTime: 5000 }
          );
        }}
        to={`/vendorsearch/companylist/${list.id}`}
        onClick={(e) => {
          const directClickTarget = e.target as HTMLElement;
          if (editingName && directClickTarget.tagName === "INPUT") {
            e.preventDefault();
            return;
          }

          setFilters({ ...filters, companyListId: list.id });
        }}
        onFocus={(e) => {
          const previouslyFocusedElement =
            e.relatedTarget as HTMLElement | null;

          if (
            previouslyFocusedElement &&
            previouslyFocusedElement.tagName === "INPUT"
          ) {
            // this input element is most likely the <EditableName /> component,
            // we return the focus to it.</span>
            // TODO check if this hack can be avoided
            previouslyFocusedElement.focus();
          }
        }}
      >
        <div
          className={classes(
            styles.companyList,
            !isSupplierSearch &&
              list.id === companyListId &&
              sidebarStyles.selectedMenuItem
          )}
        >
          <span className={styles.companyListName}>
            <EditableText
              isEditing={editingName}
              text={list.name}
              disableOnBlur
              required
              validityText="List name can not be empty"
              onSubmit={async (value) => {
                setEditingName(false);
                try {
                  await renameCompanyList({
                    listId: list.id,
                    newName: value,
                  });
                } catch (err) {
                  if (err instanceof ApiError) {
                    if (err.status === 409) {
                      openModal(
                        <SimpleModalBody
                          onClose={closeModal}
                          title="Name Already exists"
                          buttons={[
                            {
                              variant: "normal",
                              color: "gray",
                              children: "Cancel",
                              onClick: closeModal,
                            },
                            {
                              variant: "normal",
                              color: "orange",
                              children: "Ok",
                              onClick: closeModal,
                            },
                          ]}
                        >
                          The name
                          <span className={styles.warningCompanyName}>
                            &nbsp;{value}&nbsp;
                          </span>
                          is already taken. Please choose a different name.
                        </SimpleModalBody>
                      );
                    }
                  }
                }
              }}
            />
          </span>
        </div>
      </Link>
    </WithKebabMenu>
  );
}
