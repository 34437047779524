import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { FolderDto } from "@pulsemarket/api-client";
import { FolderAPIItem } from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import { getSideMenuFoldersFilters } from "components/AssetRepository/constants";
import { useAssetContext } from "contexts/AssetFilter/AssetFilterProvider";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useUserData from "hooks/queries/useUserData";
import { postFolder } from "shared/client";
import { FolderItemDto } from "shared/model";

const defaultFolderName = "New Folder";

type CreateFolderHookParams = {
  name: string;
  localId: string;
};

export default function useCreateFolder() {
  const client = useQueryClient();
  const { searchOptions } = useAssetContext();

  const { data: userData } = useUserData();
  const { data: ownCompany } = useOwnCompany();

  const ROOT_FOLDER_ID = ownCompany?.assetRepositoryId;
  const sideMenuFoldersFilters = getSideMenuFoldersFilters();

  return useMutation(
    ({ name }: CreateFolderHookParams) => {
      return postFolder(ownCompany!.id, searchOptions.folderId!, {
        companyId: ownCompany!.id,
        name: name || defaultFolderName,
        userId: userData!.id,
        parentFolderId: searchOptions.folderId!,
      });
    },
    {
      mutationKey: mutationKeys.createFolder,
      // When mutate is called:
      onMutate: async ({ name, localId }: CreateFolderHookParams) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );
        // Snapshot the previous values
        const previousSearchResult = client.getQueryData<FolderAPIItem[]>(
          queryKeys.assetRepositorySearch(searchOptions)
        );
        // Optimistically update to the new value
        const newFolder: FolderDto = {
          readOnly: false,
          id: localId,
          companyId: ownCompany!.id,
          userId: userData!.id,
          createdAt: new Date(),
          name: name || defaultFolderName,
          updatedAt: new Date(),
          parentFolderId: searchOptions.folderId!,
        };

        let previousRootDirectory: FolderItemDto[] | null = null;
        if (searchOptions.folderId === ROOT_FOLDER_ID) {
          await client.cancelQueries(
            queryKeys.assetRepositorySearch(sideMenuFoldersFilters)
          );

          await client.cancelQueries(queryKeys.allFolders);

          previousRootDirectory =
            client.getQueryData<FolderItemDto[]>(
              queryKeys.assetRepositorySearch(sideMenuFoldersFilters)
            ) || null;

          if (previousRootDirectory) {
            client.setQueryData<FolderItemDto[]>(
              queryKeys.assetRepositorySearch(sideMenuFoldersFilters),
              [...previousRootDirectory, newFolder]
            );
          }
        }

        return {
          previousSearchResult,
          previousRootDirectory,
        };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousSearchResult) {
          client.setQueryData<FolderAPIItem[]>(
            queryKeys.assetRepositorySearch(searchOptions),
            context.previousSearchResult
          );
        }

        if (
          searchOptions.folderId === ROOT_FOLDER_ID &&
          context?.previousRootDirectory
        ) {
          client.setQueryData<FolderItemDto[]>(
            queryKeys.assetRepositorySearch(sideMenuFoldersFilters),
            context.previousRootDirectory
          );
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );
        if (searchOptions.folderId === ROOT_FOLDER_ID) {
          client.invalidateQueries(
            queryKeys.assetRepositorySearch(sideMenuFoldersFilters)
          );
        }

        client.invalidateQueries(queryKeys.allFolders);
      },
    }
  );
}
