import { primaryColors } from "@pulsemarket/constants";
import { extend, media, style, stylesheet } from "typestyle";
//margin between the menu on the left and the content on the right. Set up by the design
const layoutMargin = 32;

export const sideMenuClass = (isOpen: boolean) =>
  style({
    $debugName: "sideMenuClass",
    position: "fixed",
    boxShadow: isOpen ? "5px 4px 20px rgba(0, 0, 0, 0.25)" : "none",
    zIndex: 3,
  });

const styles = stylesheet({
  layoutContent: {
    marginLeft: `calc(85px + ${layoutMargin}px)`,
    marginRight: `${layoutMargin}px`,
    display: "flex",
    flex: 1,
    flexFlow: "column wrap",
    maxWidth: `calc(100% - (${2 * layoutMargin}px + 85px))`,
    ...extend(
      media({ minWidth: 1920 }, { marginLeft: "304px", alignItems: "center" })
    ),
    minHeight: "100vh",
  },
  layoutContentFull: {
    ...extend(media({ minWidth: 1920 }, { marginLeft: "118px" })),
  },
  backgroundFilter: {
    position: "fixed",
    left: 0,
    top: 0,
    background: primaryColors.BLACK,
    opacity: "0.2",
    width: "100%",
    height: "100vh",
    zIndex: 2,
  },
  sideMenuOverlay: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  header: {
    width: "100%",
    height: "10vh",
  },
  page: {
    width: "100%",
    maxHeight: "88vh",
    flex: 1,
  },
  pageContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
});

export default styles;
