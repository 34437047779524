import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";

import { RFPType, ROUTES } from "@pulsemarket/constants";
import { FeatureFlagEnum } from "@pulsemarket/constants";
import { neutralColors, primaryColors } from "@pulsemarket/constants";
import AvatarMenu from "components/AvatarMenu";
import { Loading } from "components/FullScreenLoading";
import { NotificationBell } from "components/Notifications";
import { TabTitle } from "components/PulsePage/PulsePage";
import Breadcrumb from "components/ui/Breadcrumb";
import Button from "components/ui/Button";
import { useGetAuthToken } from "hooks/queries/useAuth";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useSwitchCompany from "hooks/queries/useSwitchCompany";
import { useUserCompanies } from "hooks/queries/useUserCompanies";
import useUserData from "hooks/queries/useUserData";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import usePassportFeatureEnabled from "hooks/usePassportFeatureEnabled";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";
import { StandardRFPType } from "shared/model";
import { colorClass, marginRightClass } from "shared/styles/classes";
import { pointerCursor } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { askForAuthenticationPreservingRoute } from "shared/utils/authentication";
import { classes, style } from "typestyle";

import styles from "./HeaderStyles";

interface HeaderProps {
  className?: string;
}

const procurementConsolePaths = [
  ROUTES.RFP_MANAGEMENT_DDQ,
  ROUTES.RFP_MANAGEMENT_RFI,
  ROUTES.RFP_MANAGEMENT_QTP,
  ROUTES.RFP_MANAGEMENT_RFP,
  ROUTES.RFP_MANAGEMENT_DDQ_RESPONSES,
  ROUTES.RFP_MANAGEMENT_RFI_RESPONSES,
  ROUTES.RFP_MANAGEMENT_QTP_RESPONSES,
  ROUTES.RFP_MANAGEMENT_RFP_RESPONSES,
  ROUTES.RFP_MANAGEMENT_FOR_BUYERS,
  ROUTES.RFP_MANAGEMENT_FOR_SUPPLIERS,
];

function Header(props: HeaderProps) {
  const [isSwitchingAccount, setIsSwitchingAccount] = useState(false);
  const { data: userData, isLoading: userLoading } = useUserData();
  const {
    data: ownCompany,
    isLoading: companyLoading,
    refetch: refetchOwnCompany,
  } = useOwnCompany();

  const switchCompany = useSwitchCompany();
  useEffect(() => {
    if (
      userData?.companyId !== ownCompany?.id ||
      localStorage.getItem(ACTING_COMPANY_ID) !== ownCompany?.id
    ) {
      refetchOwnCompany();
    }
  }, [ownCompany, refetchOwnCompany, userData]);

  const { data: companies, isLoading: companiesLoading } = useUserCompanies();

  const { data: tokenData } = useGetAuthToken();
  const actingCompanyId = localStorage.getItem(ACTING_COMPANY_ID);
  const { data: notificationsEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.notificationsEnabled,
  });

  const { data: csrdEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.csrd,
  });

  const passportEnabled = usePassportFeatureEnabled();

  const history = useHistory();

  if (userLoading || companyLoading || companiesLoading) {
    return <div className={classes(styles.header, props.className)} />;
  }

  if (isSwitchingAccount) {
    return (
      <div className={classes(styles.header, props.className)}>
        <Loading message="Switching company..." />
      </div>
    );
  }

  return (
    <div className={classes(styles.header, props.className)}>
      <div className={styles.topMenuLeftContainer}>
        <Route path="/" exact>
          <div className={styles.welcomeContainer}>
            <img src="/icons/dashboard_icon.svg" alt="profile" />
            {userData?.firstName && (
              <div className={styles.topMenuWelcome}>
                Hi <span>{userData.firstName} </span>, Welcome to Pulse Market
              </div>
            )}
          </div>
        </Route>
        <Route path="/company/:id/supplier-search" exact={true}>
          <div className={styles.breadCrumbContainer}>
            <h1
              onClick={() => {
                history.push(ROUTES.MY_SUPPLIERS);
              }}
              className={classes(
                style({
                  ...typography.SUBTITLE1,
                  color: neutralColors.GRAY5,
                  ...pointerCursor,
                }),
                styles.breadCrumbContainer
              )}
            >
              <img
                src="/icons/back_icon_gray5.svg"
                alt="back-to-supplier-list"
                className={classes(marginRightClass("16px"))}
              />
              Supplier Profiling
            </h1>

            <h1
              className={classes(
                style({ ...typography.SUBTITLE1 }),
                colorClass(primaryColors.BLACK),
                styles.breadCrumbContainer
              )}
            >
              <img
                src="/icons/arrow_right.svg"
                alt="profile"
                className={styles.breadCrumbArrow}
              />
              Supplier Search
            </h1>
          </div>
        </Route>
        <Route path={procurementConsolePaths} exact>
          <div className={styles.topMenuLeftContainer}>Value Chain</div>
        </Route>

        <Route path={ROUTES.MY_SUPPLIERS}>
          <div className={styles.topMenuLeftContainer}>Supplier Profiling</div>
        </Route>

        <Route path="/groups/:id" exact>
          <Breadcrumb
            path={{
              pathname: `/company/${actingCompanyId}`,
              state: { tabTitle: TabTitle.Groups },
            }}
            data={[
              {
                id: "",
                name: "My Groups",
                onClick: () =>
                  history.push(`/company/${actingCompanyId}/`, {
                    tabTitle: TabTitle.Groups,
                  }),
              },
              // TODO: get group id from url and set group name in breadcrumb
              // {
              //   id: group.id,
              //   name: group.name,
              // },
            ]}
            icon={{
              color: primaryColors.PURPLE2,
              src: "/icons/dashboard_icon.svg",
              linkTo: "/",
            }}
          />
        </Route>

        <Route path={`/esrsresponses/:id/:id`}>
          <h1
            onClick={() => {
              history.push(ROUTES.CSRD_QUESTIONNAIRES);
            }}
            className={classes(
              style({ ...typography.HEADLINE6, ...pointerCursor }),
              colorClass(primaryColors.BLACK)
            )}
          >
            <img
              src="/icons/back_icon.svg"
              alt="profile"
              className={marginRightClass("16px")}
            />
            CSRD
          </h1>
        </Route>

        {Object.values(StandardRFPType).map((t) => {
          const type = t.toLowerCase();
          return (
            <React.Fragment key={t}>
              <Route path={`/${type}/:id`}>
                <h1
                  onClick={() => {
                    history.push(`/${type}`);
                  }}
                  className={classes(
                    style({ ...typography.HEADLINE6, ...pointerCursor }),
                    colorClass(primaryColors.BLACK)
                  )}
                >
                  <img
                    src="/icons/back_icon.svg"
                    alt={type}
                    className={marginRightClass("16px")}
                  />
                  Value Chain
                </h1>
              </Route>

              <Route path={`/${type}responses/:id/:id`}>
                <h1
                  onClick={() => {
                    history.push(`/${type}responses`);
                  }}
                  className={classes(
                    style({ ...typography.HEADLINE6, ...pointerCursor }),
                    colorClass(primaryColors.BLACK)
                  )}
                >
                  <img
                    src="/icons/back_icon.svg"
                    alt="profile"
                    className={marginRightClass("16px")}
                  />
                  Value Chain
                </h1>
              </Route>
            </React.Fragment>
          );
        })}

        {/* ====== Passports ====== */}
        <Route path={`/${RFPType.Passport}/:id`}>
          <h1
            onClick={() => {
              history.push(`/${RFPType.Passport}`);
            }}
            className={classes(
              style({ ...typography.HEADLINE6, ...pointerCursor }),
              colorClass(primaryColors.BLACK)
            )}
          >
            <img
              src="/icons/back_icon.svg"
              alt={RFPType.Passport}
              className={marginRightClass("16px")}
            />
            Passport
          </h1>
        </Route>

        <Route path={`/${RFPType.Passport}responses/:id/:id`}>
          <h1
            onClick={() => {
              history.push(ROUTES.MY_ESG_PASSPORT);
            }}
            className={classes(
              style({ ...typography.HEADLINE6, ...pointerCursor }),
              colorClass(primaryColors.BLACK)
            )}
          >
            <img
              src="/icons/back_icon.svg"
              alt="profile"
              className={marginRightClass("16px")}
            />
            My ESG
          </h1>
        </Route>

        {passportEnabled && (
          <Route path={ROUTES.PASSPORT_MANAGEMENT}>
            <div
              className={classes(
                style({ ...typography.SUBTITLE2 }),
                colorClass(neutralColors.GRAY5)
              )}
            >
              Pulse Market Passport
            </div>
          </Route>
        )}

        {csrdEnabled && (
          <Route path={ROUTES.CSRD}>
            <Breadcrumb
              path={{
                pathname: ROUTES.CSRD,
              }}
              data={[
                {
                  id: "",
                  name: "CSRD",
                  onClick: () => history.push(ROUTES.CSRD),
                },
                // TODO: get group id from url and set group name in breadcrumb
                // {
                //   id: group.id,
                //   name: group.name,
                // },
              ]}
              icon={{
                color: primaryColors.PURPLE2,
                src: "/icons/dashboard_icon.svg",
                linkTo: "/",
              }}
            />
          </Route>
        )}

        {notificationsEnabled && (
          <Route path="/notifications" exact>
            <img src="/icons/dashboard_icon.svg" alt="profile" />
            <div className={styles.topMenuWelcome}>Notifications</div>
          </Route>
        )}
      </div>
      <div className={styles.topMenuAccountDetailsClass}>
        {!!tokenData?.userId ? (
          <>
            {notificationsEnabled && (
              <>
                <NotificationBell />

                <div className={styles.divider} />
              </>
            )}

            <div className={styles.profileTextContainer}>
              <p
                className={classes(style(typography.BODY2), styles.profileText)}
              >
                {userData?.firstName} {userData?.lastName}
              </p>
              <p
                className={classes(style(typography.BODY2), styles.profileText)}
              >
                {ownCompany?.name}
              </p>
            </div>

            <AvatarMenu
              profileImageUrl={userData?.profileImage}
              companies={companies?.filter(
                (company) =>
                  company.id !== localStorage.getItem(ACTING_COMPANY_ID)
              )}
              onSwitchCompany={async (id) => {
                setIsSwitchingAccount(true);
                await switchCompany({ companyId: id });

                //Redirect to Dashboard
                history.push("/");

                setIsSwitchingAccount(false);
              }}
            />
          </>
        ) : (
          <div>
            <Button
              variant="outlined"
              className={marginRightClass("14px")}
              onClick={askForAuthenticationPreservingRoute}
            >
              Login
            </Button>
            <Button
              variant="normal"
              onClick={askForAuthenticationPreservingRoute}
            >
              Sign up
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
