import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { getReactionDetailsForPost } from "shared/client";

export function usePostReactionDetails({
  postId,
  enabled,
}: {
  postId?: string;
  enabled?: boolean;
}) {
  return useQuery(
    queryKeys.postReactions(postId),
    () => getReactionDetailsForPost(postId!),
    { enabled: typeof enabled === "undefined" ? !!postId : enabled && !!postId }
  );
}
