import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@pulsemarket/constants";
import Button from "components/ui/Button";
import Input from "components/ui/Input";
import PasswordInput from "components/ui/PasswordInput";
import { useFormik } from "formik";
import { useLogIn } from "hooks/queries/useAuth";
import { ApiError } from "shared/client";
import { emailValidatorFn } from "shared/validators";

import { styles } from "../AuthFormLayout/AuthForm.styles";

export default function LoginForm({
  onSubmit,
}: {
  onSubmit: (email: string) => void;
}) {
  const history = useHistory();

  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const { mutateAsync: login } = useLogIn();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      if (values.email && !emailValidatorFn(values.email)) {
        return {
          email: "Invalid email address",
        };
      }
    },
    onSubmit: async (values) => {
      try {
        await login({ email: values.email, password: values.password });
        history.push("/");
      } catch (err) {
        if (err instanceof ApiError && err.status === 500) {
          onSubmit(values.email);
        }
        setInvalidCredentials(true);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      onChange={() => setInvalidCredentials(false)}
    >
      <div className={styles.root}>
        <h2 className={styles.title}>Log in to your account</h2>
        <p className={styles.subtitle}>
          Welcome to Pulse Market.
          <br />
          Please join us using your email and password.
        </p>
        <Input
          name="email"
          className={styles.input}
          required
          label="Email"
          placeholder="Email address"
          autoComplete="username"
          value={formik.values.email}
          onChange={formik.handleChange("email")}
          error={invalidCredentials}
        />
        <PasswordInput
          name="password"
          className={styles.input}
          required
          autoComplete="current-password"
          value={formik.values.password}
          onChange={formik.handleChange("password")}
          error={invalidCredentials}
          helperText={invalidCredentials && "Invalid credentials"}
        />

        <Button
          variant="text"
          color="orange"
          className={styles.secondaryButton}
          onClick={() => {
            history.push(ROUTES.FORGOT_PASSWORD);
          }}
        >
          Forgot your password?
        </Button>

        <Button
          type="submit"
          variant="normal"
          color="orange"
          disabled={!formik.isValid}
          fullWidth
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Log in
        </Button>

        <div className={styles.helperTextContainer}>
          <p className={styles.helperText}>
            New to Pulse Market?{" "}
            <Button
              type="button"
              variant="text"
              color="orange"
              onClick={() => {
                history.push(ROUTES.REGISTER);
              }}
            >
              Create an account
            </Button>
          </p>
        </div>
      </div>
    </form>
  );
}
