import React from "react";

import { classes } from "typestyle";

import styles from "./GroupPageCard.styles";

type GroupPageCardProps = {
  title?: string;
  classes?: {
    children?: string;
    title?: string;
  };
  className?: string;
  headerButtons?: React.ReactNode[];
  children?: React.ReactNode;
  fullWidthContent?: boolean;
};

export default function GroupPageCard({
  title,
  headerButtons,
  className,
  children,
  fullWidthContent = false,
  classes: componentClasses,
}: GroupPageCardProps) {
  return (
    <div className={classes(styles.card, className)}>
      {(title || headerButtons) && (
        <div className={classes(styles.titleRow, componentClasses?.title)}>
          {title && <div className={styles.title}>{title}</div>}
          {headerButtons &&
            headerButtons.map((button, i) => {
              return <React.Fragment key={i}>{button}</React.Fragment>;
            })}
        </div>
      )}
      {children && (
        <div
          className={classes(
            styles.content,
            fullWidthContent && styles.fullWidth,
            componentClasses?.children
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
}
