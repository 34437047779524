import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { deleteGroup } from "shared/client";

import useOwnCompany from "./useOwnCompany";

type DeleteGroupHookParams = {
  onSuccess?: () => void;
};

type DeleteGroupInput = string; // id of the group

export default function useDeleteGroup(options?: DeleteGroupHookParams) {
  const { data: ownCompany } = useOwnCompany();
  const client = useQueryClient();

  return useMutation((id: DeleteGroupInput) => deleteGroup(id), {
    mutationKey: mutationKeys.deleteGroup,
    onSuccess: options?.onSuccess,
    onSettled: () => {
      client.invalidateQueries(queryKeys.ownGroups(ownCompany?.id || ""));
    },
  });
}
