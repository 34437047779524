import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { createGroup } from "shared/client";
import { NewGroup } from "shared/model";

export default function useCreateGroup() {
  const client = useQueryClient();
  const { data: ownCompany } = useOwnCompany();

  return useMutation(
    (newGroup: NewGroup) => {
      return createGroup(newGroup);
    },
    {
      mutationKey: mutationKeys.createGroup,
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.ownGroups(ownCompany?.id || ""));
      },
    }
  );
}
