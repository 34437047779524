import { FeatureFlagEnum } from "@pulsemarket/constants";
import { usePassportToRfpId } from "hooks/queries/passportHooks";
import useFeatureEnabled from "hooks/useFeatureEnabled";

export default function usePassportFeatureEnabled() {
  const { data: passportEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.passport,
  });
  const { data: passportRfpId } = usePassportToRfpId();

  return !!passportEnabled && !!passportRfpId;
}
