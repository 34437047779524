import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    borderRadius: "4px",
    border: `1px solid ${utilityColors.ORANGE}`,
    backgroundColor: primaryColors.WHITE,
    ...typography.CAPTION,
    color: primaryColors.BLACK,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  part: { height: "100%", paddingLeft: "8px", paddingRight: "8px" },
  titleContainer: {
    height: "calc(100% + 16px)",
  },
  titleIconContainer: {
    height: "100%",
  },
  middleContainer: {
    height: "calc(100% + 24px)",
  },
  selectContainer: {
    $nest: {
      "& > .MuiGrid-item": {
        paddingTop: "6px",
        paddingBottom: "6px",
      },
    },
  },
  divider: {
    height: "20px",
    backgroundColor: neutralColors.GRAY3,
  },
  rightContainer: {
    height: "calc(100% + 16px)",
  },
  filter: {
    width: "100%",
  },
});

export default styles;
