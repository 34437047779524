import React, { useContext, useState } from "react";

import {
  documentTypeNames,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import Button from "components/ui/Button";
import ModalActions from "components/ui/Modal/Actions/ModalActions";
import ModalHeader from "components/ui/Modal/Header/ModalHeader";
import { RadioLabelProps } from "components/ui/RadioButton/RadioLabel";
import RadioButtonGroup from "components/ui/RadioGroup/PulseRadioGroup";
import SvgIcon from "components/ui/SvgIcon/SvgIcon";
import ModalContext from "contexts/ModalContext/ModalContext";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";
import { StandardRFPType } from "shared/model";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  modalHeader: {
    //Align first item to middle
    $nest: {
      "& .MuiGrid-item:first-child": {
        margin: "0 auto",
      },
    },
  },
  text: {
    margin: "unset",
    ...typography.BODY2,
  },
  orangeText: {
    color: utilityColors.ORANGE,
  },
  listHeader: {
    ...typography.SUBTITLE1,
    marginTop: "24px",
    marginBottom: "24px",
  },
  pulseIcon: {
    margin: "0 auto",
    color: primaryColors.PURPLE2,
    width: "104px",
  },
  modalActions: {
    marginTop: "32px",
  },
});

export enum MultiCompanyInvitationType {
  RFX = "RFX",
  ESG = "ESG",
  Group = "Group",
}

type MultiCompanyiInvitationModal = {
  inviterCompanyName: string;
  companies: { id: string; name: string; avatar?: string }[];
  type: MultiCompanyInvitationType;
  rfxType?: StandardRFPType;
  groupName?: string;
  onConfirm: (companyId: string) => void;
};

export default function MultiCompanyInvitationSelectorModal({
  inviterCompanyName,
  type,
  companies,
  rfxType,
  groupName,
  onConfirm,
}: MultiCompanyiInvitationModal) {
  const { closeModal } = useContext(ModalContext);
  const activeCompanyId = localStorage.getItem(ACTING_COMPANY_ID);
  const choices: RadioLabelProps[] = companies.map((company) => {
    return {
      id: company.id,
      title:
        company.id === activeCompanyId
          ? `${company.name} (active)`
          : company.name,
    };
  });

  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);

  return (
    <div>
      <ModalHeader hideCloseButton className={styles.modalHeader}>
        <SvgIcon className={styles.pulseIcon} src="/logo/pulse_logo_dark.svg" />
      </ModalHeader>

      <h2 className={styles.text}>
        <TitleContent
          type={type}
          inviterCompanyName={inviterCompanyName}
          rfxType={rfxType}
          groupName={groupName}
        />
      </h2>

      <div className={styles.listHeader}>
        Choose an account to accept this invite:
      </div>
      <RadioButtonGroup
        choices={choices}
        value={selectedChoice}
        onChange={(_e, choice) => {
          setSelectedChoice(choice);
        }}
      />

      <ModalActions
        className={styles.modalActions}
        actions={[
          <Button
            disabled={!selectedChoice}
            color="orange"
            variant="normal"
            size="small"
            key="confirm"
            onClick={() => {
              onConfirm(selectedChoice!);
              closeModal();
            }}
          >
            Confirm
          </Button>,
        ]}
      />
    </div>
  );
}

function TitleContent({
  inviterCompanyName,
  type,
  rfxType,
  groupName,
}: {
  inviterCompanyName: string;
  type: MultiCompanyInvitationType;
  rfxType?: StandardRFPType;
  groupName?: string;
}) {
  if (type === MultiCompanyInvitationType.RFX && !!rfxType) {
    return (
      <>
        You received a new
        <span className={styles.orangeText}>
          {" "}
          {documentTypeNames[rfxType].fullName} - {rfxType}{" "}
        </span>
        from
        <span className={styles.orangeText}> {inviterCompanyName} </span>
      </>
    );
  } else if (type === MultiCompanyInvitationType.Group && groupName) {
    return (
      <>
        <span className={styles.orangeText}> {inviterCompanyName} </span>
        has invited you to join the
        <span className={styles.orangeText}> {groupName} </span>
        on Pulse Market
      </>
    );
  } else if (type === MultiCompanyInvitationType.ESG) {
    return (
      <>
        <span className={styles.orangeText}> {inviterCompanyName} </span>
        has invited you to complete an Environmental, Social, and Governance
        (ESG) Passport.
      </>
    );
  } else {
    return null;
  }
}
