import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { clickable } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  title: {
    ...typography.TITLE1,
    marginBottom: "24px",
  },
  subtitle: {
    ...typography.SUBTITLE2,
    borderBottomColor: neutralColors.GRAY3,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    paddingBottom: "15px",
    marginBottom: "22px",
  },
  settingsContainer: {
    width: "100%",
    backgroundColor: neutralColors.GRAY0,
    padding: "19px 21px",
    borderColor: neutralColors.GRAY0,
    borderStyle: "solid",
    borderRadius: "8px",
    borderWidth: "1px",
    display: "flex",
    flexDirection: "column",
  },
  multiSelectListHeader: {
    marginBottom: "11px",
  },
  dataroomGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "24px",
  },
  dataroomCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px",
    backgroundColor: primaryColors.WHITE,
    borderRadius: "8px",
    justifyContent: "space-between",
    borderColor: neutralColors.GRAY2,
    borderStyle: "solid",
    borderWidth: "1px",
    height: "74px",
    width: "100%",
  },
  iconContainer: {
    background: neutralColors.GRAY1,
    borderRadius: "6px",
    borderColor: neutralColors.GRAY3,
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dataroomAvatar: {
    height: "22px",
    color: utilityColors.CYAN,
  },
  dataroomName: {
    ...typography.SUBTITLE2,
    marginRight: "auto",
    marginLeft: "13px",
  },
  link: {
    ...clickable,
    // underline on hover
    $nest: {
      "&:hover": {
        textDecoration: "underline",
        color: primaryColors.PURPLE2_HOVER,
      },
    },
  },
});

export default styles;
