export default async function transformFileToBase64(
  file: File
): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string); // it is readed as DataURL
    reader.onerror = (error) => reject(error);
  });
}
