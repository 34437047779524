import React from "react";

import SvgIcon from "components/ui/SvgIcon";
import { clickable } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { classes, style } from "typestyle";

import styles from "./ModalHeaderStyles";

interface ModalHeaderProps {
  title?: string;
  onClose?: () => void;
}

/**
 * @deprecated use the ModalHeader component that uses MUI instead.
 */
export default function ModalHeader({
  title,
  onClose = () => {},
}: ModalHeaderProps) {
  return (
    <div className={styles.ModalHeader}>
      <div className={styles.title}>
        {title && <span className={style(typography.HEADLINE6)}>{title}</span>}
      </div>

      <div
        className={classes(styles.closeButtonContainer, style(clickable))}
        onClick={onClose}
      >
        <SvgIcon src="/icons/close_icon.svg" className={styles.closeButton} />
      </div>
    </div>
  );
}
