import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { postCompanyList } from "shared/client";
import { CompanyDetailsDto } from "shared/model";
import generateAlmostUniqueId from "shared/utils/generateAlmostUniqueId";

import useOwnCompany from "./useOwnCompany";

type CreateCompanyHookParams = {
  onSuccess?: () => void;
};

export default function useCreateCompanyList(
  options?: CreateCompanyHookParams
) {
  const client = useQueryClient();
  const { data: ownCompany } = useOwnCompany();

  return useMutation((name: string) => postCompanyList(ownCompany!.id, name), {
    mutationKey: mutationKeys.createCompanyList,
    // When mutate is called:
    onMutate: async (newListName: string) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await client.cancelQueries(queryKeys.ownCompany);

      // Snapshot the previous value
      const previousOrganization = client.getQueryData<CompanyDetailsDto>(
        queryKeys.ownCompany
      );

      // Optimistically update to the new value
      if (previousOrganization) {
        client.setQueryData<CompanyDetailsDto>(queryKeys.ownCompany, {
          ...previousOrganization,
          companyLists: [
            ...previousOrganization.companyLists,
            {
              id: generateAlmostUniqueId(),
              name: newListName,
              companyIds: [],
            },
          ],
        });
      }

      return { previousOrganization };
    },
    onSuccess: options?.onSuccess,
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      if (context?.previousOrganization) {
        client.setQueryData<CompanyDetailsDto>(
          queryKeys.ownCompany,
          context.previousOrganization
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      client.invalidateQueries(queryKeys.ownCompany);
    },
  });
}
