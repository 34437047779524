import React, { useState } from "react";

import LoginForm from "components/auth/LoginForm/LoginForm";
import ResetPasswordSentForm from "components/auth/ResetPasswordSentForm/ResetPasswordSentForm";
import AuthenticationPageLayout from "pages/AuthenticationPageLayout";

export default function LoginPage() {
  const [loginEmail, setLoginEmail] = useState<string | null>(null);
  return (
    <AuthenticationPageLayout
      imageSrc={
        loginEmail
          ? "/illustrations/forgot_password.svg"
          : "/illustrations/login_page_people.svg"
      }
    >
      {loginEmail ? (
        <ResetPasswordSentForm email={loginEmail} isMigratedUser />
      ) : (
        <LoginForm
          onSubmit={(email) => {
            setLoginEmail(email);
          }}
        />
      )}
    </AuthenticationPageLayout>
  );
}
