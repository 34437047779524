import React from "react";
import { Router } from "react-router-dom";

import { ClickToComponent } from "click-to-react-component";
import history from "customHistory";
import { App } from "layout/App";
import { Providers } from "layout/Providers";

type Props = {};

const Root = (props: Props) => {
  return (
    <React.StrictMode>
      <ClickToComponent editor={process.env.REACT_APP_CODE_APP} />
      <Router history={history}>
        <Providers>
          <App />
        </Providers>
      </Router>
    </React.StrictMode>
  );
};

export default Root;
