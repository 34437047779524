import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    background: primaryColors.WHITE,
    border: `1px solid ${neutralColors.GRAY2}`,
    borderRadius: "8px",
    paddingTop: "21px",
    paddingBottom: "21px",
    paddingLeft: "64px",
    paddingRight: "36px",
    position: "relative",
  },
  checkboxPosition: {
    top: 0,
    bottom: 0,
    marginTop: "auto",
    marginBottom: "auto",
    left: "12px",
    height: "26px",
  },
});

export default styles;
