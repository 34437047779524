import { QuestionType } from "../constants/question";
import { Direction } from "../types";
import { AssetDto, MappedInsertedAsset } from "./asset.dto";
import { ChoiceDto } from "./choice.dto";

export type QuestionDto = {
  id: string;
  companyId: string;
  userId: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  isVerified?: boolean;
  order: number;
  weight: number;
  questionnaireId: string;
  type: QuestionType;
  options: Object;
  isMandatory: boolean;
  isKillerQuestion: boolean;
  isIfQuestion: boolean;
  isAttachmentAllowed: boolean;
  isAttachmentMandatory: boolean;
  isDateAllowed: boolean;
  isDateMandatory: boolean;
  attachments?: AssetDto[];
  insertedMedia?: MappedInsertedAsset[];
  isExpirationMandatory: boolean;
  isAutoScored?: boolean;
  autoScoringDirection?: Direction;
  choices: ChoiceDto[];
  nextQuestionId: string | null;
  note?: string;
  matrixId?: string;
};

export type QuestionImportExportDto = {
  content: string;
  type: QuestionType;
  weight?: number;
  isMandatory?: boolean;
  isAttachmentAllowed?: boolean;
  isAttachmentMandatory?: boolean;
  isDateAllowed?: boolean;
  isDateMandatory?: boolean;
  isExpirationMandatory?: boolean;
  isKillerQuestion?: boolean;
  choices?: string; // JSON stringified array of ChoiceDto
  note?: string;
};

export enum QuestionImportExportColumnName {
  content = "Question",
  type = "Type",
  weight = "Weight",
  isMandatory = "Mandatory",
  isAttachmentAllowed = "Attachment Allowed",
  isAttachmentMandatory = "Attachment Mandatory",
  isDateAllowed = "Date Allowed",
  isDateMandatory = "Date Mandatory",
  isExpirationMandatory = "Expiration Mandatory",
  isKillerQuestion = "Killer Question",
  choices = "Choices",
  note = "Comment",
}
