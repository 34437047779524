import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { primaryColors } from "@pulsemarket/constants";
import PassportForm from "components/Passports/PassportForm";
import SimpleModalBody, {
  SimpleModalBodyProps,
} from "components/ui/Modal/SimpleModalBody";
import ModalContext from "contexts/ModalContext";
import { usePassportToRfpId } from "hooks/queries/passportHooks";
import useCreateRFPresponse from "hooks/queries/useCreateRFPResponse";
import useUserData from "hooks/queries/useUserData";
import {
  LocalStorageKey,
  setCookieArray,
  TriggeredPopupEnum,
} from "hooks/utils";
import { zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  title: {
    $nest: {
      "&": {
        color: primaryColors.PURPLE2,
      },
    },
    textAlign: "center",
    ...typography.HEADLINE6,
  },
  text: {
    ...zeroPaddingMargin,
    ...typography.BODY2,
    color: primaryColors.BLACK,
    marginBottom: "32px",
  },
});

export default function GetPassportModal({
  onResponseCreated,
  modalProps,
}: {
  onResponseCreated: () => void;
  modalProps?: Pick<SimpleModalBodyProps, "hideCloseButton">;
}) {
  const { data: user } = useUserData();
  const { data: rfpId } = usePassportToRfpId();
  const { mutateAsync: createResponse } = useCreateRFPresponse();
  const history = useHistory();
  const { closeModal } = useContext(ModalContext);

  return (
    <SimpleModalBody
      titleClassName={styles.title}
      title="Welcome to the Pulse Market ESG Passport Journey!"
      {...modalProps}
      onClose={() => {
        closeModal();
      }}
    >
      <p className={styles.text}>
        We're thrilled to have you on board! To kickstart your sustainability
        journey, we need some information to be added to your passport:
      </p>
      <PassportForm
        data={{
          email: user?.email,
          ownerName: `${user?.firstName} ${user?.lastName}`,
          jobTitle: user?.jobTitle,
        }}
        onSubmit={async (form) => {
          if (!rfpId) {
            return;
          }
          try {
            setCookieArray({
              key: LocalStorageKey.OPEN_POPUP,
              type: "remove",
              value: TriggeredPopupEnum.GetPassport,
            });

            const response = await createResponse({
              accept: true,
              ownerEmail: form.email,
              ownerName: form.ownerName,
              rfpId,
              ownerTitle: form.jobTitle!,
            });

            history.push(
              `/passportresponses/passport/${response.rfpId}/response/${response.id}?`
            );
            closeModal();
            onResponseCreated();
          } catch (e) {}
        }}
        saveButton={{
          variant: "normal",
          color: "orange",
          children: "Next",
          size: "small",
        }}
      />
    </SimpleModalBody>
  );
}
