import { neutralColors } from "@pulsemarket/constants";
import { px } from "csx";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  ModalHeader: {
    position: "relative",
    marginBottom: px(32),
  },
  title: {
    paddingRight: "16px",
    wordWrap: "break-word",
  },
  closeButtonContainer: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  closeButton: {
    color: neutralColors.GRAY5,
    width: "14px",
  },
});

export default styles;
