import React, { useEffect, useMemo, useState } from "react";

import { ClickAwayListener } from "@material-ui/core";
import Select, { SelectProps } from "components/ui/FormSelect";
import MultiSelectList, {
  MultiSelectListProps,
} from "components/ui/MultiSelectList";
import { Pair } from "shared/model";
import { style } from "typestyle";

type SubSelectProps = SelectProps & {
  multiSelectListProps: Omit<
    MultiSelectListProps,
    "options" | "placeholder" | "actions" | "isLoading" | "selectedIds"
  > & {
    getSubOptions: (value: string) => Promise<MultiSelectListProps["options"]>; // This could be a promise :/
  };
  onSelect: (selectedItems: Pair[]) => void;
  submitLabel?: string;
  containerClassName?: string;
};

/**
 * Notes:
 * - after "addRFPRecipientsThroughSupplierSearch" feature flag is removed
 *   1. the ref doesn't have to be forwarded
 *   2. div containers around the select and multiselect components can be deleted.
 */

/**
 * Combination of MultiSelect(List) and Select
 */
export default React.forwardRef<HTMLDivElement, SubSelectProps>(
  function SubSelect(
    {
      onSelect,
      multiSelectListProps,
      submitLabel,
      containerClassName,
      ...props
    }: SubSelectProps,
    ref
  ) {
    const [value, setValue] = useState<null | string>(null);

    const [selectedSubIds, setSelectedSubIds] = useState<string[]>([]);

    const [subSelectOptions, setSubSelectOptions] = useState<
      MultiSelectListProps["options"]
    >([]);
    const [subOptionsLoading, setSubOptionsLoading] = useState<boolean>(false);

    useEffect(() => {
      async function getSubOptions(value: string) {
        setSubOptionsLoading(true);
        const subOptions = await multiSelectListProps.getSubOptions(value);

        setSubSelectOptions(subOptions);
        setSubOptionsLoading(false);
      }

      if (!value) {
        return;
      }

      getSubOptions(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, multiSelectListProps.getSubOptions]);

    const selectableSubOptions = useMemo(
      () =>
        subSelectOptions.filter((item) =>
          typeof item.disabled === "function"
            ? !item.disabled(item.id)
            : !item.disabled
        ),
      [subSelectOptions]
    );

    const allSelectableSubItemsSelected =
      selectedSubIds.length === selectableSubOptions.length;

    const subSelectLabel = useMemo(() => {
      if (!value) {
        return "";
      }
      return props.options.find((option) => option.id === value)?.name ?? "";
    }, [props.options, value]);

    const actionsConfig: Pick<
      MultiSelectListProps,
      "actions" | "actionsClassName"
    > = useMemo<Pick<MultiSelectListProps, "actions" | "actionsClassName">>(
      () =>
        !!selectableSubOptions.length
          ? {
              actionsClassName: style({ justifyContent: "space-between" }),
              actions: [
                {
                  color: "orange",
                  label: submitLabel || "Save",
                  disabled: (selectedIds) => !selectedIds.length,
                  onClick: (items) => {
                    onSelect(items);
                    setValue(null);
                  },
                },
                {
                  variant: "transparent",
                  color: "orange",
                  label: allSelectableSubItemsSelected
                    ? "Deselect All"
                    : "Select all",
                  onClick: () => {
                    if (allSelectableSubItemsSelected) {
                      setSelectedSubIds([]);
                    } else {
                      setSelectedSubIds(
                        selectableSubOptions.map((option) => option.id)
                      );
                    }
                  },
                },
              ],
            }
          : {
              actions: [
                {
                  color: "orange",
                  label: "Okay",
                  onClick: () => {
                    setValue(null);
                  },
                },
              ],
            },
      [
        selectableSubOptions,
        allSelectableSubItemsSelected,
        onSelect,
        submitLabel,
      ]
    );

    if (!value) {
      return (
        <div ref={ref} className={containerClassName}>
          <Select
            {...props}
            onChange={(val) => {
              setValue(val);
              setSelectedSubIds([]);
            }}
          />
        </div>
      );
    }

    return (
      <div ref={ref} className={containerClassName}>
        <ClickAwayListener onClickAway={() => setValue(null)}>
          <MultiSelectList
            {...multiSelectListProps}
            placeholder={subSelectLabel}
            options={subSelectOptions}
            isLoading={subOptionsLoading}
            actions={actionsConfig.actions}
            selectedIds={selectedSubIds}
            actionsClassName={actionsConfig.actionsClassName}
          />
        </ClickAwayListener>
      </div>
    );
  }
);
