import React, { useState } from "react";

import ForgotPasswordForm from "components/auth/ForgotPasswordForm/ForgotPasswordForm";
import ResetPasswordSentForm from "components/auth/ResetPasswordSentForm/ResetPasswordSentForm";
import AuthenticationPageLayout from "pages/AuthenticationPageLayout";

export default function ForgotPasswordPage() {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string | null>(
    null
  );

  return (
    <AuthenticationPageLayout imageSrc="/illustrations/forgot_password.svg">
      {forgotPasswordEmail ? (
        <ResetPasswordSentForm email={forgotPasswordEmail} />
      ) : (
        <ForgotPasswordForm
          onSubmit={(email) => {
            setForgotPasswordEmail(email);
          }}
        />
      )}
    </AuthenticationPageLayout>
  );
}
