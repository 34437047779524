import { neutralColors } from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  mainContainer: {
    padding: "57px",
  },
  grid: {
    overflowX: "auto",
  },
  createGroup: {
    padding: "8px",
  },
  memberCount: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
    marginBottom: px(10),
  },
  discoverGroupsButton: {
    marginTop: px(30),
  },
});
