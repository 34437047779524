import { QueryClient, UseQueryResult } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 20 seconds
      staleTime: 1000 * 20,
    },
  },
});

export default queryClient;

export function isQueryDisabledOrLoading(status: UseQueryResult["status"]) {
  return status === "idle" || status === "loading";
}
