import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { getCompany } from "shared/client";

export default function useGetCompany(companyId?: string) {
  return useQuery(queryKeys.company(companyId), () => getCompany(companyId!), {
    enabled: !!companyId,
  });
}
