import { primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export const styles = stylesheet({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  title: {
    ...typography.HEADLINE5,
    color: primaryColors.BLACK,
  },
  inline: {
    display: "inline",
  },
  boldText: {
    ...typography.TITLE2,
  },
  subtitle: {
    ...typography.BODY2,
    color: primaryColors.BLACK,
    marginBottom: "44px",
  },
  input: {
    width: "100%",
  },
  inputColumns: {
    display: "flex",
    flexDirection: "row",
    gap: "26px",
  },
  secondaryButton: {
    ...typography.SUBTITLE1,
    marginBottom: "32px",
  },
  helperTextContainer: {
    display: "inline",
  },
  helperText: { ...typography.BODY2 },
  tightFit: {
    marginBottom: "4px",
  },
});
