import { useQuery, UseQueryOptions } from "react-query";
import { useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import { RfpDto } from "@pulsemarket/api-client";
import { ApiError, getRFP } from "shared/client";

type UseRfpQueryOptions<T = RfpDto> = Omit<
  UseQueryOptions<RfpDto, Error, T, ReturnType<typeof queryKeys.rfp>>,
  "retry"
> & { rfpId?: string };

export default function useRFP<T = RfpDto>({
  rfpId,
  enabled,
  ...options
}: UseRfpQueryOptions<T>) {
  const history = useHistory();

  return useQuery<RfpDto, Error, T, ReturnType<typeof queryKeys.rfp>>(
    queryKeys.rfp(rfpId),
    () => getRFP(rfpId!),
    {
      enabled: !!rfpId && (typeof enabled === "boolean" ? enabled : true),
      // Don't retry on not found errors or unauthorized errors
      retry: (failureCount, error) => {
        if (error instanceof ApiError) {
          if ([404, 401].includes(error.status)) {
            history.replace("/" + history.location.search);
            return false;
          }
        }
        return true;
      },
      staleTime: Infinity,
      refetchOnMount: "always",
      notifyOnChangeProps: "tracked",
      ...options,
    }
  );
}
