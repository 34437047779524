import React from "react";

import { GroupDto } from "@pulsemarket/api-client";
import { DisplayType } from "components/DisplaySelector";
import CompaniesGridOrList from "components/VendorSearch/CompaniesGridOrList";

import styles from "./SupplierSearchStyles";

type GroupSearchBodyProps = {
  groups?: GroupDto[];
};

export default function GroupSearchBody({ groups = [] }: GroupSearchBodyProps) {
  return (
    <>
      <h2 className={styles.title}>All Results ({groups.length}) </h2>
      <CompaniesGridOrList
        type="Group"
        display={DisplayType.CARDS}
        cards={groups}
      />
    </>
  );
}
