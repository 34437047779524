import React from "react";

import { Grid } from "@material-ui/core";
import CompanyCard from "components/CompanyCard";
import { CompanyCardProps } from "components/CompanyCard/CompanyCard";

import styles from "./CompanyCardsRowStyles";

type CompanyCardsRowProps = {
  cards: CompanyCardProps[];
};

export default function CompanyCardsRow({ cards }: CompanyCardsRowProps) {
  return (
    <Grid className={styles.container} container spacing={2} wrap="nowrap">
      {cards.map((card) => (
        <Grid item key={card.company.id}>
          <CompanyCard {...card} />
        </Grid>
      ))}
    </Grid>
  );
}
