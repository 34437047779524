import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const itemStyles = stylesheet({
  wrapper: {
    width: "100%",
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    padding: "10px",
    transition: "80ms all linear",
    $nest: {
      "&:hover": {
        background: primaryColors.HOVER,
      },
      "&:hover.read": {
        background: neutralColors.GRAY1,
      },
    },
  },
  center: {
    flex: 1,
    marginRight: "12px",
    marginLeft: "12px",
  },
  message: {
    ...typography.CAPTION,
  },
  name: {
    color: utilityColors.ORANGE,
    fontWeight: "bolder",
  },
  orange: {
    color: utilityColors.ORANGE,
  },
  date: {
    ...typography.CAPTION2,
    color: neutralColors.GRAY4,
  },
});

export default itemStyles;
