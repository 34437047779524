import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    border: `1px dashed ${utilityColors.ORANGE}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "275px",
    flexDirection: "column",
    height: "305px",
    borderRadius: "8px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    background: primaryColors.WHITE,
    color: utilityColors.ORANGE,
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.HOVER,
        cursor: "pointer",
      },
    },
  },
});

export default styles;
