import React from "react";

import { ButtonColors } from "components/ui/Button";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";

export type ConfirmationModalBodyProps = {
  children: React.ReactNode;
  action: () => void;
  onClose: () => void;
  title?: string;
  buttonText?: string;
  buttonColor?: ButtonColors;
};

export default function ConfirmationModalBody({
  children,
  action,
  onClose,
  title,
  buttonText = "Ok",
  buttonColor = "red",
}: ConfirmationModalBodyProps) {
  const handleCancel = () => {
    onClose();
  };
  return (
    <SimpleModalBody
      title={title}
      buttons={[
        {
          variant: "normal",
          color: "gray",
          onClick: handleCancel,
          children: "Cancel",
          disableCloseAfterCallback: true,
        },
        {
          variant: "normal",
          color: buttonColor,
          onClick: () => {
            action();
            onClose();
          },
          autoFocus: true,
          children: buttonText,
          disableCloseAfterCallback: true,
        },
      ]}
      onClose={onClose}
    >
      {children}
    </SimpleModalBody>
  );
}
