import React from "react";

import NoContent from "components/ui/NoContent";
import GroupPageCard from "pages/PulseGroup/GroupPageCard";

import { styles } from "./GroupCardStyles";

function EmptyPostsCard({ isPendingView }: { isPendingView: boolean }) {
  return (
    <GroupPageCard className={styles.middleContainer}>
      <EmptyContent isPendingView={isPendingView} />
    </GroupPageCard>
  );
}

export default EmptyPostsCard;

function EmptyContent({ isPendingView }: { isPendingView: boolean }) {
  return (
    <NoContent icon={{ src: "/icons/group_avatar.svg" }}>
      {isPendingView
        ? `You don't have any Pending Post!`
        : `This group has no posts yet. Create a post now and be the first
        to publish!`}
    </NoContent>
  );
}
