import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { stylesheet } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

const blackStyles: NestedCSSProperties = {
  $nest: {
    "&": {
      // not specified in the design
      color: neutralColors.GRAY5,
    },
    "&:hover": { color: primaryColors.BLACK },
  },
};

const orangeStyles: NestedCSSProperties = {
  $nest: {
    "&": {
      color: utilityColors.ORANGE,
    },
    "&:hover": { color: utilityColors.ORANGE_HOVER },
  },
};

const grayStyles: NestedCSSProperties = {
  $nest: {
    "&": {
      color: neutralColors.GRAY4,
    },
    "&:hover": {
      // not specified in the design
      color: neutralColors.GRAY5,
    },
  },
};

const greenStyles: NestedCSSProperties = {
  $nest: {
    "&": {
      color: utilityColors.GREEN,
    },
    // missing hover color - not specified in the design
  },
};

const purpleStyles: NestedCSSProperties = {
  $nest: {
    "&": {
      color: primaryColors.PURPLE2,
    },
    "&:hover": { color: primaryColors.PURPLE2_HOVER },
  },
};

const styles = stylesheet({
  button: {
    width: "28px",
    height: "28px",
    borderRadius: "3px",
    padding: "0",
    color: primaryColors.BLACK,
  },
  icon: {
    maxWidth: "18px",
  },
  black: blackStyles,
  green: greenStyles,
  gray: grayStyles,
  orange: orangeStyles,
  purple: purpleStyles,
});

export const smallVariantStyles = stylesheet({
  button: {
    width: "24px",
    height: "24px",
    $nest: {
      [`& .${styles.icon}`]: {
        maxWidth: "14px",
      },
    },
  },
});

export const largeVariantStyles = stylesheet({
  button: {
    width: "30px",
    height: "30px",
    $nest: {
      [`& .${styles.icon}`]: {
        maxWidth: "20px",
      },
    },
  },
});

export default styles;
