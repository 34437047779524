import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { setGroupInvitationStatus } from "shared/client";
import { GroupInvitationStatus } from "shared/model";

type SetGroupInvitationStatusParams = {
  groupInvitationId: string;
  status: GroupInvitationStatus;
};

export default function useSetGroupInvitationStatus() {
  const client = useQueryClient();

  return useMutation(
    ({ groupInvitationId, status }: SetGroupInvitationStatusParams) => {
      return setGroupInvitationStatus({ groupInvitationId, status });
    },
    {
      mutationKey: mutationKeys.setGroupInvitationStatus,
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.group("")[0]);
        if (variables.status === GroupInvitationStatus.Left) {
          client.invalidateQueries(queryKeys.groupsImMember());
        }
      },
    }
  );
}
