import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { px } from "csx";
import { forceTextOneLine } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export const styles = stylesheet({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: px(26),
  },
  sideColumn: {
    flex: 3,
    gap: px(24),
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  centerColumn: {
    flex: 5,
    gap: px(24),
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  noPadding: { padding: px(0) },
  fullHeight: { height: "100%" },
  backgroundContainer: {
    width: "100%",
    borderWidth: px(3),
    borderRadius: px(8),
    borderStyle: "solid",
    borderColor: primaryColors.WHITE,
    height: "179px",
  },
  avatarContainer: {
    borderRadius: "4px",
    zIndex: 2,
    marginTop: px(-83),
    textAlign: "center",
  },
  options: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    height: px(14),
    width: "100%",
    marginRight: px(23),
    marginTop: px(-23),
  },
  svgIcon: {
    height: px(10),
  },
  groupName: {
    ...typography.HEADLINE5,
    color: primaryColors.BLACK,
    marginTop: px(34),
    marginBottom: px(38),
  },
  kebabMenuItemIcon: {
    marginRight: "15px",
  },
  ownerCompanyWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: px(20),
    alignItems: "center",
    width: "100%",
  },
  companyAvatar: {
    objectFit: "cover",
  },
  ownerCompanyDetails: {
    display: "flex",
    flexDirection: "column",
  },
  ownerCompanyName: {
    ...typography.HEADLINE6,
    color: primaryColors.BLACK,
    textAlign: "left",
  },
  groupCreationDate: {
    ...typography.CAPTION,
    textAlign: "left",
  },
  middleContainer: { height: "100%" },
  bigIcon: {
    color: neutralColors.GRAY2,
    width: "77px",
    height: "77px",
    marginBottom: "20px",
  },
  buttonWithMargin: { marginTop: px(30) },
  zoneTagContainer: {
    display: "block",
    textAlign: "start",
    gap: px(8),
  },
  zoneTag: {
    margin: `${px(10)} ${px(10)}`,
    gap: px(13),
    display: "inline-flex",
    height: px(30),
    padding: `${px(5)} ${px(15)} `,
    borderRadius: px(4),
    backgroundColor: utilityColors.TAG_SELECTED,
    alignItems: "center",
    color: primaryColors.PURPLE3,
  },
  memberCardContentContainer: { width: "100%" },
  membersTitleWithPendingRequests: {
    marginLeft: "auto",
    width: "unset",
  },
  joinRequestsPendingButton: { ...forceTextOneLine },

  memberButton: {
    ...forceTextOneLine,
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "20px",
    marginBottom: "20px",
  },

  memberCompanyAvatarsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  memberCompanyAvatarContainer: {
    width: px(50),
    height: px(50),
    display: "flex",
    borderRadius: px(5),
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: neutralColors.GRAY1,
  },
  memberCompanyAvatar: {
    margin: "auto",
    maxHeight: px(50),
    maxWidth: px(50),
  },
  filteredCompanyAvatarContainer: {
    height: px(35),
    width: px(35),
    display: "flex",
    borderRadius: px(5),
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: neutralColors.GRAY1,
  },
  filteredCompanyAvatar: {
    maxHeight: px(35),
    maxWidth: px(35),
    margin: "auto",
  },
  filteredCompany: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    border: `${neutralColors.GRAY2} 1px solid`,
    paddingLeft: px(8),
    paddingRight: px(8),
    borderRadius: px(8),
    marginBottom: px(9),
  },
  memberSearch: {
    width: "100%",
  },
  recentlyFilteredText: {
    ...typography.CAPTION,
    color: utilityColors.ORANGE,
    textAlign: "start",
  },
  recentlyFilteredItems: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "235px",
    overflowY: "auto",
  },
  memberCompanyButtons: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "center",
  },
  memberCompanyButton: {
    color: neutralColors.GRAY5,
    width: "20px",
    cursor: "pointer",
    marginRight: "16px",
  },
  filteredCompanyName: {
    ...typography.SUBTITLE2,
    color: primaryColors.BLACK,
    width: "70%",
  },
  memberButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  linkedDataroomItem: {
    marginBottom: "13px",
  },
});

export const companyInfoCardStyles = stylesheet({
  notificationIcon: { width: "15px" },
  manageIcon: { width: "14px" },
  leaveIcon: {
    height: "18px",
    $nest: {
      "& path:first-of-type": {
        fill: "unset",
        stroke: "currentcolor",
      },
    },
  },
});
