import { clickable } from "shared/styles/classes";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {},
  labels: {
    maxWidth: "260px",
  },
  hidden: { display: "none" },
  arrowDown: {
    height: "24px",
    width: "24px",
    $nest: {
      "&&": {
        padding: 0,
      },
    },
  },
  dropdown: {
    ...clickable,
    marginRight: "4px",
  },
  tooltip: {
    padding: "4px",
    maxHeight: "122px",
    overflowY: "auto",
  },
});

export default styles;
