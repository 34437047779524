import React from "react";

import { Identifiable } from "components/AssetRepository/types";
import { ButtonProps } from "components/ui/Button/Button";
import Card from "components/ui/Card";
import Link from "components/ui/Link";
import { DEFAULT_COMPANY_AVATAR } from "shared/constants/icons";
import { CompanyCardDto } from "shared/model";
import { SelectableComponentProps } from "shared/utils/componentTypes";
import { classes } from "typestyle";

import styles, { compactStyles } from "./CompanyCard.styles";

export type CompanyCardProps = {
  className?: string;
  company: CompanyCardDto;
  buttons?: (ButtonProps & Identifiable)[];
  compact?: boolean;
} & SelectableComponentProps<CompanyCardDto>;

export default function CompanyCard({
  company,
  className,
  buttons = [],
  compact,
  selected,
  onToggleSelect,
  disabled,
  disabledReason,
}: CompanyCardProps) {
  const cardDisabled =
    typeof disabled === "function" ? disabled(company) : disabled;

  const disabledStyles = cardDisabled && styles.disabled;

  return (
    <Card
      className={classes(
        styles.container,
        compact && compactStyles.container,
        disabledStyles,
        className
      )}
      avatar={company.avatar}
      defaultAvatar={DEFAULT_COMPANY_AVATAR}
      title={company.name}
      buttons={buttons}
      onToggleSelect={onToggleSelect}
      disabled={cardDisabled}
      selected={selected}
    >
      {!compact && (
        <div className={classes(styles.specialities, disabledStyles)}>
          {company.specialities?.map((element) => element.name).join(", ")}
        </div>
      )}
      {cardDisabled && (
        <span className={styles.disabledReason}>{disabledReason}</span>
      )}
      {(company.city || company.country) && (
        <div className={classes(styles.location, disabledStyles)}>
          {[company.city, company.country?.name].filter(Boolean).join(", ")}
        </div>
      )}
      {!compact && (
        <Link url={`/company/${company.id}`} underline="none">
          View Pulse Page
        </Link>
      )}
    </Card>
  );
}
