import React from "react";

import TableHeaderFilter from "components/ui/Table/TableHeaderFilter";
import { useAssetContext } from "contexts/AssetFilter/AssetFilterProvider";
import useAssetLabels from "hooks/queries/useAssetLabels";

function LabelColumnHeader() {
  const { data, isLoading } = useAssetLabels();
  const { searchOptions, setSearchOptions } = useAssetContext();

  const disabled =
    !searchOptions.folderId && searchOptions.labelIds?.length === 1;

  return (
    <>
      <TableHeaderFilter
        options={data?.map((label) => ({
          id: label.id,
          name: label.name,
        }))}
        isLoading={isLoading}
        columnName="Labels"
        selectedIds={searchOptions.labelIds || []}
        onChange={(selectedIds) => {
          setSearchOptions({
            ...searchOptions,
            labelIds: selectedIds.length ? selectedIds : undefined,
          });
        }}
        disabled={disabled}
      />
    </>
  );
}

export default LabelColumnHeader;
