import React from "react";

import IconButton from "components/ui/IconButton";
import { MappedInsertedAsset } from "shared/model";
import { DeletableComponentProps } from "shared/utils/componentTypes";
import { classes } from "typestyle";

import styles from "./InsertedImage.styles";

type InsertedImageProps = DeletableComponentProps & {
  className?: string;
  image: MappedInsertedAsset;
};

export default function InsertedImage({
  image,
  className,
  disableDelete,
  onDelete,
}: InsertedImageProps) {
  return (
    <div className={classes(styles.imageContainer, className)}>
      <img src={image.url} alt="" className={styles.image} />
      {!disableDelete && (
        <div className={styles.deleteIconWrapper}>
          <IconButton
            tooltip="Delete"
            color="orange"
            src="/icons/delete_icon.svg"
            iconClassname={styles.deleteIcon}
            onClick={onDelete}
          />
        </div>
      )}
    </div>
  );
}
