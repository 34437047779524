import { SharedVideoManagerProps } from "components/VideoManager/types";
import {
  useCreatePostVideo,
  useUpdatePostVideo,
} from "hooks/queries/postMediaItemHooks";

import UploadOrEditVideo from "./UploadOrEditVideo";

type PostVideoManagerProps = SharedVideoManagerProps & {
  groupId: string;
  postId: string;
};

export default function PostVideoManager({
  groupId,
  postId,
  onClose,
  video,
}: PostVideoManagerProps) {
  const { mutateAsync: addVideo } = useCreatePostVideo({ groupId });
  const { mutateAsync: updateVideo } = useUpdatePostVideo({ groupId });
  return (
    <UploadOrEditVideo
      video={video}
      onClose={onClose}
      onAddVideo={(input) =>
        addVideo({
          name: input.name,
          reference: input.reference,
          postId: postId,
        })
      }
      onUpdateVideo={(input) =>
        updateVideo({ name: input.name, mediaItemId: input.id })
      }
    />
  );
}
