import { PassportTypeId } from "shared/model";

type PassportAdditions = {
  image: string;
  description: { owner: string; notOwner: string };
  survey: string;
  pdfAvatar: string;
  clarification?: string;
};

export const passportDetail: Record<PassportTypeId, PassportAdditions> = {
  esg_health_check_v1: {
    image: "/icons/ESG_passport.svg",
    description: {
      owner: `Congratulations! You have unlocked Pulse ESG tools that can help you expand your knowledge and showcase the standards of excellence your company offers.`,
      notOwner: ` ESG Passport unlocks tools including the SIO* approved self-assessment focused on Environment, Social and Corporate Governance plus access knowledge guides, support and Passport icon.`,
    },
    // TO-DO: this link shuold come fro the DB -Bug #3061
    survey: "https://www.surveymonkey.co.uk/r/PulseMarket_ESGPassport",
    pdfAvatar: "/illustrations/ESG.png",
    clarification: `*SIO: Scottish Investment Operations professional body for the financial services sector in Scotland`,
  },
  financial_service_readiness_v1: {
    image: "/icons/financial_passport.svg",
    description: {
      owner: "Add owner description",
      notOwner: "Add not owner description",
    },
    survey: "add passport url",
    pdfAvatar: "ADD AVATAR",
  },
};
