import React, { useState } from "react";
import { useQueryClient } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { Grid } from "@material-ui/core";
import DisplaySelector, { DisplayType } from "components/DisplaySelector";
import CompaniesGridOrList from "components/VendorSearch/CompaniesGridOrList";
import { zonesDetails } from "components/VendorSearch/SupplierSearch/constants";
import { useVendorSearchContext } from "components/VendorSearch/VendorFilterProvider";
import ZoneBox from "components/VendorSearch/ZoneBox";
import useVendorSearchOptions from "hooks/queries/useVendorSearchOptions";
import useCompanyCards from "hooks/useCompanyCards";
import { getCompanies } from "shared/client";
import { CompanyCardDto, CompanyDto, CompanyFilter } from "shared/model";

import styles from "./SupplierSearchStyles";

type ZoneDetail = {
  description: string;
  image: string;
};

const getZonesDetails = (zoneId: string) => {
  const zoneDetail = zonesDetails.find(
    (element) => element.id === zoneId
  ) as ZoneDetail;

  return { description: zoneDetail.description, image: zoneDetail.image };
};

type SupplierSearchBodyProps = {
  companies?: CompanyDto[];
  compactCards?: boolean;
  selectableCards?: boolean;
  companyDisabled?: (company?: CompanyCardDto) => boolean | undefined;
  disabledReason?: string;
};

export default function SupplierSearchBody({
  companies = [],
  compactCards,
  selectableCards,
  companyDisabled,
  disabledReason,
}: SupplierSearchBodyProps) {
  const companyCardProps = useCompanyCards(companies, {
    compact: compactCards,
    selectable: selectableCards,
    companyDisabled,
    disabledReason,
  });
  const { filters, setFilters } = useVendorSearchContext();
  const { zones } = useVendorSearchOptions();
  const client = useQueryClient();
  const [display, setDisplay] = useState<DisplayType>(DisplayType.CARDS);

  const noFiltersSet = Object.values(filters).filter(Boolean).length === 0;

  if (noFiltersSet) {
    return (
      <Grid container spacing={3} justifyContent="flex-start">
        {zones.map((zone) => (
          <Grid
            item
            key={zone.name}
            xs={12}
            md={5}
            lg={4}
            className={styles.zoneBoxContainer}
            onMouseEnter={() => {
              const params: CompanyFilter = { zones: [zone.id] };
              client.prefetchQuery(queryKeys.companies(params), () =>
                getCompanies(params)
              );
            }}
          >
            <ZoneBox
              {...zone}
              description={getZonesDetails(zone.id).description}
              logo={<img alt="zone" src={getZonesDetails(zone.id).image} />}
              className={styles.zoneBox}
              onClick={() => {
                setFilters({ ...filters, zones: [zone.id] });
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <>
      {/* ? move this to a shared component */}
      <h2 className={styles.title}>
        All Results ({companies.length}){" "}
        <DisplaySelector display={display} onDisplayChange={setDisplay} />
      </h2>
      <CompaniesGridOrList
        type={"Company"}
        cards={companyCardProps}
        display={display}
      />
    </>
  );
}
