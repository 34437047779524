import getVideoId from "get-video-id";

export function checkYoutubeUrl(youtubeUrl: string) {
  return getVideoId(youtubeUrl);
}

export function checkHttpProtocol(url: string | undefined) {
  const regex = /^http(s)?:?\/{2}\w/g;
  if (!url) {
    return;
  }
  if (url.match(regex)) {
    return url;
  }
  return `https://${url}`;
}

export const getLocationParts = (
  ...locationDetails: (string | undefined)[]
) => {
  const locationArray: string[] = [];
  locationDetails.forEach((element) => {
    if (element) {
      locationArray.push(element);
    }
  });
  return locationArray.join(", ");
};
