import React from "react";

import Button from "components/ui/Button";
import { useResendVerifyEmail } from "hooks/queries/useAuth";
import { classes } from "typestyle";

import { styles } from "../AuthFormLayout/AuthForm.styles";

export default function VerifyEmailForm({ email }: { email: string }) {
  const { mutateAsync: resendVerifyEmail } = useResendVerifyEmail();
  const handleSubmit = () => {
    resendVerifyEmail(email);
  };

  return (
    <form autoComplete="off">
      <div className={styles.root}>
        <h2 className={styles.title}> Verify your email</h2>
        <p className={classes(styles.inline, styles.subtitle, styles.tightFit)}>
          We sent a verification link to{" "}
          <span className={styles.boldText}>{email}</span>
        </p>
        <p className={styles.subtitle}>
          Didn’t receive the email? Please check you spam folder or try to
          resend the email.
        </p>

        <Button
          variant="normal"
          color="orange"
          fullWidth
          onClick={() => {
            handleSubmit();
          }}
        >
          Resend email
        </Button>
      </div>
    </form>
  );
}
