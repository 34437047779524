import React from "react";

import { getLocationParts } from "components/PulsePage/helpers";
import SvgIcon from "components/ui/SvgIcon";
import { CompanyDetailsDto } from "shared/model";

import styles from "./CompanyDetailsCardStyles";

interface CompanyDetailsCardProps {
  company: CompanyDetailsDto;
  isTargetMarketDisplayed: boolean;
}

export default function CompanyDetailsCard({
  company,
  isTargetMarketDisplayed,
}: CompanyDetailsCardProps) {
  const isResponsiveDetailField = isTargetMarketDisplayed
    ? styles.detailField
    : styles.responsiveDetailField;

  return (
    <div
      className={
        isTargetMarketDisplayed
          ? styles.companyDetailsContainerFullWidth
          : styles.companyDetailsContainer
      }
    >
      {(company?.city || company?.state || company?.country) && (
        <div className={styles.detailContainer}>
          <SvgIcon src="/icons/location_on.svg" className={styles.detailIcon} />
          <span className={isResponsiveDetailField}>Location: </span>
          <span className={styles.detail}>
            {getLocationParts(
              company.city,
              company.state,
              company.country?.name
            )}
          </span>
        </div>
      )}
      {company.phoneNumber && (
        <div className={styles.detailContainer}>
          <SvgIcon src="/icons/phone_icon.svg" className={styles.detailIcon} />
          <span className={isResponsiveDetailField}>Phone Number: </span>
          <span className={styles.detail}>{company.phoneNumber}</span>
        </div>
      )}
      {company.employees && (
        <div className={styles.detailContainer}>
          <SvgIcon src="/icons/people_alt.svg" className={styles.detailIcon} />
          <span className={isResponsiveDetailField}>Number of employees: </span>
          <span className={styles.detail}>{company.employees.name}</span>
        </div>
      )}
      {company.website && (
        <div className={styles.detailContainer}>
          <SvgIcon
            src="/icons/website_icon.svg"
            className={styles.detailIcon}
          />
          <span className={isResponsiveDetailField}>Website: </span>
          <span className={styles.detail}>{company.website}</span>
        </div>
      )}
      {company.linkedInUrl && (
        <div className={styles.detailContainer}>
          <SvgIcon
            src="/icons/linked_in_icon.svg"
            className={styles.detailIcon}
          />
          <span className={isResponsiveDetailField}>Linkedin: </span>
          <span className={styles.detail}>{company.linkedInUrl}</span>
        </div>
      )}
    </div>
  );
}
