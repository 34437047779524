import React from "react";
import queryClient from "reactQuery/client";
import queryKeys from "reactQuery/queryKeys";

import { AssetDto, ModuleDto, QuestionDto } from "@pulsemarket/api-client";
import { AppColors } from "@pulsemarket/constants";
import LabelFilter from "components/AssetRepository/AssetRepositoryBody/LabelFilter";
import LabelCell from "components/AssetRepository/LabelCell";
import { DisplayType } from "components/DisplaySelector";
import { AssetSearchOptions, CompanyDetailsDto } from "shared/model";

import { AssetRepositoryItem } from "./AssetRepositoryBody/AssetRepositoryBody";
import EditableAssetNameCell from "./AssetRepositoryBody/EditableAssetNameCell";
import { HasDataType } from "./types";
import { ListItemType } from "./types";

type AssetTypeColor = AppColors;

// can we seperate these types into two types?
export type AssetRepositoryMenuItemWithConfig<T> = {
  id: string;
  icon: string;
  text: string;
  selected?: boolean;
  subMenus?: AssetRepositoryMenuItemWithConfig<T>[];
  itemType: DataTypeInRepository;
  forcedDisplay?: DisplayType;
  filters?: [filterKey: string, filterValue: any][];
};

export type DataTypeInRepository = ListItemType | "Any";

export const AssetIconSource: Record<ListItemType, string> = {
  Proposal: "/icons/proposals_icon.svg",
  Award: "/icons/awards_icon.svg",
  Certificate: "/icons/certifications_icon.svg",
  Video: "/icons/video_icon.svg",
  Image: "/icons/image_icon.svg",
  Module: "/icons/modules_icon.svg",
  Policy: "/icons/policies_icon.svg",
  Question: "/icons/QA_icon.svg",
  Template: "/icons/templates_icon.svg",
  Folder: "/icons/folder_icon.svg",
  NewFolder: "/icons/folder_icon.svg",
  File: "/icons/file_undefined.svg",
};

export const AssetColor: Record<ListItemType, AssetTypeColor> = {
  Award: AppColors.Yellow,
  Certificate: AppColors.Orange,
  Video: AppColors.Lilac,
  Image: AppColors.Lilac,
  Module: AppColors.Cyan,
  Policy: AppColors.Red,
  Question: AppColors.Green,
  Proposal: AppColors.Purple,
  Template: AppColors.Blue,
  Folder: AppColors.Blue,
  NewFolder: AppColors.Blue,
  File: AppColors.Gray,
};

export type PossibleDtos = (AssetDto | QuestionDto | ModuleDto) & HasDataType;

export const assetRepositoryMenuIds = {
  all: "all",
  proposals: "proposals",
  templates: "templates",
  template_library: "templates-template_library",
  my_templates: "templates-my_templates",
  draft_templates: "templates-draft_templates",
  certificates: "certificates",
  awards: "awards",
  policies: "policies",
  modules: "modules",
  images: "images",
  questions: "questions",
};

export const assetRepositoryMenuElements: AssetRepositoryMenuItemWithConfig<PossibleDtos>[] =
  [
    {
      id: assetRepositoryMenuIds.all,
      icon: "/icons/assets_icon.svg",
      text: "All Files",
      itemType: "Any",
      selected: true,
    },
    {
      id: assetRepositoryMenuIds.proposals,
      icon: AssetIconSource[ListItemType.Proposal],
      text: "Proposals",
      itemType: ListItemType.Proposal,
    },
    {
      id: "templates",
      icon: AssetIconSource[ListItemType.Template],
      text: "Templates",
      itemType: ListItemType.Template,
      subMenus: [
        {
          text: "Template library",
          id: assetRepositoryMenuIds.template_library,
          icon: AssetIconSource[ListItemType.Template],
          itemType: ListItemType.Template,
        },
        {
          text: "My templates",
          id: assetRepositoryMenuIds.my_templates,
          icon: AssetIconSource[ListItemType.Template],
          itemType: ListItemType.Template,
        },
        {
          text: "Draft templates",
          id: assetRepositoryMenuIds.draft_templates,
          icon: AssetIconSource[ListItemType.Template],
          itemType: ListItemType.Template,
        },
      ],
    },
    {
      id: assetRepositoryMenuIds.certificates,
      icon: AssetIconSource[ListItemType.Certificate],
      itemType: ListItemType.Certificate,
      text: "Certifications",
    },
    {
      id: assetRepositoryMenuIds.awards,
      icon: AssetIconSource[ListItemType.Award],
      text: "Awards",
      itemType: ListItemType.Award,
    },
    {
      id: assetRepositoryMenuIds.policies,
      icon: AssetIconSource[ListItemType.Policy],
      text: "Policies",
      itemType: ListItemType.Policy,
    },
    {
      id: assetRepositoryMenuIds.modules,
      icon: AssetIconSource[ListItemType.Module],
      text: "Modules",
      itemType: ListItemType.Module,
      subMenus: [],
    },
    {
      id: assetRepositoryMenuIds.images,
      icon: AssetIconSource[ListItemType.Image],
      text: "Images & Videos",
      itemType: ListItemType.Image,
    },
    {
      id: assetRepositoryMenuIds.questions,
      icon: AssetIconSource[ListItemType.Question],
      text: "Questions & Answers",
      itemType: ListItemType.Question,
    },
  ];

type ColumnData<T> =
  | {
      field: keyof T & string;
    }
  | {
      customTableColumn: (element: T, index: number) => React.ReactNode;
      key: string;
      field?: keyof T & string;
    };

export interface TableDisplaySetting<T> {
  listConfig: {
    columns: (ColumnData<T> & {
      columnName?: string;
      customHeader?: () => React.ReactNode;
      tooltip?: React.ReactNode;
      key?: string;
      disableSort?: boolean;
      hiddenDivider?: boolean;
    })[];
  };
  tilesConfig?: {
    imageSource?: keyof T;
    textSource: keyof T;
  };
}

export const assetRepositoryTableDisplayConfig: TableDisplaySetting<AssetRepositoryItem> =
  {
    listConfig: {
      columns: [
        {
          field: "name",
          columnName: "Name",
          customTableColumn: (row) => <EditableAssetNameCell row={row} />,
        },
        {
          field: "labels",
          columnName: "Labels",
          customTableColumn: (row) => (
            <LabelCell labels={row.labels} row={row} />
          ),
          customHeader: LabelFilter,
        },
        { field: "createdDate", columnName: "Date Created" },
        { field: "updatedDate", columnName: "Last Update" },
      ],
    },
  };

export const maxUploadFileSizeMb: number = 400;
export const maxUploadFileSize: number = maxUploadFileSizeMb * 1024 * 1024;

export function getSideMenuFoldersFilters(): AssetSearchOptions {
  return {
    folderId: queryClient.getQueryData<CompanyDetailsDto>(queryKeys.ownCompany)
      ?.assetRepositoryId,
    name: "",
    showHidden: false,
  };
}
