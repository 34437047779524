import dayjs, { Dayjs } from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(durationPlugin);
dayjs.extend(relativeTime);

export const createDateFormat = "DD-MM-YYYY";
export const updateDateFomat = "DD/MM/YY [at] h:mm a";
export const rfpDateFormat = "DD/MM/YYYY";
export const dataRoomDateFormat = "DD/MM/YYYY";
export const contractDateFormat = "DD/MM/YYYY";
export const passportExpirationDateFormat = "DD/MM/YYYY";
export const rfpDueDateFormat = "YYYY-MM-DD";
export const excelReportDateFormat = "YYMMDD_hhmmss";

/**
 *
 * @param ISO8601Duration duration of the YouTube video, returned from YouTube Data API v3.
 * @returns Transformed length. Examples: 03:12:01, 02:12
 */
export function getYouTubeVideoReadableDuration(
  ISO8601Duration: string
): string {
  const duration = dayjs.duration(ISO8601Duration);
  if (duration.hours()) {
    return duration.format("HH:mm:ss");
  }
  return duration.format("mm:ss");
}

const now = new Date();
export const thisMonth = dayjs(now).startOf("month").toDate();
export const last3Months = dayjs(now)
  .subtract(3, "month")
  .startOf("month")
  .toDate();
export const last6Months = dayjs(now)
  .subtract(6, "month")
  .startOf("month")
  .toDate();

export const humanizedDateInterval = (from: Dayjs, to: Dayjs) =>
  dayjs.duration(to.diff(from)).humanize(true);
