import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  CreateGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  svg: {
    height: px(16),
    cursor: "pointer",
    justifyContent: "center",
    marginRight: px(16),
    color: neutralColors.GRAY5,
  },
  titleContainer: {
    height: "30px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "0px",
  },
  title: {
    ...typography.HEADLINE5,
    marginBottom: px(0),
    marginTop: px(0),
    color: primaryColors.PURPLE2,
  },
});
