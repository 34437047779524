import React from "react";

import { ButtonForModalWithSelectableItems } from "components/assets/RepositoryModal";
import PageLayoutWithSidebar from "components/ui/PageLayoutWithSidebar";
import { VendorSearchSideMenuItem } from "components/VendorSearch/Sidebar/SideBar";
import { CompanyDto } from "shared/model";

import VendorSearchHeader from "../Header";
import VendorSearchSidebar from "../Sidebar";

type VendorSearchLayoutProps = {
  children: React.ReactNode;
  showHeaderCreateButton?: boolean;
  showHeaderFilters?: boolean;
  actions?: ButtonForModalWithSelectableItems<CompanyDto>[];
  activeTab: VendorSearchSideMenuItem;
};

export default function VendorSearchLayout({
  children,
  showHeaderCreateButton,
  showHeaderFilters,
  actions,
  activeTab,
}: VendorSearchLayoutProps) {
  return (
    <PageLayoutWithSidebar
      actions={actions}
      sidebar={<VendorSearchSidebar activeTab={activeTab} />}
      header={
        <VendorSearchHeader
          activeTab={activeTab}
          showCreateButton={showHeaderCreateButton}
          showFilters={showHeaderFilters}
        />
      }
    >
      {children}
    </PageLayoutWithSidebar>
  );
}
