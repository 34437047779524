import React, { useEffect, useState } from "react";

import { ModalAction } from "components/ui/Modal/Actions/ModalActions";
import { useCreateCompany } from "hooks/queries/useCompanies";
import { nameValidatorFn } from "shared/validators";

import Input from "../ui/Input";
import SimpleModalBody from "../ui/Modal/SimpleModalBody/SimpleModalBody";
import styles from "./CreateCompanyModal.styles";

export function CreateCompanyModal() {
  const [companyName, setcompanyName] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>();
  useEffect(() => {
    if (companyName) {
      if (companyName.length < 3) {
        setErrorMessage("Name should have a minimum 3 Characters.");
      } else if (!nameValidatorFn(companyName)) {
        setErrorMessage("The name contains invalid characters.");
      } else {
        setErrorMessage(undefined);
      }
    }
  }, [companyName]);

  const { mutateAsync: createCompany } = useCreateCompany();
  const buttons: ModalAction[] = [
    {
      variant: "normal",
      color: "gray",
      onClick: () => {},
      children: "Cancel",
    },
    {
      variant: "normal",
      color: "orange",
      onClick: () => {
        createCompany(companyName);
      },
      disabled: !companyName || !!errorMessage,
      children: "Add",
    },
  ];
  return (
    <SimpleModalBody title="Add new company" buttons={buttons}>
      <div className={styles.root}>
        <Input
          label="Company Name"
          name="name"
          placeholder="Name"
          fullWidth
          value={companyName}
          onChange={(e) => setcompanyName(e.target.value)}
          helperText={errorMessage}
          error={!!errorMessage}
        />
      </div>
    </SimpleModalBody>
  );
}
