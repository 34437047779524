import React from "react";

import Avatar from "components/ui/Avatar";

import styles from "./RadioLabel.styles";

export type RadioLabelProps = {
  title: string;
  image?: string;
  label?: string;
};

export default function RadioLabel({
  title,
  label,
  image,
}: RadioLabelProps): JSX.Element {
  return (
    <div className={styles.container}>
      {image && <Avatar src={image} size="24" className={styles.image} />}
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        {label && <span className={styles.label}>{label}</span>}
      </div>
    </div>
  );
}
