import Button from "components/ui/Button";
import Switch from "components/ui/Switch";
import { classes } from "typestyle";

import styles from "./GroupSettingsStyle";

type GroupSettingsRowProps = {
  className?: string;
  title: string;
  items: {
    /** todo rename to setting? */
    subtitle?: string;
    description: string;
    value: boolean;
    /** todo rename to onToggle? */
    onChange?: (e: boolean) => void;
    onClick?: () => void;
    buttonTitle?: string;
  }[];
};

/** todo
 * - generalize
 * - rename to SettingsGroup (?) */
export function GroupSettingsRow({
  className,
  title,
  items,
}: GroupSettingsRowProps) {
  return (
    <div className={classes(styles.secondPageInputContainer, className)}>
      <label className={styles.inputLabel}>{title}</label>
      <div className={styles.rowItemsContainer}>
        {items.map((item, i) => {
          return (
            <div className={styles.rowItem} key={i}>
              {item.subtitle && (
                <label className={styles.labelSecondPage}>
                  {item.subtitle}
                </label>
              )}
              <span className={styles.span}>{item.description}</span>

              {item.onChange && (
                <Switch value={item.value} onChange={item.onChange} />
              )}
              {item.onClick && (
                <Button onClick={item.onClick} color="gray" variant="normal">
                  {item.buttonTitle}
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
