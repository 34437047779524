import React from "react";

import { Loading } from "components/FullScreenLoading";
import { downloadCert } from "components/PassportPDF/useRenderPdfBlob";
import Button from "components/ui/Button";
import Link from "components/ui/Link";
import LockedButton from "components/ui/LockedButton";
import useGetCompany from "hooks/queries/useGetCompany";
import useIsAdmin from "hooks/queries/useIsAdmin";
import permissionMessages from "shared/constants/permissionMessages";
import { PassportDto } from "shared/model";

import styles from "./PassportCardStyles";

type PassportCardProps = {
  title: string;
  description: { owner: string; notOwner: string };
  clarification?: string;
  avatar: string;
  isOwner: boolean;
  passportId: string;
  passport: PassportDto;
  companyId: string;
};

export default function PassportCard({
  title,
  description,
  avatar,
  passport,
  isOwner,
  passportId,
  clarification,
  companyId,
}: PassportCardProps) {
  const { isAdmin, loading, error } = useIsAdmin();

  const { data: companyData, isLoading: companyIsLoading } =
    useGetCompany(companyId);

  if (!companyData || companyIsLoading) {
    return <Loading />;
  }

  const linkToPulsePassport =
    !loading && !error && !isAdmin ? (
      <LockedButton
        variant="link"
        color="orange"
        tooltip={permissionMessages.pulsePassport}
        locked={true}
      >
        Access Pulse Passport
      </LockedButton>
    ) : (
      <Link url="/company/edit/pulsepassport">Access Pulse Passport</Link>
    );

  return (
    <div className={styles.container}>
      <img src={avatar} alt="pulse passport avatar" className={styles.icon} />
      <div className={styles.details}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>
          {isOwner ? description.owner : description.notOwner}
        </p>
        {!isOwner && <p className={styles.clarification}>{clarification}</p>}
        {isOwner ? (
          linkToPulsePassport
        ) : (
          <Button
            key={passportId}
            variant="link"
            color="orange"
            onClick={async () => {
              downloadCert({
                date: {
                  from: new Date(passport.createdAt),
                  until: new Date(passport.expirationDate),
                },
                companyName: companyData.name,

                // Do not show score for now for visitors
                // score: passport.score,
                //TODO: get score from related rfp response or save the score on the passport itself
              });
            }}
          >
            View Passport
          </Button>
        )}
      </div>
    </div>
  );
}
