import React, { useCallback, useContext, useState } from "react";

import { Identifiable } from "components/AssetRepository/types";
import { Selectable } from "components/ui/Table/types";

export type ListContextType<T> = {
  listData: T[];
  setListData: (newArr: T[]) => void;
  onToggleSelect: (id: string) => void;
  onToggleSelectAll: (allElementsSelected: boolean) => void;
};

// ListContextType needs a T variable. We declare T as any here so we can later overwrite it in the useContext() declaration.
// The other solution would be a generic function that returns a a context of type ListContextType<T>,
// but then we would have to declare multiple providers too, one for each list.
export const ListContext = React.createContext<ListContextType<any>>({
  listData: [],
  setListData: () => {},
  onToggleSelect: () => {},
  onToggleSelectAll: () => {},
});

export function useListContext<T>() {
  return useContext(ListContext) as ListContextType<T>;
}

export default function ListProvider<T extends Identifiable & Selectable>({
  children,
  context,
}: {
  children: React.ReactNode;
  context: React.Context<ListContextType<T>>;
}) {
  const [listData, setListData] = useState<T[]>([]);

  const onToggleSelectAll = useCallback((allElementsSelected: boolean) => {
    if (allElementsSelected) {
      setListData((listData) =>
        listData.map((item) => {
          item.selected = false;
          return item;
        })
      );
    } else {
      setListData((listData) =>
        listData.map((item) => {
          if (!item.selected) {
            item.selected = true;
          }
          return item;
        })
      );
    }
  }, []);

  const onToggleSelect = useCallback((id: string) => {
    setListData((listData) =>
      listData.map((item) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      })
    );
  }, []);

  const setListDataAndKeepSelectedItems = useCallback((newArr: T[]) => {
    setListData((listData) =>
      newArr.map((newItem) => ({
        ...newItem,
        selected:
          listData.find((oldItem) => oldItem.id === newItem.id)?.selected ||
          newItem.selected,
      }))
    );
  }, []);

  return (
    <context.Provider
      value={{
        listData,
        setListData: setListDataAndKeepSelectedItems,
        onToggleSelect,
        onToggleSelectAll,
      }}
    >
      {children}
    </context.Provider>
  );
}
