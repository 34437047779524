import { NestedCSSProperties } from "typestyle/lib/types";

export const pointerCursor: NestedCSSProperties = {
  cursor: "pointer",
};

export const forceTextOneLine: NestedCSSProperties = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export const hiddenScrollbarStyles: NestedCSSProperties = {
  "-ms-overflow-style": "none" /* for Internet Explorer, Edge */,
  "-ms-scroll-rails": "none",
  "-ms-scrollbar-base-color": "transparent",
  scrollbarWidth: "none" /* for Firefox */,
  scrollbarColor: "transparent transparent",
  $nest: {
    "&::-webkit-scrollbar": {
      display: "none" /* for Chrome, Safari, and Opera */,
      backgroundColor: "transparent",
      scrollbarColor: "transparent",
      width: "0px",
    },
  },
};

// ? TODO if the business decides this support is not enough we can use overflow: hidden with max-height set
// https://caniuse.com/css-line-clamp !
export const maxLineNumber = (lineNumber: number): NestedCSSProperties => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  lineClamp: lineNumber,
  "-webkit-line-clamp": lineNumber,
  display: "-webkit-box",
  //@ts-ignore
  "-webkit-box-orient": "vertical",
});

export const hoverShadow: NestedCSSProperties = {
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
};

type ColoredDotOptions = Omit<
  NestedCSSProperties,
  "width" | "height" | "borderRadius" | "backgroundColor" | "color"
> & {
  color: NestedCSSProperties["backgroundColor"];
  size: NestedCSSProperties["height"];
};

export const coloredDot = ({
  color,
  size,
  ...rest
}: ColoredDotOptions): NestedCSSProperties => ({
  ...rest,
  width: size,
  height: size,
  borderRadius: "100%",
  backgroundColor: color,
});

export function applyMultipleSelectors(
  css: NestedCSSProperties
): NestedCSSProperties {
  return { $nest: { "&&": css } };
}

/** ol, ul, menu reset */
export const listReset: NestedCSSProperties = {
  listStyle: "none",
  margin: 0,
  padding: 0,
};
