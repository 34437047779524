import React, { useState } from "react";

import RegisterForm from "components/auth/RegisterForm/RegisterForm";
import VerifyEmailForm from "components/auth/VerifyEmailForm/VerifyEmailForm";
import { Loading } from "components/FullScreenLoading";
import { useGetInviterCompany } from "hooks/queries/useAuth";
import AuthenticationPageLayout from "pages/AuthenticationPageLayout";

export default function RegisterPage() {
  const [registrationEmail, setRegistrationEmail] = useState<string | null>(
    null
  );

  const { data: inviterCompany, isLoading: companyLoading } =
    useGetInviterCompany();

  if (companyLoading) {
    return <Loading />;
  }

  return (
    <AuthenticationPageLayout
      imageSrc={
        registrationEmail
          ? "/illustrations/verify_email.svg"
          : "/illustrations/login_page_people.svg"
      }
    >
      {registrationEmail ? (
        <VerifyEmailForm email={registrationEmail} />
      ) : (
        <RegisterForm
          inviterCompany={inviterCompany}
          onSubmit={(email) => {
            setRegistrationEmail(email);
          }}
        />
      )}
    </AuthenticationPageLayout>
  );
}
