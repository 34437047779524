import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { spacing } from "shared/styles/spacing";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  form: {},
  detailsParagraph: {
    ...typography.BODY1,
    marginBottom: "32px",
  },
  textAreaContainer: {},
  textAreaTitle: {
    ...typography.SUBTITLE1,
  },
  imagesContainerTitle: {
    color: neutralColors.GRAY5,
    ...typography.BODY2,
  },
  headerImage: {
    width: "100%",
    height: "38vh",
    maxHeight: "412px",
    border: `3px solid ${primaryColors.WHITE}`,
    position: "relative",
    backgroundColor: neutralColors.GRAY0,
  },
  cyanCloud: {
    position: "absolute",
    color: utilityColors.CYAN,
    top: 0,
    width: "100%",
    zIndex: 1,
  },
  grayCloud: {
    position: "absolute",
    color: neutralColors.GRAY1,
    top: 0,
    width: "100%",
  },
  imagesContainer: {
    position: "relative",
    height: "fit-content",
    marginBottom: "120px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    padding: "25px 0",
  },
  button: {
    marginRight: "3%",
  },
  youtubeInputContainer: {
    paddingBottom: "32px",
  },
  backgroundSpan: {
    ...typography.CAPTION,
    position: "absolute",
    right: "0",
    bottom: "-28px",
    color: neutralColors.GRAY5,
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    position: "relative",
    $nest: {
      "& label": {
        marginBottom: spacing.LABEL_MARGIN,
      },
    },
  },
  inputSpan: {
    position: "absolute",
    right: "0",
    bottom: "10px",
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
  youtubeWarning: {
    position: "absolute",
    right: "0",
    bottom: "10px",
    ...typography.CAPTION,
    color: utilityColors.RED,
  },
  youtubeTitleContainer: {
    display: "flex",
    alignItems: "end",
    $nest: {
      "& label": {
        marginBottom: spacing.LABEL_MARGIN,
      },
    },
  },
  youtubeHelpText: {
    ...typography.BODY2,
    whiteSpace: "pre-line",
  },
  multiTagLabel: {
    ...typography.SUBTITLE2,
    paddingBottom: "8px",
  },
  multitagLabelInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    paddingBottom: "8px",
  },
});
