import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { clickable } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    height: "40px",
    ...clickable,
  },
  text: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
  columnName: {
    ...typography.CAPTION,
    color: primaryColors.BLACK,
    marginLeft: "8px",
  },
  clearButton: {
    color: neutralColors.GRAY5,
    margin: "4px",
  },
  closeIcon: {
    margin: "8px",
    width: "13px",
    color: neutralColors.GRAY5,
    textAlign: "center",
  },
});

export default styles;
