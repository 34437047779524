import React from "react";

import { neutralColors, utilityColors } from "@pulsemarket/constants";
import { classes, stylesheet } from "typestyle";

type Props = {
  className?: string;
  isRead: boolean;
};

const itemStyles = stylesheet({
  circle: {
    width: "7px",
    height: "7px",
    borderRadius: "100%",
    marginRight: "15px",
    background: utilityColors.ORANGE,
    $nest: {
      "&.read": {
        background: neutralColors.GRAY3,
      },
    },
  },
});

const ReadCircle = ({ isRead, className }: Props) => {
  return (
    <div className={classes(itemStyles.circle, isRead && "read", className)} />
  );
};

export default ReadCircle;
