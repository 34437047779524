import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

import { typography } from "../../../shared/styles/typography";

const styles = stylesheet({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "100%",
    background: neutralColors.GRAY0,
    borderRadius: "8px",
    backgroundClip: "content-box",
    marginBottom: "24px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    wordBreak: "break-word",
    margin: "24px",
  },
  headerText: {
    ...typography.CAPTION,
    color: primaryColors.BLACK,
  },
  text: {
    margin: "3px 0px",
  },
  errorText: {
    ...typography.CAPTION,
    color: utilityColors.RED,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default styles;
