import React from "react";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@pulsemarket/constants";
import SvgIcon from "components/ui/SvgIcon";

import { styles } from "./AuthenticationPageLayout.styles";
export default function AuthenticationPageLayout({
  imageSrc,
  children,
}: {
  imageSrc: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.root}>
      <div className={styles.leftSide}>
        <PulseIconLoginRedirect containerClassName={styles.iconContainer} />
        <div className={styles.imageContainer}>
          <img
            className={styles.image}
            src={imageSrc}
            alt="auth_page_illustration"
          />
        </div>
      </div>
      <div className={styles.rightSide}>
        <PulseIconLoginRedirect
          containerClassName={styles.rightSideIconContainer}
        />

        <div className={styles.formContainer}>{children}</div>
      </div>
    </div>
  );
}

function PulseIconLoginRedirect({
  containerClassName,
}: {
  containerClassName: string;
}) {
  const history = useHistory();

  return (
    <div className={containerClassName}>
      <SvgIcon
        className={styles.icon}
        src="/logo/pulse_logo_dark.svg"
        onClick={() => {
          if (history.location.pathname !== ROUTES.LOGIN) {
            history.push("/");
          }
        }}
      />
    </div>
  );
}
