import React from "react";

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "@material-ui/core";
import { RadioLabelProps } from "components/ui/RadioButton/RadioLabel";
import RadioLabel from "components/ui/RadioButton/RadioLabel";
import { classes } from "typestyle";

import styles from "./PulseRadioGroup.styles";

export type RadioChoice = RadioLabelProps & RadioProps;

type PulseRadioGroupProps = RadioGroupProps & {
  className?: string;
  choices: RadioChoice[];
  classes?: RadioGroupProps["classes"] & {
    choiceWrapper?: string;
  };
};

const RadioButtonGroup = ({
  choices,
  value,
  onChange,
  className,
  classes: classesProp,
  ...props
}: PulseRadioGroupProps) => {
  const { choiceWrapper, ...rootClasses } = classesProp || {};
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={classes(styles.root, className)}
      classes={rootClasses}
      {...props}
    >
      {choices.map((choice) => (
        <FormControlLabel
          disabled={choice.disabled}
          classes={{ root: styles.choiceLabelRoot }}
          className={classes(styles.choiceWrapper, classesProp?.choiceWrapper)}
          key={choice.id}
          value={choice.id}
          control={<Radio />}
          label={<RadioLabel {...choice} />}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioButtonGroup;
