import { useContext } from "react";

import ConfirmationModalBody from "components/ConfirmationModalBody";
import ModalContext from "contexts/ModalContext";
import useSetGroupInvitationStatus from "hooks/queries/useSetGroupInvitationStatus";
import { GroupInvitationStatus } from "shared/model";

export function DenyGroupMember({ invitationId }: { invitationId: string }) {
  const { closeModal } = useContext(ModalContext);
  const { mutate: setGroupInvitationStatus } = useSetGroupInvitationStatus();

  return (
    <ConfirmationModalBody
      title="Decline"
      buttonText="Yes, Decline"
      action={async () => {
        try {
          setGroupInvitationStatus({
            groupInvitationId: invitationId,
            status: GroupInvitationStatus.Removed,
          });
        } catch (err) {
          console.log(err);
        }
      }}
      onClose={closeModal}
    >
      Are you sure you want to decline this company to join your group?
    </ConfirmationModalBody>
  );
}

export function RemoveGroupMemberModal({
  invitationId,
}: {
  invitationId: string;
}) {
  const { closeModal } = useContext(ModalContext);
  const { mutate: setGroupInvitationStatus } = useSetGroupInvitationStatus();

  return (
    <ConfirmationModalBody
      title="Remove"
      buttonText="Yes, Remove"
      action={async () => {
        try {
          setGroupInvitationStatus({
            groupInvitationId: invitationId,
            status: GroupInvitationStatus.Removed,
          });
        } catch (err) {
          console.log(err);
        }
      }}
      onClose={closeModal}
    >
      Are you sure you want to remove this company?
    </ConfirmationModalBody>
  );
}
