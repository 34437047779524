import { useContext, useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useHistory } from "react-router-dom";

import { Grid, GridProps, GridSize } from "@material-ui/core";
import { ROUTES } from "@pulsemarket/constants";
import { Loading } from "components/FullScreenLoading";
import GetStartedBox from "components/GetStartedBox";
import CreateGroup from "components/PulsePage/Groups/createGroup";
import JoinableGroupCard from "components/PulsePage/Groups/GroupCard/Cards/JoinableGroupCard";
import OwnGroupCard from "components/PulsePage/Groups/GroupCard/Cards/OwnGroupCard";
import BlankCard from "components/ui/BlankCard";
import Button from "components/ui/Button";
import ModalContext from "contexts/ModalContext";
import useJoinedGroups from "hooks/queries/useJoinedGroups";
import useOwnGroups from "hooks/queries/useOwnGroups";

import styles from "./MyGroupsStyles";

const groupBoxGridSizes = {
  xs: 6 as GridSize,
  lg: 4 as GridSize,
  xl: 3 as GridSize,
};

const groupBoxContainerProps: Partial<GridProps> = {
  container: true,
  spacing: 2,
  direction: "row",
  wrap: "nowrap",
  alignItems: "center",
  className: styles.grid,
};

const MyGroups = () => {
  const history = useHistory();

  const {
    data: ownGroupPages,
    fetchNextPage: fetchNextOwnGroups,
    hasNextPage: hasMoreOwnGroups,
    isFetchingNextPage: isFetchingOwnGroups,
    isLoading: ownGroupsLoading,
  } = useOwnGroups();
  const {
    data: joinedGroupPages,
    fetchNextPage: fetchNextJoinedGroups,
    hasNextPage: hasMoreJoinedGroups,
    isFetchingNextPage: isFetchingJoinedGroups,
    isLoading: joinedGroupsLoading,
  } = useJoinedGroups();

  const { openModal, closeModal } = useContext(ModalContext);

  const ownGroups = useMemo(
    () => (ownGroupPages?.pages || []).flatMap((group) => group),
    [ownGroupPages?.pages]
  );
  const joinedGroups = useMemo(
    () => (joinedGroupPages?.pages || []).flatMap((group) => group),
    [joinedGroupPages?.pages]
  );

  const [ownGroupsInfiniteRef, { rootRef: ownGroupsRootRef }] =
    useInfiniteScroll({
      loading: isFetchingOwnGroups,
      hasNextPage: !!hasMoreOwnGroups,
      onLoadMore: fetchNextOwnGroups,
    });
  const [joinedGroupsInfiniteRef, { rootRef: joinedGroupsRootRef }] =
    useInfiniteScroll({
      loading: isFetchingJoinedGroups,
      hasNextPage: !!hasMoreJoinedGroups,
      onLoadMore: fetchNextJoinedGroups,
    });

  if (!ownGroupPages || !joinedGroupPages) {
    return <Loading />;
  }

  return (
    <div className={styles.mainContainer}>
      <h3>My Groups</h3>

      {ownGroupsLoading ? (
        <Loading isWindowed />
      ) : !ownGroups.length ? (
        <GetStartedBox
          name="Create new group"
          title="Create new group"
          caption="Create a new group and invite people with the same goal to connect and build a community"
          type="button"
          action={() =>
            openModal(
              <CreateGroup
                onSuccess={(group) => {
                  closeModal();
                  history.push(ROUTES.GROUP(group.id));
                }}
                onCancel={closeModal}
              />,
              { props: { maxWidth: "lg" } }
            )
          }
          lockedButtonProps={{
            color: "orange",
            variant: "normal",
            name: "Create new Group",
            "data-cy": "create-new-group",
          }}
          image={{ src: "/illustrations/illustration_create_group.svg" }}
        />
      ) : (
        <Grid {...groupBoxContainerProps} ref={ownGroupsRootRef}>
          <Grid item {...groupBoxGridSizes} key={"createGroupGrid"}>
            <BlankCard
              className={styles.createGroup}
              text={"+ Create Group"}
              {...{
                "data-cy": "create-new-group",
              }}
              onClick={() =>
                openModal(
                  <CreateGroup
                    onSuccess={(group) => {
                      closeModal();
                      history.push(ROUTES.GROUP(group.id));
                    }}
                    onCancel={closeModal}
                  />,
                  { props: { maxWidth: "lg" } }
                )
              }
            />
          </Grid>
          {ownGroups.map((group) => (
            <Grid item {...groupBoxGridSizes} key={group.id}>
              <OwnGroupCard group={group} />
            </Grid>
          ))}
          {hasMoreOwnGroups && (
            <Grid item {...groupBoxGridSizes} ref={ownGroupsInfiniteRef}>
              <Loading isWindowed />
            </Grid>
          )}
        </Grid>
      )}
      <h3>Member of</h3>
      {joinedGroupsLoading || !joinedGroups ? (
        <Loading isWindowed />
      ) : !joinedGroups.length ? (
        <GetStartedBox
          name="Discover groups"
          title="Discover groups"
          caption="Find other communities that share and support your goals"
          type="button"
          action={() => history.push("/discovergroups")}
          lockedButtonProps={{
            color: "orange",
            variant: "normal",
          }}
          image={{ src: "/illustrations/illustration_discover_group.svg" }}
        />
      ) : (
        <>
          <Grid {...groupBoxContainerProps} ref={joinedGroupsRootRef}>
            {joinedGroups.map((group) => (
              <Grid item {...groupBoxGridSizes} key={group.id}>
                <JoinableGroupCard group={group} />
              </Grid>
            ))}
            {hasMoreJoinedGroups && (
              <Grid item {...groupBoxGridSizes} ref={joinedGroupsInfiniteRef}>
                <Loading isWindowed />
              </Grid>
            )}
          </Grid>
          <Button
            className={styles.discoverGroupsButton}
            variant="outlined"
            onClick={() => history.push("/discovergroups")}
          >
            Discover Groups
          </Button>
        </>
      )}
    </div>
  );
};

export default MyGroups;
