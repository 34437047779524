import React, { useContext, useState } from "react";

import { Loading } from "components/FullScreenLoading";
import Button from "components/ui/Button";
import Input from "components/ui/Input/Input";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import ModalHeader from "components/ui/ModalHeader";
import ModalContext from "contexts/ModalContext";
import useCreateCompanyList from "hooks/queries/useCreateCompanyList";
import { ApiError } from "shared/client";
import { classes } from "typestyle";

import styles from "./CreateListStyles";

export interface CreateListProps {
  onSuccess?: () => void;
  onCancel?: () => void;
}

export default function CreateList({
  onSuccess = () => {},
  onCancel = () => {},
}: CreateListProps) {
  const [name, setName] = useState<string>("");
  const { mutateAsync: createCompanyList, isLoading } = useCreateCompanyList({
    onSuccess,
  });
  const { openModal, closeModal } = useContext(ModalContext);
  async function createList() {
    if (!name) {
      // todo this should be a form instead, so we can use the required attribute, that is cleaner
      return;
    }
    try {
      await createCompanyList(name);
      onSuccess();
    } catch (err) {
      if (err instanceof ApiError) {
        if (err.status === 409) {
          openModal(
            <SimpleModalBody
              onClose={closeModal}
              title="Name Already exists"
              buttons={[
                {
                  variant: "normal",
                  color: "gray",
                  children: "Cancel",
                  onClick: closeModal,
                },
                {
                  variant: "normal",
                  color: "orange",
                  children: "Ok",
                  onClick: () => {
                    openModal(
                      <CreateList
                        onSuccess={closeModal}
                        onCancel={closeModal}
                      />
                    );
                  },
                },
              ]}
            >
              The name
              <span className={styles.warningCompanyName}>
                &nbsp;{name}&nbsp;
              </span>
              is already taken. Please choose a different name.
            </SimpleModalBody>
          );
        }
      }
    }
  }

  return (
    <div className={styles.CreateList}>
      <div className={styles.contentWrapper}>
        <ModalHeader title="Creating a new List" onClose={onCancel} />
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Input
              placeholder="Type Name"
              className={styles.input}
              fullWidth={true}
              value={name}
              onChange={(event) => setName(event.target.value)}
            />

            <div className={styles.actions}>
              <Button
                className={classes(styles.button, styles.createListButton)}
                variant="normal"
                color="orange"
                onClick={createList}
              >
                <span className={classes(styles.buttonLabel)}>Create List</span>
              </Button>
              <Button
                className={classes(styles.button)}
                variant="normal"
                color="gray"
                onClick={onCancel}
              >
                <span className={classes(styles.buttonLabel)}>Cancel</span>
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
