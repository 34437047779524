import { neutralColors, utilityColors } from "@pulsemarket/constants";
import mime from "mime-types";

// TODO: replace `/icons/arrow_down.svg` and `/icons/arrow_up.svg` in all components
export function getExpandableIconSource(isExpanded?: boolean) {
  const arrowIconSrc = !isExpanded
    ? "/icons/arrow_down.svg"
    : "/icons/arrow_up.svg";
  return arrowIconSrc;
}

type IconWithColor = {
  src: string;
  color: string;
};

export function getIconForFile({
  extension,
  mimeType,
}: {
  extension?: string;
  mimeType?: string;
}): IconWithColor {
  const calculatedExtension =
    extension || (mimeType && mime.extension(mimeType));
  if (calculatedExtension && (FILE_ICONS as any)[calculatedExtension]) {
    return (FILE_ICONS as any)[calculatedExtension];
  }

  if (mimeType?.startsWith("image")) {
    return FILE_ICONS.image;
  }

  if (mimeType?.startsWith("video")) {
    return FILE_ICONS.video;
  }

  if (mimeType?.startsWith("audio")) {
    return FILE_ICONS.audio;
  }

  return FILE_ICONS.unknown;
}

export const FILE_COLORS = {
  image: utilityColors.LILAC,
  video: utilityColors.LILAC,
  audio: utilityColors.LILAC,
  /** maybe document*/
  ai: utilityColors.ORANGE,
  compressed: utilityColors.YELLOW,
  word: utilityColors.BLUE,
  pdf: utilityColors.RED,
  ppt: utilityColors.RED,
  excel: utilityColors.GREEN,
  unknown: neutralColors.GRAY5,
};

/**
 * SVG sources for files based on their extension
 **/
export const FILE_ICONS = {
  zip: { src: "/icons/zipfile_icon.svg", color: FILE_COLORS.compressed },
  xls: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlm: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xla: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlc: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlt: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlw: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlsb: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlsm: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xltm: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlam: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  xlsx: { src: "/icons/xlsx_icon.svg", color: FILE_COLORS.excel },
  wma: { src: "/icons/wma_icon.svg", color: FILE_COLORS.audio },
  txt: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  text: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  conf: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  def: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  list: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  log: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  in: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  ini: { src: "/icons/txt_icon.svg", color: FILE_COLORS.word },
  svg: { src: "/icons/svg_icon.svg", color: FILE_COLORS.image },
  svgz: { src: "/icons/svg_icon.svg", color: FILE_COLORS.image },
  psd: { src: "/icons/psd_icon.svg", color: FILE_COLORS.ppt },
  ppt: { src: "/icons/ppt_icon.svg", color: FILE_COLORS.ppt },
  pps: { src: "/icons/ppt_icon.svg", color: FILE_COLORS.ppt },
  pot: { src: "/icons/ppt_icon.svg", color: FILE_COLORS.ppt },
  pptx: { src: "/icons/pptx_icon.svg", color: FILE_COLORS.ppt },
  png: { src: "/icons/png_icon.svg", color: FILE_COLORS.image },
  pdf: { src: "/icons/pdf_icon.svg", color: FILE_COLORS.pdf },
  mp4: { src: "/icons/mp4_icon.svg", color: FILE_COLORS.video },
  mp4v: { src: "/icons/mp4_icon.svg", color: FILE_COLORS.video },
  mpg4: { src: "/icons/mp4_icon.svg", color: FILE_COLORS.video },
  mp3: { src: "/icons/mp3_icon.svg", color: FILE_COLORS.audio },
  mov: { src: "/icons/mov_icon.svg", color: FILE_COLORS.video },
  qt: { src: "/icons/mov_icon.svg", color: FILE_COLORS.video },
  jp2: { src: "/icons/jpg_icon.svg", color: FILE_COLORS.image },
  jpg2: { src: "/icons/jpg_icon.svg", color: FILE_COLORS.image },
  jpg: { src: "/icons/jpg_icon.svg", color: FILE_COLORS.image },
  jpeg: { src: "/icons/jpeg_icon.svg", color: FILE_COLORS.image },
  jpe: { src: "/icons/jpeg_icon.svg", color: FILE_COLORS.image },
  html: { src: "/icons/html_icon.svg", color: FILE_COLORS.ai },
  htm: { src: "/icons/html_icon.svg", color: FILE_COLORS.ai },
  shtml: { src: "/icons/html_icon.svg", color: FILE_COLORS.ai },
  gif: { src: "/icons/gif_icon.svg", color: FILE_COLORS.image },
  docx: { src: "/icons/docx_icon.svg", color: FILE_COLORS.word },
  doc: { src: "/icons/docx_icon.svg", color: FILE_COLORS.word },
  dot: { src: "/icons/docx_icon.svg", color: FILE_COLORS.word },
  avi: { src: "/icons/avi_icon.svg", color: FILE_COLORS.video },
  ai: { src: "/icons/ai_icon.svg", color: FILE_COLORS.ai },
  eps: { src: "/icons/ai_icon.svg", color: FILE_COLORS.ai },
  ps: { src: "/icons/ai_icon.svg", color: FILE_COLORS.ai },
  "7z": { src: "/icons/7z_icon.svg", color: FILE_COLORS.compressed },
  unknown: { src: "/icons/file_undefined.svg", color: FILE_COLORS.unknown },
  image: { src: "/icons/image_icon.svg", color: FILE_COLORS.image },
  video: { src: "/icons/video_icon.svg", color: FILE_COLORS.video },
  audio: { src: "/icons/file_undefined_icon.svg", color: FILE_COLORS.audio },
};

export const DEFAULT_USER_AVATAR = "/icons/user_avatar.svg";
export const DEFAULT_COMPANY_AVATAR = "/icons/company_avatar.svg";

export const ESG_PASSPORT_ICON: IconWithColor = {
  color: utilityColors.GREEN,
  src: "/icons/ESG_passport.svg",
};
