import { useContext } from "react";

import { AssetDto } from "@pulsemarket/api-client";
import ConfirmationModalBody from "components/ConfirmationModalBody";
import ModalContext from "contexts/ModalContext";
import { filesize } from "filesize";
import { getIconForFile } from "shared/constants/icons";
import { classes } from "typestyle";

import AttachmentRow, { AttachmentStatus } from "./AttachmentRow";
import styles from "./Attachments.styles";

export type AttachmentsProps = {
  className?: string;
  attachments?: (AssetDto & { status?: AttachmentStatus })[];
  onDownload?: (attachment: AssetDto) => void;
  onDelete?: (attachment: AssetDto) => void;
  disableDelete?: boolean | ((attachment: AssetDto) => boolean | undefined);
  disableDownload?: boolean | ((attachment: AssetDto) => boolean | undefined);
  deleteConfirmationText?: string;
  foreceDelete?: boolean;
};

export default function Attachments({
  className,
  attachments = [],
  onDownload = () => {},
  onDelete = () => {},
  disableDelete,
  disableDownload,
  deleteConfirmationText,
  foreceDelete,
}: AttachmentsProps) {
  const { openModal, closeModal } = useContext(ModalContext);

  if (!attachments.length) {
    return null;
  }

  return (
    <div className={classes(className)}>
      {attachments?.map((attachment) => {
        return (
          <AttachmentRow
            key={attachment.id}
            className={styles.attachmentRow}
            name={attachment.name}
            size={attachment.size ? (filesize(attachment.size) as string) : ""}
            onDownload={() => {
              onDownload(attachment);
            }}
            isDeleteIconHidden={
              typeof disableDelete === "function"
                ? disableDelete(attachment)
                : disableDelete
            }
            isDownloadIconHidden={
              typeof disableDownload === "function"
                ? disableDownload(attachment)
                : disableDownload
            }
            status={attachment.status || "default"}
            icon={getIconForFile(attachment)}
            onRemove={() => {
              foreceDelete
                ? onDelete(attachment)
                : openModal(
                    <ConfirmationModalBody
                      title="Delete Attachment"
                      action={() => onDelete(attachment)}
                      onClose={closeModal}
                    >
                      <p>Are you sure you want to delete this Attachment?</p>
                      <p>{deleteConfirmationText}</p>
                    </ConfirmationModalBody>
                  );
            }}
          />
        );
      })}
    </div>
  );
}
