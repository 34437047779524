import Link from "components/ui/Link";

const permissionMessages = {
  pulsePassport: "Only the Admin can access Pulse Passport.",
  companyName: (
    <>
      Company name can be updated in{" "}
      <Link url="/company/edit/info">Company account</Link> by the Admin.
    </>
  ),
  teamMembers: "Only the Admin can manage team members.",
};

export default permissionMessages;
