import React, { useContext } from "react";

import { Dialog, DialogProps, ModalProps } from "@material-ui/core";
import ModalContext, { ModalHistoryEntry } from "contexts/ModalContext";
import { classes } from "typestyle";

import { dialogTitleId } from "./Header";
import styles from "./Modal.styles";

export type CustomizableDialogProps = Omit<ModalProps, "children" | "open"> &
  Pick<
    DialogProps,
    | "className"
    | "disableBackdropClick"
    | "disableEscapeKeyDown"
    | "disableEnforceFocus"
    | "maxWidth"
    | "BackdropProps"
    | "PaperProps"
  > & {
    paperClassName?: string;
  };

export const unclosableDialogProps: CustomizableDialogProps = {
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
};

/**
 * You shouldn't directly render this component. To open and manage a modal use the `openModal` function from `ModalContext`.
 *
 * @example
 * const { openModal, closeModal } = useContext(ModalContext);
 * openModal(<div>content</div>, props)
 */
export default function PulseModal({
  history,
}: {
  history: ModalHistoryEntry[];
}) {
  const { closeModal } = useContext(ModalContext);

  return (
    <>
      {history.map(
        (
          {
            content,
            props: {
              paperClassName,
              disableBackdropClick,
              disableEscapeKeyDown,
              ...props
            },
          },
          i
        ) => {
          const isLast = i === history.length - 1;

          return (
            <Dialog
              key={i}
              open={isLast}
              onClose={(_, reason) => {
                if (
                  (reason === "backdropClick" && disableBackdropClick) ||
                  (reason === "escapeKeyDown" && disableEscapeKeyDown)
                ) {
                  return false;
                }
                closeModal();
              }}
              classes={{
                root: props.className,
                paper: classes(styles.containerPaper, paperClassName),
              }}
              aria-labelledby={dialogTitleId}
              {...props}
            >
              {content}
            </Dialog>
          );
        }
      )}
    </>
  );
}
