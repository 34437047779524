import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { getAssetBlobUrls } from "shared/client";

export default function useDownloadAssets() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const downloadAssets = useCallback(
    async ({
      assetIds,
      openInNewTab = false,
      fileNamePrefix,
    }: {
      assetIds: string[];
      openInNewTab?: boolean;
      fileNamePrefix?: string;
    }) => {
      const assetsNotCachedOrStale = assetIds?.filter((assetId) => {
        const queryKeyCachedAt = queryClient.getQueryState(
          queryKeys.blobUrl(assetId)
        )?.dataUpdatedAt;

        // Cache is valid for 10 seconds
        const isStale =
          !queryKeyCachedAt ||
          (queryKeyCachedAt &&
            new Date().getTime() - queryKeyCachedAt > 10 * 1000);

        return !!isStale && assetId;
      });

      // Fetch the blob urls for the assets that are not cached and set them in the cache
      if (!!assetsNotCachedOrStale?.length) {
        setIsLoading(true);
        const data = await getAssetBlobUrls(
          assetsNotCachedOrStale,
          openInNewTab,
          fileNamePrefix
        );
        if (data) {
          data.forEach((asset) => {
            queryClient.setQueryData(
              queryKeys.blobUrl(asset.assetId),
              asset.blobUrl
            );
          });
        }
        setIsLoading(false);
      }

      // Download each files separately
      assetIds.forEach((asset) => {
        const blobUrl = queryClient.getQueryData(
          queryKeys.blobUrl(asset)
        ) as string;
        window.open(blobUrl);
      });
    },
    [queryClient]
  );

  return { downloadAssets, isLoading };
}

// write a documentation for the below code snippet
export function usePreDownloadAssets({
  assetIds,
  openInNewTab = false,
}: {
  assetIds: string[];
  openInNewTab?: boolean;
}) {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const assetsNotCached = (assetIds || []).filter((assetId) => {
        return !queryClient.getQueryData(queryKeys.blobUrl(assetId)) && assetId;
      });
      setIsLoading(true);
      const data = await getAssetBlobUrls(assetsNotCached, openInNewTab);
      if (data) {
        data.forEach((asset) => {
          queryClient.setQueryData(
            queryKeys.blobUrl(asset.assetId),
            asset.blobUrl
          );
        });

        setIsLoading(false);
      }
    })();
  }, [assetIds, openInNewTab, queryClient]);

  const downloadAssets = useCallback(async () => {
    let assetsNotCached: string[];
    do {
      assetsNotCached = (assetIds || []).filter((assetId) => {
        return !queryClient.getQueryData(queryKeys.blobUrl(assetId)) && assetId;
      });
      await wait(1000);
    } while (assetsNotCached.length > 0);

    assetIds.forEach((asset) => {
      const blobUrl = queryClient.getQueryData(queryKeys.blobUrl(asset));
      window.open(blobUrl as string);
    });
  }, [assetIds, queryClient]);

  return { downloadAssets, isLoading };
}

function wait(ms: number) {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
