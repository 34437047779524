import React from "react";

import { primaryColors } from "@pulsemarket/constants";
import Button from "components/ui/Button";
import ModalActions from "components/ui/Modal/Actions/ModalActions";
import ModalHeader from "components/ui/Modal/Header/ModalHeader";
import { zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

import modalHeaderStyles from "../../ui/Modal/Header/ModalHeader.styles";

const styles = stylesheet({
  title: {
    $nest: {
      [`& .${modalHeaderStyles.title}`]: {
        textAlign: "center",
        ...typography.HEADLINE6,
        color: primaryColors.PURPLE2,
        justifyContent: "center",
      },
      "& .MuiGrid-item:first-child": {
        margin: "0 auto",
      },
    },
  },
  image: {
    width: "84px",
    display: "block",
    margin: "0 auto",
  },
  text: {
    textAlign: "center",
    ...typography.BODY2,
    color: primaryColors.BLACK,
    ...zeroPaddingMargin,
    margin: "24px 0px",
  },
});

type WelcomePassportModalProps = {
  onCancel: () => void;
  onNext: () => void;
  title: string;
  text: string;
};

export default function WelcomePassportModal({
  onCancel,
  onNext,
  title,
  text,
}: WelcomePassportModalProps) {
  return (
    <div>
      <ModalHeader className={styles.title} onClose={onCancel}>
        Welcome
      </ModalHeader>
      <p className={styles.text}>{title}</p>
      <img
        src="/illustrations/illustration_edit.svg"
        alt=""
        className={styles.image}
      />
      <p className={styles.text}>{text}</p>
      <ModalActions
        actions={[
          <Button
            color="gray"
            variant="normal"
            size="small"
            key="cancel"
            onClick={onCancel}
          >
            Cancel
          </Button>,
          <Button
            color="orange"
            variant="normal"
            size="small"
            key="next"
            onClick={onNext}
          >
            Next
          </Button>,
        ]}
      />
    </div>
  );
}
