import { useQuery, UseQueryOptions } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { RfpDto } from "@pulsemarket/api-client";
import { ApiError, getRFPs } from "shared/client";
import { RfpFilters } from "shared/model";

export default function useRFPs<T = RfpDto[]>(
  options?: UseQueryOptions<
    RfpDto[],
    ApiError,
    T,
    ReturnType<typeof queryKeys.rfps>
  > & {
    filters?: RfpFilters;
  }
) {
  return useQuery(
    queryKeys.rfps(options?.filters),
    () => getRFPs(options?.filters),
    {
      placeholderData: [],
      ...options,
    }
  );
}
