import React, { useImperativeHandle, useState } from "react";

import {
  Tooltip as MuITooltip,
  TooltipProps as MuiTooltipProps,
} from "@material-ui/core";
import { primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { classes, style } from "typestyle";

const tooltipStyles = ({
  padding,
  color,
  background,
  maxWidth,
}: {
  padding: number;
  color: string;
  background: string;
  maxWidth: number;
}) =>
  style({
    ...typography.CAPTION,
    backgroundColor: background,
    color: color,
    padding: padding + "px",
    maxWidth: `${maxWidth}px`,
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  });

const arrow = (background: string) =>
  style({
    color: background,
  });

const popper = style({
  pointerEvents: "auto",
});

export const backdropStyle = (onBackdropClick: boolean) =>
  style({
    zIndex: 10,
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.25)",
    pointerEvents: onBackdropClick ? "auto" : "none",
  });

export interface TooltipRefType {
  hide: Function;
}

// todo reuse
export const purpleTooltipProps: Pick<
  TooltipProps,
  "background" | "color" | "hideOnBlur" | "className"
> = {
  background: primaryColors.PURPLE3,
  color: primaryColors.WHITE,
  hideOnBlur: true,
  className: style({
    $nest: {
      "&&": {
        ...typography.BODY2,
      },
    },
  }),
};

export type TooltipProps = MuiTooltipProps & {
  hideArrow?: boolean;
  backdrop?: boolean;
  noPadding?: boolean;
  background?: string;
  hideOnBlur?: boolean;
  showOnClick?: boolean;
  maxWidth?: number;
  onBackdropClick?: Function;
};

export default React.forwardRef<TooltipRefType, TooltipProps>(function Tooltip(
  {
    backdrop,
    noPadding,
    className,
    hideArrow,
    color = primaryColors.BLACK,
    background = primaryColors.WHITE,
    hideOnBlur = false,
    showOnClick = false,
    maxWidth,
    onBackdropClick,
    ...rest
  },
  ref
) {
  const [show, setShow] = useState(rest.open ?? false);

  useImperativeHandle(ref, () => ({
    hide: () => {
      setShow(false);
    },
  }));

  return (
    <>
      {(rest.open || show) && backdrop && (
        <div
          className={backdropStyle(!!onBackdropClick || !!showOnClick)}
          onClick={() => {
            onBackdropClick && onBackdropClick();
            showOnClick && setShow(false);
          }}
        />
      )}

      <MuITooltip
        {...rest}
        {...(hideOnBlur && {
          open: show,
          disableHoverListener: true,
          onMouseDown: () => setShow(false),
          onMouseEnter: () => setShow(true),
          onMouseLeave: () => setShow(false),
          interactive: true,
        })}
        {...(showOnClick && {
          open: show,
          onMouseDown: () => setShow(!show),
          interactive: true,
        })}
        classes={{
          tooltip: classes(
            tooltipStyles({
              padding: noPadding ? 0 : 8,
              color,
              background,
              maxWidth: maxWidth ?? 250,
            }),
            className
          ),
          arrow: arrow(background),
          popper,
        }}
        arrow={!hideArrow}
        TransitionProps={{ exit: false }}
      />
    </>
  );
});
