import React from "react";

import { ButtonForModalWithSelectableItems } from "components/assets/RepositoryModal";
import { Loading } from "components/FullScreenLoading";
import ModalHeader from "components/ui/Modal/Header";
import { Selectable } from "components/ui/Table/types";
import { VendorSearchSideMenuItem } from "components/VendorSearch/Sidebar/SideBar";
import { useVendorSearchContext } from "components/VendorSearch/VendorFilterProvider";
import { useListContext } from "contexts/List/ListProvider";
import useCompanies from "hooks/queries/useCompanies";
import useSearchGroups from "hooks/queries/useSearchGroups";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffects";
import { CompanyCardDto, CompanyDto } from "shared/model";
import { style } from "typestyle";

import CompanyListSearchBody from "./CompanyListSearch";
import GroupSearchBody from "./GroupSearch/GroupSearchBody";
import VendorSearchLayout from "./Layout";
import SupplierSearchBody from "./SupplierSearch";

export const SUPPLIER_SEARCH_URL_PATH = "/vendorsearch/suppliersearch";

export type VendorSearchFeatures =
  | "discover_groups"
  | "company_lists"
  | "supplier_search";

type VendorSearchProps = {
  actions?: ButtonForModalWithSelectableItems<CompanyDto>[];
  activeTab: VendorSearchSideMenuItem;
  compactCards?: boolean;
  selectableCards?: boolean;
  disableListContext?: boolean;
  onClose?: () => void;
  companyDisabled?: (company?: CompanyCardDto) => boolean | undefined;
  companyDisabledReason?: string;
};

export default function VendorSearch({
  actions,
  activeTab,
  compactCards,
  selectableCards,
  disableListContext,
  onClose,
  companyDisabled,
  companyDisabledReason,
}: VendorSearchProps) {
  // The only difference between SupplierSearch and CompanyListSearch
  // is an optional companyListId (but it can be null for the CompanyListSearch too)

  const { filters, companyListId } = useVendorSearchContext();

  const isSupplierSearch = [
    VendorSearchSideMenuItem.Vendors,
    VendorSearchSideMenuItem.CompanyLists,
  ].includes(activeTab);

  const { isLoading, data } = useCompanies({
    filters,
    companyListId,
    disabled: !isSupplierSearch && !companyListId,
  });
  const { isLoading: groupsLoading, data: groupsData } = useSearchGroups();

  const { setListData, listData } = useListContext<CompanyDto & Selectable>();

  useDeepCompareEffect(() => {
    if (disableListContext || !data) {
      return;
    }
    setListData(data);
  }, [data, setListData, disableListContext]);

  return (
    <VendorSearchLayout
      showHeaderCreateButton={
        activeTab !== VendorSearchSideMenuItem.CompanyLists
      }
      showHeaderFilters={isSupplierSearch || !!companyListId}
      actions={actions}
      activeTab={activeTab}
    >
      {onClose && (
        <ModalHeader
          onClose={onClose}
          className={style({
            position: "absolute",
            top: "12px",
            right: "12px",
          })}
        />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!isSupplierSearch ? (
            groupsLoading ? (
              <Loading />
            ) : (
              <GroupSearchBody groups={groupsData} />
            )
          ) : !companyListId ? (
            <SupplierSearchBody
              companies={!disableListContext ? listData : data}
              compactCards={compactCards}
              selectableCards={selectableCards}
              companyDisabled={companyDisabled}
              disabledReason={companyDisabledReason}
            />
          ) : (
            <CompanyListSearchBody
              companies={!disableListContext ? listData : data}
              compactCards={compactCards}
              selectableCards={selectableCards}
              companyDisabled={companyDisabled}
              disabledReason={companyDisabledReason}
            />
          )}
        </>
      )}
    </VendorSearchLayout>
  );
}
