import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { requestAccess } from "shared/client";
import { GroupDetailsDto } from "shared/model";

type RequestGroupParams = {
  groupId: string;
};

export default function useRequestGroupAccess() {
  const client = useQueryClient();
  return useMutation(
    ({ groupId }: RequestGroupParams) => {
      return requestAccess(groupId);
    },
    {
      mutationKey: mutationKeys.requestAccessToGroup,
      onMutate: async ({ groupId }: RequestGroupParams) => {
        await client.cancelQueries(queryKeys.requestGroup(groupId));
        const previousGroup = client.getQueryData<GroupDetailsDto>(
          queryKeys.requestGroup(groupId)
        );
        if (previousGroup) {
          client.setQueryData<GroupDetailsDto>(
            queryKeys.requestGroup(groupId),
            previousGroup
          );
        }
        return { previousGroup };
      },
      onError: (err, variables, context) => {
        if (context?.previousGroup) {
          client.setQueryData<GroupDetailsDto>(
            queryKeys.requestGroup(variables.groupId),
            context.previousGroup
          );
        }
      },
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.group(variables.groupId));
        client.invalidateQueries(queryKeys.groups);
        client.invalidateQueries(queryKeys.requestGroup(variables.groupId));
      },
    }
  );
}
