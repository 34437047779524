import React, { Suspense, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { AssetRepositoryItem } from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import { ButtonProps } from "components/ui/Button/Button";
import AssetFilterProvider from "contexts/AssetFilter/AssetFilterProvider";
import { percent, px } from "csx";
import { useAssetRepositoryId } from "hooks/queries/useOwnCompany";
import { FolderItemDto } from "shared/model";
import { classes, stylesheet } from "typestyle";
import lazyRetry from "util/lazyRetry";

import { marginLeftClass, width } from "../../shared/styles/classes";

const styles = stylesheet({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const AssetRepository = React.lazy(() =>
  lazyRetry(
    () => import("../AssetRepository/AssetRepository"),
    "AssetRepository"
  )
);

type RepositoryModalProps = {
  open: boolean;
  actions: ButtonForModalWithSelectableItems<FolderItemDto>[];
  initialMenuId?: string;
  headerActionsHidden?: boolean;
  disableSelection?:
    | boolean
    | ((row: AssetRepositoryItem) => boolean | undefined);
};

export type ButtonForModalWithSelectableItems<T> = Omit<
  ButtonProps,
  "onClick"
> & {
  text: string;
  className?: string;
  onClick: (selectedFolderItems: T[]) => void;
  disableIfNoItemsSelected?: boolean;
};

export default function RepositoryModal({
  open,
  actions,
  initialMenuId,
  headerActionsHidden,
  disableSelection,
}: RepositoryModalProps) {
  const [folderId, setFolderId] = useState<string | undefined>(undefined);
  useAssetRepositoryId({ onSuccess: setFolderId });

  return (
    <Modal open={open} className={styles.modal}>
      <AssetFilterProvider folderId={folderId}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            className={classes(width(percent(85)), marginLeftClass(px(85)))}
          >
            <Suspense fallback={null}>
              <AssetRepository
                headerActionsHidden={headerActionsHidden}
                folderId={folderId}
                onNavigateFolderWithoutURLChange={(newFolderId) => {
                  setFolderId(newFolderId);
                }}
                actions={actions}
                initialMenuId={initialMenuId}
                disableUrlNavigation
                disableSelection={disableSelection}
              />
            </Suspense>
          </Grid>
        </Grid>
      </AssetFilterProvider>
    </Modal>
  );
}
