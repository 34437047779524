import { primaryColors } from "@pulsemarket/constants";
import { zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const style = stylesheet({
  container: {},
  title: {
    color: primaryColors.PURPLE2,
    ...typography.SUBTITLE1,
    ...zeroPaddingMargin,
    marginBottom: "34px",
  },
});

export default style;
