export enum QuestionType {
  TEXT = "TEXT",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTI_CHOICE = "MULTI_CHOICE",
  NUMBER = "NUMBER",
  MATRIX = "MATRIX",
  DATE = "DATE",
}

export enum QuestionParentModelType {
  QUESTIONNAIRE = "QUESTIONNAIRE",
  MODULE = "MODULE",
  RFX = "RFX",
}
