import { neutralColors, utilityColors } from "@pulsemarket/constants";
import { px } from "csx";
import { stylesheet } from "typestyle";

export const dividerSpace = px(20);

const styles = stylesheet({
  CreateList: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "stretch",
  },
  contentWrapper: {},
  input: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    boxSizing: "border-box",
    color: neutralColors.GRAY5,
    verticalAlign: "center",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    marginTop: "25px",
  },
  button: {
    margin: "5px 0px 5px 24px",
  },
  createListButton: {
    padding: "0px 40px",
  },
  buttonLabel: {
    margin: `${px(0)} ${px(8)}`,
  },
  warningCompanyName: {
    color: utilityColors.ORANGE,
  },
});

export default styles;
