import { useMutation, useQueryClient } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { updateGroup } from "shared/client";
import { UpdateGroupInput } from "shared/model";

export function useUpdateGroup() {
  const queryClient = useQueryClient();
  const { data: ownCompany } = useOwnCompany();

  return useMutation(
    ({ id, ...data }: UpdateGroupInput & { id: string }) => {
      return updateGroup(id, data);
    },
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(queryKeys.group(variables.id));
        queryClient.invalidateQueries(
          queryKeys.ownGroups(ownCompany?.id || "")
        );
      },
    }
  );
}
