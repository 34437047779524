import React, { useContext } from "react";

import { DialogActions, DialogContent } from "@material-ui/core";
import FileUploader, {
  UploadProps,
} from "components/FileUploader/FileUploader";
import Button from "components/ui/Button";
import ModalHeader from "components/ui/Modal/Header/ModalHeader";
import ModalContext from "contexts/ModalContext";
import { classes, style } from "typestyle";

import modalActionStyles from "components/ui/Modal/Actions/ModalActionsStyles";
import simpleModalBodyStyles from "components/ui/Modal/SimpleModalBody/SimpleModalBodyStyles";

export type CloneOrUploadModalProps = {
  onCloneRequest?: () => void;
  uploadConfig?: UploadProps;
  onClose: () => void;
  hideCloseButton?: boolean;
  title?: string;
  text?: string;
  cloneButtonText?: string;
};

export default function CloneOrUploadModal({
  title = "Upload document",
  text = "You can choose to upload documents from the asset repository or from your desktop.",
  cloneButtonText = "Upload from assets",
  ...props
}: CloneOrUploadModalProps) {
  const { closeModal } = useContext(ModalContext);
  return (
    <>
      <ModalHeader
        className={simpleModalBodyStyles.header}
        onClose={props.onClose}
        hideCloseButton={props.hideCloseButton}
      >
        {title}
      </ModalHeader>
      <DialogContent className={simpleModalBodyStyles.contentContainer}>
        <p>{text}</p>
      </DialogContent>
      <DialogActions
        className={classes(modalActionStyles.actionsContainer)}
        classes={{
          root: modalActionStyles.actionsContainerRoot,
        }}
      >
        <FileUploader
          {...props.uploadConfig}
          allowMultiple={true}
          className={style({ height: "unset", width: "unset" })}
        >
          <Button variant="outlined" color="orange">
            Browse
          </Button>
        </FileUploader>
        <Button
          variant="normal"
          color="orange"
          onClick={() => {
            closeModal();
            props.onCloneRequest?.();
          }}
        >
          {cloneButtonText}
        </Button>
      </DialogActions>
    </>
  );
}
