import { px } from "csx";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  AddToList: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "stretch",
    width: px(400),
  },
});

export default styles;
