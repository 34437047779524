import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: { display: "flex", flexDirection: "column", gap: "12px" },
  choiceWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: primaryColors.WHITE,
    borderRadius: "8px",
    border: `1px solid ${neutralColors.GRAY0}`,
  },
  choiceLabelRoot: {
    marginLeft: "unset",
    marginRight: "unset",
  },
});

export default styles;
