// a more general useRoleName would be more useful on the long term?
import useRole from "hooks/queries/useRole";
import useRoles from "hooks/queries/useRoles";

export default function useIsAdmin() {
  const {
    isLoading: roleLoading,
    isIdle: roleIdle,
    data: role,
    error: roleError,
  } = useRole();
  const {
    isLoading: rolesLoading,
    isIdle: rolesIdle,
    data: roles,
    error: rolesError,
  } = useRoles();
  const isAdmin = roles && role && roles[role.roleId].name === "Admin";

  return {
    isAdmin,
    loading: roleIdle || rolesIdle || roleLoading || rolesLoading,
    error: roleError ?? rolesError,
  };
}
