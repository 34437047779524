import { neutralColors } from "@pulsemarket/constants";
import { px } from "csx";
import { clickable } from "shared/styles/classes";
import { stylesheet } from "typestyle";

const originalIconHeightPx = 14;
const originalIconWidthPx = 4;
const paddingSizePx = 4;

const styles = stylesheet({
  root: {
    position: "relative",
    display: "inline-block",
    maxWidth: "100%",
    ...clickable,
  },
  kebabMenuContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto",
    height: px(originalIconHeightPx + 2 * paddingSizePx),
  },
  icon: {
    color: neutralColors.GRAY5,
    // a padding is added to make it easier to press
    height: px(originalIconHeightPx + 2 * paddingSizePx),
    width: px(originalIconWidthPx + 2 * paddingSizePx),
    padding: px(paddingSizePx),
  },
});

export default styles;
