import Button from "components/ui/Button";
import SvgIcon from "components/ui/SvgIcon";
import { classes } from "typestyle";
import { style } from "typestyle";

import styles from "./DisplaySelector.styles";

export enum DisplayType {
  ROWS = "ROWS",
  CARDS = "CARDS",
}

type DisplaySelectorProps = {
  display?: DisplayType;
  onDisplayChange?: (newDisplay: DisplayType) => void;
  disabled?: boolean;
};

export default function DisplaySelector({
  display,
  disabled,
  onDisplayChange: setDisplay = () => {},
}: DisplaySelectorProps) {
  return (
    <div>
      <Button
        size="small"
        variant="normal"
        color="purple"
        disabled={disabled}
        className={classes(
          styles.displaySelectorButton,
          "left",
          display === DisplayType.ROWS && styles.selectedDisplayButton
        )}
        onClick={() => {
          setDisplay(DisplayType.ROWS);
        }}
        startIcon={<SvgIcon src="/icons/menu_icon.svg" />}
        classes={{
          startIcon: style({ margin: 0, width: "14px" }),
          root: style({
            minWidth: "unset",
            padding: 0,
            width: "28px",
            height: "28px",
          }),
        }}
      />
      <Button
        size="small"
        variant="normal"
        disabled={disabled}
        className={classes(
          styles.displaySelectorButton,
          "right",
          display === DisplayType.CARDS && styles.selectedDisplayButton
        )}
        onClick={() => setDisplay(DisplayType.CARDS)}
        startIcon={<SvgIcon src="/icons/cards_icon.svg" />}
        classes={{
          startIcon: style({ margin: 0, width: "14px" }),
          root: style({
            minWidth: "unset",
            padding: 0,
            width: "28px",
            height: "28px",
          }),
        }}
      />
    </div>
  );
}
