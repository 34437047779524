import { neutralColors, utilityColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

export default stylesheet({
  mainContainer: {
    padding: "57px",
  },
  addCardContainer: {
    width: "100%",
    height: "260px",
    border: `2px dashed ${neutralColors.GRAY3}`,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "13px",
    color: utilityColors.ORANGE,
    marginRight: "10px",
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
