import { useMutation, useQuery } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import useSwitchCompany from "hooks/queries/useSwitchCompany";
import { createCompany, getCompanies } from "shared/client";
import { CompanyFilter } from "shared/model";

type CompaniesHookParams = {
  filters: CompanyFilter;
  companyListId?: string | null;
  disabled?: boolean;
};

type CompanyFilterWithCompanyListSet = CompaniesHookParams;

export function cleanFiltersWithCompanyListId({
  filters,
  companyListId,
}: CompanyFilterWithCompanyListSet): CompanyFilter {
  const activeFilters = JSON.parse(JSON.stringify(filters));
  const params: CompanyFilter = companyListId
    ? { ...activeFilters, companyListId }
    : activeFilters;
  return params;
}

export default function useCompanies({
  filters,
  companyListId,
  disabled,
}: CompaniesHookParams) {
  const params = cleanFiltersWithCompanyListId({
    filters,
    companyListId,
  });

  const anyFilterEnabled = Object.values(params).filter(Boolean).length !== 0;

  return useQuery(
    queryKeys.companies({ ...filters, companyListId }),
    () => getCompanies(params),
    {
      enabled: !disabled && anyFilterEnabled,
    }
  );
}

export function useCreateCompany() {
  const switchCompany = useSwitchCompany();
  return useMutation(
    (companyName: string) => {
      return createCompany(companyName);
    },
    {
      mutationKey: mutationKeys.createCompany,
      async onSuccess(data, variables, context) {
        await switchCompany({ companyId: data.id });
      },
    }
  );
}
