import React from "react";

import KebabMenu, { KebabMenuProps } from "components/ui/KebabMenu";
import TallRow from "components/ui/TallRow";
import { DEFAULT_COMPANY_AVATAR } from "shared/constants/icons";
import { Pair } from "shared/model";
import { SelectableComponentProps } from "shared/utils/componentTypes";
import { classes } from "typestyle";

import styles from "./CompanyInfoRow.styles";

export type CompanyInfoRowProps = {
  className?: string;
  rightSide?: React.ReactNode;
  kebabMenu?: Omit<KebabMenuProps, "className">;
  avatar?: string;
  name: string;
  country?: Pair;
  city?: string;
} & SelectableComponentProps<CompanyInfoRowProps>;

export default function CompanyInfoRow({
  className,
  rightSide,
  kebabMenu,
  avatar,
  name,
  country,
  city,
  onToggleSelect,
  selected,
  disabled,
  disabledReason,
}: CompanyInfoRowProps) {
  const disabledStyles = disabled && styles.disabled;

  return (
    <TallRow
      className={classes(styles.root, disabledStyles, className)}
      selected={selected}
      onToggleSelect={onToggleSelect}
      disabled={disabled}
    >
      <div className={styles.leftSide}>
        <img
          alt=""
          className={styles.companyLogo}
          src={avatar ?? DEFAULT_COMPANY_AVATAR}
        />

        <div className={classes(styles.column, styles.leftSideTextContainer)}>
          <span className={classes(styles.companyName, disabledStyles)}>
            {name}
          </span>
          {disabledReason && (
            <span className={styles.disabledReason}>{disabledReason}</span>
          )}
          {country && (
            <span className={classes(styles.companyLocation, disabledStyles)}>
              {city}, {country.name}
            </span>
          )}
        </div>
      </div>
      <div className={styles.dividerCellClass}>
        <div className={styles.dividerInnerClass} />
      </div>
      <div className={styles.rightSide}>
        {rightSide}
        {kebabMenu && <KebabMenu className={styles.kebabMenu} {...kebabMenu} />}
      </div>
    </TallRow>
  );
}
