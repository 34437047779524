import { neutralColors } from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

export const buttonContainerStyles: NestedCSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: px(8),
  justifyContent: "space-between",
};

export const sharedPostStyles = stylesheet({
  postingDate: {
    ...typography.CAPTION,
    textAlign: "left",
    color: neutralColors.GRAY5,
  },
  kebabMenu: { marginLeft: "auto" },
  buttonsRow: {
    ...buttonContainerStyles,
    marginTop: px(14),
  },
});
