import { useQuery, UseQueryOptions } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import useRFP from "hooks/queries/useRFP";
import { ApiError, getRFPresponsesByRfpId } from "shared/client";
import { ReceivedResponseFilters } from "shared/model";

type UseResponsesQueryOptions<
  T = Awaited<ReturnType<typeof getRFPresponsesByRfpId>>
> = UseQueryOptions<
  ReturnType<typeof getRFPresponsesByRfpId>,
  ApiError,
  T,
  ReturnType<typeof queryKeys.rfpResponsesByRfpId>
> & { rfpId?: string; filters?: ReceivedResponseFilters };

export default function useRFPresponses({
  rfpId,
  filters,
  enabled,
  ...options
}: UseResponsesQueryOptions) {
  const { data: rfp } = useRFP({ rfpId });
  const { data: ownCompany } = useOwnCompany();

  const queryEnabled =
    (typeof enabled === "undefined" ? true : enabled) &&
    !!rfpId &&
    rfp?.companyId === ownCompany?.id;

  return useQuery(
    queryKeys.rfpResponsesByRfpId(rfpId, filters),
    () => getRFPresponsesByRfpId(rfpId!, filters),
    {
      ...options,
      enabled: queryEnabled,
      notifyOnChangeProps: "tracked",
      onError: (error) => {},
      retry: (failureCount, error) => {
        // Don't retry on not found error
        return error.status === 404 ? false : true;
      },
    }
  );
}
