import { stylesheet } from "typestyle";

const styles = stylesheet({
  Breadcrumb: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rightArrow: { height: "18px", width: "18px" },
  svgIcon: { height: "18px", width: "18px" },
});

export default styles;
