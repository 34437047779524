import { AllowedExtensions, AllowedMimes, AssetType } from "../constants";

export type AssetDto = {
  id: string;
  blobId: string | null;
  companyId: string;
  userId?: string;
  folderId?: string | null;
  name: string;
  expirationDate?: Date | null;
  createdAt: Date;
  updatedAt: Date;
  extension: AllowedExtensions;
  mimeType: AllowedMimes;
  size: number;
  isHidden: boolean;
  choiceId?: string;
  type: AssetType;
};

export enum LabelColor {
  Yellow = "#FCB831", // if used like this the underlying compiled object has both keys (Yellow and #FCB831)...
  Orange = "#FF5D45",
  Red = "#D54857",
  Lilac = "#A85B98",
  Cyan = "#92D3D7",
  Blue = "#2D9CDB",
  Green = "#63C26E",
  Gray = "#6C757D", // GRAY5
}

export type MappedInsertedAsset = { id: string; url: string };
