import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { zeroPaddingMargin } from "shared/styles/classes";
import { maxLineNumber } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const contentPadding = "32px";

const styles = stylesheet({
  root: {
    width: "275px",
    position: "relative",
    background: primaryColors.WHITE,
    border: `1px solid ${neutralColors.GRAY2}`,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  illustration: {
    background: neutralColors.GRAY0,
    borderBottom: `2px solid ${neutralColors.GRAY0}`,
  },
  avatarContainer: {
    width: "96px",
    height: "96px",
    borderRadius: "4px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-60px",
    marginBottom: "16px",
  },
  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    border: `solid 1px ${primaryColors.WHITE}`,
    backgroundColor: neutralColors.GRAY1,
  },
  backgroundImage: { height: px(87), width: "100%", objectFit: "cover" },
  content: {
    flex: 1,
    padding: `0 ${contentPadding} ${contentPadding}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    textAlign: "center",
    color: primaryColors.BLACK,
    ...typography.SUBTITLE1,
    ...zeroPaddingMargin,
    height: "2.625rem", // 2 lines
    ...maxLineNumber(2),
    cursor: "pointer",
    $nest: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  buttonContainer: {
    justifySelf: "flex-end",
    width: "100%",
    display: "flex",
    gap: "8px",
    flexDirection: "column",
  },
  checkbox: {
    background: primaryColors.WHITE,
    position: "absolute",
    top: "8px",
    left: "8px",
    borderRadius: "2px",
    padding: "3px",
  },
  kebab: {
    background: primaryColors.WHITE,
    position: "absolute",
    top: "8px",
    right: "8px",
    borderRadius: "2px",
  },
  disabled: {
    $nest: {
      "&": {
        color: neutralColors.GRAY3,
      },
    },
  },
});

export default styles;
