import React from "react";

import InformationIcon from "components/ui/InformationIcon";
import { classes } from "typestyle";

import sharedStyles from "../sharedStyles";
import styles from "./AboutStyles";

export default function YouTubeInformationIcon() {
  return (
    <InformationIcon
      title={
        <span
          className={classes(sharedStyles.helpIconText, styles.youtubeHelpText)}
        >
          {`1. Go to YouTube.
                  2. Navigate to the video you wish to import.
                  3. Click the ‘share’ link below the video.
                  4. Copy the link and paste it here.`}
        </span>
      }
      iconClassName={classes(sharedStyles.helpIcon, sharedStyles.helpIconSmall)}
    />
  );
}
