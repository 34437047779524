import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { ROUTES } from "@pulsemarket/constants";
import CombinedInvitationSender, {
  InvitationsByType,
} from "components/CombinedInvitationSender/CombinedInvitationSender";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import ModalContext from "contexts/ModalContext/ModalContext";
import {
  useInviteToTakePassport,
  useSentPassportInvitations,
} from "hooks/queries/passportHooks";

export default function PassportInvitationSender() {
  const { data: previousInvitationsData } = useSentPassportInvitations({});
  const { mutateAsync: inviteToTakePassport } = useInviteToTakePassport();
  const history = useHistory();

  const previousInvitations: InvitationsByType = (
    previousInvitationsData?.pages[0] || []
  ).reduce<InvitationsByType>(
    (acc, invitation) => {
      if (invitation.invitedCompanyId) {
        acc.companies.push(invitation.invitedCompanyId);
      }
      if (invitation.invitedSupplierId) {
        acc.suppliers.push(invitation.invitedSupplierId);
      }
      if (invitation.invitedEmail) {
        acc.emails.push(invitation.invitedEmail);
      }
      return acc;
    },
    {
      emails: [],
      suppliers: [],
      companies: [],
    }
  );

  return (
    <CombinedInvitationSender
      onInvite={async ({ recipients }) => {
        try {
          await inviteToTakePassport({ invitations: recipients });
          history.push(ROUTES.ESG_INVITATIONS);
        } catch (_e) {}
      }}
      existingInvitations={previousInvitations}
      confirmationModal={<InvitationsSentConfirmation />}
      title="Invite to take the Passport"
      subtitle="Invite a Company to get the passport today!"
      disabledReason="Already invited"
    />
  );
}

export function InvitationsSentConfirmation() {
  const { closeModal } = useContext(ModalContext);

  return (
    <SimpleModalBody
      title="Invitations Sent"
      buttons={[
        {
          variant: "normal",
          color: "orange",
          onClick: () => {
            closeModal();
          },
          children: "Ok, Thanks",
        },
      ]}
    >
      The invitations were successfully sent to all recipients.
    </SimpleModalBody>
  );
}
