import { neutralColors, utilityColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  mainRoot: {
    flexGrow: 1,
  },
  tab: {
    height: "40px",
  },
  tabRoot: {
    ...typography.BODY2,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: neutralColors.GRAY0,
    textTransform: "none",
    color: neutralColors.GRAY5,
    padding: "7 22px",
    minHeight: "28px",
    marginRight: "8px",
    borderRight: `1px solid ${neutralColors.GRAY3}`,
    borderTop: `1px solid ${neutralColors.GRAY3}`,
    borderLeft: `1px solid ${neutralColors.GRAY3}`,
  },
  selectedTab: {
    opacity: 1,
    fontWeight: 700,
    color: utilityColors.ORANGE,
    overflowY: "unset",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    position: "absolute",
    bottom: 0,
    transition: "none",
    $nest: {
      "& > span": {
        width: "99%",
        backgroundColor: neutralColors.GRAY0,
        height: "6px",
      },
    },
  },
  tabsRoot: {
    minHeight: "20px",
    overflow: "unset",
  },
  scroller: {
    overflow: "unset !important",
  },
});
