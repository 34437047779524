import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    background: primaryColors.WHITE,
    borderRadius: "8px",
    textAlign: "center",
  },
  title: { ...typography.HEADLINE5, color: primaryColors.PURPLE2 },
  infoText: { ...typography.BODY2, color: primaryColors.BLACK },
  companyName: { color: utilityColors.ORANGE },
  terms: {
    flex: "0 0 100%",
    justifyContent: "center",
    marginTop: "32px",
  },
  submitButton: {
    marginTop: "32px",
  },
});

export default styles;
