import React from "react";

import Input, { InputProps } from "components/ui/Input";
import { FieldHookConfig, useField } from "formik";

type FieldProps = {
  label?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  required?: boolean;
} & FieldHookConfig<any> &
  InputProps;

type PropertyNamesOnly<T> = {
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

function withContext<X extends Record<string, any>>() {
  return function <T extends { name: string }>(
    Component: React.ComponentType<T>
  ): React.ComponentType<Omit<T, "name"> & { name: PropertyNamesOnly<X> }> {
    return function FieldWrapper(props) {
      // @ts-ignore
      return <Component {...props} />;
    };
  };
}

function Field({
  name,
  validate,
  labelClassName,
  children,
  helperText,
  ...rest
}: FieldProps) {
  const [field, meta, helpers] = useField({
    name,
    validate:
      validate || rest.required
        ? (v) => {
            if (validate && validate(v)) {
              return validate(v);
            }
            //Converting to sting is needed for number type when value is 0.
            //TODO: check if the same conversion works at select inputs
            if (!v.toString()) {
              return "This field is required";
            }
          }
        : undefined,
  });

  return (
    <>
      {children ? (
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              ...field,
              ...rest,
              ...child.props,
              onChange: (v: any) => {
                // Some of our inputs like FormSelect returns the value instead of the React event
                if (typeof v === "string") {
                  helpers.setValue(v);
                } else {
                  field.onChange(v);
                }
              },
            });
          }

          return null;
        })
      ) : (
        <Input
          {...field}
          {...rest}
          helperText={meta.touched && meta.error ? meta.error : helperText}
          error={
            typeof rest.error !== "undefined"
              ? rest.error
              : meta.touched && !!meta.error
          }
        />
      )}
    </>
  );
}

export default function getField<T extends Record<string, any>>() {
  return withContext<T>()(Field);
}
