import React, { useMemo, useState } from "react";

import { Popover } from "@material-ui/core";
import { Loading } from "components/FullScreenLoading";
import { ReactionDetailsDto } from "shared/model";
import { classes } from "typestyle";

import { reactionCountStyles, styles } from "./PostCard.styles";

type ReactionCountProps = {
  count?: number;
  className?: string;
  isLoading?: boolean;
  reactions?: ReactionDetailsDto[];
  initiateLoading?: () => void;
};

export default function ReactionCount({
  count,
  className,
  isLoading,
  reactions = [],
  initiateLoading,
}: ReactionCountProps) {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const popoverOpen = useMemo(() => Boolean(anchorElement), [anchorElement]);

  function openPopover(event: React.MouseEvent<Element, MouseEvent>) {
    initiateLoading?.();
    setAnchorElement(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorElement(null);
  }

  return (
    <>
      <p
        className={classes(styles.reactionCount, className)}
        onClick={openPopover}
      >
        {count}
      </p>
      <Popover
        open={popoverOpen}
        anchorEl={anchorElement}
        getContentAnchorEl={null}
        onClose={handlePopoverClose}
        classes={{
          paper: reactionCountStyles.paper,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {isLoading && <Loading isWindowed />}
        {reactions.map((reaction, i) => (
          <p key={i} className={reactionCountStyles.user}>
            {reaction.firstName} {reaction.lastName} - {reaction.companyName}
          </p>
        ))}
      </Popover>
    </>
  );
}
