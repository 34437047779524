import { utilityColors } from "@pulsemarket/constants";
import { forceTextOneLine } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export const menuRightPosition = -8; // px

const styles = stylesheet({
  container: {
    width: "100%",
  },
  companyList: {
    ...typography.BODY2,
    ...forceTextOneLine,
  },
  companyListName: {
    ...forceTextOneLine,
    width: `calc(100% - ${menuRightPosition}px)`,
  },
  warningCompanyName: {
    color: utilityColors.ORANGE,
  },
});

export default styles;
