import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { clickable } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {},
  avatar: {
    ...clickable,
  },
  styles: {
    borderRadius: "8px",
  },
  itemSize: {
    paddingBottom: "16px",
    paddingTop: "16px",
  },
  pageItem: {
    ...typography.BODY1,
    color: neutralColors.GRAY5,
    $nest: {
      "&:hover": {
        color: primaryColors.BLACK,
      },
    },
  },
  selectedPageItem: {
    $nest: {
      "&": {
        color: primaryColors.BLACK,
      },
    },
  },
  switchCompany: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    pointerEvents: "none",
  },
  arrowIcon: {
    height: "18px",
    color: neutralColors.GRAY5,
  },
  actionItem: {
    ...typography.BODY1,
    color: primaryColors.BLACK,
  },
  companyItem: {
    ...typography.BODY2,
  },
  companyAvatar: {
    width: "27px",
    height: "27px",
    marginRight: "12px",
  },
  menuRoot: {
    borderRadius: "8px",
    width: "250px",
    padding: "12px",
  },
  divider: {
    background: neutralColors.GRAY3,
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "12px",
    marginBottom: "12px",
  },
});

export default styles;
