import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { searchGroups } from "shared/client";
import { GroupSearchOptions } from "shared/model";

const DEFAULT_PAGESIZE = 20;

export default function useSearchGroups(
  {
    offset,
    limit,
    query,
  }: { offset: number; limit: number; query: GroupSearchOptions } = {
    offset: 0,
    limit: DEFAULT_PAGESIZE,
    query: {},
  }
) {
  return useQuery(
    [...queryKeys.groups, { offset, limit, query }],
    () => {
      return searchGroups({ offset, limit, query });
    },
    {
      keepPreviousData: true,
    }
  );
}
