import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { updatePost } from "shared/client";
import { UpdatePostInput } from "shared/model";

export function useUpdatePost() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      groupId,
      postId,
      post,
    }: {
      groupId: string;
      postId: string;
      post: UpdatePostInput;
    }) => {
      return updatePost(postId, post);
    },
    {
      mutationKey: mutationKeys.updatePost,
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(queryKeys.group(variables.groupId));
        queryClient.invalidateQueries(
          queryKeys.posts(variables.groupId, {}).slice(0, 2)
        );
      },
    }
  );
}
