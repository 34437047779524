export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TokenType {
  Refresh = "Refresh",
  PasswordReset = "PasswordReset",
  EmailVerify = "EmailVerify",
}

export enum CommentStatus {
  Pending = "Pending",
  Sent = "Sent",
}

export enum CSRDDocumentStatus {
  NotRelevant = "Not Relevant",
  NotStarted = "Not Started",
  Draft = "Draft",
  InReview = "In Review",
  Complete = "Complete",
}

export enum RfpResponseStatus {
  Draft = "Draft",
  InReview = "InReview",
  Submitted = "Submitted",
}

export enum DynamicRfpResponseStatus {
  /** Applies to unanswered invitations */
  New = "New",
  Draft = "Draft",
  /** Read from invitation status */
  Declined = "Declined",
  InReview = "InReview",
  Submitted = "Submitted",
  /** After due date and it's not submitted nor declined */
  NotResponded = "NotResponded",
}

export enum PassportStatus {
  None = "None",
  Draft = "Draft",
  InReview = "InReview",
  Submitted = "Submitted",
  Completed = "Completed",
  CloseToExpire = "CloseToExpire",
  Expired = "Expired",
}

// Union of DynamicRfpResponseStatus and PassportStatus
export enum RfpResponseDtoStatus {
  New = "New",
  Draft = "Draft",
  Declined = "Declined",
  InReview = "InReview",
  Submitted = "Submitted",
  NotResponded = "NotResponded",
  None = "None",
  Completed = "Completed",
  CloseToExpire = "CloseToExpire",
  Expired = "Expired",
}

export enum RfpResponseEvaluationStatus {
  ReadyForEvaluation = "ReadyForEvaluation",
  InEvaluation = "InEvaluation",
  Evaluated = "Evaluated",
}

export enum RfpResponseAnalysisStatus {
  ReadyForAnalysis = "ReadyForAnalysis",
  InAnalysis = "InAnalysis",
  Analysed = "Analysed",
}

export enum CSRDFolderType {
  GapAnalysis = "Gap Analysis",
  SustainabilityReport = "Sustainability Report",
  KeyDocuments = "Key Documents",
}
