import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import { DashboardType } from "@pulsemarket/constants";
import useUserData from "hooks/queries/useUserData";
import { ApiError, getCompany } from "shared/client";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";
import { CompanyDetailsDto } from "shared/model";

type BaseCompany = Partial<Pick<CompanyDetailsDto, "id">>;

const getDefaultOwnCompany = (baseParams: BaseCompany): CompanyDetailsDto => ({
  id: baseParams.id || "",
  name: "",
  employees: {
    name: "",
    id: "",
  },
  industries: [],
  sectors: [],
  specialities: [],
  servicing: [],
  zones: [],
  productsAndServices: [],
  creationDate: "",
  companyLists: [],
  passports: [],
  attributes: [],
  dashboardType: DashboardType.General,
});

type UseOwnCompanyQueryOptions<T = CompanyDetailsDto> = Omit<
  UseQueryOptions<CompanyDetailsDto, unknown, T, typeof queryKeys.ownCompany>,
  "placeholderData" | "retry" | "enabled" | "notifyOnChangeProps"
>;

export default function useOwnCompany<T = CompanyDetailsDto>(
  options?: UseOwnCompanyQueryOptions<T>
) {
  const { data: userData } = useUserData();

  const companyId =
    //Prevent sending bad requests to api/commpanies/  when company is undefined
    (userData?.companyId || localStorage.getItem(ACTING_COMPANY_ID)) ??
    undefined;

  return useQuery(
    queryKeys.ownCompany,
    () => {
      return getCompany(companyId!);
    },
    {
      retry: (failureCount, error) => {
        if (error instanceof ApiError) {
          if (error.status === 404) {
            return false;
          }
        }
        if (failureCount > 3) {
          return false;
        }
        return true;
      },
      enabled: !!companyId,
      placeholderData: useMemo(
        () =>
          getDefaultOwnCompany({
            id: companyId,
          }),
        [companyId]
      ),
      notifyOnChangeProps: "tracked",
      ...options,
      ...longCachedQueryOptions,
    }
  );
}

export function useAssetRepositoryId(
  options?: Omit<UseOwnCompanyQueryOptions<string | undefined>, "select">
) {
  return useOwnCompany({
    select(data) {
      return data.assetRepositoryId;
    },
    ...options,
  });
}
