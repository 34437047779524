import React from "react";

import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import Button from "components/ui/Button";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    width: "292px",
    borderRadius: "4px",
    overflow: "hidden",
  },
  topPart: {
    background: neutralColors.GRAY2,
    textAlign: "center",
    paddingTop: "36px",
    paddingBottom: "36px",
    position: "relative",
  },
  progress: {
    position: "absolute",
    right: "16px",
    top: "16px",
    ...typography.CAPTION,
  },
  img: {
    width: "140px",
  },
  newTag: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
    background: utilityColors.BLUE,
    color: primaryColors.WHITE,
    ...typography.BUTTON_SMALL,
    padding: "4px 8px",
    borderRadius: "4px",
  },
  bottomPart: {
    padding: "16px 32px ",
  },
  title: {
    ...typography.SUBTITLE2,
    marginBottom: "12px",
  },
  text: {
    ...typography.BODY2,
  },
  buttonPart: {
    marginTop: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rightButton: {
    marginLeft: "auto",
  },
});

export interface TipContentProps {
  steps?: {
    total: number;
    current: number;
  };
  imageSrc: string;
  isNew?: boolean;
  title: string;
  text: string;
  action: string;
  onSkip?: () => void;
  onAction?: () => void;
}

function TipContent({
  steps,
  imageSrc,
  isNew,
  title,
  text,
  action,
  onSkip,
  onAction,
}: TipContentProps) {
  return (
    <div className={styles.root}>
      <div className={styles.topPart}>
        {steps && (
          <div className={styles.progress}>
            {steps.current} of {steps.total}
          </div>
        )}

        <img src={imageSrc} className={styles.img} alt="Illustration" />

        {isNew && <div className={styles.newTag}>New</div>}
      </div>
      <div className={styles.bottomPart}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
        <div className={styles.buttonPart}>
          {onSkip && (
            <Button variant="text" color="gray" size="small" onClick={onSkip}>
              Skip
            </Button>
          )}

          <Button
            className={styles.rightButton}
            variant="outlined"
            color="orange"
            size="small"
            onClick={onAction as React.MouseEventHandler<HTMLButtonElement>}
          >
            {action}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default TipContent;
