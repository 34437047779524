import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { FeatureFlagEnum } from "@pulsemarket/constants";
import About from "components/PulsePage/About";
import MyGroups from "components/PulsePage/Groups/MyGroups";
import { checkHttpProtocol } from "components/PulsePage/helpers";
import ProductsAndServices from "components/PulsePage/ProductsAndServices";
import PulsePassport from "components/PulsePage/PulsePassport";
import Tabs from "components/PulsePage/Tabs";
import Button from "components/ui/Button";
import SvgIcon from "components/ui/SvgIcon";
import AddToList from "components/vendorlists/AddToList";
import RemoveFromList from "components/vendorlists/RemoveFromList";
import { checkIsInOrg } from "components/vendorlists/utils";
import ModalContext from "contexts/ModalContext";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import usePassportFeatureEnabled from "hooks/usePassportFeatureEnabled";
import { DEFAULT_COMPANY_AVATAR } from "shared/constants/icons";
import { CompanyDetailsDto, CompanyDto } from "shared/model";

import styles, { avatarStyles } from "./PulsePageStyles";

type PulsePageProps = {
  isOwner: boolean;
  company: CompanyDetailsDto;
  onEditButtonClick: () => void;
  similarVendors: CompanyDto[];
};

export enum TabTitle {
  About = "About",
  ProductsAndServices = "Products and Services",
  PulsePassport = "Pulse Passport",
  Groups = "Groups",
}

function displayTabs(company: CompanyDetailsDto) {
  const tabsArr: TabTitle[] = [];
  if (company.productsAndServices.length) {
    tabsArr.push(TabTitle.ProductsAndServices);
  }
  if (company.passports.some((passport) => passport.show)) {
    tabsArr.push(TabTitle.PulsePassport);
  }
  return tabsArr;
}

export default function PulsePage({
  company,
  isOwner,
  onEditButtonClick,
  similarVendors = [],
}: PulsePageProps) {
  const { data: ownCompany } = useOwnCompany();
  const { openModal, closeModal } = useContext(ModalContext);
  const history = useHistory();

  const location = useLocation<{ tabTitle: TabTitle } | undefined>();
  const [openTab, setOpenTab] = useState<number | undefined>(undefined);

  const companyAvatar = company?.avatar
    ? company.avatar
    : DEFAULT_COMPANY_AVATAR;
  const websiteURL = checkHttpProtocol(company.website);
  const linkedinURL = checkHttpProtocol(company.linkedInUrl);

  const { data: groupsEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.groupsEnabled,
  });

  const passportEnabled = usePassportFeatureEnabled();

  const getTabs = useCallback(() => {
    const res: TabTitle[] = [TabTitle.About];
    if (isOwner) {
      res.push(TabTitle.ProductsAndServices);
      if (groupsEnabled) {
        res.push(TabTitle.Groups);
      }
      if (passportEnabled) {
        res.push(TabTitle.PulsePassport);
      }
    } else {
      res.push(...displayTabs(company));
    }
    return res;
  }, [company, groupsEnabled, isOwner, passportEnabled]);

  const tabs: TabTitle[] = getTabs();

  useEffect(() => {
    if (location.state?.tabTitle) {
      const index = tabs.findIndex((x) => x === location.state?.tabTitle);
      setOpenTab(index);
    }
  }, [location.state, tabs]);

  function tabComponent(tab: TabTitle) {
    switch (tab) {
      case TabTitle.About:
        return (
          <About
            key={"about_tab"}
            company={company}
            isOwner={isOwner}
            historyPush={(url) => history.push(url)}
            similarVendors={similarVendors}
          />
        );

      case TabTitle.ProductsAndServices:
        return (
          <ProductsAndServices
            key={"products_services_tab"}
            company={company}
            isOwner={isOwner}
            historyPush={(url) => history.push(url)}
          />
        );

      case TabTitle.PulsePassport:
        return (
          <PulsePassport
            key={"pulse_passport_tab"}
            company={company}
            isOwner={isOwner}
            historyPush={(url) => history.push(url)}
          />
        );

      /*
      TODO --> uncomment this when RSS feed is implemented
        case "Activity":
        return (
          <Activity
            company={company}
            isOwner={isOwner}
            historyPush={(url) => history.push(url)}
          />
        );
 */
      case "Groups":
        return <MyGroups key={"groups_tab"} />;
      default:
        return null;
    }
  }

  const openAddToListModal = useCallback<(company: CompanyDetailsDto) => void>(
    (company: CompanyDetailsDto) => {
      openModal(
        <AddToList
          company={{ id: company.id, name: company.name }}
          onSuccess={() => {
            closeModal();
          }}
          onCancel={() => {
            closeModal();
          }}
        />
      );
    },
    [openModal, closeModal]
  );

  const openRemoveFromListModal = useCallback<
    (company: CompanyDetailsDto) => void
  >(
    (company: CompanyDetailsDto) => {
      openModal(
        <RemoveFromList
          company={{ id: company.id, name: company.name }}
          onSuccess={() => {
            closeModal();
          }}
          onCancel={() => {
            closeModal();
          }}
        />
      );
    },
    [openModal, closeModal]
  );

  // TODO: This is a costly check - change when better api solution is found
  const RemoveButton = useMemo(() => {
    if (checkIsInOrg(company.id, ownCompany!.companyLists)) {
      return (
        <Button
          variant="normal"
          color="red"
          className={styles.button}
          onClick={() => openRemoveFromListModal(company)}
        >
          Remove
        </Button>
      );
    }
    return null;
  }, [openRemoveFromListModal, ownCompany, company]);

  return (
    <div className={styles.flexColumn}>
      <div className={styles.headerContainer}>
        {company?.backgroundImage ? (
          <div className={styles.backgroundImageContainer}>
            <img
              src={company.backgroundImage}
              alt="background"
              className={styles.backgroundImage}
            />
          </div>
        ) : (
          <div className={styles.headerImage}>
            <SvgIcon
              src="/img/cyan_cloud_background.svg"
              className={styles.cyanCloud}
            />
            <SvgIcon
              src="/img/gray_cloud_background.svg"
              className={styles.grayCloud}
            />
          </div>
        )}

        <div className={styles.basicInfoContainer}>
          <div className={styles.avatarContainer}>
            {company?.avatar ? (
              <div className={avatarStyles.avatarBox}>
                <img
                  src={companyAvatar}
                  alt="avatar"
                  className={avatarStyles.avatar}
                />
              </div>
            ) : (
              <div className={avatarStyles.avatarBox}>
                <SvgIcon
                  src={companyAvatar}
                  className={avatarStyles.defaultAvatar}
                />
              </div>
            )}
          </div>
          <div>
            <div className={styles.buttonsContainer}>
              {company.website && (
                <Button
                  variant="outlined"
                  color="orange"
                  onClick={() => window.open(websiteURL)}
                  className={styles.button}
                >
                  Website
                </Button>
              )}
              {company.linkedInUrl && (
                <Button
                  variant="outlined"
                  color="orange"
                  onClick={() => window.open(linkedinURL)}
                  className={styles.button}
                >
                  Linkedin
                </Button>
              )}
              {isOwner ? (
                <Button
                  variant="normal"
                  color="orange"
                  onClick={onEditButtonClick}
                  className={styles.editButton}
                >
                  Edit Company Info
                </Button>
              ) : (
                <>
                  <Button
                    variant="normal"
                    color="orange"
                    className={styles.button}
                    onClick={() => openAddToListModal(company)}
                  >
                    Add to List
                  </Button>
                  {RemoveButton}
                </>
              )}
            </div>
            <div className={styles.nameAndHeadlineContainer}>
              <h1 className={styles.companyName}>{company.name}</h1>
              {/*
              TODO --> uncomment this when headline is implemented
               <p className={styles.headline}>{company.name}</p> */}
            </div>
          </div>
        </div>
      </div>

      <Tabs tabs={tabs} openTab={openTab}>
        {tabs.map((tab) => tabComponent(tab))}
      </Tabs>
    </div>
  );
}
