import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { deletePost } from "shared/client";

export default function useDeletePost() {
  const client = useQueryClient();

  return useMutation(
    ({ postId }: { postId: string; groupId: string }) => deletePost(postId),
    {
      mutationKey: mutationKeys.deletePost,
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.group(variables.groupId));
        client.invalidateQueries(
          queryKeys.posts(variables.groupId, {}).slice(0, 2)
        );
      },
    }
  );
}
