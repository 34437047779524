import { px } from "csx";
import { zeroPaddingMargin } from "shared/styles/classes";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  actionsContainerRoot: {
    $nest: {
      "&.MuiDialogActions-spacing > :not(:first-child)": {
        marginLeft: px(16),
      },
    },
  },
  actionsContainer: {
    ...zeroPaddingMargin,
  },
});

export default styles;
