import React, { useState } from "react";

import { Badge } from "@material-ui/core";
import NotificationPopUp from "components/Notifications/NotificationPopUp";
import Tooltip from "components/ui/Tooltip";
import { useRecentNotifications } from "hooks/queries/useNotifications";

import styles from "./NotificationBellStyles";

const NotificationBell = () => {
  const [isOpen, setisOpen] = useState(false);
  const { data } = useRecentNotifications(6);

  if (!data) {
    return null;
  }

  return (
    <>
      <Badge
        className={styles.notificationBadge}
        badgeContent={data.unread}
        max={99}
        color="primary"
      >
        <Tooltip
          className={styles.tooltip}
          title={
            <NotificationPopUp
              notifications={data.notifications}
              onClose={() => setisOpen(false)}
            />
          }
          open={isOpen}
          noPadding
          backdrop
          placement="bottom-end"
          disableHoverListener
          onBackdropClick={() => setisOpen(false)}
        >
          <img
            alt="notifications"
            className={styles.notificationBell}
            src="/icons/bell.svg"
            onClick={() => setisOpen(true)}
          />
        </Tooltip>
      </Badge>
    </>
  );
};

export default React.memo(NotificationBell);
