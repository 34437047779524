import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import useUserData from "hooks/queries/useUserData";
import { deleteCompanyList } from "shared/client";
import { CompanyDetailsDto } from "shared/model";

export default function useDeleteCompanyList() {
  const client = useQueryClient();

  const { data: userData } = useUserData();

  return useMutation(
    (listId: string) => deleteCompanyList(userData!.companyId, listId),
    {
      mutationKey: mutationKeys.deleteCompanyList,
      // When mutate is called:
      onMutate: async (listId: string) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(queryKeys.ownCompany);
        // Snapshot the previous value
        const previousOrganization = client.getQueryData<CompanyDetailsDto>(
          queryKeys.ownCompany
        );
        // Optimistically update to the new value
        if (previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(queryKeys.ownCompany, {
            ...previousOrganization,
            companyLists: previousOrganization.companyLists.filter(
              (list) => list.id !== listId
            ),
          });
        }
        return { previousOrganization };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(
            queryKeys.ownCompany,
            context.previousOrganization
          );
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        client.invalidateQueries(queryKeys.ownCompany);
      },
    }
  );
}
