import { stylesheet } from "typestyle";

const styles = stylesheet({
  imageContainer: {
    position: "relative",
    $nest: {
      "& button": {
        opacity: 0,
      },
      "&:hover": {
        $nest: {
          "& button": {
            opacity: 1,
          },
        },
      },
    },
  },
  image: {
    width: "100%",
    maxHeight: "300px",
    objectFit: "contain",
    objectPosition: "center",
    marginBottom: "12px",
  },
  deleteIconWrapper: {
    position: "absolute",
    right: "12px",
    bottom: "12px",
  },
  deleteIcon: {
    width: "14px",
    height: "18px",
  },
});

export default styles;
