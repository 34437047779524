import { useInfiniteQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { getGroupsImMeber as getGroupsImMember } from "shared/client";

const DEFAULT_PAGESIZE = 5;

export default function useJoinedGroups(
  { limit }: { limit: number } = {
    limit: DEFAULT_PAGESIZE,
  }
) {
  const { data: ownCompany } = useOwnCompany();

  return useInfiniteQuery(
    queryKeys.groupsImMember(),
    ({ pageParam: offset }) => getGroupsImMember({ offset, limit }),
    {
      enabled: !!ownCompany?.id,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.length !== limit) {
          return null;
        }

        const fetchedCount = allPages.reduce(
          (sum, page) => sum + page.length,
          0
        );

        return fetchedCount;
      },
    }
  );
}
