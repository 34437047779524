import { stylesheet } from "typestyle";

const styles = stylesheet({
  fileUploaderRoot: {
    width: "100%",
    height: "100%",
  },
});

export default styles;
