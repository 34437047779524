import React from "react";

import SvgIcon from "components/ui/SvgIcon";

import styles from "./ProductOrServiceStyles";

type ProductsAndServicesProps = {
  image: string;
  title: string;
  description: string;
};

export default function ProductsAndServices({
  image,
  title,
  description,
}: ProductsAndServicesProps) {
  return (
    <div className={styles.container}>
      {image ? (
        <div className={styles.imageContainer}>
          <img src={image} alt="product or service" className={styles.image} />
        </div>
      ) : (
        <div className={styles.svgContainer}>
          <SvgIcon src="/icons/product_or_service.svg" className={styles.svg} />
        </div>
      )}
      <div className={styles.detailsContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.paragraph}>{description}</p>
      </div>
    </div>
  );
}
