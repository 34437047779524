import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { RFPType, ROUTES } from "@pulsemarket/constants";
import itemStyles from "components/Notifications/NotificationItem.styles";
import ReadCircle from "components/Notifications/ReadCircle";
import { useGetPassport } from "components/Passports/utils";
import Avatar from "components/ui/Avatar/Avatar";
import type { History } from "history";
import { useMessageDetails } from "hooks/queries/commentHooks";
import useOwnCompany from "hooks/queries/useOwnCompany";
import useReadNotification from "hooks/queries/useReadNotification";
import { GroupPageLocationState } from "pages/PulseGroup/GroupPage";
import { GroupSettingTab } from "pages/PulseGroup/GroupSettingsPage/GroupSettingsPage";
import {
  getGroup,
  getMessageDetails,
  getPost,
  getRfpInvitation,
  getRFPresponse,
} from "shared/client";
import { DEFAULT_COMPANY_AVATAR } from "shared/constants/icons";
import {
  CompanyDetailsDto,
  CompanyDto,
  NotificationDto,
  NotificationType,
} from "shared/model";
import { format } from "timeago.js";
import { classes } from "typestyle";

type GroupDeleteNotificationDetails = {
  deletedGroupName: string;
};

type DocumentMessageNotificationDetails = {
  documentName: string;
  documentType: RFPType;
};

type NotificationDetails =
  | GroupDeleteNotificationDetails
  | DocumentMessageNotificationDetails;

export const MESSAGES: Record<
  NotificationType,
  (sender: string, details?: NotificationDetails) => React.ReactFragment
> = {
  [NotificationType.InvitedForRFP]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new RFP
    </>
  ),
  [NotificationType.InvitedForRFI]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new RFI
    </>
  ),
  [NotificationType.InvitedForDDQ]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new DDQ
    </>
  ),
  [NotificationType.InvitedForQTP]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new QTP
    </>
  ),
  [NotificationType.InvitedForPassport]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      invited you to take a Passport
    </>
  ),
  [NotificationType.RFPResponseReceived]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new RFP Response
    </>
  ),
  [NotificationType.RFIResponseReceived]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new RFI Response
    </>
  ),
  [NotificationType.DDQResponseReceived]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new DDQ Response
    </>
  ),
  [NotificationType.QTPResponseReceived]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      sent you a new QTP Response
    </>
  ),
  [NotificationType.GroupInvitationReceived]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      invited you to join a group
    </>
  ),
  [NotificationType.GroupJoinRequestReceived]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      requested to join a group
    </>
  ),
  [NotificationType.GroupInvitationApproved]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      approved your request to join the group
    </>
  ),
  [NotificationType.GroupDeleted]: (
    sender: string,
    details?: NotificationDetails
  ) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      deleted{" "}
      <span className={itemStyles.orange}>
        {(details as GroupDeleteNotificationDetails)?.deletedGroupName}
      </span>
      . The group will no be longer accesible.
    </>
  ),
  [NotificationType.PostApproveRequestReceived]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      requested a post to be approved
    </>
  ),
  [NotificationType.PostApproved]: (sender: string) => (
    <>
      <span className={itemStyles.name}>{sender}</span>
      <br />
      approved your post
    </>
  ),
  [NotificationType.DocumentMessageReceived]: (
    sender: string,
    details?: NotificationDetails
  ) => (
    <>
      <span className={itemStyles.name}>Message from {sender}</span>
      <br />
      You got a message on the{" "}
      {(details as DocumentMessageNotificationDetails)?.documentName}{" "}
      {(details as DocumentMessageNotificationDetails)?.documentType}
    </>
  ),
  [NotificationType.DocumentReplyReceived]: (
    sender: string,
    details?: NotificationDetails
  ) => (
    <>
      <span className={itemStyles.name}>Reply from {sender}</span>
      <br />
      You got a reply from your message on the{" "}
      {(details as DocumentMessageNotificationDetails)?.documentName}{" "}
      {(details as DocumentMessageNotificationDetails)?.documentType}
    </>
  ),
  [NotificationType.OwnPassportReportReady]: (_sender: string) => (
    <>
      <span className={itemStyles.name}>
        You Did It: ESG Passport Complete!
      </span>
      <br />
      Congratulations, Your ESG Action Guide and Certificate are ready!
    </>
  ),
  [NotificationType.InvitedContactPassportReportReady]: (sender: string) => (
    <>
      <span className={itemStyles.name}>Your invited contact report</span>
      <br />
      The report of {sender} is ready, you can access and view it.
    </>
  ),
  [NotificationType.RenewPassport]: (_sender: string) => (
    <>
      <span className={itemStyles.name}>Renew your ESG Passport</span>
      <br />
      Your Pulse ESG passport is close to expire, the new passport is available
      for you.
    </>
  ),
};

async function getResponse(rfpResponseId: string, company: CompanyDetailsDto) {
  const response = await getRFPresponse(rfpResponseId);

  // FIXME: Remove comment after refactoring routing logic on RFPEditor side
  // client.setQueryData(
  //   queryKeys.rfpResponse(company.id, rfpResponseId),
  //   response
  // );

  return response;
}

type PulseHistoryState =
  GroupPageLocationState /*| extend later on for type safety*/;

type HistoryParameters = Parameters<History<PulseHistoryState>["push"]>;

/** todo use react query to fetch data to cache */
export const NotificationRoutes: Record<
  NotificationType,
  (
    id: string,
    company: CompanyDetailsDto
  ) => Promise<HistoryParameters | undefined> | HistoryParameters
> = {
  [NotificationType.InvitedForRFP]: async (invitationId) => {
    const invitation = await getRfpInvitation(invitationId);
    return [`/rfpresponses/rfp/${invitation.rfpId}/invitation/${invitationId}`];
  },
  [NotificationType.InvitedForRFI]: async (invitationId) => {
    const invitation = await getRfpInvitation(invitationId);
    return [`/rfiresponses/rfi/${invitation.rfpId}/invitation/${invitationId}`];
  },
  [NotificationType.InvitedForDDQ]: async (invitationId) => {
    const invitation = await getRfpInvitation(invitationId);
    return [`/ddqresponses/ddq/${invitation.rfpId}/invitation/${invitationId}`];
  },
  [NotificationType.InvitedForQTP]: async (invitationId) => {
    const invitation = await getRfpInvitation(invitationId);
    return [`/qtpresponses/qtp/${invitation.rfpId}/invitation/${invitationId}`];
  },
  [NotificationType.InvitedForPassport]: async (invitationId) => {
    const invitation = await getRfpInvitation(invitationId);

    if (invitation.responseId) {
      if (invitation.responseDate) {
        return [`/passports/esg/my_passports`];
      }
      return [
        `/passportresponses/passport/${invitation.rfpId}/invitation/${invitationId}`,
      ];
    }
    return;
  },
  [NotificationType.RFPResponseReceived]: async (rfpResponseId, company) => {
    const response = await getResponse(rfpResponseId, company);
    return [`/rfp/${response.rfpId}/response/${response.id}`];
  },
  [NotificationType.RFIResponseReceived]: async (rfpResponseId, company) => {
    const response = await getResponse(rfpResponseId, company);
    return [`/rfi/${response.rfpId}/response/${response.id}`];
  },
  [NotificationType.DDQResponseReceived]: async (rfpResponseId, company) => {
    const response = await getResponse(rfpResponseId, company);
    return [`/ddq/${response.rfpId}/response/${response.id}`];
  },
  [NotificationType.QTPResponseReceived]: async (rfpResponseId, company) => {
    const response = await getResponse(rfpResponseId, company);
    return [`/qtp/${response.rfpId}/response/${response.id}`];
  },
  [NotificationType.GroupInvitationReceived]: async (groupId) => {
    const group = await getGroup(groupId);
    return [ROUTES.GROUP(group.id)];
  },
  [NotificationType.GroupJoinRequestReceived]: async (groupId) => {
    const group = await getGroup(groupId);
    return [ROUTES.GROUP(group.id)];
  },
  [NotificationType.GroupInvitationApproved]: async (groupId) => {
    const group = await getGroup(groupId);
    return [ROUTES.GROUP(group.id)];
  },
  [NotificationType.GroupDeleted]: () => {
    return [`/groups`];
  },
  [NotificationType.PostApproved]: async (postId) => {
    const post = await getPost(postId);
    return [ROUTES.GROUP(post.groupId)];
  },
  [NotificationType.PostApproveRequestReceived]: async (groupId) => {
    return [
      ROUTES.GROUP(groupId),
      { initialSettingsPage: GroupSettingTab.Posts },
    ];
  },
  [NotificationType.DocumentMessageReceived]: async (messageId) => {
    const messageDetails = await getMessageDetails(messageId);
    // todo constant routes
    return [
      `/${messageDetails.rfpType.toLocaleLowerCase()}responses/${messageDetails.rfpType.toLowerCase()}/${
        messageDetails.rfpId
      }/response/${messageDetails.rfpResponseId}#message-${messageDetails.id}`,
    ];
  },
  [NotificationType.DocumentReplyReceived]: async (messageId) => {
    const messageDetails = await getMessageDetails(messageId);

    return [
      `/${messageDetails.rfpType.toLocaleLowerCase()}/${
        messageDetails.rfpId
      }/response/${messageDetails.rfpResponseId}#message-${messageDetails.id}`,
    ];
  },
  [NotificationType.OwnPassportReportReady]: () => {
    return [`/passports/esg/my_passports`];
  },
  [NotificationType.InvitedContactPassportReportReady]: () => {
    // todo redirect to correct url
    return [`/passports/esg/invitations`];
  },
  [NotificationType.RenewPassport]: () => {
    return [`/passports/esg/my_passports`];
  },
};

function useCustomNotificationClickActionHandlers() {
  const getPassport = useGetPassport();

  const customNotificationClickActionHandlers: Record<
    NotificationType,
    () => void
  > = {
    [NotificationType.InvitedForPassport]: getPassport,
    [NotificationType.RenewPassport]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.InvitedForRFP]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.InvitedForDDQ]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.InvitedForRFI]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.InvitedForQTP]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.RFPResponseReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.DDQResponseReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.RFIResponseReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.QTPResponseReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.GroupInvitationReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.GroupJoinRequestReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.GroupInvitationApproved]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.GroupDeleted]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.PostApproveRequestReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.PostApproved]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.DocumentMessageReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.DocumentReplyReceived]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.OwnPassportReportReady]: function (): void {
      throw new Error("Function not implemented.");
    },
    [NotificationType.InvitedContactPassportReportReady]: function (): void {
      throw new Error("Function not implemented.");
    },
  };

  return customNotificationClickActionHandlers;
}

export const NOTIFICATION_IMAGES: Record<
  NotificationType,
  (company: CompanyDto) => string | undefined
> = {
  [NotificationType.InvitedForRFP]: (company) => {
    return company.avatar;
  },
  [NotificationType.InvitedForRFI]: (company) => {
    return company.avatar;
  },
  [NotificationType.InvitedForDDQ]: (company) => {
    return company.avatar;
  },
  [NotificationType.InvitedForQTP]: (company) => {
    return company.avatar;
  },
  [NotificationType.InvitedForPassport]: (company) => {
    return company.avatar;
  },
  [NotificationType.RFPResponseReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.RFIResponseReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.DDQResponseReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.QTPResponseReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.GroupInvitationReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.GroupJoinRequestReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.GroupInvitationApproved]: (company) => {
    return company.avatar;
  },
  [NotificationType.GroupDeleted]: (company) => {
    return company.avatar;
  },
  [NotificationType.PostApproved]: (company) => {
    return company.avatar;
  },
  [NotificationType.PostApproveRequestReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.DocumentMessageReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.DocumentReplyReceived]: (company) => {
    return company.avatar;
  },
  [NotificationType.OwnPassportReportReady]: () => {
    return "/illustrations/ESG.png";
  },
  [NotificationType.InvitedContactPassportReportReady]: () => {
    return "/illustrations/ESG.png";
  },
  [NotificationType.RenewPassport]: () => {
    return "/illustrations/ESG.png";
  },
};

export function useNotificationClick(notification: NotificationDto) {
  const readAction = useReadNotification();
  const history = useHistory();
  const { data: company } = useOwnCompany();

  const customNotificationClickAction =
    useCustomNotificationClickActionHandlers();

  const handleClick = useCallback(async () => {
    if (!company) {
      return;
    }

    readAction.mutate({ notification, isRead: true });

    const route = await NotificationRoutes[notification.type](
      notification.relatedId,
      company
    );

    !!route
      ? history.push(...route)
      : customNotificationClickAction[notification.type]();
  }, [
    company,
    readAction,
    notification,
    history,
    customNotificationClickAction,
  ]);

  return handleClick;
}

const NotificationItem = ({
  notification,
  onItemClick,
}: {
  notification: NotificationDto;
  onItemClick: () => void;
}) => {
  const handleClick = useNotificationClick(notification);

  const queryRelatedMessage =
    notification.type === NotificationType.DocumentMessageReceived ||
    notification.type === NotificationType.DocumentReplyReceived;

  const { data: messageDetails } = useMessageDetails({
    messageId: notification.relatedId,
    enabled: queryRelatedMessage,
  });

  return (
    <div
      className={classes(itemStyles.wrapper, notification.isRead && "read")}
      onClick={() => {
        onItemClick();
        handleClick();
      }}
    >
      <Avatar
        size="44"
        src={
          NOTIFICATION_IMAGES[notification.type](notification.sentByCompany) ||
          DEFAULT_COMPANY_AVATAR
        }
      />
      <div className={itemStyles.center}>
        <div className={itemStyles.message}>
          {MESSAGES[notification.type]?.(notification.sentByCompany.name, {
            deletedGroupName: notification.relatedId,
            documentName: messageDetails?.rfpName,
            documentType: messageDetails?.rfpType,
          })}
        </div>
        <div className={itemStyles.date}>{format(notification.createdAt)}</div>
      </div>
      <ReadCircle isRead={notification.isRead} />
    </div>
  );
};

export default NotificationItem;
