import { neutralColors, utilityColors } from "@pulsemarket/constants";
import { clickable, zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  titleContainer: { padding: 0, marginBottom: "24px" },
  title: {
    ...zeroPaddingMargin,
    ...typography.HEADLINE6,
    color: "inherit",
    display: "flex",
    flexDirection: "row",
  },
  closeIcon: {
    ...clickable,
    color: neutralColors.GRAY5,
    width: "14px",
    height: "14px",
    $nest: {
      "&:hover": {
        color: utilityColors.RED_HOVER,
      },
    },
  },
});

export default styles;
