import { percent } from "csx";
import { style, stylesheet } from "typestyle";

export const svgIconColor = (color?: string, hoverColor?: string) =>
  style({
    $nest: {
      // with currentColor, the fill inherits the color value set, but there are some use cases where color and backgroundColor props are used
      // so we can't remove it yet
      "&:hover": {
        $nest: {
          "& path": {
            fill: hoverColor || "currentColor",
          },
        },
      },
      "& path": {
        fill: color || "currentColor",
      },
    },
  });

const styles = stylesheet({
  wrapper: {
    $nest: {
      // react-svg v14 adds two wrappers to the svg, see:
      // https://github.com/tanem/react-svg#faq and https://github.com/tanem/react-svg/blob/master/CHANGELOG.md#v1400-2021-06-08
      "& > div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      },
    },
  },
  baseIcon: {
    display: "block",
    objectFit: "cover",
    objectPosition: "center",
    height: percent(100),
    width: percent(100),
  },
});

export default styles;
