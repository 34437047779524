import { primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  mainContainer: {
    padding: "57px",
  },
  overviewContainer: {
    flex: 1,
    padding: "36px",
    backgroundColor: primaryColors.WHITE,
    borderRadius: "8px",
  },
  overviewTitle: {
    ...typography.HEADLINE6,
    marginTop: "0",
  },
  paragraph: {
    ...typography.BODY1,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  videoContainer: {
    width: "100%",
    marginTop: "24px",
    borderRadius: "8px",
  },
  video: {
    width: "100%",
    height: "250px",
    border: "none",
    borderRadius: "8px",
  },
  industryTagBox: {
    backgroundColor: primaryColors.WHITE,
    width: "50%",
    borderRadius: "8px",
    marginRight: "8px",
  },
  tagBox: {
    backgroundColor: primaryColors.WHITE,
    width: "50%",
    borderRadius: "8px",
  },
  tagsContainer: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    padding: "24px 0px",
    gap: "20px",
  },
  basicInfoContainer: {
    position: "relative",
    height: "fit-content",
  },
});
