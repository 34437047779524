import { useEffect, useState } from "react";

const DEFAULT_OPTIONS: MutationObserverInit = {
  characterData: true,
  subtree: true,
};

export function useMutationObservable(
  targetEl: HTMLElement | null,
  cb: MutationCallback,
  config: MutationObserverInit = DEFAULT_OPTIONS
) {
  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb, setObserver, config]);

  useEffect(() => {
    if (!observer || !targetEl) return;

    observer.observe(targetEl, config);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, config]);
}
