import { neutralColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

export default stylesheet({
  container: {
    background: neutralColors.GRAY0,
    border: `1px solid ${neutralColors.GRAY3}`,
    borderRadius: "8px",
    height: "100%",
    padding: "24px",
  },
  sideBarContainer: {
    height: "100%",
  },
  rightSide: {
    height: "100%",
    flexWrap: "nowrap",
  },
  mainContentContainer: {
    background: neutralColors.GRAY1,
    border: `1px solid ${neutralColors.GRAY2}`,
    borderRadius: "8px",
    flex: "1 1 auto", // take up remaining space
    overflowY: "auto",
  },
  mainContent: {
    padding: "32px",
    height: "100%",
  },
});
