import { useInfiniteQuery, useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { getNotifications } from "shared/client";

const BATCH_SIZE = 10;

export default function useNotifications(pageSize = BATCH_SIZE) {
  const { data: company } = useOwnCompany();

  return useInfiniteQuery(
    queryKeys.notifications(company?.id),
    async ({ pageParam }) => {
      const response = await getNotifications({
        limit: pageSize,
        cursorId: pageParam,
      });

      return response;
    },
    {
      enabled: !!company?.id,
      getNextPageParam(lastPage) {
        if (lastPage.notifications.length === pageSize) {
          return lastPage.notifications.slice().reverse()[0].id;
        }

        return null;
      },
    }
  );
}

export function useRecentNotifications(pageSize = 6) {
  const { data: company } = useOwnCompany();

  return useQuery(
    queryKeys.recentNotifications(company?.id),
    () =>
      getNotifications({
        limit: pageSize,
      }),
    {
      enabled: !!company?.id,
      refetchInterval: 1000 * 10,
    }
  );
}
