import React, { useCallback, useContext, useState } from "react";
import ReactConfetti, { Props as ConfettiProps } from "react-confetti";

import { utilityColors } from "@pulsemarket/constants";
import { useWindowSize } from "hooks/useWindowsSize";

import { sideBarWidth } from "layout/SideMenu/SideMenuStyles";

export type ConfettiContextType = {
  showConfetti: (props: ConfettiProps) => void;
};

export const ConfettiContext = React.createContext<ConfettiContextType>({
  showConfetti: () => {},
});

export function useConfettiContext() {
  return useContext(ConfettiContext);
}

const defaultConfettiColors = [
  utilityColors.GREEN,
  utilityColors.GREEN_OUTLINE_HOVER,
  utilityColors.ORANGE,
  utilityColors.CYAN,
  utilityColors.LILAC,
  utilityColors.YELLOW,
  // Added more green color to highilght the ESG colors
  utilityColors.GREEN_OUTLINE_HOVER,
  utilityColors.GREEN,
  utilityColors.GREEN,
  utilityColors.GREEN,
];

export default function ConfettiProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [props, setProps] = useState<ConfettiProps | null>(null);
  const showConfetti = useCallback(
    (props: ConfettiProps) => {
      setProps(props);
    },
    [setProps]
  );

  const { windowHeight, windowWidth } = useWindowSize();

  const canvas = {
    height: props?.height || windowHeight * 0.7,
    width: props?.width || windowWidth * 0.5,
  };

  return (
    <ConfettiContext.Provider value={{ showConfetti }}>
      {children}
      {props && (
        <ReactConfetti
          //change the gracity property to make the confetti fall faster or slower
          // gravity={0.5}
          //change the wind property to make the confetti blow left or right
          // wind={0.5}
          {...props}
          style={{
            top: `calc(50% - ${canvas.height / 2}px)`,
            left: `calc(50% - ${
              canvas.width / 2
            }px + ${sideBarWidth})` /* 85px is the width of the sidebar */,
            ...props.style,
          }}
          width={canvas.width}
          height={canvas.height}
          confettiSource={
            props.confettiSource || {
              x: (canvas.width * 5) / 12,
              y: (canvas.height * 5) / 12,
              w: (canvas.width * 2) / 12,
              h: (canvas.height * 2) / 12,
            }
          }
          colors={props.colors || defaultConfettiColors}
          recycle={typeof props.recycle === "undefined" ? false : props.recycle}
          onConfettiComplete={(...args) => {
            props.onConfettiComplete?.(...args);
            setProps(null);
          }}
        />
      )}
    </ConfettiContext.Provider>
  );
}
