import { CompanyListDto } from "shared/model";
// TODO: This function is O(mn) time - need to improve the api to reduce this, or smarter algo
export const checkIsInOrg = (id: string, companyLists: CompanyListDto[]) => {
  let isInOrg = false;
  companyLists.forEach((list) => {
    list.companyIds.forEach((companyId) => {
      if (companyId === id) {
        isInOrg = true;
      }
    });
  });
  return isInOrg;
};
