import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import {
  getCountries,
  getEmployeeNumbers,
  getIndustries,
  getPassportTypes,
  getSectors,
  getSpecialties,
  getZones,
} from "shared/client";
import { Pair } from "shared/model";

export default function useVendorSearchOptions() {
  // ! https://github.com/tannerlinsley/react-query/pull/1527 - useQueries isn't strongly typed, we are better off with many queries

  const { data: industries, isLoading: industriesLoading } = useQuery({
    queryKey: queryKeys.industries,
    queryFn: () => getIndustries(),
    ...longCachedQueryOptions,
  });
  const { data: sectors, isLoading: sectorsLoading } = useQuery({
    queryKey: queryKeys.sectors,
    queryFn: () => getSectors(),
    ...longCachedQueryOptions,
  });
  const { data: specialities, isLoading: specialitiesLoading } = useQuery({
    queryKey: queryKeys.specialities,
    queryFn: () => getSpecialties(),
    ...longCachedQueryOptions,
  });
  const { data: countries, isLoading: countriesLoading } = useQuery({
    queryKey: queryKeys.countries,
    queryFn: () => getCountries(),
    ...longCachedQueryOptions,
  });
  const { data: zones, isLoading: zonesLoading } = useQuery({
    queryKey: queryKeys.zones,
    queryFn: () => getZones(),
    ...longCachedQueryOptions,
  });
  const { data: employeeNumbers, isLoading: employeesLoading } = useQuery({
    queryKey: queryKeys.employeeNumbers,
    queryFn: () => getEmployeeNumbers(),
    ...longCachedQueryOptions,
  });
  const { data: passports, isLoading: passportsLoading } = useQuery({
    queryKey: queryKeys.passports,
    queryFn: () => getPassportTypes(),
    ...longCachedQueryOptions,
    select: (passports): Pair[] =>
      passports.map((passport) => ({ id: passport.id, name: passport.name })),
  });

  const loading =
    industriesLoading ||
    sectorsLoading ||
    specialitiesLoading ||
    countriesLoading ||
    zonesLoading ||
    employeesLoading ||
    passportsLoading;
  //TODO --> remove financial service filter when financial passport is added
  return {
    loading,
    industries: industries || [],
    sectors: sectors || [],
    specialities: specialities || [],
    countries: countries || [],
    zones: zones || [],
    employeeNumbers: employeeNumbers || [],
    passports:
      passports?.filter(
        (passport) => passport.id !== "financial_service_readiness_v1"
      ) || [],
  };
}
