import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";

const selectedDisplayStyles: NestedCSSProperties = {
  background: "#69385E!important",
  color: "#FFFFFF!important",
};

const styles = stylesheet({
  displaySelectorButton: {
    background: "#FFFFFF",
    color: primaryColors.PURPLE2,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    border: `1px solid ${neutralColors.GRAY2}`,
    $nest: {
      "&.left": {
        borderRadius: "4px 0px 0px 4px",
      },
      "&.right": {
        borderRadius: "0px 4px 4px 0px",
      },
      "&:hover": {
        ...selectedDisplayStyles,
      },
    },
  },
  selectedDisplayButton: selectedDisplayStyles,
});

export default styles;
