import { useEffect, useState } from "react";

import fastDeepEqual from "fast-deep-equal";

type SetterFunction<T> = (state: T) => Partial<T>;
type ListenerFunction = () => void;

const STORE: Record<string, any> = {};

let devTools: any;

if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
  if ((window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__.connect();
    setTimeout(() => devTools.init(STORE), 200);
  }
}

export default class Store<T> {
  name;
  _listeners: Array<ListenerFunction> = [];
  initialState: T;

  constructor(initialState: T, name: string) {
    this.initialState = initialState;
    this.name = name;

    STORE[this.name] = initialState;

    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV !== "production"
    ) {
      if (devTools) {
        devTools.subscribe((message: any) => {
          switch (message.payload && message.payload.type) {
            case "JUMP_TO_STATE":
            case "JUMP_TO_ACTION":
              STORE[this.name] = JSON.parse(message.state)[this.name];
              this._listeners.forEach((fn) => fn());
          }
        });
      }
    }
  }

  get(): T {
    return STORE[this.name];
  }

  set(state: SetterFunction<T>, info?: string) {
    STORE[this.name] = {
      ...STORE[this.name],
      ...state(STORE[this.name]),
    };

    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV !== "production"
    ) {
      if (devTools) {
        devTools.send(this.name ? this.name + " - " + info : info, STORE);
      }
    }

    this._listeners.forEach((fn) => fn());
  }

  replace(state: SetterFunction<T>, info?: string) {
    STORE[this.name] = state(STORE[this.name]);

    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV !== "production"
    ) {
      if (devTools) {
        devTools.send(this.name ? this.name + " - " + info : info, STORE);
      }
    }

    this._listeners.forEach((fn) => fn());
  }

  reset() {
    STORE[this.name] = this.initialState;

    this._listeners.forEach((fn) => fn());
  }

  subscribe(fn: ListenerFunction) {
    this._listeners.push(fn);
  }

  unsubscribe(fn: ListenerFunction) {
    this._listeners = this._listeners.filter((f) => f !== fn);
  }
}

export function getGlobalState() {
  return STORE;
}

export function useStore<T>(store: Store<T>) {
  const [state, setState] = useState(store.get());

  useEffect(() => {
    const updateState = () => {
      const newState = store.get();

      if (!fastDeepEqual(state, newState)) {
        setState(newState);
      }
    };

    store.subscribe(updateState);

    return () => store.unsubscribe(updateState);
  }, [store, state]);

  return state;
}

// export function useSelector<T, X = T>(
//   store: Store<T>,
//   selector: (storeState: T) => X = (x) => x as unknown as X
// ) {
//   const [state, setState] = useState(selector(store.get()));

//   const updateState = useCallback(() => {
//     console.log("Running updater");

//     const newState = selector(store.get());

//     console.log("Recieved new state", newState);

//     if (!fastDeepEqual(state, newState)) {
//       console.log("Setting new state", store.name);

//       setState(newState);
//     }
//   }, [setState, state, store, selector]);

//   useEffect(() => {
//     console.log("Registering updater");
//     store.subscribe(updateState);

//     return () => store.unsubscribe(updateState);
//   }, [store, updateState]);

//   return state;
// }

export function createPersistedStore<T>(defaultState: T, name: string) {
  const item = localStorage.getItem(name);
  const persistedState = item ? JSON.parse(item) : {};

  const state = {
    ...defaultState,
    ...persistedState,
  };

  const store = new Store<T>(state, name);

  store.subscribe(() => {
    localStorage.setItem(store.name, JSON.stringify(store.get()));
  });

  return store;
}
