import React from "react";

import { Loading } from "components/FullScreenLoading";
import NoContent from "components/ui/NoContent";
import useOwnCompany from "hooks/queries/useOwnCompany";
import GroupPageCard from "pages/PulseGroup/GroupPageCard";

type ReviewFilesProps = {};

const ReviewPosts = (_props: ReviewFilesProps) => {
  const { data: ownCompany } = useOwnCompany();

  if (!ownCompany) {
    return <Loading />;
  }

  return (
    <GroupPageCard>
      <NoContent icon={{ src: "/icons/folder_icon.svg" }}>
        You'll soon be able to review files uploaded by others here
      </NoContent>
    </GroupPageCard>
  );
};

export default ReviewPosts;
