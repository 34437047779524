import { useQueryClient } from "react-query";

import { switchCompany } from "shared/client";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";

export default function useSwitchCompany() {
  const client = useQueryClient();

  return async ({ companyId }: { companyId: string }) => {
    localStorage.setItem(ACTING_COMPANY_ID, companyId);
    await switchCompany({ activeCompanyId: companyId });
    client.resetQueries();
  };
}
