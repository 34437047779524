import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

export const styles = stylesheet({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: neutralColors.GRAY0,
    $nest: {
      "@media screen and (max-width: 992px)": {
        height: "auto",
      },
    },
  },
  leftSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    height: "100%",
    width: "54%",
    padding: "66px",
    // hidden on small screens
    $nest: {
      "@media screen and (max-width: 992px)": {
        display: "none",
      },
    },
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    cursor: "pointer",
  },
  icon: { color: primaryColors.PURPLE2, width: "125px" },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  image: { width: "60%", height: "60%" },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "46%",
    height: "100%",
    backgroundColor: primaryColors.WHITE,
    borderTopLeftRadius: "60px",
    borderBottomLeftRadius: "60px",
    padding: "5% 10%",
    $nest: {
      "@media screen and (max-width: 992px)": {
        maxWidth: "650px",
        width: "100%",
        borderRadius: "0px",
        justifyContent: "normal",
        height: "100%",
      },
    },
  },
  rightSideIconContainer: {
    cursor: "pointer",
    alignSelf: "start",
    marginBottom: "50px",
    $nest: {
      "@media screen and (min-width: 992px)": {
        display: "none",
      },
    },
  },
  formContainer: { width: "80%", height: "60%" },
});
