import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { FolderAPIItem } from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import { getSideMenuFoldersFilters } from "components/AssetRepository/constants";
import { useAssetContext } from "contexts/AssetFilter/AssetFilterProvider";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { ApiError, renameFolder } from "shared/client";
import { FolderItemDto } from "shared/model";

type RenameFolderParams = {
  folderId: string;
  newName: string;
};

export default function useRenameFolder() {
  const client = useQueryClient();
  const { searchOptions } = useAssetContext();

  const { data: ownCompany } = useOwnCompany();

  const ROOT_FOLDER_ID = ownCompany?.assetRepositoryId;
  const sideMenuFoldersFilters = getSideMenuFoldersFilters();

  return useMutation(
    ({ folderId, newName }: RenameFolderParams) => {
      return renameFolder(ownCompany!.id, folderId, newName);
    },
    {
      mutationKey: mutationKeys.renameFolder,
      retry: (_, error) => {
        if (error instanceof ApiError) {
          if (error.status === 409) {
            return false;
          }
        }
        return true;
      },
      // When mutate is called:
      onMutate: async ({ folderId, newName }: RenameFolderParams) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );

        // Snapshot the previous values
        const previousSearchResult = client.getQueryData<FolderAPIItem[]>(
          queryKeys.assetRepositorySearch(searchOptions)
        );
        // Optimistically update to the new value
        if (previousSearchResult) {
          client.setQueryData<FolderAPIItem[]>(
            queryKeys.assetRepositorySearch(searchOptions),
            previousSearchResult.map((item) =>
              item.id === folderId ? { ...item, name: newName } : item
            )
          );
        }

        let previousRootDirectory: FolderItemDto[] | null = null;
        if (folderId === ROOT_FOLDER_ID) {
          await client.cancelQueries(
            queryKeys.assetRepositorySearch(sideMenuFoldersFilters)
          );

          previousRootDirectory =
            client.getQueryData<FolderItemDto[]>(
              queryKeys.assetRepositorySearch(sideMenuFoldersFilters)
            ) || null;

          if (previousRootDirectory) {
            client.setQueryData<FolderItemDto[]>(
              queryKeys.assetRepositorySearch(sideMenuFoldersFilters),

              previousRootDirectory.map((item) =>
                item.id === folderId ? { ...item, name: newName } : item
              )
            );
          }
        }

        return { previousSearchResult, previousRootDirectory };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousSearchResult) {
          client.setQueryData<FolderAPIItem[]>(
            queryKeys.assetRepositorySearch(searchOptions),
            context.previousSearchResult
          );
        }
        if (
          searchOptions.folderId === ROOT_FOLDER_ID &&
          context?.previousRootDirectory
        ) {
          client.setQueryData<FolderItemDto[]>(
            queryKeys.assetRepositorySearch(sideMenuFoldersFilters),
            context.previousRootDirectory
          );
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );

        client.invalidateQueries(queryKeys.allFolders);
      },
    }
  );
}
