import React from "react";

import { DEFAULT_COMPANY_AVATAR } from "shared/constants/icons";
import { GroupInvitationDto } from "shared/model";
import { WithRequired } from "shared/utilityTypes";

import { groupMemberDataStyles as styles } from "./GroupMembers.styles";

type GroupMemberDataProps = {
  invitation: WithRequired<GroupInvitationDto, "invitedCompany">;
};

export default function GroupMemberData({ invitation }: GroupMemberDataProps) {
  return (
    <div className={styles.companyContainer}>
      <div className={styles.memberCompanyAvatarContainer}>
        <img
          className={styles.memberCompanyAvatar}
          alt={`${invitation.invitedCompany.name} avatar`}
          src={invitation.invitedCompany.avatar || DEFAULT_COMPANY_AVATAR}
        />
      </div>
      <div className={styles.inviteeInfoContainer}>
        <span className={styles.memberCompanyName}>
          {invitation.invitedCompany.name}
        </span>
        <span className={styles.inviteeLocation}>
          {invitation.invitedCompany.address}
        </span>
      </div>
    </div>
  );
}
