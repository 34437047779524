import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const getSharedButtonStyles = (
  color: string,
  backgroundColor: string,
  hover: string,
  active: string
) => ({
  backgroundColor,
  color,
  border: 0,
  $nest: {
    "&:hover": {
      backgroundColor: hover,
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: active,
    },
  },
});

const styles = stylesheet({
  // Sizes are declared inside mui theme
  root: {
    ...typography.BUTTON_MEDIUM,
  },
  small: {
    ...typography.BUTTON_SMALL,
  },
  large: {
    ...typography.BUTTON_LARGE,
  },
  startIcon: {
    marginRight: "12px",
  },
  endIcon: {
    marginLeft: "12px",
  },
  loadingIcon: {
    height: "20px!important",
    width: "20px!important",
  },
});

export const normalVariantStyles = stylesheet({
  gray: getSharedButtonStyles(
    primaryColors.BLACK,
    neutralColors.GRAY3,
    neutralColors.GRAY3_HOVER,
    neutralColors.GRAY3_ACTIVE
  ),
  green: getSharedButtonStyles(
    primaryColors.WHITE,
    utilityColors.GREEN,
    utilityColors.GREEN,
    utilityColors.GREEN
  ),
  purple: getSharedButtonStyles(
    primaryColors.WHITE,
    primaryColors.PURPLE2,
    primaryColors.PURPLE2_HOVER,
    primaryColors.PURPLE3
  ),
  orange: getSharedButtonStyles(
    primaryColors.WHITE,
    utilityColors.ORANGE,
    utilityColors.ORANGE_HOVER,
    utilityColors.ORANGE_ACTIVE
  ),
  red: getSharedButtonStyles(
    primaryColors.WHITE,
    utilityColors.RED,
    utilityColors.RED_HOVER,
    utilityColors.RED_ACTIVE
  ),
  orangeHover: getSharedButtonStyles(
    utilityColors.ORANGE_HOVER,
    utilityColors.ORANGE_OUTLINED_HOVER,
    utilityColors.ORANGE_LIGHT_HOVER,
    utilityColors.ORANGE_ACTIVE
  ),
  noShadow: {
    boxShadow: "none",
  },
});

export const transparentVariantStyles = stylesheet({
  root: {
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    $nest: {
      "&:hover": {
        background: primaryColors.HOVER,
      },
    },
  },
  gray: {
    color: neutralColors.GRAY5,
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.HOVER,
        color: primaryColors.BLACK,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: primaryColors.ACTIVE,
      },
      "&.disabled": {
        backgroundColor: "transparent",
        color: neutralColors.GRAY5,
        fontStyle: "italic",
      },
    },
  },
  orange: {
    color: utilityColors.ORANGE,
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.HOVER,
        color: utilityColors.ORANGE,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: primaryColors.ACTIVE,
      },
    },
  },
});

export const outlinedVariantStyles = stylesheet({
  root: {
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    $nest: {
      "&:hover": {
        background: primaryColors.HOVER,
      },
    },
  },

  orange: {
    color: utilityColors.ORANGE,
    border: `1.5px solid ${utilityColors.ORANGE}`,
    $nest: {
      "&:hover": {
        backgroundColor: utilityColors.ORANGE_OUTLINED_HOVER,
        color: utilityColors.ORANGE,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: utilityColors.ORANGE_OUTLINED_ACTIVE,
      },
    },
  },
  purple: {
    color: utilityColors.LILAC,
    border: `1.5px solid ${utilityColors.LILAC}`,
    $nest: {
      "&:hover": {
        backgroundColor: primaryColors.PURPLE1,
        color: utilityColors.LILAC,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: primaryColors.PURPLE1,
      },
    },
  },
  green: {
    color: utilityColors.GREEN,
    border: `1.5px solid ${utilityColors.GREEN}`,
    $nest: {
      "&:hover": {
        backgroundColor: utilityColors.GREEN_OUTLINE_HOVER,
        color: utilityColors.GREEN,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: utilityColors.GREEN_OUTLINE_FOCUSED,
      },
    },
  },
  red: {
    color: utilityColors.RED,
    border: `1.5px solid ${utilityColors.RED}`,
    $nest: {
      "&:hover": {
        backgroundColor: utilityColors.RED_OUTLINE_HOVER,
        color: utilityColors.RED,
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: utilityColors.RED_OUTLINE_FOCUSED,
      },
    },
  },
});

export const textVariantStyles = stylesheet({
  black: {
    color: primaryColors.BLACK,
    padding: "0",
    $nest: {
      "&:hover": {
        backgroundColor: "transparent",
        color: utilityColors.ORANGE,
        boxShadow: "none",
        textDecoration: "underline",
      },
    },
  },
  orange: {
    color: utilityColors.ORANGE,
    padding: "0",
    $nest: {
      "&:hover": {
        backgroundColor: "transparent",
        color: utilityColors.ORANGE_HOVER,
        boxShadow: "none",
        textDecoration: "underline",
      },
    },
  },
  gray: {
    color: neutralColors.GRAY4,
    padding: "0",
    $nest: {
      "&:hover": {
        backgroundColor: "transparent",
        color: neutralColors.GRAY3_HOVER,
        boxShadow: "none",
        textDecoration: "underline",
      },
    },
  },
});

export const linkVariantStyles = stylesheet({
  root: {
    boxShadow: "none",
    textDecoration: "underline",
    padding: "0",
    $nest: {
      "&.disabled": {
        color: neutralColors.GRAY4,
        backgroundColor: "transparent",
        textDecoration: "none",
      },
    },
  },

  orange: {
    color: utilityColors.ORANGE,
    $nest: {
      "&:hover": {
        backgroundColor: "transparent",
        color: utilityColors.ORANGE,
      },
    },
  },
});

export const toggledVariantStyles = stylesheet({
  root: {
    boxShadow: "none",
    textDecoration: "underline",
    padding: "0",
    $nest: {
      "&.disabled": {
        color: neutralColors.GRAY4,
        backgroundColor: "transparent",
        textDecoration: "none",
      },
    },
  },

  orange: {
    color: utilityColors.ORANGE_HOVER,
    backgroundColor: utilityColors.ORANGE_OUTLINED_HOVER,
    $nest: {
      "&:hover": {
        backgroundColor: utilityColors.ORANGE_HOVER,
        color: utilityColors.ORANGE,
      },
    },
  },
});

export default styles;
