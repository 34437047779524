import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  input: {
    ...typography.BODY2,
    color: primaryColors.TEXT_ACTIVE,
    $nest: {
      "&::placeholder": {
        color: primaryColors.TEXT_HELPER,
      },
    },
  },
  labelRoot: {
    ...typography.BODY2,
    color: primaryColors.TEXT_HELPER,
  },
  labelError: {
    color: primaryColors.ERROR,
  },
  labelFocused: {
    color: utilityColors.LILAC,
  },
  outlinedLabel: {
    transform: "translate(14px, 12px) scale(1)",
    zIndex: 1,
    pointerEvents: "none",
    backgroundColor: primaryColors.WHITE,
    padding: "0 4px",
  },
  labelShrink: {
    $nest: {
      "&&": {
        transform: "translate(14px, -6px) scale(0.85)",
      },
    },
  },
  helperText: {
    ...typography.CAPTION3,
    color: primaryColors.TEXT_HELPER,
  },
});

export default styles;
