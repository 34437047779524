import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import { GroupDto } from "@pulsemarket/api-client";
import { ROUTES } from "@pulsemarket/constants";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import ModalContext from "contexts/ModalContext";
import { GroupSettingTab } from "pages/PulseGroup/GroupSettingsPage/GroupSettingsPage";
import { getGroup } from "shared/client";

import GroupCard from "../GroupCard";

function OwnGroupCard({ group }: { group: GroupDto }) {
  const history = useHistory();
  const client = useQueryClient();
  const { openModal, closeModal } = useContext(ModalContext);

  return (
    <GroupCard
      group={group}
      buttons={[
        {
          id: `${group.id}-group-button1`,
          children: "Add member",
          variant: "outlined",
          color: "orange",
          onClick: () =>
            history.push(ROUTES.GROUP(group.id), {
              initialSettingsPage: GroupSettingTab.Members,
            }),
        },
        {
          id: `${group.id}-group-button2`,
          children: "View group",
          variant: "outlined",
          color: "purple",
          onMouseEnter: () =>
            client.prefetchQuery(
              queryKeys.group(group.id),
              () => getGroup(group.id),
              {
                staleTime: 5000,
              }
            ),
          onClick: () => history.push(ROUTES.GROUP(group.id)),
        },
      ]}
      kebabMenuActions={[
        {
          text: "Copy link to group",
          onClick: () => {
            navigator.clipboard.writeText(
              `${window.location.origin}/${ROUTES.GROUP(group.id)}`
            );
            openModal(
              <SimpleModalBody
                title="Link copied"
                buttons={[
                  {
                    variant: "normal",
                    color: "orange",
                    onClick: () => closeModal(),
                    children: "Ok, Thanks",
                  },
                ]}
              >
                The link was copied. Paste to share this group with others.
              </SimpleModalBody>
            );
          },
        },
        {
          text: "Settings",
          onClick: () =>
            history.push(ROUTES.GROUP(group.id), {
              initialSettingsPage: GroupSettingTab.Settings,
            }),
        },
      ]}
    />
  );
}

export default OwnGroupCard;
