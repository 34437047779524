import React from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "reactQuery/client";

import { ThemeProvider } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import ConfettiProvider from "contexts/Confetti/ConfettiProvider";
import { ModalProvider } from "contexts/ModalContext";
import PublicVisitorProvider from "contexts/PublicVisitorContext/PublicVisitorContext";
import { SnackBarProvider } from "contexts/Snackbar/SnackbarProvider";
import theme from "muiTheme";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <PublicVisitorProvider>
            <ConfettiProvider>
              <SnackBarProvider>
                <>
                  <ReactQueryDevtools
                    initialIsOpen={false}
                    position="bottom-right"
                  />
                  <ModalProvider>{children}</ModalProvider>
                </>
              </SnackBarProvider>
            </ConfettiProvider>
          </PublicVisitorProvider>
        </QueryClientProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}
