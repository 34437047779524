import { useCallback, useMemo, useState } from "react";

import { SelectOption } from "components/ui/FormSelect";
import { MultiSelectOption } from "components/ui/MultiSelect/Body/Body";
import { CheckablePair } from "components/ui/MultiSelect/MultiSelect";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffects";

type MultiSelectHookParams = {
  onChange: (selectedIds: string[], changedId?: string) => void;
  options?: SelectOption[];
  selectedIds: string[];
};

export default function useMultiSelect({
  onChange,
  options = [],
  selectedIds,
}: MultiSelectHookParams) {
  const [items, setItems] = useState<CheckablePair[]>([]);
  const [selectedItemsCount, setSelectedItemsCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

  useDeepCompareEffect(() => {
    //initialization or control from parent
    const newSelectionItems = options.map((item) => {
      return {
        ...item,
        checked: selectedIds.includes(item.id),
      };
    });
    setItems(newSelectionItems);
    setSelectedItemsCount(
      newSelectionItems.filter((item) => item.checked).length
    );
  }, [options, selectedIds]);

  const selectionDataChanged = useCallback<
    (newSelectionItems: CheckablePair[], changedId?: string) => void
  >(
    (newSelectionItems, changedId) => {
      setItems(newSelectionItems);
      setSelectedItemsCount(
        newSelectionItems.filter((item) => item.checked).length
      );
      onChange(
        newSelectionItems.filter((item) => item.checked).map((item) => item.id),
        changedId
      );
    },
    [onChange]
  );

  function toggleChecked(item: CheckablePair) {
    const newSelectionItems = items.map((selectionItem) => {
      if (item.id === selectionItem.id) {
        return {
          ...selectionItem,
          checked: !selectionItem.checked,
        };
      }
      return selectionItem;
    });
    selectionDataChanged(newSelectionItems, item.id);
  }

  function toggleAllChecked() {
    if (selectedItemsCount < items.length) {
      const newSelectionItems = items.map((selectionItem) => {
        return {
          ...selectionItem,
          checked: true,
        };
      });
      selectionDataChanged(newSelectionItems);
      return;
    }
    const newSelectionItems = items.map((selectionItem) => {
      return {
        ...selectionItem,
        checked: !selectionItem.checked,
      };
    });
    selectionDataChanged(newSelectionItems);
  }

  function clearChecked() {
    const newSelectionItems = items.map((selectionItem) => {
      return {
        ...selectionItem,
        checked: false,
      };
    });
    selectionDataChanged(newSelectionItems);
  }

  const filteredItems = useMemo<MultiSelectOption[]>(() => {
    const filteredSelectionItems = items.filter((item) => {
      if (search === "") {
        return true;
      }
      return item.name.search(new RegExp(`${search}`, "i")) !== -1;
    });
    return filteredSelectionItems;
  }, [items, search]);

  return {
    search,
    setSearch,
    items,
    selectionDataChanged,
    toggleChecked,
    toggleAllChecked,
    clearChecked,
    selectedItemsCount,
    filteredItems,
  };
}
