import {
  InfiniteData,
  QueryClient,
  useMutation,
  useQueryClient,
} from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { readNotification } from "shared/client";
import { NotificationDto, NotificationsResponse } from "shared/model";

function updateInfiniteCache(
  queryClient: QueryClient,
  notificationId: string,
  isRead: boolean,
  companyId: string
) {
  const data = queryClient.getQueryData<InfiniteData<NotificationsResponse>>(
    queryKeys.notifications(companyId)
  );

  if (!data) {
    return;
  }

  queryClient.setQueryData(queryKeys.notifications(companyId), {
    ...data,
    pages: data.pages.map((page) => ({
      ...page,
      notifications: page.notifications.map((n) =>
        n.id === notificationId ? { ...n, isRead } : n
      ),
      unread: page.unread + (isRead ? -1 : 1),
    })),
  });
}

export default function useReadNotification() {
  const queryClient = useQueryClient();
  const { data: company } = useOwnCompany();

  return useMutation(
    async ({
      notification,
      isRead = true,
    }: {
      notification: NotificationDto;
      isRead?: boolean;
    }) => {
      if (notification.isRead === isRead) {
        return;
      }

      return readNotification(notification.id, isRead);
    },
    {
      onSuccess(_, { notification, isRead = true }) {
        if (notification.isRead === isRead) {
          return;
        }

        updateInfiniteCache(queryClient, notification.id, isRead, company!.id);
      },
    }
  );
}
