import React from "react";

import { Box } from "@material-ui/core";

import styles from "./TabPanelStyles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function TabPanel({
  children,
  value,
  index,
  ...rest
}: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
      className={index ? styles.tabPanel : styles.tabPanelZeroIndex}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
