import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { ToggleLabelOnAssetsInput } from "@pulsemarket/api-client";
import { useAssetContext } from "contexts/AssetFilter/AssetFilterProvider";
import { removeLabelFromAssets } from "shared/client";

import useOwnCompany from "./useOwnCompany";

type RemoveLabelFromAssetsHookParams = {
  onSuccess?: () => void;
};

type RemoveLabelInput = ToggleLabelOnAssetsInput & { labelId: string };

export default function useRemoveLabelFromAssets(
  options?: RemoveLabelFromAssetsHookParams
) {
  const { data: ownCompany } = useOwnCompany();
  const { searchOptions } = useAssetContext();

  const client = useQueryClient();

  return useMutation(
    ({ folderIds, assetIds, labelId }: RemoveLabelInput) =>
      removeLabelFromAssets(ownCompany!.id, labelId, { folderIds, assetIds }),
    {
      mutationKey: mutationKeys.removeLabelFromAssets,
      onSuccess: options?.onSuccess,
      onSettled: () => {
        client.invalidateQueries(
          queryKeys.assetRepositorySearch(searchOptions)
        );
      },
    }
  );
}
