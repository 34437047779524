import { neutralColors } from "@pulsemarket/constants";
import { px } from "csx";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  memberCount: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
    marginBottom: px(10),
  },
});
