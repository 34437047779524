import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    borderRadius: "50%",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "center",
  },
  square: {
    $nest: {
      "&&": {
        borderRadius: "8px",
      },
    },
  },
});

export default styles;
