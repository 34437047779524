import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  container: {
    width: "100%",
    backgroundColor: primaryColors.WHITE,
    borderRadius: "8px",
    display: "flex",
    padding: "44px",
    margin: "24px 0",
  },
  svgContainer: {
    width: "200px",
    height: "200px",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: neutralColors.GRAY1,
  },
  svg: {
    width: "100%",
    color: utilityColors.CYAN,
  },
  imageContainer: {
    width: "200px",
    height: "200px",
    borderRadius: "8px",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  detailsContainer: {
    padding: "0 20px",
    flex: 1,
  },
  title: {
    ...typography.HEADLINE6,
    margin: "0",
  },
  paragraph: {
    ...typography.BODY1,
    marginTop: "24px",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
});
