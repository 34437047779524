import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { Grid } from "@material-ui/core";
import { ROUTES } from "@pulsemarket/constants";
import CreateGroup from "components/PulsePage/Groups/createGroup";
import Button from "components/ui/Button";
import Searchbar from "components/ui/Searchbar";
import SvgIcon from "components/ui/SvgIcon";
import CreateList from "components/vendorlists/CreateList";
import FilterBar from "components/VendorSearch/Header/FilterBar";
import { VendorSearchSideMenuItem } from "components/VendorSearch/Sidebar/SideBar";
import { useVendorSearchContext } from "components/VendorSearch/VendorFilterProvider";
import ModalContext from "contexts/ModalContext";
import { classes, style } from "typestyle";

import styles from "./HeaderStyles";
import assetRepositoryHeaderStyles from "components/AssetRepositoryHeader/AssetRepositoryHeaderStyles";

type VendorSearchHeaderProps = {
  showCreateButton?: boolean;
  showFilters?: boolean;
  activeTab: VendorSearchSideMenuItem;
};

export default function VendorSearchHeader({
  activeTab,
  showCreateButton,
  showFilters,
}: VendorSearchHeaderProps) {
  const [filterBarExplicitlySetOpen, setFilterBarOpen] = useState(false);
  const { filters, setFilters } = useVendorSearchContext();

  const someFiltersAreSet = Object.values(filters).filter(Boolean).length > 0;
  const filterBarOpen = filterBarExplicitlySetOpen || someFiltersAreSet;
  const { openModal, closeModal } = useContext(ModalContext);
  const history = useHistory();
  function openCreateListModal() {
    openModal(
      <CreateList
        onSuccess={() => {
          closeModal();
        }}
        onCancel={() => {
          closeModal();
        }}
      />
    );
  }

  return (
    <>
      <Grid
        container
        className={styles.root}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {showCreateButton &&
            (activeTab === VendorSearchSideMenuItem.Groups ? (
              <Button
                color="orange"
                variant="outlined"
                onClick={() =>
                  openModal(
                    <CreateGroup
                      onSuccess={(group) => {
                        closeModal();
                        history.push(ROUTES.GROUP(group.id));
                      }}
                      onCancel={closeModal}
                    />,
                    { props: { maxWidth: "lg" } }
                  )
                }
              >
                Create new group
              </Button>
            ) : (
              <Button
                color="gray"
                variant="transparent"
                onClick={openCreateListModal}
              >
                <SvgIcon
                  src="/icons/add_icon.svg"
                  className={assetRepositoryHeaderStyles.buttonIcon}
                />
                <span>New List</span>
              </Button>
            ))}
        </Grid>
        <Grid item>
          {showFilters && (
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <Searchbar
                  initialValue={filters.companyName}
                  placeholder="What are you looking for?"
                  onSearch={(value) => {
                    setFilters({ ...filters, companyName: value });
                  }}
                  onClose={() => {
                    setFilters({ ...filters, companyName: undefined });
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  color={filterBarExplicitlySetOpen ? "orange" : "gray"}
                  variant="transparent"
                  onClick={() => {
                    // ? should it close the bar?
                    setFilterBarOpen((value) => !value);
                  }}
                >
                  <SvgIcon
                    src="/icons/filter_outline_icon.svg"
                    className={classes(
                      assetRepositoryHeaderStyles.buttonIcon,
                      style({ width: "18px", height: "16px" })
                    )}
                  />
                  <span>Filters</span>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {filterBarOpen && <FilterBar onClear={() => setFilterBarOpen(false)} />}
    </>
  );
}
