import React from "react";

import { px } from "csx";
import { LiteralUnion } from "shared/utilityTypes";
import { classes, style } from "typestyle";

import styles from "./Avatar.styles";

type AvatarSize = "24" | "32" | "40" | "48" | 24 | 32 | 40 | 48;

export type AvatarProps = {
  className?: string;
  imageClassName?: string;
  isSquare?: boolean;
  size?: LiteralUnion<AvatarSize, string | number>;
} & JSX.IntrinsicElements["img"];

/**
 * Based on https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=12921%3A81208&t=O33tFUv6V3bJdgWd-0
 */
export default function Avatar({
  className,
  imageClassName,
  size = "24",
  isSquare,
  ...props
}: AvatarProps) {
  return (
    style({ width: px(size as number), height: px(size as number) }),
    (
      <div
        className={classes(
          styles.root,
          style({ width: px(size as number), height: px(size as number) }),
          isSquare && styles.square,
          className
        )}
      >
        <img
          alt=""
          {...props}
          className={classes(
            styles.image,
            isSquare && styles.square,
            imageClassName
          )}
        />
      </div>
    )
  );
}
