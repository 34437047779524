import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { zeroPaddingMargin } from "shared/styles/classes";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  title: {
    color: primaryColors.PURPLE2,
    ...typography.SUBTITLE1,
    ...zeroPaddingMargin,
    marginBottom: "34px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  emptyContent: {
    ...typography.BODY2,
    color: neutralColors.GRAY4,
    textAlign: "center",
    ...zeroPaddingMargin,
  },
});

export default styles;
