import React from "react";

import Button from "components/ui/Button";
import { GroupInvitationDto } from "shared/model";
import { WithRequired } from "shared/utilityTypes";

import GroupMemberData from "./GroupMemberData";
import { groupMemberRequestBoxStyles as styles } from "./GroupMembers.styles";

type GroupMemberRequestBoxProps = {
  invitation: WithRequired<GroupInvitationDto, "invitedCompany">;
  onDecline: (companyId: string) => void;
  onAccept: (companyId: string) => void;
};

export default function GroupMemberRequestBox({
  invitation,
  onAccept,
  onDecline,
}: GroupMemberRequestBoxProps) {
  return (
    <div className={styles.container}>
      <GroupMemberData invitation={invitation} />
      <div className={styles.buttons}>
        <Button
          type="button"
          variant="normal"
          color="red"
          className={styles.declineButton}
          onClick={() => onDecline(invitation.id)}
        >
          Decline
        </Button>
        <Button
          type="button"
          variant="normal"
          color="green"
          onClick={() => onAccept(invitation.id)}
        >
          Accept
        </Button>
      </div>
    </div>
  );
}
