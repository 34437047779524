export function isMatchingMimeType(
  enabledMimeType: string,
  actualMimeType: string
) {
  return (
    enabledMimeType === actualMimeType ||
    (enabledMimeType.includes("*") &&
      actualMimeType.startsWith(enabledMimeType.replace("*", "")))
  );
}
