// ? shouldn't use rem where possible
// 8 by 8 px

const baseSpacing = 8;

// todo we should use px function given by typestyle
// todo use these variables everywhere, maybe make reusalbe classes in classes.ts

export const spacing = {
  DIVIDER: `${6 * baseSpacing}px`,
  BUTTON: `${4 * baseSpacing}px`,
  TEXTLEFT: `${3 * baseSpacing}px`, // ? used in fields
  ICON: `${2 * baseSpacing}px`,
  LABEL: `${1 * baseSpacing}px`,
  POPUP_MARGIN: `${6 * baseSpacing}px`,
  POPUP_DROPDOWN: `${3 * baseSpacing}px`,
  LABEL_MARGIN: `${1 * baseSpacing}px`,
};
