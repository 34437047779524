import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    width: "100%",
    height: "fit-content",
    borderRadius: "8px",
    padding: "37px 61px",
  },
  icon: {
    width: "150px",
    height: "150px",
  },
  details: {
    paddingLeft: "54px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  title: {
    ...typography.HEADLINE6,
    color: primaryColors.PURPLE3,
  },
  description: {
    marginTop: 0,
    ...typography.BODY1,
  },
  clarification: {
    marginTop: 0,
    ...typography.BODY1,
    color: neutralColors.GRAY3,
  },
});
