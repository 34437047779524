import { useMutation, useQuery, useQueryClient } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import {
  createSupplier,
  deleteSupplier,
  getMySupplier,
  getMySuppliers,
  getSupplierAttachments,
  updateSupplier,
  uploadSuppliersCsv,
} from "shared/client";
import {
  AttributeFilter,
  NewSupplierInput,
  SupplierFilters,
  UpdateSupplierInput,
} from "shared/model";

export const DEFAULT_PAGESIZE = 250;

export default function useGetMySuppliers(
  {
    offset,
    limit,
    filters,
  }: { offset: number; limit: number; filters?: SupplierFilters } = {
    offset: 0,
    limit: DEFAULT_PAGESIZE,
    filters: {},
  }
) {
  const { data: ownCompany } = useOwnCompany();

  return useQuery(
    [
      ...queryKeys.mySuppliers(ownCompany?.id || ""),
      { offset, limit, ...filters },
    ],
    () => {
      const formatedSupplierAttributeFilters: AttributeFilter[] =
        filters?.attributes?.map((attribute) => ({
          attributeTypeId: attribute.attributeTypeId,
          attributeChoiceIds: attribute.choices
            ?.filter((choice) => choice.isSelected)
            .map((choice) => choice.id),
        })) || [];

      // need to remove those filters where no choice is selected
      const filteredAttributeFilters = formatedSupplierAttributeFilters.filter(
        (attribute) => attribute.attributeChoiceIds?.length
      );

      const formatedFilters = {
        ...filters,
        attributes: filteredAttributeFilters?.length
          ? filteredAttributeFilters
          : undefined,
      };
      return getMySuppliers({ offset, limit, ...formatedFilters });
    },
    {
      enabled: !!ownCompany?.id,
      keepPreviousData: true,
    }
  );
}

export function useGetMySupplierDetails(id: string) {
  const { data: ownCompany } = useOwnCompany();

  return useQuery(queryKeys.supplierDetails(id), () => getMySupplier(id), {
    enabled: !!ownCompany?.id,
  });
}

export function useGetSupplierAttachments(supplierId: string) {
  const { data: ownCompany } = useOwnCompany();

  return useQuery(
    queryKeys.supplierAttachments(supplierId),
    () => getSupplierAttachments(supplierId),
    {
      enabled: !!ownCompany?.id,
    }
  );
}

export function useCreateSupplier() {
  const queryClient = useQueryClient();

  return useMutation(
    (data: NewSupplierInput) => {
      return createSupplier(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("mySuppliers");
      },
    }
  );
}

type UploadSuppliersCsvProps = {
  file: File;
};

export function useUploadSuppliersCsv() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ file }: UploadSuppliersCsvProps) => {
      return uploadSuppliersCsv({ file });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("mySuppliers");
      },
    }
  );
}

export function useUpdateSupplier(id: string) {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateSupplierInput) => {
      return updateSupplier(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("mySuppliers");
        queryClient.invalidateQueries(queryKeys.supplierDetails(id));
      },
    }
  );
}

export function useDeleteSupplier() {
  const queryClient = useQueryClient();

  return useMutation(
    (id: string) => {
      return deleteSupplier(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("mySuppliers");
      },
    }
  );
}
