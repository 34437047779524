import React from "react";

import { DialogTitle, Grid, IconButton } from "@material-ui/core";
import SvgIcon from "components/ui/SvgIcon";
import { classes } from "typestyle";

import styles from "./ModalHeader.styles";

export const dialogTitleId = "dialog-title";

type ModalHeaderProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  hideCloseButton?: boolean;
};

// todo delete old ModalHeader

export default function ModalHeader({
  children,
  onClose = () => {},
  className,
  hideCloseButton,
}: ModalHeaderProps) {
  return (
    <DialogTitle className={classes(styles.titleContainer, className)}>
      <Grid
        container
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {children && (
            <h6 className={styles.title} id={dialogTitleId}>
              {children}
            </h6>
          )}
        </Grid>
        {!hideCloseButton && (
          <Grid item>
            <IconButton onClick={onClose} size="small">
              <SvgIcon
                src="/icons/close_icon.svg"
                className={styles.closeIcon}
              />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </DialogTitle>
  );
}
