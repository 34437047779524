import { ChangeEvent } from "react";

import Avatar from "components/CompanyDetails/Avatar";
import Background from "components/CompanyDetails/Background";
import InformationIcon from "components/ui/InformationIcon";
import Input from "components/ui/Input";
import TaggedMultiSelect from "components/ui/TaggedMultiSelect";
import TextArea from "components/ui/TextArea";
import { Pair } from "shared/model";
import { classes } from "typestyle";

import styles from "./GroupDescriptionStyles";

type Props = {
  description: string;
  onDescriptionChange: (e: string | ChangeEvent<any>) => void;
  backgroundImage: string;
  onBackgroundChange: (base64: string, file: File) => Promise<void> | void;
  avatar: string;
  onAvatarChange: (base64: string, file: File) => Promise<void> | void;
  name: string;
  onNameChange: (e: string | ChangeEvent<any>) => void;
  zoneOptions: Pair[];
  onZonesChange: (selected: string[]) => void;
  extraAvatarStyles?: string;
  zones: string[];
};

const GroupDescription = ({
  description,
  onDescriptionChange,
  backgroundImage,
  onBackgroundChange,
  avatar,
  onAvatarChange,
  name,
  onNameChange,
  zoneOptions,
  onZonesChange,
  extraAvatarStyles,
  zones,
}: Props) => {
  return (
    <form className={styles.form}>
      {/* <Form formik={formik}> */}

      <div className={styles.mainContainer}>
        <div className={styles.firstPageHalfSide}>
          <div className={styles.inputsRow}>
            <div className={styles.subtitleContainer}>
              <p className={styles.subtitle}> Cover Image and logo</p>
              <p className={styles.caption}>{`(jpg, jpeg, png).`}</p>
            </div>
            <div className={styles.imagesContainer}>
              <Background
                className={styles.headerImage}
                onUploadBase64={(base64Background, file) => {
                  onBackgroundChange(base64Background, file);
                }}
                backgroundImage={backgroundImage}
                recommendedSizeText="338x145px"
                showHelpText={false}
              />
              <div
                className={classes(
                  styles.avatarBoxContainer,
                  extraAvatarStyles
                )}
              >
                <Avatar
                  onUploadBase64={(base64Avatar, file) => {
                    // formik.setFieldValue("avatar", base64Avatar);
                    onAvatarChange(base64Avatar, file);
                  }}
                  className={styles.avatarCentered}
                  avatar={avatar}
                  type="groups"
                  showHelpText={false}
                  recommendedSizeText="200x200px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.firstPageHalfSide}>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Group name"
              required
              value={name}
              onChange={onNameChange}
              fullWidth
              label="Name"
            />
          </div>
          <div className={styles.inputContainer}>
            <TextArea
              maxRows={5}
              placeholder="Description"
              required
              value={description}
              onChange={onDescriptionChange}
              maxLength={2000}
              label="Description"
            />
          </div>
        </div>
      </div>
      <div className={styles.firstPageBottomSide}>
        <div className={styles.zonesLableContainer}>
          <InformationIcon title={"Help others to find your group"} />
        </div>

        <TaggedMultiSelect
          placeholder="Please, select zones the group are in"
          selectionGroups={[{ name: "Zones", values: zoneOptions }]}
          selectedIds={zones.map((z) => z)}
          onAdd={(clickedTag) => {
            onZonesChange([...zones, clickedTag]);
          }}
          onDelete={(clickedTag) => {
            onZonesChange(zones.filter((z) => z !== clickedTag));
          }}
          label="Zones"
        />
      </div>
      {/* </Form> */}
    </form>
  );
};

export default GroupDescription;
