import { primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  label: {
    ...typography.CAPTION,
    color: primaryColors.WHITE,
    borderRadius: "4px",
    padding: "4px 14px",
    cursor: "default",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeIconWrapper: {
    width: "16px",
    height: "16px",
    $nest: { "&:hover": { backgroundColor: "unset" } },
    marginLeft: "8px",
  },
  closeIcon: {
    color: primaryColors.WHITE,
  },
});

export default styles;
