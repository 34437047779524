import { useState } from "react";
import { useDropzone } from "react-dropzone";

import SvgIcon from "components/ui/SvgIcon";
import transformFileToBase64 from "shared/utils/fileToBase64";
import { classes } from "typestyle";

import styles from "./BackgroundStyles";

type BackgroundProps = {
  backgroundImage?: string;
  onUploadBase64: (base64Background: string, file: File) => void;
  className?: string;
  hideSizeText?: boolean;
  recommendedSizeText?: string;
  showHelpText?: boolean;
  disabled?: boolean;
};

export default function Background({
  backgroundImage,
  onUploadBase64: onBackgroundUpload,
  className,
  hideSizeText,
  recommendedSizeText,
  showHelpText = true,
  disabled = false,
}: BackgroundProps) {
  const [maxSizeReached, setMaxSizeReached] = useState<boolean>(false);
  const maxSize = 20485760; //bytes

  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    accept: ["image/*"],
    onDropAccepted: async (files) => {
      const base64File = await transformFileToBase64(files[0]);
      setMaxSizeReached(false);
      onBackgroundUpload(base64File, files[0]);
    },
    onDropRejected: () => setMaxSizeReached(true),
    multiple: false,
    maxSize,
    disabled,
  });

  return (
    <div className={classes(styles.headerImage, className)} {...getRootProps()}>
      <input {...getInputProps()} />
      {backgroundImage ? (
        <img src={backgroundImage} alt="background" className={styles.image} />
      ) : (
        <>
          <SvgIcon
            src="/img/cyan_cloud_background.svg"
            className={styles.cyanCloud}
          />
          <SvgIcon
            src="/img/gray_cloud_background.svg"
            className={styles.grayCloud}
          />
        </>
      )}

      {!disabled && (
        <div className={styles.cameraIconContainer}>
          <SvgIcon src="/icons/edit_a_photo.svg" className={styles.editIcon} />
        </div>
      )}
      {!hideSizeText && (
        <div>
          <span className={styles.backgroundSpan}>
            {recommendedSizeText ?? "Recommended 1300x412px"}
          </span>
          {!maxSizeReached && showHelpText && (
            <span className={styles.maxSizeSpan}>Max size 20 MB</span>
          )}
          {maxSizeReached && (
            <span className={styles.tryAgain}>Max size 20 MB. Try Again</span>
          )}
        </div>
      )}
    </div>
  );
}
