import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  label: {
    color: primaryColors.BLACK,
    ...typography.BODY2,
  },
  warningText: {
    ...typography.CAPTION,
    color: utilityColors.RED,
    textAlign: "right",
    display: "inline-block",
    width: "100%",
  },
  infoText: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
    textAlign: "right",
    display: "inline-block",
    width: "100%",
  },
});

export default styles;
