import { useRef } from "react";

import { Grid } from "@material-ui/core";
import { AssetLabelDto } from "@pulsemarket/api-client";
import { AssetRepositoryItem } from "components/AssetRepository/AssetRepositoryBody/AssetRepositoryBody";
import Label from "components/ui/Label";
import SvgIcon from "components/ui/SvgIcon";
import Tooltip from "components/ui/Tooltip";
import useRemoveLabelFromAssets from "hooks/queries/useRemoveLabelFromAssets";
import useFittingFlexItemsCount from "hooks/useFittingFlexItemsCount";
import { classes, style } from "typestyle";

import styles from "./LabelCell.styles";

type LabelCellProps = { row: Pick<AssetRepositoryItem, "id"> } & {
  labels?: AssetLabelDto[];
  className?: string;
};

export default function LabelCell({
  labels = [],
  className,
  row,
}: LabelCellProps) {
  const labelContainer = useRef<HTMLDivElement>(null);

  const {
    hasDeterminedHiddenElements,
    fittingElementCount,
    hiddenElementCount,
  } = useFittingFlexItemsCount({
    flexContainer: labelContainer,
    items: labels,
  });

  const { mutateAsync: removeLabelFromAssets } = useRemoveLabelFromAssets();

  return (
    <Grid
      container
      className={classes(styles.root, className)}
      spacing={1}
      alignItems="center"
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid item>
        <Grid
          container
          item
          className={classes(
            styles.labels,
            !hasDeterminedHiddenElements &&
              style({ visibility: "hidden", opacity: 0 })
          )}
          wrap={hasDeterminedHiddenElements ? "nowrap" : "wrap"}
          ref={labelContainer}
          spacing={1}
        >
          {labels.map((label, i) => (
            <Grid
              item
              key={i}
              className={classes(
                hasDeterminedHiddenElements &&
                  i + 1 > fittingElementCount &&
                  styles.hidden
              )}
            >
              <Label
                color={label.color}
                onDelete={() => {
                  removeLabelFromAssets({
                    labelId: label.id,
                    assetIds: [row.id],
                    folderIds: [row.id],
                  });
                }}
              >
                {label.name}
              </Label>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {(hiddenElementCount > 0 || !hasDeterminedHiddenElements) && (
        <Tooltip
          interactive
          title={
            <Grid container spacing={1} className={styles.tooltip}>
              {labels.map((label, i) => {
                return (
                  <Grid item key={i}>
                    <Label
                      color={label.color}
                      onDelete={() => {
                        removeLabelFromAssets({
                          labelId: label.id,
                          assetIds: [row.id],
                          folderIds: [row.id],
                        });
                      }}
                    >
                      {label.name}
                    </Label>
                  </Grid>
                );
              })}
            </Grid>
          }
        >
          <Grid item>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={1}
              className={styles.dropdown}
            >
              <Grid item>
                <span>+{hiddenElementCount}</span>
              </Grid>
              <Grid item className={styles.arrowDown}>
                <SvgIcon
                  className={styles.arrowDown}
                  src="/icons/arrow_down.svg"
                />
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      )}
    </Grid>
  );
}
