import React from "react";

import { utilityColors } from "@pulsemarket/constants";
import SvgIcon from "components/ui/SvgIcon";
import { classes, style } from "typestyle";

import { PromiseState } from "../FileUploadingWindow";

import styles from "./IconDisplayStyles";

const statusIcons: Record<PromiseState, React.ReactElement> = {
  pending: (
    <SvgIcon
      src="/icons/loading_icon.svg"
      className={classes(
        styles.statusIcon,
        style({ color: utilityColors.ORANGE })
      )}
    />
  ),
  resolved: (
    <SvgIcon
      src="/icons/select_icon.svg"
      className={classes(
        styles.statusIcon,
        style({ color: utilityColors.GREEN })
      )}
    />
  ),
  rejected: (
    <SvgIcon
      src="/icons/alert_icon.svg"
      className={classes(
        styles.statusIcon,
        style({ color: utilityColors.RED })
      )}
    />
  ),
};

type IconDisplayProps = {
  status: PromiseState;
};

function IconDisplay({ status }: IconDisplayProps) {
  const statusIcon = statusIcons[status];

  return (
    <div className={classes(styles.statusIconContainer)}>{statusIcon}</div>
  );
}

export default IconDisplay;
