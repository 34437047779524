import React from "react";

import { InputProps } from "components/ui/Input";
import Input from "components/ui/Input/Input";
import { classes } from "typestyle";

import styles from "./TextArea.styles";

export type TextAreaProps = Omit<InputProps, "value" | "multiline"> & {
  maxLength?: number;
  minLength?: number;
  value?: string;
};

/** Wrapper around `<Input />` */
export default function TextArea({
  className,
  fullWidth = true,
  maxLength,
  minLength,
  value,
  minRows = 4,
  ...props
}: TextAreaProps) {
  return (
    <Input
      {...props}
      value={value}
      multiline
      className={classes(className)}
      fullWidth={fullWidth}
      minRows={minRows}
      inputProps={{
        maxLength,
        minLength,
        ...props.inputProps,
      }}
      InputProps={{
        ...props.InputProps,
        classes: {
          ...props.InputProps?.classes,
          multiline: classes(
            styles.multilineInput,
            props.InputProps?.classes?.multiline
          ),
        },
      }}
      helperText={
        props.helperText ||
        ((maxLength || minLength) && (
          <span className={styles.maxCharSpan}>
            {maxLength
              ? value?.length
                ? `${value.length}/${maxLength}`
                : `max ${maxLength} characters`
              : `min ${minLength} characters`}
          </span>
        ))
      }
    />
  );
}
