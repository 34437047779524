import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  dropdown: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "stretch",
    width: px(240),
    height: px(316),
    borderRadius: px(8),
    background: neutralColors.GRAY0,
  },
  search: {
    margin: "15px 12px",
    borderRadius: px(3),
    height: px(33),
    background: primaryColors.WHITE,
  },
  icon: {
    margin: px(6),
    width: px(17),
    color: neutralColors.GRAY5,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    height: px(50),
    background: primaryColors.WHITE,
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  list: {
    flex: 1,
    overflowY: "scroll",
  },
});

export default styles;
