import React from "react";

import { Grid } from "@material-ui/core";
import {
  AddYouTubeVideoInput,
  UpdateYouTubeVideoInput,
} from "@pulsemarket/api-client";
import YouTubeInformationIcon from "components/CompanyInfo/forms/About/YouTubeInformationIcon";
import Button from "components/ui/Button";
import Input from "components/ui/Input";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import { FormikErrors, useFormik } from "formik";
import getVideoId from "get-video-id";
import { MediaItemDto } from "shared/model";
import { validateYouTubeUrl } from "shared/validators";
import { classes } from "typestyle";

import styles from "./UploadOrEditVideo.styles";

type UploadOrEditVideoProps = {
  onClose: () => void;
  video?: MediaItemDto;
  onAddVideo: (input: AddYouTubeVideoInput) => void;
  onUpdateVideo: (input: UpdateYouTubeVideoInput & { id: string }) => void;
};

const validate = (
  values: AddYouTubeVideoInput
): FormikErrors<AddYouTubeVideoInput> => {
  const errors: FormikErrors<AddYouTubeVideoInput> = {};
  if (!validateYouTubeUrl(values.reference)) {
    errors.reference = "Pulse only supports YouTube videos";
  }
  return errors;
};

export default function UploadOrEditVideo({
  onClose,
  video,
  onAddVideo,
  onUpdateVideo,
}: UploadOrEditVideoProps) {
  const editingItem = !!video;

  const formik = useFormik<AddYouTubeVideoInput>({
    initialValues: {
      reference: video ? `https://youtu.be/${video.reference}` : "",
      name: video?.name || "",
    },
    validate,
    onSubmit: async (data) => {
      if (!editingItem) {
        await onAddVideo({
          name: data.name,
          reference: getVideoId(data.reference).id as string,
        });
      } else {
        await onUpdateVideo({
          name: data.name,
          id: video.id,
        });
      }
      formik.resetForm({ values: data });
      onClose();
    },
  });

  return (
    <SimpleModalBody
      onClose={onClose}
      title={
        <>
          {editingItem ? "Rename Video" : "Upload Video"}
          <YouTubeInformationIcon />
        </>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div>
              <label className={styles.label}>Video Title:</label>
            </div>
            <Input
              placeholder="Choose a title for this video"
              fullWidth={true}
              value={formik.values.name}
              onChange={formik.handleChange("name")}
            />
          </Grid>
          <Grid item>
            <div>
              <label className={styles.label}>
                {editingItem ? "Video URL:" : "Add Video:"}
              </label>
            </div>
            <Input
              placeholder="Please add Youtube link"
              disabled={editingItem}
              fullWidth={true}
              inputProps={{
                type: "url",
              }}
              value={formik.values.reference}
              onChange={formik.handleChange("reference")}
            />

            <span
              className={classes(
                formik.errors.reference ? styles.warningText : styles.infoText
              )}
            >
              {`Pulse Market supports only Youtube links. 
          ${formik.errors.reference ? "Please try again." : ""}`}
            </span>
          </Grid>
          <Grid item container justifyContent="flex-end">
            <Grid item>
              <Button variant="normal" color="orange" type="submit">
                {editingItem ? "Save" : "Add Link"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </SimpleModalBody>
  );
}
