import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { stylesheet } from "typestyle";

export default stylesheet({
  notificationBell: {
    height: "32px",
    color: primaryColors.PURPLE2,
    cursor: "pointer",
    $nest: {
      "&:hover": {
        color: primaryColors.PURPLE2_HOVER,
      },
    },
  },
  notificationBadge: {
    $nest: {
      ".MuiBadge-badge": {
        background: utilityColors.ORANGE,
        top: 2,
        right: 2,
      },
    },
  },
  tooltip: {
    maxWidth: "none !important",
  },
});
