export type GroupLayout = "posts" | "files";

export enum GroupAccessContext {
  Own = "Own",
  PublicJoinable = "PublicJoinable",
  PublicJoined = "PublicJoined",
  PrivateJoinable = "PrivateJoinable",
  PrivateJoined = "PrivateJoined",
  PrivateJoinRequestSent = "PrivateJoinRequestSent",
  PrivateInvited = "PrivateInvited",
}
