import React, { useContext, useState } from "react";

import { IconButton, InputAdornment } from "@material-ui/core";
import CombinedInvitationSender from "components/CombinedInvitationSender/CombinedInvitationSender";
import Input from "components/ui/Input";
import SvgIcon from "components/ui/SvgIcon";
import ModalContext from "contexts/ModalContext";
import { useGroupInvitations } from "hooks/queries/useGroup";
import useInviteToGroup from "hooks/queries/useInviteToGroup";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { GroupInvitationDto } from "shared/model";
import { WithRequired } from "shared/utilityTypes";
import { style } from "typestyle";

import GroupMemberBox from "./GroupMemberBox";
import GroupMemberRequestBox from "./GroupMemberRequestBox";
import styles from "./GroupMembers.styles";
import { DenyGroupMember } from "./modals";

type GroupMembersProps = {
  requestedAccess: WithRequired<GroupInvitationDto, "invitedCompany">[];
  onMemberRequestDecline: (companyId: string) => void;
  onMemberRequestAccept: (companyId: string) => void;
  members: WithRequired<GroupInvitationDto, "invitedCompany">[];
  ownerCompanyId: string;
  groupId: string;
  title: string;
};

const GroupMembers = ({
  requestedAccess,
  onMemberRequestAccept,
  members,
  ownerCompanyId,
  groupId,
  title,
}: GroupMembersProps) => {
  const { openModal } = useContext(ModalContext);
  const { data: invitations } = useGroupInvitations(groupId);

  const { data: ownCompany } = useOwnCompany();
  const { mutateAsync: inviteToGroup } = useInviteToGroup({ groupId });

  const [memberFilter, setMemberFilter] = useState<null | string>(null);

  const isGroupOwn = ownerCompanyId === ownCompany?.id;
  const accessRequests = requestedAccess.filter((r) => !!r.invitedCompany);

  return (
    <>
      <div>
        <CombinedInvitationSender
          className={styles.invitationSender}
          title={title}
          disabledReason="Already a member"
          onInvite={({ recipients }) => inviteToGroup({ recipients })}
          existingInvitations={invitations}
        />

        {isGroupOwn && (
          <div className={styles.membersContainer}>
            <h4>Member Requests</h4>
            {accessRequests && (
              <>
                {accessRequests.map((invitation) => {
                  return (
                    <GroupMemberRequestBox
                      key={invitation.id}
                      invitation={invitation}
                      onDecline={() =>
                        openModal(
                          <DenyGroupMember invitationId={invitation.id} />
                        )
                      }
                      onAccept={onMemberRequestAccept}
                    />
                  );
                })}
              </>
            )}

            {!requestedAccess.length && <>No companies</>}
          </div>
        )}

        {isGroupOwn && (
          <div className={styles.membersContainer}>
            {members && (
              <div className={styles.memberCardsContainer}>
                <Input
                  fullWidth
                  placeholder="Search by name"
                  value={memberFilter}
                  onChange={(e) => setMemberFilter(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => {}}>
                          <SvgIcon
                            src="/icons/search_icon.svg"
                            className={style({ width: "15px" })}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {members
                  .filter((member) => {
                    if (!memberFilter) {
                      return true;
                    }
                    return member.invitedCompany.name
                      .toLowerCase()
                      .includes(memberFilter.toLowerCase());
                  })
                  .map((invitation) => {
                    return (
                      <GroupMemberBox
                        key={invitation.id}
                        invitation={invitation}
                      />
                    );
                  })}
              </div>
            )}

            {!members.length && <>No members joined yet</>}
          </div>
        )}
      </div>
    </>
  );
};

export default GroupMembers;
