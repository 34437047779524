import React, { useEffect, useRef, useState } from "react";

import {
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from "@material-ui/core";
import SvgIcon from "components/ui/SvgIcon/SvgIcon";
import { heights } from "shared/styles/height";
import { typography } from "shared/styles/typography";
import { classes, style } from "typestyle";

import styles from "./SearchbarStyles";

type SearchbarProps = {
  // todo add className prop(s)
  visible?: boolean;
  onSearch?: (text: string) => void;
  onClose?: () => void;
  initialValue?: string;
  placeholder?: string;
  searchOnChange?: boolean;
};

// todo make popover optional - currently this is only used on supplier search
export default function Searchbar({
  onSearch = () => {},
  onClose = () => {},
  initialValue = "",
  placeholder = "Search by keyword",
  searchOnChange = false,
}: SearchbarProps) {
  const [value, setValue] = useState(initialValue);
  const [visible, setVisible] = useState(false);

  const [popoverExplicitlyClosed, setPopoverExplicitlyClosed] =
    useState<boolean>(false);

  const containerEl = useRef<HTMLDivElement>(null);
  const containerWidth = containerEl?.current?.offsetWidth || 0;

  const popoverOpen =
    !popoverExplicitlyClosed && containerEl && initialValue !== value;

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      ref={containerEl}
    >
      <IconButton
        onClick={() => {
          if (visible) {
            onSearch(value);
            return;
          }
          setVisible(true);
        }}
      >
        <SvgIcon
          src="/icons/search_icon.svg"
          className={classes(styles.searchIcon, visible && styles.iconSmall)}
        />
      </IconButton>
      {visible && (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSearch(value);
            }}
          >
            <TextField
              variant="filled"
              autoFocus
              placeholder={placeholder}
              className={styles.search}
              value={value}
              onBlur={() => {
                if (!value) {
                  setVisible(false);
                  onSearch(value);
                }
              }}
              onChange={(e) => {
                setPopoverExplicitlyClosed(false);

                if (searchOnChange) {
                  onSearch(e.target.value);
                }

                setValue((e.target as HTMLInputElement).value);
              }}
              InputProps={{
                classes: {
                  root: style(
                    { height: heights.FIELD_SMALLEST, paddingLeft: "8px" },
                    typography.CAPTION
                  ),
                  underline: styles.searchUnderLine,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      className={style({ width: "19px", height: "19px" })}
                      onClick={() => {
                        setVisible(false);
                        setValue(initialValue); // Reset the searchbar
                        onClose();
                      }}
                    >
                      <SvgIcon
                        src="/icons/close_icon.svg"
                        className={style({ width: "13px" })}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>

          {!searchOnChange && (
            <Popover
              disableAutoFocus
              disableEnforceFocus
              open={popoverOpen}
              onClose={() => setPopoverExplicitlyClosed(true)}
              anchorEl={containerEl.current}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                style: { marginTop: "-8px" },
                elevation: 0,
              }}
            >
              <div
                style={{ width: containerWidth }}
                className={styles.popoverContainer}
              >
                Press enter to view all results
              </div>
            </Popover>
          )}
        </>
      )}
    </Grid>
  );
}
