import { primaryColors } from "@pulsemarket/constants";
import { px } from "csx";
import { spacing } from "shared/styles/spacing";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

/**
 * styles shared between forms in company info. Todo:
 * - use everywhere
 * - move to shared styles
 * */
const styles = stylesheet({
  title: {
    ...typography.HEADLINE5,
    color: primaryColors.PURPLE2,
  },
  inputRow: {
    marginBottom: px(32),
    $nest: {
      "& label": {
        marginBottom: spacing.LABEL_MARGIN,
      },
    },
  },
  inputLabel: {
    ...typography.SUBTITLE2,
  },
  helpIcon: {
    display: "inline-block",
    marginLeft: px(8),
  },
  helpIconSmall: {
    width: px(18),
    height: px(18),
  },
  helpIconText: {
    ...typography.CAPTION,
    color: primaryColors.BLACK,
  },
  labelWithIcon: {
    display: "flex",
    alignItems: "center",
  },
  labelIcon: {
    marginLeft: px(8),
    height: px(16),
  },
});

export default styles;
