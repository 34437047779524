import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { maxLineNumber } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  container: {
    height: "405px",
    width: "240px",
  },
  specialities: {
    color: primaryColors.PURPLE2,
    ...typography.CAPTION,
    textAlign: "center",
    height: "3rem", // 3 lines
    ...maxLineNumber(3),
  },
  location: {
    color: neutralColors.GRAY5,
    ...typography.CAPTION,
    textAlign: "center",
    height: "2rem", // 2 lines
    ...maxLineNumber(2),
  },
  disabled: {
    $nest: {
      "&": {
        color: neutralColors.GRAY3,
      },
    },
  },
  disabledReason: {
    ...typography.CAPTION2,
  },
});

export default styles;

export const compactStyles = stylesheet({
  container: {
    width: "180px",
    height: "340px",
  },
});
