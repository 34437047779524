import React from "react";

import { neutralColors } from "@pulsemarket/constants";
import Button, { ButtonProps } from "components/ui/Button";
import SvgIcon, { SvgIconProps } from "components/ui/SvgIcon";
import { typography } from "shared/styles/typography";
import { classes, stylesheet } from "typestyle";

type NoContentProps = {
  /** todo could also accept ReactElement<ButtonProps> */
  button?: ButtonProps;
  children?: React.ReactNode;
  icon?: SvgIconProps;
  className?: string;
};

const styles = stylesheet({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
  },
  icon: {
    color: neutralColors.GRAY3,
    maxWidth: "80px",
    flexBasis: "80px",
  },
  text: {
    ...typography.CAPTION,
    color: neutralColors.GRAY5,
  },
});

export default function NoContent({
  className,
  children,
  button,
  icon,
}: NoContentProps) {
  return (
    <div className={classes(styles.root, className)}>
      {icon && (
        <SvgIcon {...icon} className={classes(styles.icon, icon.className)} />
      )}
      <span className={styles.text}>{children}</span>
      {button && <Button {...button} />}
    </div>
  );
}
