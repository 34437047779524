import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Divider, Menu, MenuItem, MenuItemProps } from "@material-ui/core";
import { FeatureFlagEnum } from "@pulsemarket/constants";
import FeedbackModal from "components/FeedbackModal";
import Avatar from "components/ui/Avatar";
import IconButton from "components/ui/IconButton";
// import SubSelect from "components/ui/SubSelect";
import SvgIcon from "components/ui/SvgIcon";
import ModalContext from "contexts/ModalContext";
import { useLogOut } from "hooks/queries/useAuth";
import useIsAdmin from "hooks/queries/useIsAdmin";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import usePassportFeatureEnabled from "hooks/usePassportFeatureEnabled";
import {
  DEFAULT_COMPANY_AVATAR,
  DEFAULT_USER_AVATAR,
} from "shared/constants/icons";
import { CompanyDetailsDto } from "shared/model";
import { classes } from "typestyle";

import { CreateCompanyModal } from "../CreateCompanyModal/CreateCompanyModal";
import styles from "./AvatarMenu.styles";

type AvatarMenuProps = {
  className?: string;
  profileImageUrl?: string;
  companies?: CompanyDetailsDto[];
  onSwitchCompany?: (id: string) => void;
};

export default function AvatarMenu({
  className,
  profileImageUrl,
  companies = [],
  onSwitchCompany = () => {},
}: AvatarMenuProps) {
  const { mutateAsync: logout } = useLogOut();
  const { isAdmin } = useIsAdmin();
  const { openModal, closeModal } = useContext(ModalContext);
  const history = useHistory();
  const location = useLocation();

  const [anchorElement, setAnchorElement] = React.useState<Element | null>(
    null
  );

  const handleMenuOpen = (event: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const { data: createCompanyFeatureEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.createNewCompany,
  });

  const passportEnabled = usePassportFeatureEnabled();
  const menuOpen = Boolean(anchorElement);

  // https://github.com/mui/material-ui/issues/16245
  const pages: Omit<MenuItemProps, "button">[] = [
    {
      children: "Company account",
      onClick: () => {
        history.push(`/company/edit/info`);
      },
      selected: location.pathname === "/company/edit/info",
    },
    {
      children: "About",
      onClick: () => {
        history.push("/company/edit/about");
      },
      selected: location.pathname === "/company/edit/about",
    },
    ...(passportEnabled
      ? [
          {
            children: "Pulse Passport",
            onClick: () => {
              isAdmin
                ? history.push("/company/edit/pulsepassport")
                : window.open("https://pulsemarket.com/esg-passport");
            },
            selected: location.pathname === "/company/edit/pulsepassport",
          },
        ]
      : []),
  ];

  return (
    <>
      <Avatar
        className={classes(styles.root, className)}
        src={profileImageUrl ?? DEFAULT_USER_AVATAR}
        size="40"
        onClick={handleMenuOpen}
        alt="User avatar"
        imageClassName={styles.avatar}
      />
      <Menu
        open={menuOpen}
        anchorEl={anchorElement}
        getContentAnchorEl={null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: styles.menuRoot }}
      >
        {pages.map((page, i) => (
          <MenuItem
            {...page}
            key={i}
            className={classes(styles.itemSize, styles.pageItem)}
            classes={{ selected: styles.selectedPageItem }}
          />
        ))}
        {!!companies.length && (
          <>
            <Divider className={styles.divider} />
            <MenuItem
              className={classes(styles.itemSize, styles.switchCompany)}
            >
              <span>Switch Company: </span>
              <SvgIcon
                className={styles.arrowIcon}
                src="/icons/arrow_right.svg"
              />
            </MenuItem>
            {companies.map((company, i) => {
              return (
                <MenuItem
                  key={i}
                  className={classes(styles.itemSize, styles.companyItem)}
                  onClick={() => onSwitchCompany(company.id)}
                >
                  <img
                    className={styles.companyAvatar}
                    src={company.avatar ?? DEFAULT_COMPANY_AVATAR}
                    alt="Company avatar"
                  />
                  {company.name}
                </MenuItem>
              );
            })}
          </>
        )}
        {/*  TODO: Implement the SubSelect component which is inclided in the design:
          shttps://www.figma.com/design/CccZ82VO9kpIsNFzSju0wd/Demo---Pulse-3.0?node-id=2019-92103&t=gGPu9yBVxgmcdaAT-0
        {companies.length > 0 && (
          <SubSelect
            // className={styles.supplierSelect}
            options={companies.map((c) => {
              return {
                id: c.id,
                name: c.name,
              };
            })}
            onSelect={(items) => {
              onSwitchCompany(items[0].id);
            }}
            placeholder="Select from your Lists"
            multiSelectListProps={{
              asPopover: true,
              getSubOptions(value) {
                return Promise.resolve(
                  companies.map((c) => {
                    return {
                      id: c.id,
                      name: c.name,
                      // Add other properties as needed to match WithAvatarProps<Pair & { disabled?: boolean | ((id: string) => boolean | undefined) | undefined; }>
                      avatarUrl: c.avatar, // Example property
                      disabled: false, // Example property
                    };
                  })
                );
              },
            }}
            multiple={false}
          />
        )} */}

        {createCompanyFeatureEnabled && [
          <MenuItem
            key="create-account"
            className={classes(styles.itemSize, styles.companyItem)}
            onClick={() => {
              openModal(<CreateCompanyModal />, {
                props: {
                  disableEnforceFocus: true,
                },
              });
            }}
          >
            <IconButton
              className={styles.companyAvatar}
              src="/icons/add_icon.svg"
              tooltip="Create a new company with ease"
              color="purple"
            />
            Add Account
          </MenuItem>,
        ]}

        <Divider className={styles.divider} />
        <MenuItem
          className={classes(styles.itemSize, styles.actionItem)}
          onClick={() => {
            openModal(<FeedbackModal onClose={closeModal} />, {
              props: {
                disableEnforceFocus: true,
              },
            });
          }}
        >
          Feedback
        </MenuItem>
        <MenuItem
          className={classes(styles.itemSize, styles.actionItem)}
          onClick={() => logout()}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
}
