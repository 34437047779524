import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { TokenType } from "@pulsemarket/constants";
import InvalidTokenForm from "components/auth/ResetPasswordForm/InvalidTokenForm";
import ResetPasswordForm from "components/auth/ResetPasswordForm/ResetPasswordForm";
import ResetPasswordSentForm from "components/auth/ResetPasswordSentForm/ResetPasswordSentForm";
import { Loading } from "components/FullScreenLoading";
import { useVerifyToken } from "hooks/queries/useAuth";
import AuthenticationPageLayout from "pages/AuthenticationPageLayout";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ResetPasswordPage() {
  const query = useQuery();
  const token = query.get("token") || "";

  const { isError, isLoading } = useVerifyToken({
    token,
    type: TokenType.PasswordReset,
    enabled: !!token,
  });

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string | null>(
    null
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AuthenticationPageLayout imageSrc="/illustrations/forgot_password.svg">
      {isError ? (
        forgotPasswordEmail ? (
          <ResetPasswordSentForm email={forgotPasswordEmail} />
        ) : (
          <InvalidTokenForm
            onSubmit={(email) => {
              setForgotPasswordEmail(email);
            }}
            type={TokenType.PasswordReset}
          />
        )
      ) : (
        <ResetPasswordForm token={token} />
      )}
    </AuthenticationPageLayout>
  );
}
