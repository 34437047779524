import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { inviteGroupMembers } from "shared/client";
import { CombinedInvitationRecipientInput } from "shared/model";

type InviteToGroupHookParams = {
  onSuccess?: () => void;
  groupId: string;
};

type InviteToGroupParams = {
  recipients: CombinedInvitationRecipientInput[];
};

export default function useInviteToGroup(options: InviteToGroupHookParams) {
  const client = useQueryClient();
  return useMutation(
    ({ recipients }: InviteToGroupParams) =>
      inviteGroupMembers(options.groupId, recipients),
    {
      mutationKey: mutationKeys.inviteToGroup,
      onSettled: () => {
        client.invalidateQueries(queryKeys.groupInvitations(options.groupId));
      },
      onSuccess: options.onSuccess,
    }
  );
}
