import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/ui/Button";
import { Pair } from "shared/model";
import { classes } from "typestyle";

import styles from "./TagsBoxStyles";

type TagsBoxProps = {
  title: string;
  selectedTags?: Pair[];
  icon: string;
  hideAddButton?: boolean;
  className?: string;
};

export default function TagsBox({
  title,
  selectedTags = [],
  icon,
  hideAddButton = false,
  className,
}: TagsBoxProps): JSX.Element {
  const hasTags = !!selectedTags?.length;
  const history = useHistory();

  return (
    <div className={classes(styles.tagContainer, className)}>
      <img src={icon} className={styles.groupIcon} alt="tag group" />
      <h2 className={styles.title}>{title}</h2>
      {hasTags && (
        <span className={styles.tag}>
          {selectedTags.map((tag) => tag.name).join(", ")}
        </span>
      )}
      {!hideAddButton && !hasTags && (
        <Button
          variant="transparent"
          onClick={() => history.push(`/company/edit/info`)}
          className={styles.addButton}
        >
          Add +
        </Button>
      )}
    </div>
  );
}
