import React, { useCallback, useContext } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";

import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { maxUploadFileSize } from "components/AssetRepository/constants";
import {
  CustomFileUploaderHeaderText,
  FileUploadingWindowProps,
  PromiseState,
} from "components/FileUploader/FileUploadingWindow/FileUploadingWindow";
import { CustomizableDialogProps } from "components/ui/Modal/Modal";
import ModalContext, { uncloseableDialogSettings } from "contexts/ModalContext";
import { UploadApiError } from "shared/client";
import { classes } from "typestyle";

import styles from "./FileUploader.styles";
import FileUploadingWindow from "./FileUploadingWindow";

export interface UploadProps {
  onFilesAccepted?: (files: Array<File>) => Promise<void>;
  onFilesRejected?: (fileRejections: Array<FileRejection>) => Promise<void>;
  allowedFileTypes?: string | Array<string>;
  uploadSuccessCallback?: () => void;
  blockedFileTypes?: string[];
  destination?: string;
  customHeaderText?: CustomFileUploaderHeaderText;
  allowMultiple?: boolean;
}

interface FileUploaderProps extends UploadProps {
  children: React.ReactNode;
  className?: string;
  allowedFileTypes?: string | Array<string>;
  uploadSuccessCallback?: () => void;
}

export const fileUploaderModalProps = (
  state: PromiseState
): CustomizableDialogProps => {
  return {
    ...uncloseableDialogSettings().props,
    PaperProps: {
      style: {
        borderColor:
          state === PromiseState.Resolved
            ? utilityColors.GREEN
            : state === PromiseState.Rejected
            ? utilityColors.RED
            : utilityColors.ORANGE,
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: "8px",
        borderRadius: "8px",
        backgroundColor: primaryColors.WHITE,
        width: "440px",
      },
    },
  };
};

function FileUploader({
  children,
  onFilesAccepted = async () => {},
  onFilesRejected = async () => {},
  className = "",
  allowedFileTypes,
  uploadSuccessCallback = () => {},
  blockedFileTypes,
  destination,
  customHeaderText,
  allowMultiple = true,
}: FileUploaderProps) {
  const { openModal, closeModal } = useContext(ModalContext);

  const filesAcceptedHandler = useCallback<
    (files: File[], event: DropEvent) => void
  >(
    async (files) => {
      const uploadingWindowProps: Omit<FileUploadingWindowProps, "status"> = {
        fileName: files[0].name,
        size: files[0].size,
        destination,
        customHeaderText: customHeaderText,
      };

      closeModal();
      openModal(
        <FileUploadingWindow
          {...uploadingWindowProps}
          status={PromiseState.Pending}
          fileCount={files.length}
        />,
        {
          keepHistory: true,
          props: fileUploaderModalProps(PromiseState.Pending),
        }
      );
      try {
        await onFilesAccepted(files);
        closeModal();
        openModal(
          <FileUploadingWindow
            {...uploadingWindowProps}
            status={PromiseState.Resolved}
            onClose={closeModal}
            fileCount={files.length}
          />,
          {
            keepHistory: true,
            props: fileUploaderModalProps(PromiseState.Resolved),
          }
        );
        uploadSuccessCallback();
        return;
      } catch (err) {
        if (err instanceof UploadApiError) {
          closeModal();
          openModal(
            <FileUploadingWindow
              {...uploadingWindowProps}
              onClose={closeModal}
              status={PromiseState.Rejected}
              errors={[err.message, ...(err.errorMessages || [])]}
              fileCount={files.length}
            />,
            { props: fileUploaderModalProps(PromiseState.Rejected) }
          );
        }
      }
    },
    [
      destination,
      customHeaderText,
      closeModal,
      openModal,
      onFilesAccepted,
      uploadSuccessCallback,
    ]
  );

  const filesRejectedHandler = useCallback(
    (fileRejections) => {
      onFilesRejected(fileRejections);
    },
    [onFilesRejected]
  );

  const fileTypeValidator = useCallback(
    (file: File) => {
      const extension = file.name.split(".").pop() || "";

      if (blockedFileTypes?.includes(extension)) {
        return {
          code: "file-invalid-type",
          message: "The extension of the selected file is not allowed",
        };
      }
      return null;
    },
    [blockedFileTypes]
  );

  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    accept: allowedFileTypes,
    onDropAccepted: filesAcceptedHandler,
    onDropRejected: filesRejectedHandler,
    multiple: allowMultiple,
    maxSize: maxUploadFileSize,
    validator: fileTypeValidator,
  });
  return (
    <>
      <div
        {...getRootProps()}
        className={classes(styles.fileUploaderRoot, className)}
      >
        <input {...getInputProps()} />
        {children}
      </div>
    </>
  );
}

export default FileUploader;
