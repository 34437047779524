import { pdf } from "@react-pdf/renderer";
import PassportPDF from "components/PassportPDF";
import { PassportPDFProps } from "components/PassportPDF/PassportPDF";

export async function downloadCert(props: PassportPDFProps) {
  const blob = await pdf(<PassportPDF {...props} />).toBlob();
  const url = URL.createObjectURL(blob);

  window.open(url);
}
