import React, { useContext, useState } from "react";

import { Popover } from "@material-ui/core";
import { Loading } from "components/FullScreenLoading";
import { RemoveGroupMemberModal } from "components/PulsePage/Groups/GroupMembers/modals";
import KebabMenu from "components/ui/KebabMenu";
import UserPreview from "components/ui/UserPreview";
import ModalContext from "contexts/ModalContext";
import { useUserPreviewsBasedOnGroupInvitation } from "hooks/queries/useGroup";
import { GroupInvitationDto } from "shared/model";
import { WithRequired } from "shared/utilityTypes";

import GroupMemberData from "./GroupMemberData";
import { groupMemberBoxStyles as styles } from "./GroupMembers.styles";

type GroupMemberBoxProps = {
  invitation: WithRequired<GroupInvitationDto, "invitedCompany">;
};

export default function GroupMemberBox({ invitation }: GroupMemberBoxProps) {
  const { openModal } = useContext(ModalContext);

  const [anchorElement, setAnchorElement] = useState<Element | null>(null);

  const popoverOpen = Boolean(anchorElement);

  function handlePopoverOpenerClick(
    event: React.MouseEvent<Element, MouseEvent>
  ) {
    setAnchorElement(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorElement(null);
  }

  return (
    <>
      <div className={styles.card}>
        <GroupMemberData invitation={invitation} />
        <KebabMenu
          className={styles.kebabMenu}
          actions={[
            {
              text: "Remove",
              onClick: () => {
                openModal(
                  <RemoveGroupMemberModal invitationId={invitation.id} />,
                  {
                    keepHistory: true,
                  }
                );
              },
            },
            {
              text: "Users",
              disableCloseAfterAction: true,
              onClick: (e) => {
                handlePopoverOpenerClick(e);
              },
            },
          ]}
        />
      </div>
      <Popover
        open={popoverOpen}
        anchorEl={anchorElement}
        getContentAnchorEl={null}
        onClose={handlePopoverClose}
        classes={{
          paper: styles.usersPreviewPaper,
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <UserPreviews companyId={invitation.invitedCompany.id} />
      </Popover>
    </>
  );
}

function UserPreviews({ companyId }: { companyId: string }) {
  const { data: users, isLoading } =
    useUserPreviewsBasedOnGroupInvitation(companyId);

  return (
    <div className={styles.usersPreviewContainer}>
      {isLoading && <Loading isWindowed />}
      {(users || []).map((user) => (
        <UserPreview
          className={styles.userPreview}
          key={`${user.firstName} ${user.lastName}`}
          name={`${user.firstName} ${user.lastName}`}
          role={user.jobTitle}
          avatar={user.profileImage}
        />
      ))}
    </div>
  );
}
