import React from "react";

import { GroupDto } from "@pulsemarket/api-client";
import { ButtonProps } from "components/ui/Button";
import Card from "components/ui/Card";
import { KebabMenuAction } from "components/ui/KebabMenu";
import { DEFAULT_COMPANY_AVATAR } from "shared/constants/icons";
import { Identifiable } from "shared/model";

import styles from "./GroupCardStyles";
export type GroupCardProps = {
  group: GroupDto;
  buttons?: (ButtonProps & Identifiable)[];
  kebabMenuActions?: KebabMenuAction[];
};

const GroupCard = ({ group, buttons, kebabMenuActions }: GroupCardProps) => {
  return (
    <Card
      defaultAvatar={DEFAULT_COMPANY_AVATAR}
      avatar={group.avatarBase64}
      backgroundImage={group.backgroundImageBase64}
      title={group.name}
      buttons={buttons}
      kebabMenuActions={kebabMenuActions}
    >
      <div className={styles.memberCount}>{`${group.membersCount} member${
        group.membersCount === 1 ? "" : "s"
      }`}</div>
    </Card>
  );
};
export default GroupCard;
