import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import {
  AddYouTubeVideoInput,
  UpdateYouTubeVideoInput,
} from "@pulsemarket/api-client";
import {
  deletePostVideo,
  updatePostVideo,
  uploadVideoToPost,
} from "shared/client";

type PostMediaItemHookParams = {
  groupId: string;
};

export function useCreatePostVideo({ groupId }: PostMediaItemHookParams) {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      postId,
      name,
      reference,
    }: AddYouTubeVideoInput & { postId: string }) => {
      return uploadVideoToPost(postId, { name, reference });
    },
    {
      mutationKey: mutationKeys.createPostVideo,
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.posts(groupId, {}).slice(0, 2));
      },
    }
  );
}

export function useUpdatePostVideo({ groupId }: PostMediaItemHookParams) {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      mediaItemId,
      name,
    }: UpdateYouTubeVideoInput & { mediaItemId: string }) => {
      return updatePostVideo(mediaItemId, { name });
    },
    {
      mutationKey: mutationKeys.updatePostVideo,
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.posts(groupId, {}).slice(0, 2));
      },
    }
  );
}

export function useDeletePostVideo({ groupId }: PostMediaItemHookParams) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ mediaItemId }: { mediaItemId: string }) => {
      return deletePostVideo(mediaItemId);
    },
    {
      mutationKey: mutationKeys.deletePostVideo,
      onSettled: () => {
        queryClient.invalidateQueries(queryKeys.posts(groupId, {}).slice(0, 2));
      },
    }
  );
}
