import { useContext } from "react";

import ConfirmationModalBody from "components/ConfirmationModalBody";
import { GroupData } from "components/PulsePage/Groups/createGroup/CreateGroupModal";
import ModalContext from "contexts/ModalContext";
import useDeleteGroup from "hooks/queries/useDeleteGroup";

import { GroupSettingsRow } from "./GroupSettingsRow";

type Props = {
  groupId?: string;
  onChange: (value: boolean, fieldName: keyof GroupData) => void;
  values: GroupData;
  onDelete?: () => void;
};

const GroupSettings = ({ groupId, onChange, values, onDelete }: Props) => {
  const { openModal } = useContext(ModalContext);

  const {
    isVisible,
    isPrivate,
    onlyAdminCanCreateContent,
    postsRequireApproval,
    allowMemberInvitations,
    filesRequireApproval,
  } = values;
  return (
    <>
      <GroupSettingsRow
        title="Choose Privacy"
        items={[
          {
            subtitle: "Group Privacy",
            description: "Admin permission required for access",
            value: isPrivate,
            onChange: (e) => {
              onChange(e, "isPrivate");
            },
          },
        ]}
      />
      <GroupSettingsRow
        title="Visibility"
        items={[
          {
            subtitle: "Visible",
            description:
              "Anyone can find this group. By turning it on, people will be able to find this group on Discover group.",
            value: isVisible,
            onChange: (e) => {
              onChange(e, "isVisible");
            },
          },
        ]}
      />
      <GroupSettingsRow
        title="Invitations"
        items={[
          {
            subtitle: "Members",
            description: "Allow members to invite their connections",
            value: allowMemberInvitations,
            onChange: (e) => {
              onChange(e, "allowMemberInvitations");
            },
          },
        ]}
      />
      {groupId && (
        <GroupSettingsRow
          title="Posts and Files"
          items={[
            {
              subtitle: "Only Admin",
              description: "Only admin can create a post or share files",
              value: onlyAdminCanCreateContent,
              onChange: (e) => {
                onChange(e, "onlyAdminCanCreateContent");
              },
            },
            {
              subtitle: "Approved Post",
              description:
                "The admin has to approve the Post made by any members",
              value: postsRequireApproval,
              onChange: (e) => {
                onChange(e, "postsRequireApproval");
              },
            },
            {
              subtitle: "Approved Files",
              description:
                "The admin has to approve the files and videos uploaded (without a post) by other members",
              value: filesRequireApproval,
              onChange: (e) => {
                onChange(e, "filesRequireApproval");
              },
            },
          ]}
        />
      )}

      {groupId && (
        <GroupSettingsRow
          title="Delete group"
          items={[
            {
              description:
                "If you still wish to delete the group, we recommend informing group members that you plan to delete the group. The group will no longer be accessible after deletion.",
              value: isVisible,
              buttonTitle: "Delete group",
              onClick: () =>
                openModal(
                  <DeleteGroupModal
                    groupId={groupId}
                    onDeleteGroup={onDelete}
                  />
                ),
            },
          ]}
        />
      )}
    </>
  );
};

export default GroupSettings;

function DeleteGroupModal({
  groupId,
  onDeleteGroup = () => {},
}: {
  groupId: string;
  onDeleteGroup?: () => void;
}) {
  const { mutateAsync: deleteGroup } = useDeleteGroup();
  const { closeModal } = useContext(ModalContext);

  return (
    <ConfirmationModalBody
      title="Delete Group"
      buttonText="Yes, delete"
      action={async () => {
        try {
          await deleteGroup(groupId);
          onDeleteGroup();
        } catch (err) {
          console.error("Authentication Erro");
          console.log(err);
        }
      }}
      onClose={closeModal}
    >
      Are you sure you want to delete this group? Deletion is a permanent and
      irreversible action.
    </ConfirmationModalBody>
  );
}
