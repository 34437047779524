import React, { useCallback, useContext, useEffect } from "react";

import { Loading } from "components/FullScreenLoading";
import ModalHeader from "components/ui/ModalHeader";
import MultiSelectList from "components/ui/MultiSelectList";
import CreateList from "components/vendorlists/CreateList";
import NoList from "components/vendorlists/NoList";
import ModalContext from "contexts/ModalContext";
import useAddCompanyToList from "hooks/queries/useAddCompanyToList";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { CompanyDetailsDto, Pair } from "shared/model";
import hasSameValues from "shared/utils/hasSameValues";

import styles from "./AddToListStyles";

export interface AddToListProps {
  company: Pair;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export default function AddToList({
  company,
  onSuccess = () => {},
  onCancel = () => {},
}: AddToListProps) {
  const { openModal, closeModal } = useContext(ModalContext);
  const { data: ownCompany, isFetching: companyLoading } = useOwnCompany();

  const { isLoading: addingInprogress, mutateAsync: addCompanyToList } =
    useAddCompanyToList();

  const isLoading = companyLoading || addingInprogress;

  useEffect(() => {
    if (ownCompany!.companyLists.length === 0) {
      openModal(
        <NoList
          company={company}
          onSuccess={() => {
            openModal(
              <AddToList
                company={company}
                onSuccess={onSuccess}
                onCancel={onCancel}
              />
            );
          }}
          onCancel={closeModal}
        />
      );
    }
  });

  async function addToList(selectedIds: string[]) {
    const oldsIds = getSelectedIds(ownCompany!, company.id);
    const newIds = selectedIds.filter((id) => !oldsIds.includes(id));
    newIds.forEach((listId) =>
      addCompanyToList({ listId, companyId: company.id })
    );
    onSuccess();
  }
  function openCreateList() {
    openModal(
      <CreateList
        onSuccess={() =>
          openModal(
            <AddToList
              company={company}
              onSuccess={onSuccess}
              onCancel={onCancel}
            />
          )
        }
        onCancel={onCancel}
      />
    );
  }

  const getSelectedIds = useCallback<
    (company: CompanyDetailsDto, companyIdMatch: string) => string[]
  >(
    (company, companyIdMatch) =>
      company.companyLists
        .filter((item) => item.companyIds.includes(companyIdMatch))
        .map((item) => item.id),
    []
  );

  const getOptionsPairs = useCallback<(company: CompanyDetailsDto) => Pair[]>(
    (company) =>
      company.companyLists.map((item) => ({
        name: item.name,
        id: item.id,
      })),
    []
  );

  const calculatedSelectedIds = getSelectedIds(ownCompany!, company.id);

  return (
    <div className={styles.AddToList}>
      <ModalHeader
        title={`Add ${company.name} to My Lists`}
        onClose={closeModal}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <MultiSelectList
          disableUncheck
          placeholder={"Select List"}
          options={getOptionsPairs(ownCompany!)}
          selectedIds={calculatedSelectedIds}
          actions={[
            {
              label: "Add to List(s)",
              color: "orange",
              disabled: (selectedIds) =>
                hasSameValues(selectedIds, calculatedSelectedIds),
              onClick: (selectedItems: Pair[]) =>
                addToList(selectedItems.map((item) => item.id)),
            },
            {
              label: "Create List",
              color: "purple",
              onClick: (_selectedItems: Pair[]) => openCreateList(),
            },
          ]}
        />
      )}
    </div>
  );
}
