import { useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import useUserData from "hooks/queries/useUserData";
import { renameCompanyList } from "shared/client";
import { CompanyDetailsDto, CompanyListDto } from "shared/model";

type RenameParams = { listId: string; newName: string };

export default function useRenameCompanyList() {
  const client = useQueryClient();

  const { data: userData } = useUserData();

  return useMutation(
    ({ listId, newName }: RenameParams) => {
      return renameCompanyList(userData!.companyId, listId, newName);
    },
    {
      mutationKey: mutationKeys.renameCompanyList,
      // When mutate is called:
      onMutate: async ({ listId, newName }: RenameParams) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await client.cancelQueries(queryKeys.ownCompany);
        // Snapshot the previous values
        const previousOrganization = client.getQueryData<CompanyDetailsDto>(
          queryKeys.ownCompany
        );
        const previousList = client.getQueryData<CompanyListDto>(
          queryKeys.companyList(listId)
        );
        // Optimistically update to the new value
        if (previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(queryKeys.ownCompany, {
            ...previousOrganization,
            companyLists: previousOrganization.companyLists.map((list) =>
              list.id === listId ? { ...list, name: newName } : list
            ),
          });
        }
        if (previousList) {
          client.setQueryData<CompanyListDto>(queryKeys.companyList(listId), {
            ...previousList,
            name: newName,
          });
        }
        return { previousOrganization, previousList };
      },
      // If the mutation fails, use the context returned from onMutate to roll back
      onError: (err, variables, context) => {
        if (context?.previousOrganization) {
          client.setQueryData<CompanyDetailsDto>(
            queryKeys.ownCompany,
            context.previousOrganization
          );
        }
        if (context?.previousList) {
          client.setQueryData<CompanyListDto>(
            queryKeys.companyList(variables.listId),
            context.previousList
          );
        }
      },
      // Always refetch after error or success:
      onSettled: (data, error, variables) => {
        client.invalidateQueries(queryKeys.ownCompany);
        client.invalidateQueries(queryKeys.companyList(variables.listId));
      },
    }
  );
}
