import { primaryColors, utilityColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  tagContainer: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: primaryColors.WHITE,
    borderRadius: "8px",
    flex: 1,
  },
  title: {
    ...typography.HEADLINE6,
    paddingTop: "18px",
    marginBottom: "24px",
  },
  groupIcon: {
    width: "54.03px",
    height: "58.25px",
    color: utilityColors.CYAN,
  },
  tag: {
    ...typography.BODY2,
    color: utilityColors.ORANGE,
    maxWidth: "90%",
    textAlign: "center",
    padding: "6px",
  },
  addButton: {
    height: "fit-content",
  },
});

export default styles;
