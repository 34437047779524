import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/ui/Button";
import PasswordInput from "components/ui/PasswordInput";
import { useFormik } from "formik";
import { useResetPassword } from "hooks/queries/useAuth";
import { validatePasswordStrength } from "shared/validators";

import { styles } from "../AuthFormLayout/AuthForm.styles";

//TODO: Extract to a separate file

export default function ResetPasswordForm({ token }: { token: string }) {
  const { mutateAsync: resetPassword } = useResetPassword();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validateOnChange: false,
    validate(values) {
      const errors: { password: string; confirmPassword: string } = {
        password: "",
        confirmPassword: "",
      };
      const passwordStrengthError = validatePasswordStrength(values.password);
      if (passwordStrengthError) {
        errors.password = passwordStrengthError;
      }
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
      if (errors.password || errors.confirmPassword) {
        return errors;
      }
    },
    onSubmit: async (values) => {
      formik.validateForm();
      if (!token) return console.log("No token");
      try {
        await resetPassword({
          password: values.password,
          token: token,
        });
        history.push("/");
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className={styles.root}>
        <h2 className={styles.title}>Change password</h2>
        <p className={styles.subtitle}>Set up new password </p>
        <PasswordInput
          className={styles.input}
          label="New password"
          autoComplete="new-password"
          required
          value={formik.values.password}
          onChange={formik.handleChange("password")}
          error={!!formik.errors.password}
          helperText={formik.errors.password}
        />
        <PasswordInput
          label="Confirm new password"
          autoComplete="new-password"
          className={styles.input}
          required
          value={formik.values.confirmPassword}
          onChange={formik.handleChange("confirmPassword")}
          error={!!formik.errors.confirmPassword}
          helperText={formik.errors.confirmPassword}
        />
      </div>

      <Button
        variant="normal"
        color="orange"
        fullWidth
        disabled={!formik.dirty}
        onClick={() => {
          formik.handleSubmit();
        }}
      >
        Reset password
      </Button>
    </form>
  );
}
