import React, { useContext, useMemo, useState } from "react";

import { FeatureFlagEnum } from "@pulsemarket/constants";
import AttachmentUploader from "components/assets/AttachmentUploader";
import Attachments from "components/ui/Attachments";
import AvatarIcon from "components/ui/Avatar";
import Button from "components/ui/Button";
import IconButton from "components/ui/IconButton";
import InsertedImage from "components/ui/InsertedImage";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody";
import TextArea from "components/ui/TextArea";
import PostVideoManager from "components/VideoManager/PostVideoManager";
import ModalContext from "contexts/ModalContext";
import useCreatePost, {
  useCloneAssetsToPost,
  useDeletePostAttachment,
  useUploadPostAttachment,
} from "hooks/queries/useCreatePost";
import useDeletePost from "hooks/queries/useDeletePost";
import useDownloadAssets from "hooks/queries/useDownloadAssets";
import useOwnCompany from "hooks/queries/useOwnCompany";
import { useUpdatePost } from "hooks/queries/useUpdatePost";
import useFeatureEnabled from "hooks/useFeatureEnabled";
import PostYouTubeVideo from "pages/PulseGroup/Post/EditableYouTubeVideo";
import { DeleteVideo } from "pages/PulseGroup/Post/PostCard/modals";
import { DEFAULT_COMPANY_AVATAR, FILE_ICONS } from "shared/constants/icons";
import { PostWithReferencesDto } from "shared/model";
import { debounce } from "shared/utils";
import { classes } from "typestyle";

import commentStyles from "../Comment/Comment.styles";
import { sharedPostStyles } from "../shared.styles";
import { styles } from "./PostCard.styles";

export type EditablePostCardProps = {
  groupId: string;
  post?: PostWithReferencesDto;
  onEditFinished: () => void;
  showPostSentToApproval?: boolean;
};

type AttachmentType = "image" | "video" | "file";

function isAttachmentTypeDisabled(
  attachmentType: AttachmentType,
  post?: PostWithReferencesDto
): boolean {
  if (!post) return false;
  if (attachmentType !== "image" && post.insertedMedia?.length) return true;
  if (attachmentType !== "video" && post.mediaItems?.length) return true;
  if (attachmentType !== "file" && post.attachments?.length) return true;
  return false;
}

export default function EditablePostCard({
  groupId,
  post,
  onEditFinished,
  showPostSentToApproval,
}: EditablePostCardProps) {
  const { openModal, closeModal } = useContext(ModalContext);
  const { data: ownCompany } = useOwnCompany();
  const { data: attachmentsEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.postAttachments,
  });
  const { data: videosEnabled } = useFeatureEnabled({
    featureName: FeatureFlagEnum.postVideos,
  });

  const [postText, setPostText] = useState<string>(post?.text || "");

  const { mutateAsync: createPost } = useCreatePost();
  const { mutateAsync: updatePost } = useUpdatePost();
  const { mutateAsync: deletePost } = useDeletePost();
  const { mutateAsync: uploadAttachment } = useUploadPostAttachment({
    groupId,
  });
  const { mutateAsync: cloneAttachments } = useCloneAssetsToPost({
    groupId,
  });
  const { mutateAsync: deleteAttachment } = useDeletePostAttachment({
    groupId,
  });

  const { downloadAssets } = useDownloadAssets();

  const debouncedCreatePost = useMemo(
    () => debounce(500, createPost),
    [createPost]
  );
  const debouncedUpdatePost = useMemo(
    () => debounce(500, updatePost),
    [updatePost]
  );

  function handlePostTextChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (event.target.value === postText) {
      return;
    }

    setPostText(event.target.value);
    if (post) {
      debouncedUpdatePost({
        groupId,
        postId: post.id,
        post: { text: event.target.value },
      });
    } else {
      debouncedCreatePost({
        groupId,
        text: event.target.value,
        isDraft: true,
      });
    }
  }

  async function handleSubmitPost() {
    try {
      if (!post) {
        await createPost({ groupId, text: postText, isDraft: false });
      } else {
        await updatePost({
          groupId,
          postId: post.id,
          post: { text: postText, isDraft: false },
        });
      }
      setPostText("");
      if (showPostSentToApproval) {
        openModal(
          <SimpleModalBody
            title="Post sent to approval"
            buttons={[{ variant: "normal", color: "orange", children: "Okay" }]}
          >
            Your post was sent to approval. Once an admin approves it, you'll
            receive a notification and the post will appear in the list below.
          </SimpleModalBody>
        );
      }
    } catch (_e) {}
  }

  async function ensurePostCreated() {
    if (!!post) {
      return post;
    }

    try {
      return createPost({ groupId: groupId, text: postText, isDraft: true });
    } catch (_e) {}
  }

  async function handleDiscardPost(postId: string) {
    await deletePost({ groupId, postId });
    setPostText("");
  }

  const hasContent =
    !!postText.length ||
    !!post?.attachments?.length ||
    !!post?.insertedMedia?.length ||
    !!post?.mediaItems?.length;

  return (
    <div className={styles.editableMainContainer}>
      <div className={styles.avatarContainer}>
        <AvatarIcon
          src={
            post?.company.avatar || ownCompany?.avatar || DEFAULT_COMPANY_AVATAR
          }
          className={styles.companyAvatar}
          size="40"
        />
      </div>
      <div className={styles.contentBox}>
        <TextArea
          placeholder="Start a post in this group"
          value={postText}
          onChange={(e) => {
            e.stopPropagation();
            handlePostTextChange(e);
          }}
          helperTextExpectedLines={0}
          name="newComment"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmitPost();
            }
          }}
        />

        <div className={sharedPostStyles.buttonsRow}>
          <div className={styles.attachmentIconsContainer}>
            {videosEnabled && (
              <IconButton
                disabled={isAttachmentTypeDisabled("video", post)}
                tooltip="Add video"
                src={FILE_ICONS.video.src}
                color="orange"
                iconClassname={styles.iconButton}
                onClick={async () => {
                  const post = await ensurePostCreated();
                  if (!post) {
                    return;
                  }
                  openModal(
                    <PostVideoManager
                      groupId={groupId}
                      postId={post.id}
                      onClose={closeModal}
                    />
                  );
                }}
              />
            )}

            {attachmentsEnabled && (
              <>
                <IconButton
                  disabled={isAttachmentTypeDisabled("image", post)}
                  tooltip="Upload image"
                  src={FILE_ICONS.image.src}
                  color="orange"
                  iconClassname={styles.iconButton}
                  onClick={async () => {
                    const post = await ensurePostCreated();

                    if (!post) {
                      return;
                    }

                    openModal(
                      <AttachmentUploader
                        text="You can insert image from the asset repository or from your desktop."
                        title="Insert image"
                        cloneButtonText="Insert from assets"
                        uploadConfig={{
                          allowedFileTypes: "image/*",
                          allowMultiple: false,
                        }}
                        fileUploaderProps={{
                          destination: "Post",
                        }}
                        onCloneAssets={async (assetIds) => {
                          await cloneAttachments({
                            assetIds,
                            postId: post.id,
                            mode: "insert",
                          });
                        }}
                        onUploadAsset={async (assets) => {
                          await uploadAttachment({
                            files: assets,
                            mode: "insert",
                            postId: post.id,
                          });
                        }}
                      />
                    );
                  }}
                />
                <IconButton
                  disabled={isAttachmentTypeDisabled("file", post)}
                  tooltip="Upload document"
                  src="/icons/attach_file_icon.svg"
                  color="orange"
                  iconClassname={styles.iconButton}
                  onClick={async () => {
                    const post = await ensurePostCreated();

                    if (!post) {
                      return;
                    }

                    openModal(
                      <AttachmentUploader
                        fileUploaderProps={{
                          destination: "Post",
                        }}
                        uploadConfig={{
                          allowMultiple: false,
                        }}
                        onCloneAssets={async (assetIds) => {
                          await cloneAttachments({
                            assetIds,
                            postId: post.id,
                            mode: "attachment",
                          });
                        }}
                        onUploadAsset={async (assets) => {
                          await uploadAttachment({
                            files: assets,
                            mode: "attachment",
                            postId: post.id,
                          });
                        }}
                      />
                    );
                  }}
                />
              </>
            )}
            {/* <SvgIcon
            src="/icons/events_icon.svg"
            onClick={() => {
              //TODO: open event create modal
            }}
            className={styles.iconButton}
          /> */}
          </div>
        </div>

        {!!post?.insertedMedia?.length && (
          <div className={styles.attachmentsContainer}>
            {post?.insertedMedia?.map((image) => {
              return (
                <InsertedImage
                  key={image.id}
                  image={image}
                  onDelete={() => deleteAttachment({ assetId: image.id })}
                />
              );
            })}
          </div>
        )}
        <Attachments
          className={styles.attachmentsContainer}
          attachments={post?.attachments}
          onDelete={(attachment) => {
            deleteAttachment({ assetId: attachment.id });
          }}
          onDownload={(attachment) => {
            downloadAssets({ assetIds: [attachment.id] });
          }}
        />
        {!!post?.mediaItems.length &&
          post.mediaItems.map((item) => (
            <PostYouTubeVideo
              key={item.id}
              item={item}
              onDelete={() =>
                openModal(<DeleteVideo groupId={groupId} videoId={item.id} />)
              }
              onEdit={() =>
                openModal(
                  <PostVideoManager
                    onClose={closeModal}
                    video={item}
                    groupId={groupId}
                    postId={post.id}
                  />
                )
              }
            />
          ))}
        {!!post && !post.isDraft ? (
          <div className={styles.editIconsContainer}>
            <IconButton
              tooltip="Cancel"
              color="black"
              src="/icons/close_icon.svg"
              disabled={!hasContent}
              iconClassname={commentStyles.closeIcon}
              onClick={() => {
                onEditFinished();
              }}
            />
            <IconButton
              tooltip="Done"
              color="black"
              src="/icons/check_icon.svg"
              iconClassname={commentStyles.checkIcon}
              onClick={() => {
                updatePost({
                  groupId: groupId,
                  post: { text: postText },
                  postId: post.id,
                });
                onEditFinished();
              }}
            />
          </div>
        ) : (
          <div className={classes(styles.buttonsContainer)}>
            {post && post.isDraft && (
              <Button
                variant="normal"
                color="gray"
                onClick={() => handleDiscardPost(post.id)}
              >
                Discard
              </Button>
            )}
            <Button
              variant="normal"
              color="orange"
              disabled={!hasContent}
              onClick={handleSubmitPost}
            >
              Post it!
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
