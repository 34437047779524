import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

export default stylesheet({
  root: {
    ...typography.BODY1,
  },
  list: {
    width: "100%",
    backgroundColor: neutralColors.GRAY0,
    padding: "8px",
    maxHeight: "250px",
    overflowY: "auto",
    cursor: "default",
  },
  listItem: {
    width: "100%",
    $nest: {
      '&:hover, &[data-focus="true"], &[aria-selected="true"]': {
        backgroundColor: primaryColors.HOVER,
      },
      '&.MuiAutocomplete-option[aria-disabled="true"]': {
        opacity: "initial",
        color: neutralColors.GRAY3,
      },
    },
  },
  optionText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  disabledReason: {
    ...typography.CAPTION3,
  },
  arrowIcon: {
    width: "12px",
    height: "12px",
    color: neutralColors.GRAY5,
  },
  inputRoot: {
    backgroundColor: primaryColors.WHITE,
    paddingRight: "9px!important",
    height: "100%",
    cursor: "pointer",
  },
  input: {
    height: "100%",
    padding: "0!important",
    paddingLeft: "6px!important",
    cursor: "pointer",
  },
  disabled: {},
  disabledInput: {
    $nest: {
      "&&": {
        backgroundColor: "unset",
      },
    },
  },
  informationIconWrapper: {
    marginLeft: "12px",
    width: "18px",
    height: "18px",
  },
  informationIcon: {
    width: "18px",
    height: "18px",
  },
  iconWrapper: {
    maxHeight: "100%",
    maxWidth: "16px",
    marginRight: "8px",
  },
  selectedOptionIconWrapper: {
    maxWidth: "16px",
    marginRight: "8px",
  },
});
