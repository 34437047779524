import React, { useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Loading } from "components/FullScreenLoading";
import { useGroupContext } from "contexts/Group/GroupProvider";
import useGetPosts from "hooks/queries/useGetPosts";
import EmptyPostsCard from "pages/PulseGroup/GroupPageCards/EmptyPostsCard";
import PostCard from "pages/PulseGroup/Post/PostCard";
import { PostWithReferencesDto } from "shared/model";

import { styles } from "./PostsContainerStyles";

type PostContainerProps = {
  groupId: string;
  postsRequireApproval?: boolean;
  isApproved: boolean;
  isGroupOwner?: boolean;
};

function PostsContainer({
  groupId,
  isApproved,
  isGroupOwner,
  postsRequireApproval,
}: PostContainerProps) {
  const { group } = useGroupContext();

  const {
    data: pagedPosts,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetPosts({ groupId, isApproved, isDraft: false });

  const posts = useMemo<PostWithReferencesDto[]>(
    () => (pagedPosts?.pages || []).flatMap((p) => p.posts),
    [pagedPosts]
  );
  const length = posts.length;

  const [infiniteRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
  });

  if (!group) {
    return null;
  }

  return length ? (
    <div className={styles.postsContainer}>
      {posts.map((post) => (
        <PostCard
          key={post.id}
          post={post}
          groupId={groupId}
          isGroupOwner={isGroupOwner}
          postsRequireApproval={postsRequireApproval}
        />
      ))}
      {hasNextPage && (
        <div ref={infiniteRef}>
          <Loading isWindowed />
        </div>
      )}
    </div>
  ) : group.onlyAdminCanCreateContent ? null : (
    <EmptyPostsCard isPendingView={!isApproved} />
  );
}

export default PostsContainer;
