export const fontWeight = {
  /** 700 */
  BOLD: 700,
  /** 600 */
  SEMI_BOLD: 600,
  /** 400 */
  REGULAR: 400,
  /** 300 */
  LIGHT: 300,
};

export const typography = {
  HEADLINE1: {
    fontSize: "6.3125rem",
    fontWeight: fontWeight.LIGHT,
    letterSpacing: "-1.5px",
    lineHeight: "1.255",
  },
  HEADLINE2: {
    fontSize: "3.9375rem",
    fontWeight: fontWeight.LIGHT,
    letterSpacing: "-0.5px",
    lineHeight: "1.255",
  },
  HEADLINE3: {
    fontSize: "3.125rem",
    fontWeight: fontWeight.REGULAR,
    letterSpacing: "-0.5px",
    lineHeight: "1.255",
  },
  HEADLINE4: {
    fontSize: "2.25rem",
    fontWeight: fontWeight.SEMI_BOLD,
    letterSpacing: "-0.5px",
    lineHeight: "1.255",
  },
  HEADLINE5: {
    fontSize: "1.5625rem",
    fontWeight: fontWeight.SEMI_BOLD,
    letterSpacing: "-0.5px",
    lineHeight: "1.255",
  },
  HEADLINE6: {
    fontSize: "1.3125rem",
    fontWeight: fontWeight.SEMI_BOLD,
    letterSpacing: "0.15px",
    lineHeight: "1.255",
  },
  BODY1: {
    fontSize: "1.0625rem",
    fontWeight: fontWeight.REGULAR,
    letterSpacing: "0.5px",
    lineHeight: "1.255",
  },
  BODY2: {
    fontSize: "0.9375rem",
    fontWeight: fontWeight.REGULAR,
    letterSpacing: "0.5px",
    lineHeight: "1.255",
  },
  CAPTION: {
    fontSize: "0.8125rem",
    fontWeight: fontWeight.REGULAR,
    letterSpacing: "0.4px",
    lineHeight: "1.255",
  },
  CAPTION2: {
    fontSize: "0.6875rem",
    fontWeight: fontWeight.SEMI_BOLD,
    letterSpacing: "0.4px",
    lineHeight: "1.255",
  },
  CAPTION3: {
    fontSize: "0.6875rem",
    fontWeight: fontWeight.REGULAR,
    letterSpacing: "0.4px",
    lineHeight: "1.255",
  },
  CAPTION_SEMIBOLD: {
    fontSize: "0.8125rem",
    fontWeight: fontWeight.SEMI_BOLD,
    letterSpacing: "0.4px",
    lineHeight: "1.255",
  },
  TITLE1: {
    fontSize: "1.0625rem",
    fontWeight: fontWeight.BOLD,
    letterSpacing: "0.1px",
    lineHeight: "1.255",
  },
  TITLE2: {
    fontSize: "0.9375rem",
    fontWeight: fontWeight.BOLD,
    letterSpacing: "0.1px",
    lineHeight: "1.255",
  },
  SUBTITLE1: {
    fontSize: "1.0625rem",
    fontWeight: fontWeight.SEMI_BOLD,
    letterSpacing: "0.1px",
    lineHeight: "1.255",
  },
  SUBTITLE2: {
    fontSize: "0.9375rem",
    fontWeight: fontWeight.SEMI_BOLD,
    letterSpacing: "0.1px",
    lineHeight: "1.255",
  },
  BUTTON_MEDIUM: {
    fontSize: "0.9375rem",
    fontWeight: fontWeight.BOLD,
    lineHeight: "1.255",
    letterSpacing: "0.2px",
  },
  BUTTON_SMALL: {
    fontWeight: fontWeight.BOLD,
    fontSize: "0.8125rem",
    letterSpacing: "0.2px",
    lineHeight: "1.255",
  },
  BUTTON_LARGE: {
    fontSize: "1.0625rem",
    fontWeight: fontWeight.BOLD,
    letterSpacing: "0.5px",
    lineHeight: "1.255",
  },
  // example: https://www.figma.com/file/acH57GN2MTV9IYEgNbXzrw/Pulse-Library?node-id=9925%3A20080
  SMALL_TEXT: {
    fontSize: "0.5rem",
    fontWeight: fontWeight.REGULAR,
    letterSpacing: "0.4px",
    lineHeight: "1.255",
  },
};
