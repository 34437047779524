import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import useOwnCompany from "hooks/queries/useOwnCompany";
import { getAssetLabels } from "shared/client";

export default function useAssetLabels() {
  const { data: ownCompany } = useOwnCompany();

  return useQuery(queryKeys.assetLabels, () => getAssetLabels(ownCompany!.id), {
    enabled: !!ownCompany?.id,
  });
}
