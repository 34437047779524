import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  contentContainer: {
    ...typography.BODY2,
    color: primaryColors.BLACK,
    padding: 0,
    overflowY: "initial", //Allways show footer in modal
  },
  header: {
    marginLeft: 10,
  },
  multiTitle: {
    display: "flex",
    flexDirection: "row",
    marginRight: "44px",
    ...typography.HEADLINE6,
  },
  inactiveTitle: {
    color: neutralColors.GRAY4,
  },
  activeTitle: {
    color: primaryColors.BLACK,
  },
});

export default styles;
