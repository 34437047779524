import {
  neutralColors,
  primaryColors,
  utilityColors,
} from "@pulsemarket/constants";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    height: "fit-content",
    border: `1px solid ${neutralColors.GRAY3}`,
    backgroundColor: neutralColors.GRAY0,
    borderRadius: "8px",
    marginBottom: "32px",
  },
  backgroundImageContainer: {
    width: "100%",
    height: "227px",
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  },
  headerImage: {
    width: "100%",
    height: "227px",
    border: `3px solid ${primaryColors.WHITE}`,
    position: "relative",
    backgroundColor: neutralColors.GRAY0,
    overflow: "hidden",
    borderRadius: "8px",
  },
  cyanCloud: {
    position: "absolute",
    color: utilityColors.CYAN,
    top: -100,
    width: "100%",
    zIndex: 1,
  },
  grayCloud: {
    position: "absolute",
    color: neutralColors.GRAY1,
    top: -180,
    width: "100%",
  },
  basicInfoContainer: {
    position: "relative",
    height: "fit-content",
  },
  avatarContainer: {
    height: "202px",
    width: "202px",
    position: "absolute",
    top: "-90px",
    left: "55px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "35px 39px",
    justifyContent: "flex-end",
    zIndex: 0,
  },
  editButton: {
    width: "203px",
    margin: "0px 8px",
    padding: "0px",
  },
  button: {
    margin: "0px 8px",
  },
  nameAndHeadlineContainer: {
    paddingLeft: "55px",
  },
  companyName: {
    ...typography.HEADLINE4,
  },
  headline: {
    ...typography.SUBTITLE1,
    width: "100%",
  },
});

export const avatarStyles = stylesheet({
  avatarBox: {
    height: "202px",
    width: "202px",
    backgroundColor: neutralColors.GRAY1,
    position: "relative",
    border: `3px solid ${primaryColors.WHITE}`,
    borderRadius: "7px",
  },
  defaultAvatar: {
    width: "100%",
    height: "100%",
    padding: "15%",
    color: utilityColors.CYAN,
  },
  avatar: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "6px",
  },
});

export default styles;
