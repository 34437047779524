import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import useOwnCompany from "hooks/queries/useOwnCompany";
import useUserData from "hooks/queries/useUserData";
import { getUserCompanyRole } from "shared/client";
import { UserCompanyRole } from "shared/model";

export default function useRole() {
  const { data: userData } = useUserData();
  const { data: companyData } = useOwnCompany();

  return useQuery<
    UserCompanyRole,
    Error,
    UserCompanyRole,
    readonly ["role", string | undefined, string | undefined]
  >(
    queryKeys.role(companyData?.id, userData?.id),
    () => getUserCompanyRole(companyData!.id, userData!.id),
    {
      enabled: !!userData?.id && !!companyData?.id,
      ...longCachedQueryOptions,
    }
  );
}
