import React from "react";

import { DialogActions } from "@material-ui/core";
import Button from "components/ui/Button";
import { ButtonProps } from "components/ui/Button/Button";
import { classes } from "typestyle";

import styles from "./ModalActionsStyles";

export type ModalAction =
  | (ButtonProps & { disableCloseAfterCallback?: boolean })
  | React.ReactElement;

type ModalActionsProps = {
  actions?: ModalAction[];
  onClose?: () => void;
  className?: string;
};

export default function ModalActions({
  actions = [],
  onClose = () => {},
  className,
}: ModalActionsProps) {
  return (
    <DialogActions
      className={classes(styles.actionsContainer, className)}
      classes={{
        root: styles.actionsContainerRoot,
      }}
    >
      {actions.map((action, i) => {
        if (React.isValidElement(action)) {
          return React.cloneElement(action, { key: i });
        }

        const { ...buttonConfig } = action;

        return (
          /* @ts-expect-error TS 5 upgrade todo */
          <Button
            key={i}
            {...buttonConfig}
            onClick={(e) => {
              !("disableCloseAfterCallback" in action) && onClose();
              "onClick" in action && action.onClick?.(e);
            }}
          />
        );
      })}
    </DialogActions>
  );
}
