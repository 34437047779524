import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import mutationKeys from "reactQuery/mutationKeys";
import queryKeys from "reactQuery/queryKeys";

import { RFPType } from "@pulsemarket/constants";
import useRFPs from "hooks/queries/useRFPs";
import {
  getMyRecentPassports,
  getSentPassportInvitations,
  inviteToTakePassport,
} from "shared/client";
import {
  CombinedInvitationInput,
  PassportInvitationFilter,
} from "shared/model";

/**
 * - later it should accept a passportType param
 * - todo select the last updated passport document
 * - ESRS: Allow filtering to multiple passport types
 */
export function usePassportToRfpId() {
  return useRFPs({
    filters: { type: RFPType.Passport },
    select(passportDocuments) {
      return passportDocuments[passportDocuments.length - 1]?.id;
    },
  });
}

export function useOwnPassports({
  offset = 0,
  limit,
}: {
  offset?: number;
  limit?: number;
}) {
  return useInfiniteQuery(
    queryKeys.ownPassports,
    ({ pageParam: { limit, offset } = {} }) => {
      return getMyRecentPassports({ offset, limit });
    },
    {
      getNextPageParam(lastPage, allPages) {
        if (lastPage.length !== limit) {
          return undefined;
        }

        const calculatedOffset: number = allPages.reduce<number>(
          (sum, page) => sum + page.length,
          offset
        );

        return { offset: calculatedOffset, limit };
      },
    }
  );
}

export function useSentPassportInvitations({
  offset = 0,
  //TODO: create pagination UI
  limit = 100,
  ...filters
}: PassportInvitationFilter & {
  offset?: number;
  limit?: number;
}) {
  return useInfiniteQuery(
    queryKeys.sentPassportInvitations(filters),
    ({ pageParam: calculatedOffset = offset }) => {
      return getSentPassportInvitations({
        ...filters,
        offset: calculatedOffset,
        limit,
      });
    },
    {
      getNextPageParam(lastPage, allPages) {
        if (lastPage.length !== limit) {
          return null;
        }

        const fetchedCount = allPages.reduce(
          (sum, page) => sum + page.length,
          0
        );

        return fetchedCount;
      },
    }
  );
}

export function useInviteToTakePassport() {
  const { data: passportDocumentId } = usePassportToRfpId();
  const client = useQueryClient();

  return useMutation(
    ({ invitations }: { invitations: CombinedInvitationInput[] }) => {
      if (!passportDocumentId) {
        return Promise.reject("No passport document id");
      }
      return inviteToTakePassport(passportDocumentId, invitations);
    },
    {
      mutationKey: mutationKeys.inviteToTakePassport,
      onSettled: () => {
        client.invalidateQueries(queryKeys.sentPassportInvitations()[0]);
      },
    }
  );
}
