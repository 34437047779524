import { neutralColors, primaryColors } from "@pulsemarket/constants";
import { zeroPaddingMargin } from "shared/styles/classes";
import { maxLineNumber } from "shared/styles/styles";
import { typography } from "shared/styles/typography";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  root: {
    background: neutralColors.GRAY0,
    borderRadius: "8px",
    width: "435px",
    textAlign: "center",
    padding: "24px",
    $nest: {
      "&:hover": {
        background: primaryColors.WHITE,
        filter: "drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))",
      },
    },
  },
  logoContainer: {
    width: "97px",
    height: "85px",
    $nest: {
      "& img": {
        height: "100%",
        width: "100%",
      },
    },
  },
  title: {
    color: primaryColors.BLACK,
    ...typography.HEADLINE5,
    ...zeroPaddingMargin,
  },
  description: {
    color: neutralColors.GRAY5,
    ...typography.BODY2,
    height: "4.75rem", // 4 lines (76px/16)
    ...maxLineNumber(4),
    ...zeroPaddingMargin,
  },
});

export default styles;
