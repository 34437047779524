import React, { useContext, useState } from "react";

import { CommentDto } from "@pulsemarket/api-client";
import ConfirmationModalBody from "components/ConfirmationModalBody";
import Avatar from "components/ui/Avatar";
import Button from "components/ui/Button";
import IconButton from "components/ui/IconButton";
import KebabMenu from "components/ui/KebabMenu";
import TextArea from "components/ui/TextArea";
import ModalContext from "contexts/ModalContext";
import dayjs from "dayjs";
import {
  useAddComment,
  useDeleteComment,
  useUpdateComment,
} from "hooks/queries/commentHooks";
import useUserData from "hooks/queries/useUserData";
import { sharedPostStyles } from "pages/PulseGroup/Post/shared.styles";
import { DEFAULT_USER_AVATAR } from "shared/constants/icons";
import { humanizedDateInterval } from "shared/utils/time";
import { classes } from "typestyle";

import styles from "./Comment.styles";

type CommentProps = {
  comment?: CommentDto;
  groupId: string;
  postId: string;
  className?: string;
  isGroupOwner?: boolean;
};

export default function Comment({
  comment,
  postId,
  groupId,
  className,
  isGroupOwner,
}: CommentProps) {
  const [commentText, setCommentText] = useState<string>(comment?.text || "");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutateAsync: addComment } = useAddComment({ groupId });
  const { mutateAsync: updateComment } = useUpdateComment({ postId });
  const { data: userData } = useUserData();
  const { mutateAsync: deleteComment } = useDeleteComment({
    postId,
    groupId,
  });

  const now = dayjs(new Date());
  const commentDate = dayjs(comment?.createdAt);
  const commentDateDifference = humanizedDateInterval(now, commentDate);

  const { openModal, closeModal } = useContext(ModalContext);

  const userHasEditRights = !!comment && comment.user.id === userData?.id;
  const useHasDeleteRights = userHasEditRights || isGroupOwner;

  return (
    <div className={classes(styles.commentRow, className)}>
      <div className={styles.leftSide}>
        <Avatar
          src={
            comment
              ? comment?.user?.profileImage || DEFAULT_USER_AVATAR
              : userData?.profileImage || DEFAULT_USER_AVATAR
          }
          size="40"
          imageClassName={styles.userProfile}
        />

        {!comment || isEditing ? (
          <TextArea
            size="small"
            placeholder="Write a comment"
            name={comment?.id || "new-comment"}
            value={commentText}
            className={styles.commentInput}
            fullWidth={false}
            helperTextExpectedLines={0}
            onFocus={() => setIsEditing(true)}
            minRows={1}
            onChange={(e) => {
              e.stopPropagation();
              if (!isEditing) {
                setIsEditing(true);
              }
              setCommentText(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (comment) {
                  updateComment({
                    comment: { text: commentText },
                    commentId: comment.id,
                  });
                } else {
                  addComment({
                    referenceId: postId,
                    text: commentText,
                    referenceType: "post",
                  });
                }
                setCommentText("");
                setIsEditing(false);
              }
            }}
          />
        ) : (
          <div className={styles.nonEditableComment}>
            <div className={styles.commentAuthorRow}>
              <div className={styles.commentAuthorName}>
                {comment?.user.firstName} {comment?.user.lastName}
              </div>
              <div className={sharedPostStyles.postingDate}>
                from {comment.company.name} - {commentDateDifference}
              </div>
              {(userHasEditRights || useHasDeleteRights) && !isEditing && (
                <KebabMenu
                  className={sharedPostStyles.kebabMenu}
                  actions={[
                    ...(userHasEditRights
                      ? [
                          {
                            onClick: () => {
                              setIsEditing(!isEditing);
                            },
                            text: "Edit Comment",
                          },
                        ]
                      : []),
                    ...(useHasDeleteRights
                      ? [
                          {
                            onClick: () => {
                              openModal(
                                <ConfirmationModalBody
                                  title="Delete Comment"
                                  buttonText="Yes, delete"
                                  action={async () => {
                                    try {
                                      await deleteComment({
                                        commentId: comment.id,
                                      });
                                    } catch (err) {
                                      console.log(err);
                                    }
                                  }}
                                  onClose={closeModal}
                                >
                                  Are you sure you want to delete this comment?
                                </ConfirmationModalBody>
                              );
                            },
                            text: "Delete Comment",
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            </div>
            <p className={styles.commentText}>{comment?.text}</p>
          </div>
        )}
      </div>
      {!!comment
        ? isEditing && (
            <div className={styles.editButtons}>
              <IconButton
                tooltip="Cancel"
                src="/icons/close_icon.svg"
                size="small"
                className={classes(styles.closeIconButton)}
                iconClassname={styles.closeIcon}
                disabled={!commentText.length}
                onClick={() => setIsEditing(false)}
              />
              <IconButton
                tooltip="Done"
                src="/icons/check_icon.svg"
                size="small"
                color="orange"
                iconClassname={styles.checkIcon}
                disabled={!commentText.length || commentText === comment.text}
                onClick={() => {
                  updateComment({
                    comment: { text: commentText },
                    commentId: comment.id,
                  });
                  setIsEditing(false);
                }}
              />
            </div>
          )
        : isEditing && (
            <Button
              variant="normal"
              color="orange"
              className={styles.postCommentButton}
              disabled={!commentText.length}
              onClick={() => {
                addComment({
                  referenceId: postId,
                  text: commentText,
                  referenceType: "post",
                });
                setCommentText("");
                setIsEditing(false);
              }}
            >
              Post
            </Button>
          )}
    </div>
  );
}
