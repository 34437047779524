import { useInfiniteQuery, UseInfiniteQueryOptions } from "react-query";
import queryKeys from "reactQuery/queryKeys";

import { ApiError, getGroupPosts } from "shared/client";
import { PostsQueryResult } from "shared/model";

const BATCH_SIZE = 3;

type GetPostsParams = {
  groupId?: string;
  isApproved?: boolean;
  isDraft?: boolean;
  pageSize?: number;
};

type GetPostsHookParams<T> = Omit<
  UseInfiniteQueryOptions<
    PostsQueryResult,
    ApiError,
    T,
    PostsQueryResult,
    ReturnType<typeof queryKeys.posts>
  >,
  "getNextPageParam"
> &
  GetPostsParams;

export default function useGetPosts<T = PostsQueryResult>({
  groupId,
  isApproved,
  isDraft,
  pageSize = BATCH_SIZE,
  ...options
}: GetPostsHookParams<T>) {
  return useInfiniteQuery(
    queryKeys.posts(groupId, { isApproved, isDraft }),
    async ({ pageParam }) => {
      const response = await getGroupPosts(groupId!, {
        limit: pageSize,
        cursorId: pageParam,
        isApproved,
        isDraft,
      });

      return response;
    },
    {
      getNextPageParam(lastPage) {
        if (lastPage.posts.length === pageSize) {
          return lastPage.posts.slice().reverse()?.[0].id;
        }

        return null;
      },
      notifyOnChangeProps: "tracked",
      ...options,
      enabled: !!groupId && options.enabled,
    }
  );
}
