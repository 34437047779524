import { px } from "csx";
import { stylesheet } from "typestyle";

export const styles = stylesheet({
  avatarStyle: {
    left: "318px",
  },
  popupContainer: {
    display: "flex",
    flexDirection: "column",
  },
  borderLessContainer: {
    borderColor: "transparent",
  },
  buttonContainer: { display: "flex", justifyContent: "end", gap: px(25) },
});
