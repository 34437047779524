export class ApiError extends Error {
  constructor(public status: number, public message: string) {
    super(message);
    this.name = "ApiError";
    this.status = status;
  }
}

function mapErrorToMessage(error: string): string {
  return error;
}

export class UploadApiError extends ApiError {
  constructor(
    public status: number,
    public message: string,
    public fileName?: string,
    public errors?: (string | { errors: string[]; name: string })[]
  ) {
    super(status, message);
    this.name = "UploadApiError";
    this.fileName = fileName;
  }

  get errorMessages(): string[] | undefined {
    return this.errors?.map((error) => {
      if (typeof error === "string") {
        return mapErrorToMessage(error);
      }
      return `${error.name}: ${error.errors
        .map((fileError) => mapErrorToMessage(fileError))
        .join(", ")}`;
    });
  }
}
