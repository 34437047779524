import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import { getRoles } from "shared/client";
import { Role } from "shared/model";

export default function useRoles() {
  return useQuery<
    Role[],
    Error,
    { [roleId: string]: Role },
    readonly ["roles"]
  >(queryKeys.roles, () => getRoles(), {
    ...longCachedQueryOptions,
    select: (data) =>
      data.reduce((roles, role) => ({ ...roles, [role.id]: role }), {}),
  });
}
