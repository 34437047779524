import { classes } from "typestyle";

import styles from "./BlankCard.styles";

type Props = {
  className?: string;
  onClick: () => void;
  text?: string;
  ["data-cy"]?: string;
};

const BlankCard = ({ className, onClick, text, ...rest }: Props) => {
  return (
    <div
      className={classes(styles.root, className)}
      onClick={() => onClick()}
      {...rest}
    >
      {text}
    </div>
  );
};

export default BlankCard;
